import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Container, Skeleton, Card, Group, Text, Select, Button, Input, Space, Grid, Pagination, Table, Flex, Image, Modal, Anchor } from '@mantine/core';
import { IconHelp, IconSearch } from '@tabler/icons-react';
import '../Funnel/Funnel.css';
import { modals } from '@mantine/modals';
import { VisiLoader } from "../../components/GeneralComponent/GeneralComponent";
import TestsApi from "../../api/TestsApi";

export function ReviewTests() {

	const navigate = useNavigate();
	
	useDocumentTitle("Visiopt - Review Tests");

	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};
	const [loading, setLoading] = useState(false);
	const [SystemUsageLoader, setSystemUsageLoader] = useState(false);
	const [errorModelOpen, seterrorCloseModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showErrorHeader, setShowErrorHeader] = useState(false);
	const [showMessageHeader, setShowMessageHeader] = useState(false);
	const [selectedAllTestPageValue, setSelectedAllTestPageValue] = useState('0');
	const [activePageNo, setActivePageNo] = useState(1);
	const [keyword, setKeyword] = useState('');
	const [selectPageTests, setSelectPageTests] = useState([]);
	const [totalPages, setTotalPages] = useState(10);
	const [totalPaginationNo, setTotalPaginationNo] = useState(1);
	const [selectedThumbnail, setSelectedThumbnail] = useState(null);
	const [selectedTaguchiTestId, setSelectedTaguchiTestId] = useState(null);
	const [selectedFunnelId, setSelectedFunnelId] = useState(null);
	const [selectedTestsId, setSelectedTestsId] = useState(null);
	const [isThumbnailSelected, setIsThumbnailSelected] = useState(false);
  const [viewSelect, setViewSelect] = useState('grid');
  const [showNumber, setShowNumber] = useState(25);
  const [marksalestime, setMarksalestime] = useState(0);

	const AllTestPagesoptions = [
    { value: '0', label: 'All Pages' },
    { value: '1', label: 'Pages with Active Tests' },
    { value: '2', label: 'Pages with "Winner Set"' }
  ];

	const handleSelectAllTestPagesValueChange = (selectedAllTestPageOption) => {
    setSelectedAllTestPageValue(selectedAllTestPageOption);
    setActivePageNo(1);
  };

  useEffect(() => {
  	if(selectedMemberWebsite !== ''){
	    setLoading(true);
	    seterrorCloseModal(false);
	    TestsApi.getpageslist({
	      system_website_id: selectedMemberWebsite,
	      filter: selectedAllTestPageValue,
	      layout: viewSelect,
	      number: showNumber,
	      page: activePageNo,
	      keyword: keyword,
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          setSystemUsageLoader(false);
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	        if (data.data.test_pages_list) {
	          setSelectPageTests(data.data.test_pages_list);
	          if (data.data.active_page_no !== activePageNo) {
	            setActivePageNo(data.data.active_page_no);
	            setActivePageNo(1);
	          }
	          if (data.data.total_pages !== totalPages) {
	            setTotalPages(data.data.total_pages);
	          }
	          if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
	            setTotalPaginationNo(data.data.total_pagination_no);
	          }
	        } else{
	          console.log("No data received");
	          setSelectPageTests([]);
	        }
	      }else{
	        setSelectedAllTestPageValue('0');
	        setErrorMessage(data.message);
	        seterrorCloseModal(true);
	      }
	      setLoading(false);
	    })
	    .catch(error => {
	      console.error("API Error:", error);
	      setLoading(false);
	    });
	  }
  }, [selectedAllTestPageValue, activePageNo, selectedMemberWebsite, viewSelect, showNumber, keyword, marksalestime]);

  const handleThumbnailDoubleClick = async (selectedThumbnail,taguchi_test_id,funnel_id,tests_id) =>{	
  	setSelectedTaguchiTestId(taguchi_test_id);
    setSelectedFunnelId(funnel_id);
    try {
      const APIResponse = await TestsApi.checkpageinfunnelvalidation({
      	page_id: selectedThumbnail,
  			taguchi_test_id: taguchi_test_id,
        system_website_id : selectedMemberWebsite,
        funnel_id:funnel_id
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
      	navigate("/reviewing-test", { state: { pageId: selectedThumbnail, taguchiTestId: taguchi_test_id, funnelId:funnel_id, testsId:tests_id} });
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowMessageHeader(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleThumbnailClick = (pageId,taguchiTestId,funnelId,testsId) => {
    if (isThumbnailSelected && selectedThumbnail === pageId) {
      setSelectedThumbnail(null);
      setSelectedTaguchiTestId(null);
      setSelectedFunnelId(null);
      setSelectedTestsId(null);
      setIsThumbnailSelected(false);
    } else {
      setSelectedThumbnail(pageId);
      setSelectedTaguchiTestId(taguchiTestId);
      setSelectedFunnelId(funnelId);
      setSelectedTestsId(testsId);
      setIsThumbnailSelected(true);
    }
  };

  const handleSelectView = (selectedViewOption) => {
    setViewSelect(selectedViewOption);
  };

  const handleShowNumberChange = (selectedShowNumberOption) => {
    setShowNumber(selectedShowNumberOption);
    setActivePageNo(1);
  };

  const handlePageChange = (newPage) => {
    setActivePageNo(newPage);
  };

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
    setActivePageNo(1);
  };

  const handleAddtoFunnel = async () => {
  	try {
      const APIResponse = await TestsApi.checkpageinfunnelvalidation({
        page_id: selectedThumbnail,
  			taguchi_test_id: selectedTaguchiTestId,
        system_website_id : selectedMemberWebsite
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
      	navigate("/reviewing-test", { state: { pageId: selectedThumbnail, taguchiTestId: selectedTaguchiTestId, funnelId:selectedFunnelId, testsId:selectedTestsId} });
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowMessageHeader(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  }
  const listRows = selectPageTests.map((item) => (
    <tr key={item.page_id || ''} 
    		style={{ backgroundColor: (item.selected || selectedThumbnail === item.page_id) ? '#00b975' : '',
    						 transition: 'background-color 0.1s' 
    					}} 
				onClick={item.selected ? undefined : () => handleThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id)}
		>
      <td><a href={item.page_url} target="_blank" rel="noreferrer">Preview</a></td>
      <td><Text w={350} style={{ overflow: 'hidden' }}><Anchor href={item.page_url} target="_blank" color="inherit">{item.page_url}</Anchor></Text></td>
      <td><Text w={300} style={{ overflow: 'hidden' }}>{item.page_title}</Text></td>
      <td><Text>{item.uniques}</Text></td>
      <td><Text>{item.visits}</Text></td>
      <td><Text>{item.test_name}</Text></td>
      <td><Text>{item.test_type_name}</Text></td>
    </tr>
  ));

  const handleRemoveSetWinner = async (selectedThumbnail) => {
  	modals.openConfirmModal({
	    title: 'Unset Winner',
	    children: (
	      <Text size="sm">
	       Are you sure you want to remove "Set winner"?<br/>
	       If you do this winner variation will stop being served to visitors, instead they will start seeing the control variation of the page again.
	      </Text>
	    ),
	    labels: { confirm: 'Unset Winner', cancel: 'Cancel' },
	    confirmProps: { color: 'red' },
	    onCancel: () => console.log('Cancel'),
	    onConfirm: () => {
	      handleUnsetwinner(selectedThumbnail);
	    },
	  });
  }

  const handleUnsetwinner = async (selectedThumbnail) =>{
    try {
    	setSystemUsageLoader(true);
      const APIResponse = await TestsApi.unsetwinner({
        page_id: selectedThumbnail,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
       setMarksalestime(APIResultJSON.data.updatedtime);
      if (APIResultJSON.status === 1) {
      	setSystemUsageLoader(false);
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
        setSystemUsageLoader(false);
      }
    } catch (error) {
    	setSystemUsageLoader(false);
      console.error("API Error:", error);
    }
  };

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

	return (
	  <>
	  <VisiLoader VisiLoaderVisible={SystemUsageLoader}/>
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
	  <Container size="xl" mb={10}>
	  	<Card shadow="sm" padding="sm" radius="md" withBorder>
	  		<Group position="apart" mb="xs">
	        <Text weight={500}>REVIEW TESTS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
  	    <div style={{ display: 'flex'}}>
          <Text mt={5} mr={5}>Configured pages:</Text>
  	     	<Select 
  	     	  w={200}
  	     	  defaultValue="0"
  	     	  data={AllTestPagesoptions}
            onChange={(selectedAllTestPageOption) => handleSelectAllTestPagesValueChange(selectedAllTestPageOption)}
  	     	  searchable
  	     	  nothingFound="No options"
  	     	  maxDropdownHeight={300}
  	     	  dropdownPosition="bottom"
  	     	/>
  	     	<Text mt={5} ml={20} mr={5}>View:</Text>
  	     	<Select
	          onChange={handleSelectView}
	          defaultValue="grid"
	          value={viewSelect}
	          data={[
	            { value: 'grid', label: 'Grid' },
	            { value: 'list', label: 'List' }
	          ]}
	          w={80}
	        />
  	     	<Text mt={5} ml={20} mr={5}>Show: </Text>
  	     	<Select
  	     		onChange={handleShowNumberChange}
  	     		defaultValue="25"
	     	  	data={[
	     	      { value: '10', label: '10' },
	     	      { value: '25', label: '25' },
	     	      { value: '50', label: '50' },
	     	      { value: '100', label: '100' }
	     	    ]}
  	     	  w={80}
  	     	/>
	     	  <Flex
	     	  	style={{marginLeft:"auto"}}
	     	    justify="flex-end"
	     	    align="center"
	     	    direction="row"
	     	    wrap="wrap"
	     	  >
		     	  <Input icon={<IconSearch size={16} />} 
		     	  	placeholder="Search"
				     	maxLength={50}
				     	autoFocus
				     	onChange={handleKeywordChange}
		     	   />
	     	  </Flex>
  	    </div>
  	    <div style={{flex: "1 1 auto",overflowX: "hidden",marginTop:10}}>
  	    	<div style={{ display: viewSelect === "grid" ? "block" : "none"}}>
  	    		<Skeleton visible={loading}>
					  <Grid ml={0} mt={10} mb={10} >
					    {selectPageTests.map((item, index) => (
					      <div className="visi-page" key={index} style={{marginRight:10}} >
					        <div className={item.pageClass} 
					        			style={{ cursor: 'pointer', backgroundColor: (item.selected || selectedThumbnail === item.page_id) ? '#00b975' : '' }} 
				        				onClick={item.selected ? undefined : () => handleThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id)}
			        				>
					          <span title={item.page_url} onDoubleClick={item.selected || item.pageClass === "page winner" ? undefined : () => handleThumbnailDoubleClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id)}>
					            <span className="page-url">{item.page_title}</span>
					          </span>
					          <div className="thumbnail" style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.img_url})` }} title={item.page_url} onDoubleClick={item.selected || item.pageClass === "page winner" ? undefined : () => handleThumbnailDoubleClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id)}>
					            {(item.marge_img !== '' && (
		                      <Image
		                      id="merge_img"
		                      src={item.marge_img}
		                      title="This Page is merged with other links"
		                    />
		                    ))
		                  }
                      {(item.track_check !== '' && (
                        <Image
                          id="track_img"
                          src={item.track_check}
                          title={item.track_check_title}
                        />
                        ))
                      }
					          </div>
					          <span className="page-title">{item.custom_title}</span>
					        </div>
					      </div>
					    ))}
				  	</Grid>
				  	</Skeleton>
				  	{selectPageTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
					  <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
					    <Group spacing={5} position="center" mb={20}>
					      <Pagination.First />
					      <Pagination.Previous />
					      <Pagination.Items />
					      <Pagination.Next />
					      <Pagination.Last />
					    </Group>
					  </Pagination.Root>
					  )}
				  </div>

					  <div style={{ display: viewSelect === "list" ? "block" : "none"}}>
			  	    {selectPageTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
			  	    <>
					  	<Table striped highlightOnHover withBorder withColumnBorders mb={10} style={{cursor: 'pointer'}}>
		           <thead>
		             <tr>
		               <th>Preview</th>
		               <th>URL</th>
		               <th>Page Title</th>
		               <th>Uniques</th>
		               <th>Visits</th>
		               <th>Active Test name</th>
		               <th>Test Type</th>
		             </tr>
		           </thead>
		           <tbody>{listRows}</tbody>
			  	    </Table>
			  	    <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
			  	      <Group spacing={5} position="center" mb={20}>
			  	        <Pagination.First />
			  	        <Pagination.Previous />
			  	        <Pagination.Items />
			  	        <Pagination.Next />
			  	        <Pagination.Last />
			  	      </Group>
			  	    </Pagination.Root>
			  	    </>
			  	    )}
					  </div>
			  </div>
			  <div className="legend-holder">
				  <Grid>
		        <Grid.Col span={6} mt={15}>
		        	<Group spacing="xs" m={0}>
		        	  <span className="legend selected" />
		        	  <Text size="sm" ml={0} fz={12} className="legend-title">Selected</Text>
		        	  <span className="legend active" />
		        	  <Text size="sm" ml={0} fz={12} className="legend-title">Active Test</Text>
		        	  <span className="legend winner" />
		        	  <Text size="sm" ml={0} fz={12} className="legend-title">Winner Set</Text>
		        	</Group>
		        </Grid.Col>
		        <Grid.Col span={3} offset={3}>
		        	<div style={{ display: viewSelect === "grid" ? "block" : "none"}}>
			        	<div style={{ display: 'flex'}}>
		              <Space w="xs" />
		              {selectedThumbnail && selectPageTests.find(item => item.page_id === selectedThumbnail && item.pageClass === 'page winner') ? (
									  <>
									    <Text weight={500}>
									      <IconHelp size="1.1rem" color="red" style={{ marginTop: 11, marginLeft: 91 }} />
									    </Text>
									    <Button color="red" type="button" style={{ marginLeft: 7 }} onClick={() => handleRemoveSetWinner(selectedThumbnail)}>
									      Remove "Set Winner"
									    </Button>
									  </>
									) : (
									  <>
									    <Space w="xs" />
									    <Button type="button" style={{ marginLeft: "auto" }} onClick={() => handleAddtoFunnel()}>
									      Select
									    </Button>
									  </>
									)}
		            </div>
		          </div>
		          <div style={{ display: viewSelect === "list" ? "block" : "none"}}>
		          	<div style={{ display: 'flex'}}>
		          		<Space w="xs" />
		          		{selectedThumbnail && selectPageTests.find(item => item.page_id === selectedThumbnail && item.type === 'Winner') ? (
									  <>
									    <Text weight={500}>
									      <IconHelp size="1.1rem" color="red" style={{ marginTop: 11, marginLeft: 91 }} />
									    </Text>
									    <Button color="red" type="button" style={{ marginLeft: 7 }} onClick={() => handleRemoveSetWinner(selectedThumbnail)}>
									      Remove "Set Winner"
									    </Button>
									  </>
									) : (
									  <>
									    <Space w="xs" />
									    <Button type="button" style={{ marginLeft: "auto" }} onClick={() => handleAddtoFunnel()}>
									      Select
									    </Button>
									  </>
									)}
		          	</div>
		          </div>
		        </Grid.Col>
		      </Grid>
			  </div>
			  {/*Error Model*/}
		    {errorModelOpen && (
		    <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
		      <Modal.Overlay />
		      <Modal.Content>
				    {showMessageHeader ? (
		          <Modal.Header>
		            <Modal.Title>MESSAGE</Modal.Title>
		            <Modal.CloseButton />
		          </Modal.Header>
		        ) : showErrorHeader ? (
		          <Modal.Header>
		            <Modal.Title>ERROR</Modal.Title>
		            <Modal.CloseButton />
		          </Modal.Header>
		        ) : (
		          <Modal.Header>
		          </Modal.Header>
		        )}
		        <Modal.Body>
		          <form>
		            <div style={{ display: 'flex'}}>
		                <Text weight={500}>{errorMessage}</Text>
		            </div>
		          </form>
		        </Modal.Body>
		      </Modal.Content>
		    </Modal.Root>
		    )}
			</Card>
	  </Container>
	  </>
	);
}