import React, { useState, useRef } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { LoadingOverlay, Skeleton, Container, Card, Group, Text, Select, ActionIcon, CopyButton, Button, Menu, Modal, Input, Textarea, Anchor, Space, Grid, Pagination, Table, Flex, Kbd, TextInput, Image } from '@mantine/core';
import { IconSettings, IconHelp, IconSearch, IconX, IconCheck } from '@tabler/icons-react';
import './Funnel.css';
import { modals } from '@mantine/modals';
import cx from 'clsx';
import { useListState } from '@mantine/hooks';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import classes from './DndList.module.css';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { SelectFunnelList } from "../../components/GeneralComponent/GeneralComponent";
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import PermissionWrapper from '../../PermissionWrapper';
import { VisiLoader } from "../../components/GeneralComponent/GeneralComponent";
import FunnelApi from "../../api/FunnelApi";

export function Funnel() {

  const navigate = useNavigate();
  
  useDocumentTitle("Visiopt - Funnel");

  const [loading, setLoading] = useState(false);
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
  const [SelectedFunnelValue, setSelectedFunnelValue] = useState(null);
  const [selectedFunnelTitle, setselectedFunnelTitle] = useState('');
  const [SystemUsageLoader, setSystemUsageLoader] = useState(false);
  const [SelectPagesLoader, setSelectPagesLoader] = useState(false);
  const [funnelPageOrder, setfunnlPageOrder] = useState([]);
  const [PageTimeout, setPageTimeout] = useState('');
  const [FlickerControl, setFlickerControl] = useState('');
  const [editPageDetail, setEditPageDetail] = useState({name: '',url: ''});
  const [state, handlers] = useListState([]);
  const [funnelTitle, setFunnelTitle] = useState('');
  const [funnelButton, setFunnelButton] = useState('');
  const [newFunnelModalOpen, setNewFunnelModalOpen] = useState(false);
  const [editFunnelModalOpen, setEditFunnelModalOpen] = useState(false);
  const [addButtonFunnelModalOpen, setAddbuttonFunnelModalOpen] = useState(false);
  const [pageinAddButtonSection, setPageinAddButtonSection] = useState([]);
  const [buttoninAddButtonSection, setButtoninAddButtonSection] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [selectedButtonId, setSelectedButtonId] = useState(null);
  const [blockIPModalOpen, setBlockIPModalOpen] = useState(false);
  const [blockIPAddress, setBlockIPAddress] = useState([]);
  const [settingModalOpen, setSettingModalOpen] = useState(false);
  const [marksalestime, setMarksalestime] = useState(0);
  const [updatedtime, setUpdatedtime] = useState(0);
  const [selectedOption, setSelectedOption] = useState({});
  const [editPageModalOpen, setEditPageModalOpen] = useState(false);
  const [data, setData] = useState({ edit_funnel_name: '' });
  const [customtitle, setCustomTitle] = useState({ name: '' });
  const [customtitlepageid, setCustomTitlePageId] = useState({ pageId: ''});
  const [settingdata, setSettingData] = useState({ savepagetimeout: '',saveflickercontrol:'' });
  const [visiSmartCodeModalOpen, setVisiSmartCodeModalOpen] = useState(false);
  const [trackingScriptId, setTrackingScriptId] = useState('334.168');
  const [visiSmartCodePageURL, setvisiSmartCodePageURL] = useState('');
  const [visiSmartCodezipURL, setvisiSmartCodezipURL] = useState('');
  const [visiSmartCode, setVisiSmartCode] = useState("<!-- VisiSmart Code - DO NOT MODIFY--><script async type='text/javascript'>window.visiopt_code=window.visiopt_code||(function(){var visi_wid=334,visi_pid=168,visi_flicker_time=4000,visi_flicker_element='html',c=false,d=document,visi_fn={begin:function(){var a=d.getElementById('visi_flicker');if(!a){var a=d.createElement('style'),b=visi_flicker_element?visi_flicker_element+'{opacity:0!important;background:none!important;}':'',h=d.getElementsByTagName('head')[0];a.setAttribute('id','visi_flicker');a.setAttribute('type','text/css');if(a.styleSheet){a.styleSheet.cssText=b;}else{a.appendChild(d.createTextNode(b));}h.appendChild(a);}},complete:function(){c=true;var a=d.getElementById('visi_flicker');if(a){a.parentNode.removeChild(a);}},completed:function(){return c;},pack:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){visi_fn.complete();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){visi_fn.begin();setTimeout(function(){visi_fn.complete()},visi_flicker_time);this.pack('http://localhost/visiopt/client/js_test/test.'+visi_wid+'.'+visi_pid+'.js');return true;}};window.visiopt_code_status=visi_fn.init();return visi_fn;}());</script><!--End Of VisiSmart Code -->");
  const [errorMessage, setErrorMessage] = useState('');
  const [checkTracking, setCheckTracking] = useState('');
  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [checkTrackingModelOpen, setcheckTrackingCloseModal] = useState(false);
  const [showErrorHeader, setShowErrorHeader] = useState(true);
  const [showDeleteErrorHeader, setShowDeleteErrorHeader] = useState(false);
  const [showRemovefromfunnelErrorHeader, setShowRemovefromfunnelErrorHeader] = useState(false);
  const [editTitleModelOpen, setEditTitleModelOpen] = useState(false);
  const [selectPageModelOpen, setSelectPageModelOpen] = useState(false);
  const [selectPageFunnel, setSelectPageFunnel] = useState([]);
  const [activePageNo, setActivePageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [totalPaginationNo, setTotalPaginationNo] = useState(1);
  const [selectedPageOption, setSelectedPageOption] = useState({});
  const [selectedAllPageValue, setSelectedAllPageValue] = useState('0');
  const [keyword, setKeywords] = useState('');
  const [thumbnailClick, setThumbnailClick] = useState('');
  const searchInputRef = useRef(null);
  const [funnelThumbnailsDatas, setFunnelThumbnailsDatas] = useState([]);
  const [funnelData, setFunnelData] = useState(null);
  const [selectedThumbnails, setSelectedThumbnails] = useState([]);

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const handleSelectFunnelListComponentChange = (Value:any) => {
    setSelectedFunnelValue(Value);
    fetchData(Value);
  };

  /*Use All Funnel Form Name*/
  const form = useForm({
    initialValues: {
      funnel_name: '',
      edit_funnel_name: '',
      blockipaddress: '',
      savepagetimeout: '',
      saveflickercontrol:'',
      url: ''
    },
  });

  /*Add New Funnel*/
  const handleNewFunnelOpenModal = (value:any) => {
    setFunnelTitle('NEW FUNNEL');
    setFunnelButton('Create Funnel')
    setNewFunnelModalOpen(true);
  };

  const handleNewFunnelCloseModal = () => {
    setNewFunnelModalOpen(false);
  };

  const handleNewFunnelSubmit = async (formData:any) => {
    try {
      const { funnel_name } = formData;
      const APIResponse = await FunnelApi.addfunnel({
        system_website_id: selectedMemberWebsite,
        name: funnel_name,
        account_funnel_limit: 1000
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        handleNewFunnelCloseModal();
        setUpdatedtime(APIResultJSON.data.selected_funnel_time);
        handleSelectFunnelListComponentChange(APIResultJSON.data.selected_funnel_id);
        form.reset();
      } else {
        form.setErrors({ funnel_name: APIResultJSON.message });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  /*Rename Edit Funnel*/
  const handleEditFunnelOpenModal = (value:any) => {
    setFunnelTitle('RENAME FUNNEL');
    setFunnelButton('Update Funnel');
    setEditFunnelModalOpen(true);
  };

  const handleEditFunnelCloseModal = () => {
    setEditFunnelModalOpen(false);
    form.reset();
    setselectedFunnelTitle('');
  };

  useEffect(() => {
    if (selectedFunnelTitle) {
      setData(prevData => ({ ...prevData, edit_funnel_name: selectedFunnelTitle }));
    }
  }, [selectedFunnelTitle]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleEditFunnelSubmit = async () => {
    try {
      const { edit_funnel_name } = data;
      const APIResponse = await FunnelApi.addfunnel({
        system_website_id: selectedMemberWebsite,
        name: edit_funnel_name,
        account_funnel_limit: 1000,
        funnel_id: SelectedFunnelValue
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        handleEditFunnelCloseModal();
        setUpdatedtime(APIResultJSON.data.selected_funnel_time);
        handleSelectFunnelListComponentChange(APIResultJSON.data.selected_funnel_id);
      } else {
        form.setErrors({ edit_funnel_name: APIResultJSON.message });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  /*Delete Funnel*/
  const deleteFunnelConfirm = (selectedMemberWebsite:any) => {
    modals.openConfirmModal({
      title: 'Delete the Funnel',
      children: (
        <Text size="sm">
          This page has an active recording called Smart Relational Test. You cannot delete pages from a funnel while they're part of active insights
        </Text>
      ),
      labels: { confirm: 'Delete the Funnel', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: async () => {
        try {
          const APIResponse = await FunnelApi.deletefunnel({
            system_website_id: selectedMemberWebsite
          });
          const APIResultJSON = await APIResponse.json();
          if (APIResultJSON.status === 1) {
            notifications.show({
              color: 'green',
              icon: <IconCheck size="1.1rem" />,
              title: 'Success',
              message: APIResultJSON.message,
            })
            setUpdatedtime(APIResultJSON.data.updated_time);
            handleSelectFunnelListComponentChange(APIResultJSON.data.selected_funnel_id);
          } else {
            notifications.show({
              color: 'red',
              icon: <IconX size="1.1rem" />,
              title: 'Error',
              message: APIResultJSON.message,
            })
          }
        } catch (error) {
          console.log('Error Something Went Wrong', error);
        }
      },
    });
  };

  /*Get Page in Add Button To The Funnel*/
  const handleAddbuttonFunnelOpenModal = () => {
    setAddbuttonFunnelModalOpen(true);
    FunnelApi.getpageinaddbuttonsection({
      system_website_id: selectedMemberWebsite
    })
    .then(response => {
      if (response.ok) {
          return response.json();
      }
    })
    .then(data => {
      if (data) {
        setPageinAddButtonSection(data.data);
      }
    })
    .catch(error => {
        console.error("API Error:", error);
    });
  };

  /*Get Button in Add Button To The Funnel*/
  const handlePageSelect = async (selectedPageId) => {
    setSelectedPageId(selectedPageId);
    try {
      const response = await FunnelApi.getbuttoninaddbuttonsection({
        page_id: selectedPageId
      });
      if (response.ok) {
        const data = await response.json();
        if (data && data.data.length > 0) {
          setButtoninAddButtonSection(data.data);
        } else {
          setButtoninAddButtonSection([]);
          setSelectedButtonId('');
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  /*Add Button to the Funnel*/
  const handleAddButtonFunnelSubmit = async (formData:any) => {
    try {
      const APIResponse = await FunnelApi.addbuttontofunnel({
        system_website_id: selectedMemberWebsite,
        button_id: selectedButtonId
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        })
        setUpdatedtime(APIResultJSON.data.updated_time);
        handleAddbuttonFunnelCloseModal();
        setSelectedButtonId('');
      } else {
        form.setErrors({ button_id: APIResultJSON.message });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleAddbuttonFunnelCloseModal = () => {
    form.reset();
    setAddbuttonFunnelModalOpen(false);
    setSelectedPageId(null);
    setSelectedButtonId(null);
    setButtoninAddButtonSection([]);
  };

  /*Get Block IP Address*/
  const handleBlockIPOpenModal = () => {
    setBlockIPModalOpen(true);
    FunnelApi.getblockipaddress({
      system_website_id: selectedMemberWebsite
    })
    .then(response => {
      if (response.ok) {
          return response.json();
      } else {
          if (response.status === 401 || response.status === 400) {
              navigate("/logout");
          }
      }
    })
    .then(data => {
      if (data) {
        setBlockIPAddress(data.data);
      }
    })
    .catch(error => {
        console.error("API Error:", error);
    });
  };

  const handleBlockIPCloseModal = () => {
    setBlockIPModalOpen(false);
    form.reset();
  };

  /*Add Block IP Address*/
  const handleBlockIPAddressSubmit = async (formData:any) => {
    try{
      const { blockipaddress } = formData;
      const APIResponse = await FunnelApi.addblockipaddress({
        system_website_id: selectedMemberWebsite,
        ip: blockipaddress
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        form.setFieldValue('blockipaddress', '');
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        handleBlockIPOpenModal();
      } else{
        form.setErrors({ blockipaddress: APIResultJSON.message });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  /*Delete Block IP Address*/
  const deleteBlockIPConfirm = (ipId) => {
    modals.openConfirmModal({
      title: 'Delete Block IP Address',
      children: (
        <Text size="sm">
          This will allow traffic to be registered from this IP. Are you sure you want to continue?
        </Text>
      ),
      labels: { confirm: 'Delete Block IP Address', cancel: "No don't delete it" },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: async () => {
        try {
          const APIResponse = await FunnelApi.deleteblockipaddress({
            ip_id: ipId
          });
          const APIResultJSON = await APIResponse.json();
          if (APIResultJSON.status === 1) {
            notifications.show({
              color: 'green',
              icon: <IconCheck size="1.1rem" />,
              title: 'Success',
              message: APIResultJSON.message,
            })
            handleBlockIPOpenModal();
          } else {
            notifications.show({
              color: 'red',
              icon: <IconX size="1.1rem" />,
              title: 'Error',
              message: APIResultJSON.message,
            })
          }
        } catch (error) {
          console.log('Error deleting block IP address:', error);
        }
      },
    });
  };

  /*Select Funnel*/
  const fetchData = (funnelValue) => {
    if (funnelValue !== null) {
      FunnelApi.selectfunnel({
        system_website_id: selectedMemberWebsite,
        funnel_id: funnelValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        if (data.status === 1) {
          setselectedFunnelTitle(data.data.funnel_name);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  };

  /*Get Page Timeout*/
  const handleSettingOpenModal = (selectedPageId) => {
    setSelectedPageId(selectedPageId);
    setSettingModalOpen(true);
    FunnelApi.getpagetimeout({
      system_website_id: selectedMemberWebsite,
      page_id: selectedPageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
            navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data) {
        setPageTimeout(data.data.visiopt_timeout);
        setFlickerControl(data.data.flicker_control);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleSettingCloseModal = () => {
    setSettingModalOpen(false);
  };

  useEffect(() => {
    if (PageTimeout || FlickerControl) {
      setSettingData(prevSettingData => ({ ...prevSettingData, savepagetimeout: PageTimeout, saveflickercontrol:FlickerControl  }));
    }
  }, [PageTimeout,FlickerControl]);

  const handleChangePageTime = (e) => {
    setSettingData({ ...settingdata, savepagetimeout: e.target.value });
  };

  const handleChangeFlickerControl = (e) => {
    setSettingData({ ...settingdata, saveflickercontrol: e.target.value });
  };

  /*Save Page Timeout*/
  const handleSavePageTimeoutSubmit = async (formData:any) => {
    setSelectedPageId(selectedPageId);
    const { savepagetimeout,saveflickercontrol } = formData;
    FunnelApi.savepagetimeout({
      page_id: selectedPageId,
      visiopt_timeout: settingdata.savepagetimeout,
      flicker_control: settingdata.saveflickercontrol
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        if(data.data.msg === ""){
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
          setMarksalestime(data.updated_time);
          handleSettingCloseModal();
        } else {
          modals.open({
            title: 'Message',
            children: (
            <>
              <Text size="sm">
                {data.data.msg}
              </Text>
              <Group position="right" mt="md">
                <Button onClick={() => {
                  modals.closeAll();
                  setVisiSmartCodeModalOpen(true);
                  handleGetTreckingCode(selectedPageId);
                }}>OK</Button>
              </Group>
            </>
            ),
          });
          setMarksalestime(data.updated_time);
          handleSettingCloseModal();
        }
      } else {
        form.setErrors({ savepagetimeout: data.message });
        form.setErrors({ saveflickercontrol: data.message });
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  /*Edit Page Detail*/
  const handleEditOpenModal = (selectedPageId) => {
    setSelectedPageId(selectedPageId);
    setEditPageModalOpen(true);
    FunnelApi.getpagedetail({
      page_id: selectedPageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data) {
        setEditPageDetail(data.data);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleEditCloseModal = () => {
    setEditPageModalOpen(false);
  };

  useEffect(() => {
    if (editPageDetail) {
      setEditPageDetail(editPageDetail);
    }
  }, [editPageDetail]);

  const handleChangeEditPage = (e) => {
    const { name, value } = e.target;
    setEditPageDetail({ ...editPageDetail, [name]: value });
  };

  const handleEditPageSubmit = async (formData :any) =>  {
    try {
      const APIResponse = await FunnelApi.editpage({
        system_website_id: selectedMemberWebsite,
        page_id: selectedPageId,
        name : editPageDetail.name,
        url : editPageDetail.url
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        setMarksalestime(APIResultJSON.data.updated_time);
        handleEditCloseModal();
      } else {
        form.setErrors({ name: APIResultJSON.message, url: APIResultJSON.message });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleRecordConversion = async (selectedPageId) => {
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.marksalesconversion({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
        setMarksalestime(APIResultJSON.data.updated_time);
      if (APIResultJSON.status === 1) {
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleGetTreckingCode = async (selectedPageId) => {
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.gettrackingcode({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        setVisiSmartCode(APIResultJSON.data.tracking_code);
        setTrackingScriptId(APIResultJSON.data.tracking_script_id);
        setvisiSmartCodePageURL(APIResultJSON.data.page_url);
        setvisiSmartCodezipURL(APIResultJSON.data.wp_plugin_url);
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleUnsetwinner = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.unsetwinner({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
        setMarksalestime(APIResultJSON.data.updatedtime);
      if (APIResultJSON.status === 1) {
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleRemoveFromfunnel = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.removefromfunnel({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite,
        alert_check:1
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.message === '' && APIResultJSON.status === 0) {
        modals.openConfirmModal({
          title: 'REMOVE FROM FUNNEL',
          children: (
            <Text size="sm">
              Are you sure you want to remove this page from the Funnel?
            </Text>
          ),
          labels: { confirm: 'Remove from Funnel', cancel: "No don't Remove it" },
          confirmProps: { color: 'red' },
          onCancel: () => console.log('Cancel'),
          onConfirm: () => {
            handleRemoveFromfunnelAlertCheck(selectedPageId);
            handleChooseActionReset('');
            notifications.show({
              color: 'green',
              icon: <IconCheck size="1.1rem" />,
              title: 'Success',
              message: APIResultJSON.message,
            });
            setMarksalestime(APIResultJSON.data.updatedtime);
          },
        });
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowDeleteErrorHeader(false);
        setShowRemovefromfunnelErrorHeader(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  
  const handleRemoveFromfunnelAlertCheck = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.removefromfunnel({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite,
        alert_check:0
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        setMarksalestime(APIResultJSON.data.updatedtime);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleRemoveButtonFromfunnel = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.removebuttonfromfunnel({
        button_id: selectedPageId,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
        setMarksalestime(APIResultJSON.data.updatedtime);
      if (APIResultJSON.status === 1) {
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleCheckTrecking = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    setSystemUsageLoader(true);
    try {
      const APIResponse = await FunnelApi.checktracking({
        page_id: selectedPageId,
      });
       const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        setSystemUsageLoader(false);
        setMarksalestime(APIResultJSON.data.updatedtime);
        setCheckTracking(APIResultJSON.data.message);
        setcheckTrackingCloseModal(true);
      }else{
        setSystemUsageLoader(false);
        setCheckTracking(APIResultJSON.message);
        setcheckTrackingCloseModal(true);
      }
    } catch (error) {
      console.error("API Error:", error);
      setSystemUsageLoader(false);
    }
  };

  const handleDiscarddraft = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.discarddraft({
        page_id: selectedPageId,
        system_website_id : selectedMemberWebsite
      });
       const APIResultJSON = await APIResponse.json();
        setMarksalestime(APIResultJSON.data.updatedtime);
      if (APIResultJSON.status === 1) {
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleDeletePage = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.deletepage({
        system_website_id: selectedMemberWebsite,
        page_id: selectedPageId,
        alert_check:1
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.message === '' && APIResultJSON.status === 0) {
        modals.openConfirmModal({
          title: 'DELETE PAGE',
          children: (
            <Text size="sm">
              Are you sure you want to remove this page?
            </Text>
          ),
          labels: { confirm: 'Delete the Page', cancel: "No don't Delete it" },
          confirmProps: { color: 'red' },
          onCancel: () => console.log('Cancel'),
          onConfirm: () => { 
            handleDeletePageAlertCheck(selectedPageId);
            handleChooseActionReset('');
            notifications.show({
              color: 'green',
              icon: <IconCheck size="1.1rem" />,
              title: 'Success',
              message: APIResultJSON.message,
            });
            setMarksalestime(APIResultJSON.data.updated_time);
          },
        });
      } else {
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowRemovefromfunnelErrorHeader(false);
        setShowDeleteErrorHeader(true);
        setShowErrorHeader(false); 
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDeletePageAlertCheck = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.deletepage({
        system_website_id: selectedMemberWebsite,
        page_id: selectedPageId,
        alert_check:0
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        setMarksalestime(APIResultJSON.data.updated_time);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  /* Refresh Screenshot */
  const handleRefreshScreenshot = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await FunnelApi.refreshscreenshot({
        system_website_id: selectedMemberWebsite,
        page_id: selectedPageId
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
      } else {
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  /*Add the Page*/
  const handleAddthePageSubmit = async (formData:any) =>{
    try {
      const { url } = formData;
      let no_of_pages_access;

      const userDataString = localStorage.getItem('visiUserData');
      const userData = JSON.parse(userDataString);
      if (userData && userData && userData.no_of_add_access) {
        no_of_pages_access = userData.no_of_add_access.no_of_pages_access;
      }
      const APIResponse = await FunnelApi.addpage({
        system_website_id: selectedMemberWebsite,
        url: url,
        no_of_pages_access:no_of_pages_access
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        setMarksalestime(APIResultJSON.data.updated_time);
        form.reset();
      } else {
        form.setErrors({ url: APIResultJSON.message });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  /*Save Custom Title*/
  const handleEditTitleOpenModal = (item:any, pageId:any) => {
    setEditTitleModelOpen(true);
    setCustomTitle({ ...customtitle, name: item });
    setCustomTitlePageId({ ...customtitlepageid, pageId: pageId });
  };

  const handleChangeCustomTitle = (e) => {
    setCustomTitle({ ...customtitle, [e.target.name]: e.target.value });
  };

  const handleSaveCustomTitleSubmit = async (formData:any, pageId:any) =>{
    try {
      const APIResponse = await FunnelApi.savecustomtitle({
        system_website_id: selectedMemberWebsite,
        page_id: customtitlepageid.pageId,
        custom_title:customtitle.name
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        setMarksalestime(APIResultJSON.data.updated_time);
        handleEditTitleCloseModal();
      } else {
        form.setErrors({ name: APIResultJSON.message });
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleEditTitleCloseModal = () => {
    setEditTitleModelOpen(false);
    form.reset();
  };

  const handleSelectAllPagesValueChange = (selectedAllPageOption) => {
    setSelectedAllPageValue(selectedAllPageOption);
    setActivePageNo(1);
  };

  const [AllPagesoptions, setAllPagesOptions] = useState([
    { value: '0', label: 'All pages'},
    { value: '1', label: 'Tested Pages'},
    { value: '2', label: 'Pages in Funnel'},
    { value: '3', label: 'Affiliate Pages'},
    { value: '4', label: 'Other Pages'},
  ]);

  /*Select pages from funnel*/
  const handleSelectPageOpenModal = () => {
    setSystemUsageLoader(true);
    FunnelApi.selectpagesfromfunnel({
      system_website_id: selectedMemberWebsite,
      checktrackstate: 1,
      filter: selectedAllPageValue,
      keyword: keyword,
      page: activePageNo
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          setSystemUsageLoader(false);
          setSelectPageModelOpen(true);
        } else {
          setSystemUsageLoader(false);
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setShowErrorHeader(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setSystemUsageLoader(false);
      });
  };

  const handlePageChange = (newPage) => {
    setActivePageNo(newPage);
  };

  const setKeyword = (e) => {
    setKeywords(e.target.value);
    setActivePageNo(1);
  };

  useEffect(() => {
    if (selectPageModelOpen) {
      setLoading(true);
      seterrorCloseModal(false);
      FunnelApi.selectpagesfromfunnel({
        system_website_id: selectedMemberWebsite,
        checktrackstate: 1,
        filter: selectedAllPageValue,
        keyword: keyword,
        page: activePageNo
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setLoading(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          if (data.data.data) {
            setSelectPageFunnel(data.data.data);
            if (data.data.active_page_no !== activePageNo) {
              setActivePageNo(data.data.active_page_no);
              setActivePageNo(1);
            }
            if (data.data.total_pages !== totalPages) {
              setTotalPages(data.data.total_pages);
            }
            if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
              setTotalPaginationNo(data.data.total_pagination_no);
            }
          } else{
            console.log("No data received");
            setSelectPageFunnel([]);
          }
        }else{
          setSelectedAllPageValue('0');
          setSelectPageModelOpen(false);
          setErrorMessage(data.message);
          seterrorCloseModal(true);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error("API Error:", error);
        setLoading(false);
      });
    }
  }, [selectPageModelOpen, selectedAllPageValue, keyword, activePageNo, selectedMemberWebsite, marksalestime, navigate, totalPages, totalPaginationNo]);

  const handleSelectPageCloseModal = () => {
    setSelectedAllPageValue('0');
    setActivePageNo(1);
    setSelectPageModelOpen(false);
    resetSelection();
    form.reset();
  };

  const resetSelection = () => {
    setSelectedThumbnails([]);
  };

  const handleThumbnailDoubleClick = (pageId:any) =>{
    setLoading(true);
    FunnelApi.addtofunnel({
      system_website_id: selectedMemberWebsite,
      page_id:pageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setLoading(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setThumbnailClick(data.data);
        setMarksalestime(data.data.updated_time);
        handleSelectPageCloseModal();
        setLoading(false);
      } else {
        modals.open({
          title: 'Message',
          children: (
          <>
            <Text size="sm">
              {data.message}
            </Text>
            <Group position="right" mt="md">
              <Button onClick={() => {
                setVisiSmartCodeModalOpen(true);
                modals.closeAll();
              }}>OK</Button>
            </Group>
          </>
          ),
        });
        setLoading(false);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
      setLoading(false);
    });
  };

  const handleThumbnailClick = (pageId) => {
    setSelectedThumbnails((prevSelectedThumbnails) => {
      if (prevSelectedThumbnails.includes(pageId)) {
        return prevSelectedThumbnails.filter(id => id !== pageId);
      } else {
        return [...prevSelectedThumbnails, pageId];
      }
    });
  };

  const handleAddtoFunnel = (selectedThumbnail) =>{
    FunnelApi.addtofunnel({
      system_website_id: selectedMemberWebsite,
      page_id:selectedThumbnail
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setMarksalestime(data.data.updated_time);
        handleSelectPageCloseModal();
        setLoading(false);
        resetSelection();
      } else {
        modals.open({
          title: 'Message',
          children: (
          <>
            <Text size="sm">
              {data.message}
            </Text>
            <Group position="right" mt="md">
              <Button onClick={() => {
                setVisiSmartCodeModalOpen(true);
                modals.closeAll();
              }}>OK</Button>
            </Group>
            </>
          ),
        });
        setLoading(false);
        resetSelection();
      }
    })
    .catch(error => {
      console.error("API Error:", error);
      setLoading(false);
    });
  };

  const handleMultipleAddtoFunnel = (selectedThumbnail) =>{
    const requests = selectedThumbnails.map(pageId => 
      FunnelApi.addtofunnel({
        system_website_id: selectedMemberWebsite,
        page_id: pageId
      })
    );
    Promise.all(requests)
    .then(responses => {
        const jsonPromises = responses.map(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
              return null;
            }
          }
        });
        return Promise.all(jsonPromises);
      })
    .then(dataArray => {
      dataArray.forEach(data => {
        if (data.status === 1) {
          setMarksalestime(data.data.updated_time);
          handleSelectPageCloseModal();
          setLoading(false);
        } else {
          modals.open({
            title: 'Message',
            children: (
            <>
              <Text size="sm">
                {data.message}
              </Text>
              <Group position="right" mt="md">
                <Button onClick={() => {
                  setVisiSmartCodeModalOpen(true);
                  modals.closeAll();
                }}>OK</Button>
              </Group>
            </>
            ),
          });
          setLoading(false);
          resetSelection();
        }
      });
    })
    .catch(error => {
      console.error("API Error:", error);
      setLoading(false);
    });
  };

  /*Get Funnel Page List*/
  useEffect(() => {
    if(selectedMemberWebsite !== ''){
      setLoading(true);
      FunnelApi.getfunnelpagelist({
        system_website_id : selectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setLoading(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setselectedFunnelTitle(data.data.funnel_name);
          handlers.setState([])
          let FunnelOrderData = [];
          if(data.data.data && data.data.data.length > 0){
              data.data.data.forEach((item) => {
                FunnelOrderData.push(item.pageId);
                handlers.append(item);
              });
                setfunnlPageOrder([...FunnelOrderData]);
                setFunnelThumbnailsDatas(data.data.data);
                setFunnelData(true);
          }else{
            setFunnelData(false); 
          }
        } else {
          setFunnelData(false); 
        }
       setLoading(false);
      })
      .catch(error => {
       setLoading(false);
      });
    }
  }, [selectedMemberWebsite,selectedFunnelTitle,marksalestime,updatedtime]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'k') {
        event.preventDefault();
        searchInputRef.current.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  /*Update Order*/
  const handleDragEnd = (result:any) => {
    const { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }
    handlers.reorder({ from: source.index, to: destination?.index || 0 });
    const reorderedItems = Array.from(state);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);
    let FunnelOrderData = [];
    reorderedItems.forEach(async (item, index) => {
      FunnelOrderData.push(item.pageId);
    });
    setSystemUsageLoader(true);
    FunnelApi.updateorder({
      system_website_id : selectedMemberWebsite,
      order_funnel_page_array : FunnelOrderData
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setSystemUsageLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setSystemUsageLoader(false);
      }
    })
    .catch(error => {
     setSystemUsageLoader(false);
    });
  };

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

  const handleCheckTrackingCloseModal = () => {
    setcheckTrackingCloseModal(false);
  };

  const funnelThumbnailData = state.map((item, index) => (
    <Draggable key={item.pageId} index={index} draggableId={item.pageId}>
      {(provided, snapshot) => (
        <div
          className={cx(classes.item, { [classes.itemDragging]: snapshot.isDragging })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="visi-page">
            <div className={item.pageClass}>
              <a href={item.pageFullURL} title={item.pageFullURL} target="_blank" rel="noreferrer">
                <span className="page-url">{item.title}</span>
              </a>
              <a href={item.pageFullURL} title={item.pageFullURL} target="_blank" rel="noreferrer">
                <div className="thumbnail"  style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.imgURL})` }}>
                  <Image
                    id="track_img"
                    src={item.track_check}
                    title={item.track_check_title}
                  />
                  {(item.webhook_track_check !== '' && (
                      <Image
                      id="webhook_img"
                      src={item.webhook_track_check}
                      title={item.webhook_track_check_title}
                    />
                    ))
                  }
                  {(item.marge_img !== '' && (
                      <Image
                      id="merge_img"
                      src={item.marge_img}
                      title="This Page is merged with other links"
                    />
                    ))
                  }
                </div>
              </a>
              <span className="page-title" onClick={() => handleEditTitleOpenModal(item.custom_title,item.pageId)}>{item.custom_title || 'ENTER CUSTOM TITLE'}</span>
            </div>
            <Select
              placeholder="Choose Action"
              defaultValue={selectedOption[item.page_id]}
              onChange={handleChooseActionChange}
              value={selectedOption[item.page_id]}
              data={item.funnelBoxOption}
              maxDropdownHeight={250}
              maw={152}
              size="xs"
              dropdownPosition="top"
            />
          </div>
          {state.length === index+1 ? <PermissionWrapper permission="operational_access"><div className="add-page" title="Add a Page to the Funnel" onClick={handleSelectPageOpenModal}/></PermissionWrapper> : <div className="page-arrow" />}
        </div>
      )}
    </Draggable>
  ));

  const handleChooseActionReset = (event:any) => {
    const result = event.split('==');
    const page_id = result[1];
    setSelectedOption(prevOptions => ({
        ...prevOptions,
        [page_id]: ''
    }));
  };

  const handleChooseActionChange = (event:any) => {
    const result = event.split('==');
    const value = result[0];
    const page_id = result[1];
    setSelectedOption(prevOptions => ({
        ...prevOptions,
        [page_id]: value
    }));
    if(value === "mark_sales"){
      handleRecordConversion(page_id);
      handleChooseActionReset('');
    } else if(value === "edit_page"){
      handleEditOpenModal(page_id);
      handleChooseActionReset('');
    } else if(value === "checkTracking"){
      handleCheckTrecking(page_id);
      handleChooseActionReset('');
    } else if(value === "trackingCode"){
      handleGetTreckingCode(page_id);
      setVisiSmartCodeModalOpen(true);
      handleChooseActionReset('');
    } else if(value === "remove"){
      handleRemoveFromfunnel(page_id);
      handleChooseActionReset('');
    } else if(value === "delete"){
      handleDeletePage(page_id);
      handleChooseActionReset('');
    } else if(value === "setting"){
      handleSettingOpenModal(page_id);
      handleChooseActionReset('');
    } else if(value === "unsetWinner"){
      modals.openConfirmModal({
        title: 'Unset Winner',
        children: (
          <Text size="sm">
           Are you sure you want to Unset Winner this page?
          </Text>
        ),
        labels: { confirm: 'Unset Winner', cancel: 'Cancel' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          handleUnsetwinner(page_id);
          handleChooseActionReset('');
        },
      });
    } else if(value === "discardDraft"){
      modals.openConfirmModal({
        title: 'Discard Draft',
        children: (
          <Text size="sm">
            Are you sure you want to Discard Draft this page?
          </Text>
        ),
        labels: { confirm: 'Discard Draft', cancel: 'Cancel' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          handleDiscarddraft(page_id);
          handleChooseActionReset('');
        },
      });
    } else if(value === "removebutton"){
      modals.openConfirmModal({
        title: 'REMOVE FROM FUNNEL',
        children: (
          <Text size="sm">
            Are you sure you want to remove this button from the Funnel?
          </Text>
        ),
        labels: { confirm: 'Remove from Funnel', cancel: "No don't Remove it" },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          handleRemoveButtonFromfunnel(page_id);
          handleChooseActionReset('');
        },
      });
    }
  };
  const handleVisiSmartCodeCloseModal = () => {
    setVisiSmartCodeModalOpen(false);
  };
  const handleEditPageCloseModal = () => {
    setEditPageModalOpen(false);
    form.reset();
  };

  const [selectPageBoxOption, setSelectPageBoxOption] = useState([
    { value: '', label: 'Choose Action', hidden:'hidden' },
    { value: 'addtofunnel', label: 'Add to funnel' },
    { value: 'remove', label: 'Remove from funnel' },
    { value: 'checkTracking', label: 'Check Tracking' },
    { value: 'trackingCode', label: 'VisiSmart Code' },
    { value: 'edit_page', label: 'Edit Page' },
    { value: 'delete', label: 'Delete Page' },
    { value: 'refresh_screenshot', label: 'Refresh Screenshot' },
  ]);
    
  const handleSelectPageAction = (value:any, page_id:any) => {
    setSelectedPageOption(prevOptions => ({
        ...prevOptions,
        [page_id]: ''
    }));
    if(value === "addtofunnel"){
      handleAddtoFunnel(page_id);
    } else if(value === "remove"){
      handleRemoveFromfunnel(page_id);
    } else if(value === "checkTracking"){
      handleCheckTrecking(page_id);
    } else if(value === "trackingCode"){
      handleGetTreckingCode(page_id);
      setVisiSmartCodeModalOpen(true);
    } else if(value === "edit_page"){
      handleEditOpenModal(page_id);
    } else if(value === "delete"){
      handleDeletePage(page_id);
    } else if(value === "refresh_screenshot"){
      modals.openConfirmModal({
        title: 'Refresh Screenshot',
        children: (
          <Text size="sm">
            Your screenshot will be updated soon!!!
          </Text>
        ),
        labels: { confirm: 'Ok', cancel: "Close" },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => handleRefreshScreenshot(page_id),
      });
    }
  };

  return (
    <>
    <VisiLoader VisiLoaderVisible={SystemUsageLoader}/>
    <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
    {/*New funnel*/}
    <Modal.Root opened={newFunnelModalOpen} onClose={handleNewFunnelCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{funnelTitle} <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleNewFunnelSubmit(formData); })}>
            <Group>
              <Input.Wrapper label="Enter Funnel Name:" error={form.errors.funnel_name}>
                <Input id="funnel_name" value={form.values.funnel_name} onChange={(event) => form.setFieldValue('funnel_name', event.currentTarget.value)} error={form.errors.funnel_name && 'Invalid funnel name'} w={242} ml={0}/>
              </Input.Wrapper>
              <Button type="submit" size="sm" w={150} mt={25}>
                {funnelButton}
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Rename funnel*/}
    <Modal.Root opened={editFunnelModalOpen} onClose={handleEditFunnelCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{funnelTitle} <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleEditFunnelSubmit(); })}>
            <Group>
              <Input.Wrapper label="Enter Funnel Name:" error={form.errors.edit_funnel_name}>
                <Input id="edit_funnel_name" name="edit_funnel_name" value={data.edit_funnel_name} hidden={SelectedFunnelValue ? true : false} onChange={handleChange} error={form.errors.edit_funnel_name && 'Invalid funnel name'} w={242} ml={0}/>
              </Input.Wrapper>
              <Button type="submit" size="sm" w={150} mt={25}>
                {funnelButton}
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Add Button to the Funnel*/}
    <Modal.Root opened={addButtonFunnelModalOpen} onClose={handleAddbuttonFunnelCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>ADD BUTTON(S) <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleAddButtonFunnelSubmit(formData); })}>
          <>
            <Select
              label="Pages"
              placeholder="Select Page"
              data={pageinAddButtonSection.map(pageinAdd => ({
                value: pageinAdd.page_id,
                label: pageinAdd.page_title
              }))}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
              mb={15}
              onChange={(selectedPageId) => {
                handlePageSelect(selectedPageId);
              }}
            />
            {selectedPageId && (
            <Select
              label="Buttons"
              placeholder="Select Button"
              data={buttoninAddButtonSection.map(buttoninAdd => ({
                value: buttoninAdd.button_id,
                label: buttoninAdd.button_name
              }))}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="top"
              value={selectedButtonId}
              onChange={(selectedButtonId) => setSelectedButtonId(selectedButtonId)} 
              error={form.errors.button_id}
            />
            )}
            </>
            <Group position="right">
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Block IP Address*/}
    <Modal.Root opened={blockIPModalOpen} onClose={handleBlockIPCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>BlOCK IP ADDRESSES <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleBlockIPAddressSubmit(formData); })}>
            <Group>
              <Input.Wrapper label="Enter Block IP:" error={form.errors.blockipaddress}>
                <Input id="blockipaddress" value={form.values.blockipaddress} onChange={(event) => form.setFieldValue('blockipaddress', event.currentTarget.value)} error={form.errors.blockipaddress && 'Block IP Address Required'} w={242} ml={0}/>
              </Input.Wrapper>
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </Group>
            <Group mt={10}>
              <Table striped highlightOnHover withBorder>
                <tbody>
                  {blockIPAddress.map((item, index) => (
                  <tr key={index}>
                    <td>{item.ip}</td>
                    <td>
                      <Flex
                        justify="flex-end"
                        align="center"
                        direction="row"
                        wrap="wrap"
                      >
                        <ActionIcon
                          variant="default"
                          size="lg"
                          onClick={() => deleteBlockIPConfirm(item.ip_id)}
                        >
                          <IconX size="1.1rem" />
                        </ActionIcon>
                      </Flex>
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Edit Page*/}
    {editPageModalOpen && (
    <Modal.Root opened={true} onClose={handleEditPageCloseModal} style={{ zIndex: 2 }}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>EDIT PAGE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleEditPageSubmit(formData); })}>
            <Input.Wrapper label="Name" error={form.errors.name}>
              <Input name="name" value={editPageDetail.name} onChange={handleChangeEditPage} error={form.errors.name && 'Page is Required field'}/>
            </Input.Wrapper>
            <Input.Wrapper label="URL" error={form.errors.url}>
              <Input name="url" value={editPageDetail.url} onChange={handleChangeEditPage} error={form.errors.url && 'URL is Required field'}/>
            </Input.Wrapper>
            <Group position="right">
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    )}
    {/*VisiSmart Code*/}
    {visiSmartCodeModalOpen && (
    <Modal.Root opened={true} onClose={handleVisiSmartCodeCloseModal} style={{ zIndex: 2 }} size="xl">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>VISISMART CODE  <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Text size="sm" ml={0} fz={14}>Please Copy/Paste this code on the following page </Text>
          <div style={{ display: 'flex',marginBottom: '10px' }}>
            <Text fw={700} size="sm" ml={0} fz={14}>{visiSmartCodePageURL},</Text>
            <Text size="sm" ml={3} fz={14}>right before the</Text>
            <Text fw={700} size="sm" ml={3} fz={14}>&lt;/head&gt;</Text>
            <Text size="sm" ml={3} fz={14}>tag:</Text>
          </div>
          <Textarea
            value={visiSmartCode}
            size="xs"
            autosize
          />
          <CopyButton value={visiSmartCode} timeout={5000}>
            {({ copied, copy }) => (
              <Button color={copied ? 'teal' : 'blue'} onClick={copy} size="sm" w={190} mt={10} mb={10}>
                {copied ? 'Copied VisiSmart Code' : ' Copy VisiSmart Code'}
              </Button>
            )}
          </CopyButton>
          <Text size="sm" ml={0} fz={14}>Using a WordPress site? Install our WordPress plugin for easy integration.</Text>
          <div style={{ display: 'flex',marginBottom: '10px' }}>
            <Text size="sm" fz={14}>You can download the plug in from this 
              <Anchor href={visiSmartCodezipURL} target="_blank" ml={5} fw={700}>
              LINK.
              </Anchor>
            </Text>
          </div>
          <div style={{ display: 'flex',marginBottom: '10px' }}>
            <Text size="sm" fz={14} mt={10}>Tracking script ID : </Text>
            <Space w="xs" />
            <Text size="sm" fz={14} mt={10} fw={700}>{trackingScriptId}</Text>
            <Space w="md" />
            <CopyButton value={trackingScriptId} timeout={5000}>
              {({ copied, copy }) => (
                <Button color={copied ? 'teal' : 'blue'} onClick={copy} size="sm" w={100}>
                  {copied ? 'Copied ID' : ' Copy ID'}
                </Button>
              )}
            </CopyButton>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    )}
    {/*setting*/}
    <Modal.Root opened={settingModalOpen} onClose={handleSettingCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>SETTING <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleSavePageTimeoutSubmit(formData); })}>
            <Input.Wrapper label="Set Page Delay Timeout (in milliseconds)" error={form.errors.savepagetimeout}>
              <Input id="savepagetimeout" type="number" name="savepagetimeout" value={settingdata.savepagetimeout} hidden={PageTimeout ? true : false} onChange={handleChangePageTime}/>
            </Input.Wrapper>        
            <Input.Wrapper label="Flicker Control (in milliseconds)" error={form.errors.saveflickercontrol}>
              <Input id="saveflickercontrol" type="number" name="saveflickercontrol" value={settingdata.saveflickercontrol} hidden={FlickerControl ? true : false} onChange={handleChangeFlickerControl}/>
            </Input.Wrapper>
            <Space w="xs" />
            <Group position="right">
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Edit Title*/}
    <Modal.Root opened={editTitleModelOpen} onClose={handleEditTitleCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>EDIT TITLE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleSaveCustomTitleSubmit(formData, selectedPageId); })}>
            <div style={{ display: 'flex'}}>
              <Input.Wrapper label="Name" error={form.errors.name}>
                <Input name="name" value={customtitle.name} onChange={handleChangeCustomTitle} error={form.errors.name && 'Title is Required field'} w={315}/>
              </Input.Wrapper>
              <Space w="xs" />          
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Error Model*/}
    {errorModelOpen && (
    <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
      <Modal.Overlay />
      <Modal.Content>
        {showRemovefromfunnelErrorHeader ? (
          <Modal.Header>
            <Modal.Title>REMOVE FROM FUNNEL</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
        ) : showDeleteErrorHeader ? (
          <Modal.Header>
            <Modal.Title>DELETE PAGE</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
        ) : showErrorHeader && (
          <Modal.Header>
            <Modal.Title>Error</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
        )}
        <Modal.Body>
          <form>
            <div style={{ display: 'flex'}}>
                <Text weight={500}>{errorMessage}</Text>
            </div>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    )}
    {/*Check Tracking Model*/}
    {checkTrackingModelOpen && (
    <Modal.Root opened={true} onClose={handleCheckTrackingCloseModal} style={{ zIndex: 2 }}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>CHECK TRACKING</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form>
            <div style={{ display: 'flex'}}>
                <Text weight={500}>{checkTracking}</Text>
            </div>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    )}
    {/*New Select Page*/}
    <Modal.Root opened={selectPageModelOpen} onClose={handleSelectPageCloseModal} style={{ zIndex: 1 }} size="80%">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>ADD PAGES TO YOUR FUNNEL <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={form.onSubmit((formData) => { handleAddthePageSubmit(formData); })}>
            <Text size="sm" ml={0} fz={14}>Note: Any page you add must have your VisiSmart Code. </Text>
            <Text size="sm" mt={10} fz={14} fw={700}>Add pages to Visiopt</Text>
            <div style={{ display: 'flex',marginBottom: '10px' }}>
              <Input.Wrapper size="sm" ml={0} fz={14} mt={5}>Enter the url of the page you want to add to Visiopt:</Input.Wrapper>
              <Space w="xs" />
              <Input id="url" value={form.values.url} onChange={(event) => form.setFieldValue('url', event.currentTarget.value)} error={form.errors.url && 'Invalid URL'} w={400} ml={0}/>
              <Space w="xs" />
              <Button type="submit" size="sm" maw={150}>
                Add the Page
              </Button>
            </div>
            {form.errors.url && (
              <span style={{ color: 'red', marginTop: '5px' }}>{form.errors.url}</span>
            )}
          </form>
          <Text size="sm" mt={10} fz={14} fw={700}>Select pages for your funnel</Text>
          <div style={{ display: 'flex',marginBottom: '10px' }}>
            <Text size="sm" ml={0} fz={14} mt={8}>Pages you already added to Visiopt: </Text>
            <Space w="xs" />
            <Select
              placeholder="All pages"
              defaultValue="0"
              data={AllPagesoptions}
              onChange={(selectedAllPageOption) => handleSelectAllPagesValueChange(selectedAllPageOption)}
              value={selectedAllPageValue}
              maxDropdownHeight={300}
              maw={152}
              size="sm"
              dropdownPosition="bottom"
            />
            <TextInput
              ref={searchInputRef}
              style={{marginLeft:"auto"}}
              placeholder="Search"
              icon={<IconSearch size="1rem" />}
              rightSectionWidth={60}
              rightSection={
                <Flex align="center" justify="flex-end">
                  <Kbd mr={5}>Ctrl+K</Kbd>
                </Flex>
              }
              onChange={setKeyword}
            />
          </div>
          <Grid ml={0} mt={20} mb={20} style={{height: 450,flex: "1 1 auto",overflow: "auto"}}>
            {selectPageFunnel.map((item, index) => (
              <div className="visi-page" key={index} style={{marginRight:10}}>
                <Skeleton visible={loading}>
                  <div className={`hover-pointer ${item.pageClass}`}
                      style={{ backgroundColor: (item.selected || selectedThumbnails.includes(item.page_id)) ? '#38d9a9' : '' }} 
                      onClick={item.selected ? undefined : () => handleThumbnailClick(item.page_id)}
                    >
                    <span title={item.page_url}>
                      <span className="page-url">{item.page_title}</span>
                    </span>
                    <div className="thumbnail" style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.img_url})` }} title={item.page_url} onDoubleClick={item.selected ? undefined : () => handleThumbnailDoubleClick(item.page_id)}>
                      <LoadingOverlay visible={SelectPagesLoader} overlayBlur={2} loaderProps={{variant:"bars", color:"gray"}} />
                      {(item.marge_img !== '' && (
                          <Image
                          id="merge_img"
                          src={item.marge_img}
                          title="This Page is merged with other links"
                          style={{}}
                        />
                        ))
                      }
                      {(item.track_check !== '' && (
                        <Image
                          id="track_img"
                          src={item.track_check}
                          title={item.track_check_title}
                        />
                        ))
                      }
                    </div>
                    <span className="page-title">{item.custom_title}</span>
                  </div>
                </Skeleton>
                <Select
                  placeholder="Choose Action"
                  onChange={(value) => handleSelectPageAction(value, item.page_id)} 
                  value={selectedPageOption[item.page_id] || ''}
                  data={item.selected ? selectPageBoxOption.filter(option => option.value !== 'addtofunnel') : selectPageBoxOption && (!item.selected) ? selectPageBoxOption.filter(option => option.value !== 'remove') : selectPageBoxOption}
                  maxDropdownHeight={250}
                  maw={152}
                  size="xs"
                  dropdownPosition="top"
                />
              </div>
            ))}
          </Grid>
          {selectPageFunnel.some(item => Array.isArray(item) ? item.length > 0 : true) && (
          <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange}>
            <Group spacing={5} position="center">
              <Pagination.First />
              <Pagination.Previous />
              <Pagination.Items />
              <Pagination.Next />
              <Pagination.Last />
            </Group>
          </Pagination.Root>
          )}
          <Group position="right">
          <Button type="submit" size="sm" maw={400} 
                  onClick={() => handleMultipleAddtoFunnel(selectedThumbnails)} 
                  disabled={!selectedThumbnails.length} 
                >
            Add Selected to the Funnel
          </Button>
          </Group>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    <Container size="auto">
      <Card shadow="sm" padding="sm" radius="md" withBorder>
        <Group position="apart" mb="xs">
          <Text weight={500}>FUNNELS  <SelectTooltip/></Text>
        </Group>
        <Group spacing="xs" mb={10} mt={20}>
          <Text size="sm" fz={12} fw={500} maw={150}>Select Funnel:</Text>
          <SelectFunnelList updated_time={updatedtime} Value={SelectedFunnelValue} OnChange={handleSelectFunnelListComponentChange} MemberWebsiteId={selectedMemberWebsite} Width={180}/>
          <PermissionWrapper permission="operational_access">
            <Menu width={210} position="bottom-start">
              <Menu.Target>
                <ActionIcon variant="default" size="lg"><IconSettings size="1rem"/></ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item px={10} py={6} onClick={() => handleEditFunnelOpenModal(1)}>Rename the Funnel</Menu.Item>
                <Menu.Item px={10} py={6} onClick={handleAddbuttonFunnelOpenModal}>Add Button to the Funnel</Menu.Item>
                <Menu.Item px={10} py={6} component="a" href="">Assign New URLs</Menu.Item>
                <Menu.Item px={10} py={6} onClick={handleBlockIPOpenModal}>Block IP Address</Menu.Item>
                <Menu.Item px={10} py={6} onClick={() => deleteFunnelConfirm(selectedMemberWebsite)}>Delete the Funnel</Menu.Item>
              </Menu.Dropdown>
            </Menu>        
            <Button type="submit" size="sm" maw={150} onClick={() => handleNewFunnelOpenModal(0)}>
              New Funnel
            </Button>
          </PermissionWrapper>
        </Group>
        <Group spacing="xs" mb={10}>
          <Text fz={14} fw={500} >{selectedFunnelTitle}</Text>
        </Group>
        <>
        {funnelData === true ? (
          <Group spacing="xs" mb={10}>
            <Skeleton visible={loading}>
              <DragDropContext
                onDragEnd={handleDragEnd}
              >
                <Droppable droppableId="dnd-list" direction="horizontal">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {funnelThumbnailData}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Skeleton>
          </Group>
        ) : funnelData === false ? (
            <div className="main-content" style={{ textAlign: 'center', marginTop:'75px', height:'110px' }}>
              <Text size="sm" fz={14}>You do not yet have any pages set up in this funnel.</Text>
              <Text size="sm" fz={14} style={{ color: 'grey' }} onClick={handleSelectPageOpenModal}>Click here to select pages now</Text>
            </div>
        ) : funnelData === null && (
            <Skeleton visible={loading}>
            </Skeleton>
        )}
        </>
        <div className="legend-holder">
          <Group spacing="xs" m={0}>
            <span className="legend selected" />
            <Text size="sm" ml={0} fz={12} className="legend-title">Selected</Text>
            <span className="legend active" />
            <Text size="sm" ml={0} fz={12} className="legend-title">Active Test</Text>
            <span className="legend rotation" />
            <Text size="sm" ml={0} fz={12} className="legend-title">Rotation Page</Text>
            <span className="legend no-test" />
            <Text size="sm" ml={0} fz={12} className="legend-title">No Test</Text>
            <span className="legend completed" />
            <Text size="sm" ml={0} fz={12} className="legend-title">Test Completed</Text>
            <span className="legend unique" />
            <Text size="sm" ml={0} fz={12} className="legend-title">Record Unique Conversions Only</Text>
            <span className="legend winner" />
            <Text size="sm" ml={0} fz={12} className="legend-title">Winner Set</Text>
            <span className="legend draft" />
            <Text size="sm" ml={0} fz={12} className="legend-title">Draft Test</Text>
          </Group>
        </div>

      </Card>
    </Container>
    </>
  );
}