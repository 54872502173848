import { Group, Text, Box, Button, Grid, Flex, ActionIcon, Select, Input, Divider, Space, Table, Checkbox, Textarea, CopyButton, TextInput } from '@mantine/core';
import { IconHelp, IconPencil, IconTrash } from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';
import { modals } from '@mantine/modals';
import $ from 'jquery';

export function URLBuilderAndCustomTags() {
  const [activeURLBuilder,setActiveURLBuilder] = useState('URL-builder-and-custom-tags');
  const [activeAddURLBuilder, setActiveAddURLBuilder] = useState('');

  const [urlBuilderAndCustomData, setUrlBuilderAndCustomData] = useState([
    { id: 1, name: 'Facebook URL', url: 'www.test.com/upsell-1'},
    { id: 2, name: 'Main CTA', url: 'www.test.com/lp.html'},
    { id: 3, name: 'Google', url: 'www.test.com/downsell.html'},
  ]);
  
  const handleAddURLBuilder = () => {
    setActiveURLBuilder('');
    setActiveAddURLBuilder('add-URLbuilder');
  }

  const updateURLBuilder = (id:any) => {
  }

  const deleteURLBuilderConfirm = (deleteId:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to delete this URL Builder And Custom Tags?
      </Text>
    ),
    labels: { confirm: 'Delete', cancel: 'Cancel' },
    confirmProps: { color: 'red' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => console.log('Confirmed'),
  });


  const [customTagTableData, setCustomTagTableData] = useState([
    { id: 1, name: 'CustomTag1' },
    { id: 2, name: 'CustomTag2' },
    { id: 3, name: 'CustomTag3' },
    { id: 4, name: 'CustomTag4' },
    { id: 5, name: 'CustomTag5' },
  ]);
  const deleteCustomeTagConfirm = (deleteCustomeTagId:any) => modals.openConfirmModal({
    title: 'Delete Custom Tag',
    children: (
      <Text size="sm">
        Are you sure you want to delete this Custom Tag?
      </Text>
    ),
    labels: { confirm: 'Delete Custom Tag', cancel: "No don't delete it" },
    confirmProps: { color: 'red' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => console.log('Confirmed',handleCustomeTagDelete(deleteCustomeTagId)),
  });
  const handleCustomeTagDelete = (id:any) => {
    setCustomTagTableData(customTagTableData.filter(item => item.id !== id));
  };

  const [customTagDropDown, setCustomTagDropDown] = useState([
    { value: 'CustomTag1', label: 'CustomTag1' },
    { value: 'CustomTag2', label: 'CustomTag2' },
    { value: 'CustomTag3', label: 'CustomTag3' },
    { value: 'CustomTag4', label: 'CustomTag4' },
    { value: 'CustomTag5', label: 'CustomTag5' }
  ]);

  const [networkTokenValue, setNetworkTokenValue] = useState('facebook');
  const handleNetworkTokenAction = (value:any) => {
    setNetworkTokenValue(value);
    $('[data-urlitem="fb_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="mic_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="ggl_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="out_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="rev_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="mg_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="tub_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="sna_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="tiktok_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="yt_ads_net_param"]').prop('checked', false);
  }; 
  
  const getCustomTag = (event:any) => {
    $("#your_url").val(event);
    $('[data-urlitem="generate_url"]').val('');
    $('[data-urlitem="custom_tag_key"]').val('');
    $('[data-urlitem="custom_tag"]').val('');
    $('[data-urlitem="fb_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="mic_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="ggl_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="out_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="rev_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="mg_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="tub_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="sna_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="tiktok_ads_net_param"]').prop('checked', false);
    $('[data-urlitem="yt_ads_net_param"]').prop('checked', false);
  };

  useEffect(() => {
    function generate_page_url_function(ele){
      var page_url = $("#your_url").val();
      var page_id = $("#page_dd").val();
      var utm_val = $(ele).val();
      var utm_name = $(ele).attr('id');
      
      console.log(utm_val);
      console.log(utm_name);

      var ads_network_tag = $('input[name="ads_network_tags"]').val();
      if(page_url === '' || page_id === '0'){
        alert("Please select a Page");
        return false;
      }
      if($(ele).is('[data-urlitem="custom_tag_key"]') === true)
      {
        utm_name = utm_val;
        utm_val = $(ele).next().val();
      }
      if($(ele).is('[data-urlitem="custom_tag"]') === true)
      {
        utm_name = $(ele).prev().val();
      }
      if(utm_name === ''){
        alert("Please select a custom tag");
        return false;
      }
      if(page_url.indexOf("?") === '-1'){
        generate_page_url  = page_url+'?'+utm_name+'='+utm_val+'&';
      }
      else {
        if(page_url.indexOf(utm_name) !== '-1')
        {
          var old_utmval = page_url.split(utm_name)[1];
          old_utmval = old_utmval.split('&')[0];
          if($('#'+utm_name).attr('type') !== 'checkbox'){
             var generate_page_url = page_url.replace(utm_name+old_utmval, utm_name+"="+utm_val);
            }
        }
        else{
          var lastChar = page_url.substr(page_url.length - 1);
          if(lastChar === "&"){
            generate_page_url  = page_url+utm_name+'='+utm_val+'&';
          }
          else {
            var lastChar_a = (lastChar === "?")?'':'&';
            generate_page_url  = page_url+lastChar_a+utm_name+'='+utm_val+'&';
          }
        }
      }

      if($('#'+utm_name).attr('type') === 'checkbox'){
        var checkBox = document.getElementById(utm_name);
        if (checkBox.checked === false){
          if(utm_name === 'g_placement'){
            generate_page_url = generate_page_url.replace("placement="+utm_val+"&", "");
          }else{
            generate_page_url = page_url.replace(utm_name+"="+utm_val+"&", "");
          }
          if (utm_name === 's_campaign_id' || utm_name === 'tub_campaign_id' || utm_name === 'mg_campaign_id' || utm_name === 'out_campaign_id') {
            generate_page_url = generate_page_url.replace("campaign_id=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'm_keyword') {
            generate_page_url = generate_page_url.replace("keyword=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'mg_widget_id') {
            generate_page_url = generate_page_url.replace("widget_id=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 's_ad_id') {
            generate_page_url = generate_page_url.replace("ad_id=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'tub_campaign_name') {
            generate_page_url = generate_page_url.replace("campaign_name=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'tub_country') {
            generate_page_url = generate_page_url.replace("country=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 's_adSet_id') {
            generate_page_url = generate_page_url.replace("adSet_id=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'm_AdGroup') {
            generate_page_url = generate_page_url.replace("AdGroup=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 's_campaign_name') {
            generate_page_url = generate_page_url.replace("campaign_name=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'm_Campaign') {
            generate_page_url = generate_page_url.replace("Campaign=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'm_Device') {
            generate_page_url = generate_page_url.replace("Device=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'g_device') {
            generate_page_url = generate_page_url.replace("device=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 't_adgroupid') {
            generate_page_url = generate_page_url.replace("adgroupid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 't_campaignid') {
            generate_page_url = generate_page_url.replace("campaignid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 't_adid') {
            generate_page_url = generate_page_url.replace("adid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_campaignid') {
            generate_page_url = generate_page_url.replace("campaignid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_adgroupid') {
            generate_page_url = generate_page_url.replace("adgroupid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_creative') {
            generate_page_url = generate_page_url.replace("creative=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_keyword') {
            generate_page_url = generate_page_url.replace("keyword=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_placement') {
            generate_page_url = generate_page_url.replace("placement=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_target') {
            generate_page_url = generate_page_url.replace("target=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_devicemodel') {
            generate_page_url = generate_page_url.replace("devicemodel=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_matchtype') {
            generate_page_url = generate_page_url.replace("matchtype=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_network') {
            generate_page_url = generate_page_url.replace("netwo=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_device') {
            generate_page_url = generate_page_url.replace("device=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_adposition') {
            generate_page_url = generate_page_url.replace("adposition=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_gclid') {
            generate_page_url = generate_page_url.replace("gclid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_gbraid') {
            generate_page_url = generate_page_url.replace("gbra=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
          if (utm_name === 'yt_wbraid') {
            generate_page_url = generate_page_url.replace("wbraid=" + utm_val + "&", "");
          }else{
            generate_page_url = generate_page_url.replace(utm_name + "=" + utm_val + "&", "");
          }
        }
      }

      var ads_params = false;
      if (ads_network_tag === 'facebook') {
        var facebook_array = ["ad_id", "adset_id", "campaign_id", "ad_name", "adset_name", "campaign_name", "placement", "site_source_name", "fbclid"];
        for (let i = 0; i < facebook_array.length; i++) {
          if (generate_page_url.indexOf(facebook_array[i]) !== -1) {
            ads_params = true;
            break;
          }
        }
        if (!generate_page_url.includes('visi_source') && ads_params) {
          generate_page_url = generate_page_url + 'visi_source=' + ads_network_tag + '&';
        } else if (generate_page_url.includes('visi_source') && !ads_params) {
          generate_page_url = generate_page_url.replace('visi_source=facebook&', '');
        }
      } else if (ads_network_tag === 'google') {
        var google_array = ["campaignid", "creative", "placement", "target", "keyword", "devicemodel", "matchtype", "network", "device", "adposition", "gclid", "gbraid", "wbraid", "adgroupid"];
        for (let i = 0; i < google_array.length; i++) {
          if (generate_page_url.indexOf(google_array[i]) !== -1) {
            ads_params = true;
            break;
          }
        }
        if (!generate_page_url.includes('visi_source') && ads_params) {
          generate_page_url = generate_page_url + 'visi_source=' + ads_network_tag + '&';
        } else if (generate_page_url.includes('visi_source') && !ads_params) {
          generate_page_url = generate_page_url.replace('visi_source=google&', '');
        }
      } else if (ads_network_tag === 'microsoft') {
        var microsoft_array = ["keyword", "QueryString", "AdGroupID", "AdGroup", "AdID", "CampaignID", "Campaign", "Device", "MatchType", "Network","msclkid"];
        for (let i = 0; i < microsoft_array.length; i++) {
          if (generate_page_url.indexOf(microsoft_array[i]) !== -1) {
            ads_params = true;
            break;
          }
        }
        if (!generate_page_url.includes('visi_source') && ads_params) {
          generate_page_url = generate_page_url + 'visi_source=' + ads_network_tag + '&';
        } else if (generate_page_url.includes('visi_source') && !ads_params) {
          generate_page_url = generate_page_url.replace('visi_source=microsoft&', '');
        }
      } else if (ads_network_tag === 'snapchat') {
        var snapchat_array = ["ad_id", "adSet_id", "campaign_id", "creative_name", "adSet_name", "campaign_name", "creative_headline"];
        for (let i = 0; i < snapchat_array.length; i++) {
          if (generate_page_url.indexOf(snapchat_array[i]) !== -1) {
            ads_params = true;
            break;
          }
        }
        if (!generate_page_url.includes('visi_source') && ads_params) {
          generate_page_url = generate_page_url + 'visi_source=' + ads_network_tag + '&';
        } else if (generate_page_url.includes('visi_source') && !ads_params) {
          generate_page_url = generate_page_url.replace('visi_source=snapchat&', '');
        }
      } else if (ads_network_tag === 'revcontent') {
        var revcontent_array = ["adv_targets", "boost_id", "content_id", "city", "state", "country", "widget_id"];
        for (let i = 0; i < revcontent_array.length; i++) {
          if (generate_page_url.indexOf(revcontent_array[i]) !== -1) {
            ads_params = true;
            break;
          }
        }
        if (!generate_page_url.includes('visi_source') && ads_params) {
          generate_page_url = generate_page_url + 'visi_source=' + ads_network_tag + '&';
        } else if (generate_page_url.includes('visi_source') && !ads_params) {
          generate_page_url = generate_page_url.replace('visi_source=revcontent&', '');
        }
      } else if (ads_network_tag === 'taboola') {
        var taboola_array = ["tblci", "campaign_id", "campaign_name", "country", "platform", "site"];
        for (let i = 0; i < taboola_array.length; i++) {
          if (generate_page_url.indexOf(taboola_array[i]) !== -1) {
            ads_params = true;
            break;
          }
        }
        if (!generate_page_url.includes('visi_source') && ads_params) {
          generate_page_url = generate_page_url + 'visi_source=' + ads_network_tag + '&';
        } else if (generate_page_url.includes('visi_source') && !ads_params) {
          generate_page_url = generate_page_url.replace('visi_source=taboola&', '');
        }
      } else if (ads_network_tag === 'mgid') {
        var mgid_array = ["teaser_id", "widget_id", "campaign_id", "category_id", "geo_region", "click_id", "click_price"];
        for (let i = 0; i < mgid_array.length; i++) {
          if (generate_page_url.indexOf(mgid_array[i]) !== -1) {
            ads_params = true;
            break;
          }
        }
        if (!generate_page_url.includes('visi_source') && ads_params) {
          generate_page_url = generate_page_url + 'visi_source=' + ads_network_tag + '&';
        } else if (generate_page_url.includes('visi_source') && !ads_params) {
          generate_page_url = generate_page_url.replace('visi_source=mgid&', '');
        }
      } else if (ads_network_tag === 'outbrain') {
        var outbrain_array = ["ad_title", "section_name", "publisher_name", "campaign_id", "section_id", "publisher_id", "ob_click_id"];
        for (let i = 0; i < outbrain_array.length; i++) {
          if (generate_page_url.indexOf(outbrain_array[i]) !== -1) {
            ads_params = true;
            break;
          }
        }
        if (!generate_page_url.includes('visi_source') && ads_params) {
          generate_page_url = generate_page_url + 'visi_source=' + ads_network_tag + '&';
        } else if (generate_page_url.includes('visi_source') && !ads_params) {
          generate_page_url = generate_page_url.replace('visi_source=outbrain&', '');
        }
      } else if (ads_network_tag === 'tiktok') {
        var tiktok_array = ["adgroupid", "ttclid", "campaignid", "adid"];
        for (let i = 0; i < tiktok_array.length; i++) {
          if (generate_page_url.indexOf(tiktok_array[i]) !== -1) {
            ads_params = true;
            break;
          }
        }
        if (!generate_page_url.includes('visi_source') && ads_params) {
          generate_page_url = generate_page_url + 'visi_source=' + ads_network_tag + '&';
        } else if (generate_page_url.includes('visi_source') && !ads_params) {
          generate_page_url = generate_page_url.replace('visi_source=tiktok&', '');
        }
      } else if (ads_network_tag === 'youtube') {
        var youtube_array = ["campaignid", "creative", "placement", "target", "keyword", "devicemodel", "matchtype", "network", "device", "adposition", "gclid", "gbraid", "wbraid", "adgroupid"];
        for (let i = 0; i < youtube_array.length; i++) {
          if (generate_page_url.indexOf(youtube_array[i]) !== -1) {
            ads_params = true;
            break;
          }
        }
        if (!generate_page_url.includes('visi_source') && ads_params) {
          generate_page_url = generate_page_url + 'visi_source=' + ads_network_tag + '&';
        } else if (generate_page_url.includes('visi_source') && !ads_params) {
          generate_page_url = generate_page_url.replace('visi_source=youtube&', '');
        }
      }
      if (ads_network_tag === 'google') {
        generate_page_url = generate_page_url.replace('g_placement', 'placement');
        generate_page_url = generate_page_url.replace('g_device', 'device');
      }
      if (ads_network_tag === 'microsoft') {
        generate_page_url = generate_page_url.replace('m_keyword', 'keyword');
        generate_page_url = generate_page_url.replace('m_Device', 'Device');
        generate_page_url = generate_page_url.replace('m_Campaign', 'Campaign');
        generate_page_url = generate_page_url.replace('m_AdGroup', 'AdGroup');
      }
      if (ads_network_tag === 'taboola') {
        generate_page_url = generate_page_url.replace('tub_campaign_id', 'campaign_id');
        generate_page_url = generate_page_url.replace('tub_campaign_name', 'campaign_name');
        generate_page_url = generate_page_url.replace('tub_country', 'country');
      }
      if (ads_network_tag === 'snapchat') {
        generate_page_url = generate_page_url.replace('s_campaign_id', 'campaign_id');
        generate_page_url = generate_page_url.replace('s_campaign_name', 'campaign_name');
        generate_page_url = generate_page_url.replace('s_ad_id', 'ad_id');
        generate_page_url = generate_page_url.replace('s_adSet_id', 'adSet_id');
      }
      if (ads_network_tag === 'mgid') {
        generate_page_url = generate_page_url.replace('mg_campaign_id', 'campaign_id');
        generate_page_url = generate_page_url.replace('mg_widget_id', 'widget_id');
      }
      if (ads_network_tag === 'outbrain') {
        generate_page_url = generate_page_url.replace('out_campaign_id', 'campaign_id');
      }
      if (ads_network_tag === 'tiktok') {
        generate_page_url = generate_page_url.replace('t_adgroupid', 'adgroupid');
        generate_page_url = generate_page_url.replace('t_campaignid', 'campaignid');
        generate_page_url = generate_page_url.replace('t_adid', 'adid');
      }
      if (ads_network_tag === 'youtube') {
        generate_page_url = generate_page_url.replace('yt_campaignid', 'campaignid');
        generate_page_url = generate_page_url.replace('yt_adgroupid', 'adgroupid');
        generate_page_url = generate_page_url.replace('yt_creative', 'creative');
        generate_page_url = generate_page_url.replace('yt_keyword', 'keyword');
        generate_page_url = generate_page_url.replace('yt_placement', 'placement');
        generate_page_url = generate_page_url.replace('yt_target', 'target');
        generate_page_url = generate_page_url.replace('yt_devicemodel', 'devicemodel');
        generate_page_url = generate_page_url.replace('yt_matchtype', 'matchtype');
        generate_page_url = generate_page_url.replace('yt_network', 'network');
        generate_page_url = generate_page_url.replace('yt_device', 'device');
        generate_page_url = generate_page_url.replace('yt_adposition', 'adposition');
        generate_page_url = generate_page_url.replace('yt_gclid', 'gclid');
        generate_page_url = generate_page_url.replace('yt_gbraid', 'gbraid');
        generate_page_url = generate_page_url.replace('yt_wbraid', 'wbraid');
      }

      $("#your_url").val(generate_page_url)
    }
    $('[data-urlitem="generate_url"]').keyup(function() {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="custom_tag_key"]').click(function() {
      generate_page_url_function();
    });
    $('[data-urlitem="custom_tag"]').change(function() {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="fb_ads_net_param"]').change(function() {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="ggl_ads_net_param"]').change(function() {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="mic_ads_net_param"]').change(function () {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="out_ads_net_param"]').change(function () {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="rev_ads_net_param"]').change(function () {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="mg_ads_net_param"]').change(function () {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="tub_ads_net_param"]').change(function () {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="sna_ads_net_param"]').change(function () {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="tiktok_ads_net_param"]').change(function () {
      generate_page_url_function($(this));
    });
    $('[data-urlitem="yt_ads_net_param"]').change(function () {
      generate_page_url_function($(this));
    });
    function clearvalues(generate_page_url){
        var google_array = {"campaignid" : "{campaignid}",
        "creative" : "{creative}",
        "placement" : "{placement}",
        "target" : "{target}",
        "keyword" : "{keyword}",
        "devicemodel" : "{devicemodel}",
        "matchtype" : "{matchtype}",
        "network" : "{network}",
        "device" : "{device}",
        "adposition" : "{adposition}",
        "gclid" : "{gclid}",
        "gbraid" : "{gbraid}",
        "wbraid" : "{wbraid}",
        "adgroupid" : "{adgroupid}"};

        for (const [key, value] of Object.entries(google_array)) {
            generate_page_url = generate_page_url.replace(key+"="+value+"&", "");
            $("#your_url").val(generate_page_url);
        }
        var facebook_array = {"ad_id" : "{{ad.id}}",
        "adset_id" : "{{adset.id}}",
        "campaign_id" : "{{campaign.id}}",
        "ad_name" : "{{ad.name}}",
        "adset_name" : "{{adset.name}}",
        "campaign_name" : "{{campaign.name}}",
        "placement" : "{{placement}}",
        "site_source_name" : "{{site_source_name}}",
        "fbclid" : "{{fbclid}}"};
        for (const [key, value] of Object.entries(facebook_array)) {
            generate_page_url = generate_page_url.replace(key+"="+value+"&", "");
            $("#your_url").val(generate_page_url);
        }
        var microsoft_array = {"keyword" : "{keyword}",
        "QueryString" : "{QueryString}",
        "AdGroupID" : "{AdGroupID}",
        "AdGroup" : "{AdGroup}",
        "AdID" : "{AdID}",
        "CampaignID" : "{CampaignID}",
        "Campaign" : "{Campaign}",
        "Device" : "{Device}",
        "MatchType" : "{MatchType}",
        "Network" : "{Network}",
        "msclkid" : "{msclkid}"};
        for (const [key, value] of Object.entries(microsoft_array)) {
            generate_page_url = generate_page_url.replace(key+"="+value+"&", "");
            $("#your_url").val(generate_page_url);
        }
        var snapchat_array = {"ad_id" : "{{ad.id}}",
        "adSet_id" : "{{adSet.id}}",
        "campaign_id" : "{{campaign.id}}",
        "creative_name" : "{{creative.name}}",
        "adSet_name" : "{{adSet.name}}",
        "campaign_name" : "{{campaign.name}}",
        "creative_headline" : "{{creative.headline}}"};
        for (const [key, value] of Object.entries(snapchat_array)) {
            generate_page_url = generate_page_url.replace(key+"="+value+"&", "");
            $("#your_url").val(generate_page_url);
        }
        var revcontent_array = {"adv_targets" : "{adv_targets}",
        "boost_id" : "{boost_id}",
        "content_id" : "{content_id}",
        "city" : "{city}",
        "state" : "{state}",
        "country" : "{country}",
        "widget_id" : "{widget_id}"};
        for (const [key, value] of Object.entries(revcontent_array)) {
            generate_page_url = generate_page_url.replace(key+"="+value+"&", "");
            $("#your_url").val(generate_page_url);
        }
        var taboola_array = {"tblci" : "{tblci}",
        "campaign_id" : "{campaign_id}",
        "campaign_name" : "{campaign_name}",
        "country" : "{country}",
        "platform" : "{platform}",
        "site" : "{site}"};
        for (const [key, value] of Object.entries(taboola_array)) {
            generate_page_url = generate_page_url.replace(key+"="+value+"&", "");
            $("#your_url").val(generate_page_url);
        }
        var mgid_array = {"teaser_id" : "{teaser_id}",
        "widget_id" : "{widget_id}",
        "campaign_id" : "{campaign_id}",
        "category_id" : "{category_id}",
        "geo_region" : "{geo_region}",
        "click_id" : "{click_id}",
        "click_price" : "{click_price}"};
        for (const [key, value] of Object.entries(mgid_array)) {
            generate_page_url = generate_page_url.replace(key+"="+value+"&", "");
            $("#your_url").val(generate_page_url);
        }
        var outbrain_array = {"ad_title" : "{ad_title}",
        "section_name" : "{section_name}",
        "publisher_name" : "{publisher_name}",
        "campaign_id" : "{campaign_id}",
        "section_id" : "{section_id}",
        "publisher_id" : "{publisher_id}",
        "ob_click_id" : "{ob_click_id}"};
        for (const [key, value] of Object.entries(outbrain_array)) {
            generate_page_url = generate_page_url.replace(key+"="+value+"&", "");
            $("#your_url").val(generate_page_url);
        }
        var tiktok_array = {"campaignid" : "__CAMPAIGNID__",
        "adgroupid" : "__ADGROUPID__",
        "adid" : "__ADID__",
        "ttclid" : "_CLICKID_"};
        for (const [key, value] of Object.entries(tiktok_array)) {
            generate_page_url = generate_page_url.replace(key+"="+value+"&", "");
            $("#your_url").val(generate_page_url);
        }
        var youtube_array = {"campaignid" : "{campaignid}",
        "creative" : "{creative}",
        "placement" : "{placement}",
        "target" : "{target}",
        "keyword" : "{keyword}",
        "devicemodel" : "{devicemodel}",
        "matchtype" : "{matchtype}",
        "network" : "{network}",
        "device" : "{device}",
        "adposition" : "{adposition}",
        "gclid" : "{gclid}",
        "gbraid" : "{gbraid}",
        "wbraid" : "{wbraid}",
        "adgroupid" : "{adgroupid}"};

        for (const [key, value] of Object.entries(youtube_array)) {
            generate_page_url = generate_page_url.replace(key+"="+value+"&", "");
            $("#your_url").val(generate_page_url);
        }
    }
    $('input[id="ads_network_tags"]').on('click', function () {
      var generate_page_url = $("#your_url").val();
      generate_page_url = generate_page_url.replace('visi_source=facebook&','');
      generate_page_url = generate_page_url.replace('visi_source=google&','');
      generate_page_url = generate_page_url.replace('visi_source=microsoft&','');
      generate_page_url = generate_page_url.replace('visi_source=snapchat&','');
      generate_page_url = generate_page_url.replace('visi_source=revcontent&','');
      generate_page_url = generate_page_url.replace('visi_source=taboola&','');
      generate_page_url = generate_page_url.replace('visi_source=mgid&','');
      generate_page_url = generate_page_url.replace('visi_source=outbrain&','');
      generate_page_url = generate_page_url.replace('visi_source=tiktok&','');
      generate_page_url = generate_page_url.replace('visi_source=youtube&','');
      $("#your_url").val(generate_page_url);
      clearvalues(generate_page_url);
    });
  }, []);
  return (
    <>
      <div style={{ display: activeURLBuilder === "URL-builder-and-custom-tags" ? "block" : "none"}}>
        <Group position="apart" mb="xs">
          <Text weight={500}>URL BUILDER AND CUSTOME TAGS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
        </Group>
        <Button onClick={() => handleAddURLBuilder()}>
          Add a New URL
        </Button>
        {urlBuilderAndCustomData.map((item,index) => (
          <Box
           key={index}
            p={4}
            pl={20}
            mt={10}
            sx={(theme) => ({
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
              padding: "10px",
              borderRadius: theme.radius.md,
              border: "0.0625rem solid transparent",
              borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] :  '#dee2e6'
            })}
          >
            <Grid gutter="xs">
              <Grid.Col span={3}>
                <Text mt={3}>
                  {item.name}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text mt={3}>
                  {item.url}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Flex
                  justify="flex-end"
                  align="center"
                  direction="row"
                  wrap="wrap"
                >
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    onClick={() => updateURLBuilder(item.id)}
                  >
                    <IconPencil size="1.1rem" />
                  </ActionIcon>
                  <ActionIcon
                    variant="default"
                    size="lg"
                    mx={3}
                    onClick={() => deleteURLBuilderConfirm(item.id)}
                  >
                    <IconTrash size="1.1rem" />
                  </ActionIcon>
                </Flex>
              </Grid.Col>
            </Grid>
          </Box>
        ))}
      </div>
      <div style={{ display: activeAddURLBuilder === "add-URLbuilder" ? "block" : "none",marginLeft:10}}>
        <Group position="apart" mb="xs">
          <Text weight={500}>URL BUILDER AND CUSTOME TAGS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
        </Group>
        <div>
          <Group spacing="xs" mb={20} mt={20}>
            <Text size="sm" fz={14} mt={-10}>Select URL Page:</Text>
            <Select
              name="page_dd"
              id="page_dd"
              placeholder="Select Page"
              data={[
                { value: 'https://ztrackingsystem.com/lp.html', label: 'lp.html' },
                { value: 'https://ztrackingsystem.com/upsell.html', label: 'upsell.html' },
                { value: 'https://ztrackingsystem.com/downsell.html', label: 'downsell.html' },
                { value: 'https://ztrackingsystem.com/checkout.html', label: 'checkout.html' },
                { value: 'https://ztrackingsystem.com/thankyou.html', label: 'thankyou.html' }
              ]}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
              mb={10}
              onChange={(event) => getCustomTag(event)}
            />
          </Group>
          <Text size="sm" fw={500} mb={10}>Standard Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_id :</Text>
            <Input name="utm_id" id="utm_id" data-urlitem="generate_url" w={205} ml={55}/>
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_source :</Text>
            <Input name="utm_source" id="utm_source" data-urlitem="generate_url" w={205} ml={25}/>
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_medium :</Text>
            <Input name="utm_medium" id="utm_medium" data-urlitem="generate_url" w={205} ml={15}/>
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_campaign :</Text>
            <Input name="utm_campaign" id="utm_campaign" data-urlitem="generate_url" w={205} ml={5}/>
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_term :</Text>
            <Input name="utm_term" id="utm_term" data-urlitem="generate_url" w={205} ml={36}/>
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={10}>utm_content :</Text>
            <Input name="utm_content" id="utm_content" data-urlitem="generate_url" w={205} ml={18}/>
          </Group>
        </div>
        <Divider my="md" />
        <div>
          <Text size="sm" fw={500} mb={10}>Custom Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
          <Group mb={10}>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                padding: "10px",
                borderRadius: "4px"
              })}
            >
              <Text size="sm" fz={14}>Below you can create up to 5 custom tags. that you can use to filter your test or campaign stats.</Text>
              <Space h="xs" />
              <div style={{ display: "Flex" }}>
                <Text size="sm" fz={14} fw={500} mr={5}>NOTE:</Text>
                <Text size="sm" fz={14}>you have to add the custom tags to your test or campaign URLs for this feature to work!</Text>
              </div>
            </Box>
          </Group>
          <Group spacing="xs" mb={15} mt={15}>
            <Text size="sm" fz={14} mb={10}>Add New Custom Tag :</Text>
            <Input w={205}/>
            <Button type="submit" size="sm">
              Save Custom Tag
            </Button>
          </Group>
          <Group mt={10}>
            <Table striped highlightOnHover withBorder w={513}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Custom Tag</th>
                <th></th>
              </tr>
            </thead>
              <tbody>
                {customTagTableData.map((item,index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>
                      <Flex
                        justify="flex-end"
                        align="center"
                        direction="row"
                        wrap="wrap"
                      >
                        <ActionIcon
                          variant="default"
                          size="md"
                          onClick={() => deleteCustomeTagConfirm(item.id)}
                        >
                          <IconTrash size="1.1rem" />
                      </ActionIcon>
                      </Flex>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Group>
          <Group spacing="xs" mt={20} mb={10}>
            <Text size="sm" fz={14} w={100}>custom_tag-1:</Text>
            <Select
              name="custom_tag_key1"
              id="custom_tag_key1"
              data-urlitem="custom_tag_key"
              w={180}
              placeholder="Select Custom Tag"
              data={customTagDropDown}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
              
            />
            <Input id="custom_tag_value1" data-urlitem="custom_tag" name="custom_tag_1" w={200}/>
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} w={100}>custom_tag-2:</Text>
            <Select
              name="custom_tag_key2"
              id="custom_tag_key2"
              data-urlitem="custom_tag_key"
              w={180}
              placeholder="Select Custom Tag"
              data={customTagDropDown}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
            />
            <Input id="custom_tag_value2" data-urlitem="custom_tag" name="custom_tag_2" w={200}/>
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} w={100}>custom_tag-3:</Text>
            <Select
              name="custom_tag_key3"
              id="custom_tag_key3"
              data-urlitem="custom_tag_key"
              w={180}
              placeholder="Select Custom Tag"
              data={customTagDropDown}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
            />
            <Input id="custom_tag_value3" data-urlitem="custom_tag" name="custom_tag_3" w={200}/>
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} w={100}>custom_tag-4:</Text>
            <Select
              name="custom_tag_key4"
              id="custom_tag_key4"
              data-urlitem="custom_tag_key"
              w={180}
              placeholder="Select Custom Tag"
              data={customTagDropDown}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
            />
            <Input id="custom_tag_value4" data-urlitem="custom_tag" name="custom_tag_4" w={200}/>
          </Group>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} w={100}>custom_tag-5:</Text>
            <Select
              name="custom_tag_key5"
              id="custom_tag_key5"
              data-urlitem="custom_tag_key"
              w={180}
              placeholder="Select Custom Tag"
              data={customTagDropDown}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
            />
            <Input id="custom_tag_value5" data-urlitem="custom_tag" name="custom_tag_5" w={200}/>
          </Group>
        </div>
        <Divider my="md" />
        <div>
          <Text size="sm" fw={500} mb={10}>Network Token <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} mb={5}>Select Network Token:</Text>
            <Select
              name="ads_network_tags"
              id="ads_network_tags"
              w={180}
              placeholder="Select Network Token"
              data={[
                { value: 'facebook', label: 'Facebook Tag' },
                { value: 'google', label: 'Google Tag' },
                { value: 'microsoft', label: 'Microsoft Tag' },
                { value: 'snapchat', label: 'Snapchat Tag' },
                { value: 'revcontent', label: 'RevContent Tag' },
                { value: 'taboola', label: 'Taboola Tag' },
                { value: 'mgid', label: 'MGID Tag' },
                { value: 'outbrain', label: 'Outbrain Tag' },
                { value: 'tiktok', label: 'Tiktok Tag' },
                { value: 'youtube', label: 'Youtube Tag' }
              ]}
              value={networkTokenValue}
              searchable
              nothingFound="No options"
              maxDropdownHeight={120}
              size="sm"
              dropdownPosition="bottom"
              onChange={handleNetworkTokenAction}
            />
          </Group>
          <div style={{marginBottom:20,display: networkTokenValue === "facebook" ? "block" : "none"}}>
            <Group mb={10}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                  padding: "10px",
                  borderRadius: "4px"
                })}
              >
                <div style={{ display: "Flex" }}>
                  <Text size="sm" fz={14} fw={500} mr={5} w={80}>Please note:</Text>
                  <Text size="sm" fz={14} w={530}>Visiopt has the ability to automatically track and pass Facebook Data directly through</Text>
                </div>
                <Text size="sm" fz={14} w={610}>Facebook's API. When you do this, you do not have to manually pass data from UTM parameters.</Text>
              </Box>
            </Group>
            <Text size="sm" fw={500} mb={20}>Facebook Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
            <Checkbox
              name="campaign_name" id="campaign_name" value="{{campaign.name}}" data-urlitem="fb_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_name</Text>
                  <Text c="red">the name of the campaign that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="adset_name" id="adset_name" value="{{adset.name}}" data-urlitem="fb_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adset_name</Text>
                  <Text c="red">the name of the ad set that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="ad_name" id="ad_name" value="{{ad.name}}" data-urlitem="fb_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>ad_name</Text>
                  <Text c="red">the name of the ad that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="placement" id="placement" value="{{placement}}" data-urlitem="fb_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={140}>placement</Text>
                  <Text c="red">shows where the ad appeared. Includes: the desktop feed, mobile feed, right column of Facebook Messenger inbox,Messenger inbox, within the Instagram Feed or Instagram Stories</Text>
                </div>
              }
            />
            <Checkbox
              name="site_source_name" id="site_source_name" value="{{site_source_name}}" data-urlitem="fb_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>site_source_name</Text>
                  <Text c="red">returns “fb” for Facebook, “ig” for Instagram, “msg” for Messenger, or “an” for the Audience Network</Text>
                </div>
              }
            />
            <Checkbox
              name="fbclid" id="fbclid" value="{{fbclid}}" data-urlitem="fb_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>fbclid</Text>
                  <Text c="red"></Text>
                </div>
              }
            />
            <Checkbox
              name="campaign_id" id="campaign_id" value="{{campaign.id}}" data-urlitem="fb_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_id</Text>
                  <Text c="red">the ID of the campaign that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="adset_id" id="adset_id" value="{{adset.id}}" data-urlitem="fb_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adset_id</Text>
                  <Text c="red">the ID of the ad set that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="ad_id" id="ad_id" value="{{ad.id}}" data-urlitem="fb_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>ad_id</Text>
                  <Text c="red">the ID of the ad that generated the click</Text>
                </div>
              }
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "google" ? "block" : "none"}}>
            <Group mb={10}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                  padding: "10px",
                  borderRadius: "4px"
                })}
              >
                <div style={{ display: "Flex" }}>
                  <Text size="sm" fz={14} fw={500} mr={5} w={80}>Please note:</Text>
                  <Text size="sm" fz={14} w={530}>Visiopt has the ability to automatically track and pass Google Data directly through </Text>
                </div>
                <Text size="sm" fz={14} w={610}>Google's API. When you do this, you do not have to manually pass data from UTM parameters.</Text>
              </Box>
            </Group>
            <Text size="sm" fw={500} mb={20}>Google Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
            <Checkbox
              name="campaignid" id="campaignid" value="{campaignid}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaignid</Text>
                  <Text c="red">the ID of the campaign that served the ad</Text>
                </div>
              }
            />
            <Checkbox
              name="wbraid" id="adgroupid" value="{adgroupid}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adgroupid</Text>
                  <Text c="red">The ad group ID</Text>
                </div>
              }
            />
            <Checkbox
              name="creative" id="creative" value="{creative}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>creative</Text>
                  <Text c="red">the ID of your ad creative</Text>
                </div>
              }
            />
            <Space h="md" />
            <Checkbox
              name="keyword" id="keyword" value="{keyword}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>keyword</Text>
                  <Text c="red">the keyword phrase that triggered the ad</Text>
                </div>
              }
            />
            <Checkbox
              name="placement" id="g_placement" value="{placement}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>placement</Text>
                  <Text c="red">the site your ad was clicked on</Text>
                </div>
              }
            />
            <Checkbox
              name="target" id="target" value="{target}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>target</Text>
                  <Text c="red">the category of the placement</Text>
                </div>
              }
            />
            <Checkbox
              name="devicemodel" id="devicemodel" value="{devicemodel}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>devicemodel</Text>
                  <Text c="red">What model of phone or tablet the click came from (for example, "Apple+iPhone")</Text>
                </div>
              }
            />
            <Checkbox
              name="matchtype" id="matchtype" value="{matchtype}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>matchtype</Text>
                  <Text c="red">will return “e” for exact, “p” for phrase, or “b” for broad</Text>
                </div>
              }
            />
            <Checkbox
              name="network" id="network" value="{network}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>network</Text>
                  <Text c="red">returns “g” for Google search, “s” for search partner or “d” for display</Text>
                </div>
              }
            />
            <Checkbox
              name="g_device" id="g_device" value="{device}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>device</Text>
                  <Text c="red">returns “m” for mobile, “t” for tablet or “c” for computer</Text>
                </div>
              }
            />
            <Checkbox
              name="adposition" id="adposition" value="{adposition}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adposition</Text>
                  <Text c="red">The position on the page that your ad appeared in, with a value like "1t2"(this means page 1, top, position 2)</Text>
                </div>
              }
            />
            <Checkbox
              name="gclid" id="gclid" value="{gclid}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>gclid</Text>
                  <Text c="red">The Google click identifier of a click that comes from your ad</Text>
                </div>
              }
            />
            <Checkbox
              name="gbraid" id="gbraid" value="{gbraid}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>gbraid</Text>
                  <Text c="red">App measurement (deep linked campaigns)</Text>
                </div>
              }
            />
            <Checkbox
              name="wbraid" id="wbraid" value="{wbraid}" data-urlitem="ggl_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>wbraid</Text>
                  <Text c="red">Web measurement</Text>
                </div>
              }
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "microsoft" ? "block" : "none"}}>
            <Group mb={10}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                  padding: "10px",
                  borderRadius: "4px"
                })}
              >
                <div style={{ display: "Flex" }}>
                  <Text size="sm" fz={14} fw={500} mr={5} w={80}>Please note:</Text>
                  <Text size="sm" fz={14} w={530}>Visiopt has the ability to automatically track and pass Microsoft Data directly through </Text>
                </div>
                <Text size="sm" fz={14} w={610}>Microsoft's API. When you do this, you do not have to manually pass data from UTM parameters.</Text>
              </Box>
            </Group>
            <Text size="sm" fw={500} mb={20}>Microsoft Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
            <Checkbox
              name="Campaign" id="m_Campaign" value="{Campaign}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>Campaign</Text>
                  <Text c="red">the name of the campaign that triggered the ad</Text>
                </div>
              }
            />
            <Checkbox
              name="AdGroup" id="m_AdGroup" value="{AdGroup}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>AdGroup</Text>
                  <Text c="red">the name of the ad group that triggered the ad –</Text>
                </div>
              }
            />
            <Checkbox
              name="keyword" id="m_keyword" value="{keyword}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>keyword</Text>
                  <Text c="red">the keyword phrase that triggered the ad</Text>
                </div>
              }
            />
            <Space h="md" />
            <Checkbox
              name="CampaignID" id="CampaignID" value="{CampaignID}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>CampaignID</Text>
                  <Text c="red">the ID of the campaign that triggered the ad</Text>
                </div>
              }
            />
            <Checkbox
              name="AdGroupID" id="AdGroupID" value="{AdGroupID}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>AdGroupID</Text>
                  <Text c="red">the ID of the ad group that triggered the ad</Text>
                </div>
              }
            />
            <Checkbox
              name="AdID" id="AdID" value="{AdID}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>AdID</Text>
                  <Text c="red">the numeric ID of your ad creative</Text>
                </div>
              }
            />
            <Space h="md" />
            <Checkbox
              name="QueryString" id="QueryString" value="{QueryString}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>QueryString</Text>
                  <Text c="red">the exact search phrase entered by the user</Text>
                </div>
              }
            />
            <Checkbox
              name="m_Device" id="m_Device" value="{Device}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>Device</Text>
                  <Text c="red">returns “m” for mobile, “t” for tablet or “c” for computer</Text>
                </div>
              }
            />
            <Checkbox
              name="MatchType" id="MatchType" value="{MatchType}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>MatchType</Text>
                  <Text c="red">will return “e” for exact, “p” for phrase, or “b” for broad</Text>
                </div>
              }
            />
            <Checkbox
              name="Network" id="Network" value="{Network}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>Network</Text>
                  <Text c="red">returns “o” for Bing and AOL or “s” for syndicated ads</Text>
                </div>
              }
            />
            <Checkbox
              name="msclkid" id="msclkid" value="{msclkid}" data-urlitem="mic_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>msclkid</Text>
                  <Text c="red">The Microsoft click identifier of a click that comes from your ad</Text>
                </div>
              }
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "snapchat" ? "block" : "none"}}>
            <Text size="sm" fw={500} mb={20}>Snapchat Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
            <Checkbox
              name="ad_id" id="s_ad_id" value="{{ad.id}}" data-urlitem="sna_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>ad_id</Text>
                  <Text c="red">the ID of the ad that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="adSet_id" id="s_adSet_id" value="{{adSet.id}}" data-urlitem="sna_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adSet_id</Text>
                  <Text c="red">the ID of the ad set that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="campaign_id" id="s_campaign_id" value="{{campaign.id}}" data-urlitem="sna_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_id</Text>
                  <Text c="red">the ID of the campaign that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="creative_name" id="creative_name" value="{{creative.name}}" data-urlitem="sna_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>creative_name</Text>
                  <Text c="red">the name of the ad that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="adSet_name" id="adSet_name" value="{{adSet.name}}" data-urlitem="sna_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adSet_name</Text>
                  <Text c="red">name of the ad set that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="campaign_name" id="s_campaign_name" value="{{campaign.name}}" data-urlitem="sna_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_name</Text>
                  <Text c="red">the name of the campaign that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="creative_headline" id="creative_headline" value="{{creative.headline}}" data-urlitem="sna_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>creative_headline</Text>
                  <Text c="red">the headline of the ad that generated the click</Text>
                </div>
              }
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "revcontent" ? "block" : "none"}}>
            <Text size="sm" fw={500} mb={20}>RevContent Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
            <Checkbox
              name="adv_targets" id="adv_targets" value="{adv_targets}" data-urlitem="rev_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adv_targets</Text>
                  <Text c="red">traffic type the creative was serving based on</Text>
                </div>
              }
            />
            <Checkbox
              name="boost_id" id="boost_id" value="{boost_id}" data-urlitem="rev_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>boost_id</Text>
                  <Text c="red">numerical identifier of the campaign clicked on</Text>
                </div>
              }
            />
            <Checkbox
              name="content_id" id="content_id" value="{content_id}" data-urlitem="rev_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>content_id</Text>
                  <Text c="red">numerical identifier of the content clicked on</Text>
                </div>
              }
            />
            <Checkbox
              name="city" id="city" value="{city}" data-urlitem="rev_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>city</Text>
                  <Text c="red">city of the user clicking on the content</Text>
                </div>
              }
            />
            <Checkbox
              name="state" id="state" value="{state}" data-urlitem="rev_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>state</Text>
                  <Text c="red">state of the user clicking on the content</Text>
                </div>
              }
            />
            <Checkbox
              name="country" id="country" value="{country}" data-urlitem="rev_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>country</Text>
                  <Text c="red">country of the user clicking on the content</Text>
                </div>
              }
            />
            <Checkbox
              name="widget_id" id="widget_id" value="{widget_id}" data-urlitem="rev_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>widget_id</Text>
                  <Text c="red">numerical identifier of the widget the click occurred on</Text>
                </div>
              }
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "taboola" ? "block" : "none"}}>
            <Text size="sm" fw={500} mb={20}>Taboola Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
            <Checkbox
              name="tblci" id="tblci" value="{tblci}" data-urlitem="tub_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>tblci</Text>
                  <Text c="red">The Taboola click identifier of a click that comes from your ad</Text>
                </div>
              }
            />
            <Checkbox
              name="campaign_id" id="tub_campaign_id" value="{campaign_id}" data-urlitem="tub_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_id</Text>
                  <Text c="red">A unique Taboola campaign ID. This campaign ID can be found on the "Campaign Management" page in Backstage</Text>
                </div>
              }
            />
            <Checkbox
              name="campaign_name" id="tub_campaign_name" value="{campaign_name}" data-urlitem="tub_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_name</Text>
                  <Text c="red">The campaign name, as defined in the "Campaign Management" page in Backstage</Text>
                </div>
              }
            />
            <Checkbox
              name="country" id="tub_country" value="{country}" data-urlitem="tub_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>country</Text>
                  <Text c="red">country of the user clicking on the content</Text>
                </div>
              }
            />
            <Checkbox
              name="platform" id="platform" value="{platform}" data-urlitem="tub_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>platform</Text>
                  <Text c="red">The user platform on which your item was presented.This returns "Desktop," "Mobile," or "Tablet"</Text>
                </div>
              }
            />
            <Checkbox
              name="site" id="site" value="{site}" data-urlitem="tub_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>site</Text>
                  <Text c="red">The user site on which your item was presented.</Text>
                </div>
              }
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "mgid" ? "block" : "none"}}>
            <Text size="sm" fw={500} mb={20}>MGID Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
            <Checkbox
              name="teaser_id" id="teaser_id" value="{teaser_id}" data-urlitem="mg_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>teaser_id</Text>
                  <Text c="red">Returns the Teaser ID</Text>
                </div>
              }
            />
            <Checkbox
              name="widget_id" id="mg_widget_id" value="{widget_id}" data-urlitem="mg_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>widget_id</Text>
                  <Text c="red">Returns the site from which the user came</Text>
                </div>
              }
            />
            <Checkbox
              name="campaign_id" id="mg_campaign_id" value="{campaign_id}" data-urlitem="mg_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_id</Text>
                  <Text c="red">Returns the Campaign ID in the MGID system</Text>
                </div>
              }
            />
            <Checkbox
              name="category_id" id="category_id" value="{category_id}" data-urlitem="mg_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>category_id</Text>
                  <Text c="red">Returns the ID of the teaser category in the MGID system</Text>
                </div>
              }
            />
            <Checkbox
              name="geo_region" id="geo_region" value="{geo_region}" data-urlitem="mg_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>geo_region</Text>
                  <Text c="red">Returns the city or region of impressions selected in the campaign settings</Text>
                </div>
              }
            />
            <Checkbox
              name="click_id" id="click_id" value="{click_id}" data-urlitem="mg_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>click_id</Text>
                  <Text c="red">Returns the unique ID of the click</Text>
                </div>
              }
            />
            <Checkbox
              name="click_price" id="click_price" value="{click_price}" data-urlitem="mg_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>click_price</Text>
                  <Text c="red">Returns the price per click</Text>
                </div>
              }
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "outbrain" ? "block" : "none"}}>
            <Text size="sm" fw={500} mb={20}>Outbrain Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
            <Checkbox
              name="ad_title" id="ad_title" value="{ad_title}" data-urlitem="out_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>ad_title</Text>
                  <Text c="red">Returns the headline of your ad</Text>
                </div>
              }
            />
            <Checkbox
              name="section_name" id="section_name" value="{section_name}" data-urlitem="out_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>section_name</Text>
                  <Text c="red">Returns the referrer's section name</Text>
                </div>
              }
            />
            <Checkbox
              name="publisher_name" id="publisher_name" value="{publisher_name}" data-urlitem="out_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>publisher_name</Text>
                  <Text c="red">Returns the referrer's publisher name</Text>
                </div>
              }
            />
            <Checkbox
              name="campaign_id" id="out_campaign_id" value="{campaign_id}" data-urlitem="out_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaign_id</Text>
                  <Text c="red">Returns the ID of the campaign</Text>
                </div>
              }
            />
            <Checkbox
              name="section_id" id="section_id" value="{section_id}" data-urlitem="out_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>section_id</Text>
                  <Text c="red">Returns the section ID of the referrer</Text>
                </div>
              }
            />
            <Checkbox
              name="publisher_id" id="publisher_id" value="{publisher_id}" data-urlitem="out_ads_net_param"s
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>publisher_id</Text>
                  <Text c="red">Returns the referrer's publisher ID</Text>
                </div>
              }
            />
            <Checkbox
              name="ob_click_id" id="ob_click_id" value="{ob_click_id}" data-urlitem="out_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>ob_click_id</Text>
                  <Text c="red">Returns the unique click ID</Text>
                </div>
              }
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "tiktok" ? "block" : "none"}}>
            <Group mb={10}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                  padding: "10px",
                  borderRadius: "4px"
                })}
              >
                <div style={{ display: "Flex" }}>
                  <Text size="sm" fz={14} fw={500} mr={5} w={80}>Please note:</Text>
                  <Text size="sm" fz={14} w={530}>Visiopt has the ability to automatically track and pass Tiktok Data directly through</Text>
                </div>
                <Text size="sm" fz={14} w={610}>Tiktok's API. When you do this, you do not have to manually pass data from UTM parameters.</Text>
              </Box>
            </Group>
            <Text size="sm" fw={500} mb={20}>Tiktok Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
            <Checkbox
              name="campaignid" id="t_campaignid" value="__CAMPAIGNID__" data-urlitem="tiktok_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaignid</Text>
                  <Text c="red">the ID of the campaign that served the ad</Text>
                </div>
              }
            />
            <Checkbox
              name="adgroupid" id="t_adgroupid" value="__ADGROUPID__" data-urlitem="tiktok_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adgroupid</Text>
                  <Text c="red">The ad group ID</Text>
                </div>
              }
            />
            <Checkbox
              name="adid" id="t_adid" value="__ADID__" data-urlitem="tiktok_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adid</Text>
                  <Text c="red">the ID of the ad that generated the click</Text>
                </div>
              }
            />
            <Checkbox
              name="ttclid" id="ttclid" value="_CLICKID_" data-urlitem="tiktok_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={140}>ttclid</Text>
                  <Text c="red">The Tiktok click identifier of a click that comes from your ad</Text>
                </div>
              }
            />
          </div>
          <div style={{marginBottom:20,display: networkTokenValue === "youtube" ? "block" : "none"}}>
            <Group mb={10}>
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
                  padding: "10px",
                  borderRadius: "4px"
                })}
              >
                <div style={{ display: "Flex" }}>
                  <Text size="sm" fz={14} fw={500} mr={5} w={80}>Please note:</Text>
                  <Text size="sm" fz={14} w={530}>Visiopt has the ability to automatically track and pass Google Data directly through </Text>
                </div>
                <Text size="sm" fz={14} w={610}>Google's API. When you do this, you do not have to manually pass data from UTM parameters.</Text>
              </Box>
            </Group>
            <Text size="sm" fw={500} mb={20}>Youtube Tag <IconHelp size="1.1rem" color="red" style={{marginBottom:-4}}/></Text>
            <Checkbox
              name="campaignid" id="yt_campaignid" value="{campaignid}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>campaignid</Text>
                  <Text c="red">the ID of the campaign that served the ad</Text>
                </div>
              }
            />
            <Checkbox
              name="wbraid" id="yt_adgroupid" value="{adgroupid}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adgroupid</Text>
                  <Text c="red">The ad group ID</Text>
                </div>
              }
            />
            <Checkbox
              name="creative" id="yt_creative" value="{creative}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>creative</Text>
                  <Text c="red">the ID of your ad creative</Text>
                </div>
              }
            />
            <Space h="md" />
            <Checkbox
              name="keyword" id="yt_keyword" value="{keyword}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>keyword</Text>
                  <Text c="red">the keyword phrase that triggered the ad</Text>
                </div>
              }
            />
            <Checkbox
              name="placement" id="yt_placement" value="{placement}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>placement</Text>
                  <Text c="red">the site your ad was clicked on</Text>
                </div>
              }
            />
            <Checkbox
              name="target" id="yt_target" value="{target}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>target</Text>
                  <Text c="red">the category of the placement</Text>
                </div>
              }
            />
            <Checkbox
              name="devicemodel" id="yt_devicemodel" value="{devicemodel}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>devicemodel</Text>
                  <Text c="red">What model of phone or tablet the click came from (for example, "Apple+iPhone")</Text>
                </div>
              }
            />
            <Checkbox
              name="matchtype" id="yt_matchtype" value="{matchtype}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>matchtype</Text>
                  <Text c="red">will return “e” for exact, “p” for phrase, or “b” for broad</Text>
                </div>
              }
            />
            <Checkbox
              name="network" id="yt_network" value="{network}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>network</Text>
                  <Text c="red">returns “g” for Google search, “s” for search partner or “d” for display</Text>
                </div>
              }
            />
            <Checkbox
              name="yt_device" id="yt_device" value="{device}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>device</Text>
                  <Text c="red">returns “m” for mobile, “t” for tablet or “c” for computer</Text>
                </div>
              }
            />
            <Checkbox
              name="adposition" id="yt_adposition" value="{adposition}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>adposition</Text>
                  <Text c="red">The position on the page that your ad appeared in, with a value like "1t2"(this means page 1, top, position 2)</Text>
                </div>
              }
            />
            <Checkbox
              name="gclid" id="yt_gclid" value="{gclid}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>gclid</Text>
                  <Text c="red">The Google click identifier of a click that comes from your ad</Text>
                </div>
              }
            />
            <Checkbox
              name="gbraid" id="yt_gbraid" value="{gbraid}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>gbraid</Text>
                  <Text c="red">App measurement (deep linked campaigns)</Text>
                </div>
              }
            />
            <Checkbox
              name="wbraid" id="yt_wbraid" value="{wbraid}" data-urlitem="yt_ads_net_param"
              mb={10}
              label={
                <div style={{display:"Flex",marginTop: -3}}>
                  <Text w={130}>wbraid</Text>
                  <Text c="red">Web measurement</Text>
                </div>
              }
            />
          </div>
        </div>
        <Divider my="md" />
        <div>
          <Group spacing="xs" mb={10}>
            <Text size="sm" fz={14} w={60} mt={-75}>Your URL:</Text>
            <Textarea
              name="your_url" id="your_url"
              //size="xl"
              autosize
              minRows={3}
              maxRows={20}
              w={700}
            />
          </Group>
          <CopyButton timeout={5000}>
            {({ copied, copy }) => (
              <Button color={copied ? 'teal' : 'blue'} onClick={copy} size="sm" w={120} ml={70}>
                {copied ? 'Copied URL' : ' Copy URL'}
              </Button>
            )}
          </CopyButton>
          <Text fz={14} mt={40}>To save the URL for future use, enter a name below:</Text>
          <Group spacing="xs" mb={20}>
            <TextInput name="urlname" id="urlname" w={170}/>
            <Button size="sm" w={100}>
              Save URL
            </Button>
          </Group>
        </div>
      </div>
    </>
  );
}