import {
  Paper,
  Text,
  Container,
  Button,
  Center,
  Space,
  Radio,
  Stack
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import bigLogo from '../../img/big-logo.png';
import { useEffect } from 'react';
import { visiMemberData } from '../../GeneralFunction';
import { useNavigate } from "react-router-dom";
import { visiUserSession } from '../../GeneralFunction';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import {
  IconX
} from "@tabler/icons-react";
import { visiUserPermission } from '../../GeneralFunction';
import { useAuth } from '../../AuthContext';
import LoginLogoutApi from "../../api/LoginLogoutApi";

export const SelectMember = () => {

  useDocumentTitle("Visiopt - Select Member");

  const navigate = useNavigate();
  const { userRolePermissions } = useAuth();

  useEffect(() => {
    if(visiUserSession() && visiUserSession().page){
      navigate(visiUserSession().page);
    }
  }, []);

  const handleSelectMemberFormSubmit = (event:any) => {
    event.preventDefault();
    try {
      let localMemberData = visiMemberData();
      const SelectMemberSubmitAPIResult = LoginLogoutApi.login({
        email : selectedMemberEmail,
        select_member : selectedMemberId,
        remember_me : localMemberData.remember_me,
        select_member_token : localMemberData.select_member_token,
      })
      .then(SelectMemberSubmitAPIResult => SelectMemberSubmitAPIResult.text())
      .then(SelectMemberSubmitAPIResult => {
        const SelectMemberSubmitAPIResultJSON = JSON.parse(SelectMemberSubmitAPIResult);
        if(SelectMemberSubmitAPIResultJSON.status === 1){
          localStorage.setItem("visiUserData", JSON.stringify(SelectMemberSubmitAPIResultJSON.data));
          localStorage.removeItem('visiMemberData');
          let userPermissions = [];
          if(visiUserPermission()){
            for(const role in visiUserPermission()){
              userPermissions = visiUserPermission()[role];
            }
          }
          userRolePermissions(userPermissions);
          if(visiUserSession() && visiUserSession().page){
            navigate(visiUserSession().page);
          }else{
            navigate("/dashboard");
          }
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: SelectMemberSubmitAPIResultJSON.message,
          })
        }
      })
      .catch(error => console.log('error', error));
    } catch (error) {
      console.log('error', error)
    }
  };

  const [MemberData, setMemberData] = useState<Member[]>([]);
  const [selectedMemberId, setSelectedMemberId] = useState('');
  const [selectedMemberEmail, setSelectedMemberEmail] = useState('');

  type Member = {
    member_id: string;
    member_email: string;
  }

  useEffect(() => {
    let localMemberData = visiMemberData();
    if(localMemberData){
      try {
        const APIResponse = LoginLogoutApi.getmemberlist({
          email : localMemberData.member_email
        })
        .then(APIResponse => APIResponse.text())
        .then(APIResult => {
          const APIResultJSON = JSON.parse(APIResult);
          if(APIResultJSON.status === 1){
            if(APIResultJSON.data){
              setMemberData(APIResultJSON.data);
              if(APIResultJSON.data.length > 0){
                setSelectedMemberId(APIResultJSON.data[0].member_id);
                setSelectedMemberEmail(APIResultJSON.data[0].member_email);
              }
            }
          }else{
            notifications.show({
              color: 'red',
              icon: <IconX size="1.1rem" />,
              title: 'Error',
              message: APIResultJSON.message,
            })
          }
        })
        .catch(error => console.log('error', error));
      } catch (error) {
        console.log('error', error)
      }
    }else{
      navigate("/login");
    }
  }, []);

  return (
    <Container size={800} my={40}>
      <Paper p={30} mt={30} radius="8px" style={{ border : '1px solid #dee2e6' }}>
        <Paper w="50%" mx="auto">
          <Center>
            <img src={bigLogo} alt="Company Logo"/>
          </Center>
          <Center>
            <Text>You have to choose account for login</Text>
          </Center>
          <form onSubmit={handleSelectMemberFormSubmit}>
            <Center>
              <Stack my={15}>
                {MemberData.map((item,index) => (
                  <Radio label={item.member_email}
                    name="select_member"
                    value={item.member_id}
                    onChange={(event) => {
                      setSelectedMemberId(item.member_id);
                      setSelectedMemberEmail(item.member_email);
                    }}
                    checked={selectedMemberId === item.member_id}
                  />
                ))}
              </Stack>
            </Center>
            <Button fullWidth mt="xl" type="submit">
              Continue
            </Button>
          </form>
        </Paper>
      </Paper>
      <Center>
        <Text>By Using This Site You Understand & Agree To Our</Text>
        <Space w="xs"/>
        <Text fw={700}>Term Of Service & Privacy Policy.</Text>
      </Center>
    </Container>
  );
}