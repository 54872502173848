import { useState } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Container, Card, Text, Button, Group, Flex, Accordion, Grid, Divider, Select, ActionIcon, Radio, Box } from '@mantine/core';
import {
  IconHelp, IconClockHour4
} from "@tabler/icons-react";
import classes from './DashBoard.module.css';
import { Chart } from "react-google-charts";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { SelectMemberWebsiteList } from "../../components/GeneralComponent/GeneralComponent";
import { SelectFilterType } from "../../components/GeneralComponent/GeneralComponent";
import { SelectTimePicker } from "../../components/GeneralComponent/GeneralComponent";
import { SelectActiveTest } from "../../components/GeneralComponent/GeneralComponent";
import { SelectWebsitePages } from "../../components/GeneralComponent/GeneralComponent";
import { VisiLoader } from "../../components/GeneralComponent/GeneralComponent";
import { SelectGetGoal } from "../../components/GeneralComponent/GeneralComponent";
import { BoxPageThumb } from "../../components/GeneralComponent/GeneralComponent";
import PermissionWrapper from '../../PermissionWrapper';
import DashBoardApi from "../../api/DashBoardApi";

export function DashBoard() {

  const [IsVisiLoaderVisible, setIsVisiLoaderVisible] = useState(false);

  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

  const [selectedActiveTestMemberWebsite, setSelectedActiveTestMemberWebsite] = useState('');

  const [selectedGoalMemberWebsite, setSelectedGoalMemberWebsite] = useState('');

  const [selectedBoxPageThumbMemberWebsite, setSelectedBoxPageThumbMemberWebsite] = useState('');

  const [selectedPieChartMemberWebsite, setSelectedPieChartMemberWebsite] = useState('');

  const [selectedFilterType, setSelectedFilterType] = useState('');

  const [selectedTimePicker, setSelectedTimePicker] = useState('');

  const [selectedPieChartTimePicker, setSelectedPieChartTimePicker] = useState('');

  const [selectedActiveTest, setSelectedActiveTest] = useState('');

  const [selectedGoalActiveTest, setSelectedGoalActiveTest] = useState('');

  const [selectedBoxPageThumbActiveTest, setSelectedBoxPageThumbActiveTest] = useState('');

  const [selectedGoal, setSelectedGoal] = useState('');

  const [selectedWebsitePage, setSelectedWebsitePage] = useState('');

  const [selectedTimePickerFromDate, setSelectedTimePickerFromDate] = useState('');

  const [selectedPieChartTimePickerFromDate, setSelectedPieChartTimePickerFromDate] = useState('');

  const [selectedTimePickerToDate, setSelectedTimePickerToDate] = useState('');

  const [selectedPieChartTimePickerToDate, setSelectedPieChartTimePickerToDate] = useState('');

  const [PieChartData, setPieChartData] = useState([]);

  const [TestBarChartData, setTestBarChartData] = useState([]);

  const [PieChartColors, setPieChartColors] = useState([]);

  const [TestBarCharColors, setTestBarCharColors] = useState([]);

  const [GoalTypevalue, setGoalTypeValue] = useState('1');

  const [ActiveTestReportTypeValue, setActiveTestReportTypeValue] = useState('1');

  const [DashboardSystemUsageTotalVisitCount, setDashboardSystemUsageTotalVisitCount] = useState('0');
  const [DashboardSystemUsageTotalHeatmapCount, setDashboardSystemUsageTotalHeatmapCount] = useState('0');
  const [DashboardSystemUsageTotalScrollmapCount, setDashboardSystemUsageTotalScrollmapCount] = useState('0');
  const [DashboardSystemUsageTotalRecordingCount, setDashboardSystemUsageTotalRecordingCount] = useState('0');
  const [DashboardSystemUsageVisitDomainCount, setDashboardSystemUsageVisitDomainCount] = useState('0');
  const [DashboardSystemUsageHeatmapsDomainCount, setDashboardSystemUsageHeatmapsDomainCount] = useState('0');
  const [DashboardSystemUsageScrollmapDomainCount, setDashboardSystemUsageScrollmapDomainCount] = useState('0');
  const [DashboardSystemUsageRecordingsDomainCount, setDashboardSystemUsageRecordingsDomainCount] = useState('0');

  const [SystemUsageLoader, setSystemUsageLoader] = useState(false);
  const [SourcesLoader, setSourcesLoader] = useState(false);
  const [ActiveTestsLoader, setActiveTestsLoader] = useState(false);
  const [ActiveCampaignsLoader, setActiveCampaignsLoader] = useState(false);
  const [activeTests, setActiveTests] = useState([]);

  const ChangeGoalTypeValue = (e) => {
    setGoalTypeValue(e);
    setSelectedGoal('');
  }
 
  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
    setSelectedPieChartMemberWebsite(Value);
    setSelectedActiveTestMemberWebsite(Value);
    setSelectedGoalMemberWebsite(Value);
    setSelectedBoxPageThumbMemberWebsite(Value);
    setActiveTests(Value);
  };

  const handleSelectMemberWebsiteListComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  const handleselectedFilterTypeComponentChange = (Value:any) => {
    setSelectedFilterType(Value);
  }

  const handleselectedTimePickerComponentChange = (Value:any) => {
    setSelectedTimePicker(Value);
  }

  const handleselectedPieChartTimePickerComponentChange = (Value:any) => {
    setSelectedPieChartTimePicker(Value);
  }

  const handleselectedActiveTestComponentChange = (Value:any) => {
    setSelectedActiveTest(Value);
    setSelectedGoalActiveTest(Value);
    setSelectedBoxPageThumbActiveTest(Value);
  }

  const handleselectedGoalComponentChange = (Value:any) => {
    setSelectedGoal(Value);
  }

  const handleselectedWebsitePageComponentChange = (Value:any) => {
    setSelectedWebsitePage(Value);
  }

  const handleselectedTimePickerFromDateChange = (Value:any) => {
    setSelectedTimePickerFromDate(Value);
  }

  const handleselectedTimePickerToDateChange = (Value:any) => {
    setSelectedTimePickerToDate(Value);
  }

  const handleselectedPieChartTimePickerFromDateChange = (Value:any) => {
    setSelectedPieChartTimePickerFromDate(Value);
  }

  const handleselectedPieChartTimePickerToDateChange = (Value:any) => {
    setSelectedPieChartTimePickerToDate(Value);
  }

  const handleActiveTestReportTypeChange = (Value:any) => {
    setActiveTestReportTypeValue(Value);
  }

  const navigate = useNavigate();

  useEffect(() => {
    if(selectedMemberWebsite !== ''){
      setSystemUsageLoader(true);
      DashBoardApi.getdashboardsystemusage({
        system_website_id : selectedMemberWebsite,
        filter_type : selectedFilterType,
        time : selectedTimePicker,
        from : selectedTimePickerFromDate,
        to : selectedTimePickerToDate
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setSystemUsageLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          if(data && data.data && data.data.total_visit_count){
            setDashboardSystemUsageTotalVisitCount(data.data.total_visit_count);
          }else{
            setDashboardSystemUsageTotalVisitCount('0');
          }
          if(data && data.data && data.data.visit_domain_count){
            setDashboardSystemUsageVisitDomainCount(data.data.visit_domain_count);
          }else{
            setDashboardSystemUsageVisitDomainCount('0');
          }
          if(data && data.data && data.data.total_heatmap_count){
            setDashboardSystemUsageTotalHeatmapCount(data.data.total_heatmap_count);
          }else{
            setDashboardSystemUsageTotalHeatmapCount('0');
          }
          if(data && data.data && data.data.total_scrollmap_count){
            setDashboardSystemUsageTotalScrollmapCount(data.data.total_scrollmap_count);
          }else{
            setDashboardSystemUsageTotalScrollmapCount('0');
          }
          if(data && data.data && data.data.total_recording_count){
            setDashboardSystemUsageTotalRecordingCount(data.data.total_recording_count);
          }else{
            setDashboardSystemUsageTotalRecordingCount('0');
          }
          if(data && data.data && data.data.heatmaps_domain_count){
            setDashboardSystemUsageHeatmapsDomainCount(data.data.heatmaps_domain_count);
          }else{
            setDashboardSystemUsageHeatmapsDomainCount('0');
          }
          if(data && data.data && data.data.scrollmap_domain_count){
            setDashboardSystemUsageScrollmapDomainCount(data.data.scrollmap_domain_count);
          }else{
            setDashboardSystemUsageScrollmapDomainCount('0');
          }
          if(data && data.data && data.data.recordings_domain_count){
            setDashboardSystemUsageRecordingsDomainCount(data.data.recordings_domain_count);
          }else{
            setDashboardSystemUsageRecordingsDomainCount('0');
          }
          setSystemUsageLoader(false);
        }
        setSystemUsageLoader(false);
      })
      .catch(error => {
        console.log(error);
        setSystemUsageLoader(false);
      });
    }
  }, [selectedMemberWebsite, selectedFilterType, selectedTimePicker, selectedTimePickerFromDate, selectedTimePickerToDate,navigate]);

  useEffect(() => {
    if(selectedPieChartMemberWebsite !== '' && selectedWebsitePage !== '' && selectedPieChartTimePicker !== ''){
      setSourcesLoader(true);
      DashBoardApi.getsourcepia({
        system_website_id : selectedPieChartMemberWebsite,
        filter_page : selectedWebsitePage,
        time : selectedPieChartTimePicker,
        from : selectedPieChartTimePickerFromDate,
        to : selectedPieChartTimePickerToDate
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setSourcesLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        let PieData = [
          ["Website", "Traffic"]
        ];
        let PieColor = [];
        if(data.status === 1){
          if(data.data && data.data.length > 0){
            data.data.map(Pie => (
              PieData.push([Pie.labels, Number(Pie.percentage)]),
              PieColor.push(Pie.backgroundColor)
            ))
            setPieChartData(PieData);
            setPieChartColors(PieColor);
            setSourcesLoader(false);
          }
          setSourcesLoader(false);
        }
        setSourcesLoader(false);
      })
      .catch(error => {
        console.log(error);
        setSourcesLoader(false);
      });
    }
  }, [selectedPieChartMemberWebsite, selectedWebsitePage, selectedPieChartTimePicker, selectedPieChartTimePickerFromDate, selectedPieChartTimePickerToDate,navigate]);

  useEffect(() => {
    if(selectedMemberWebsite !== '' && selectedActiveTest !== '' && selectedGoal !== '' && GoalTypevalue !== '' && ActiveTestReportTypeValue !== ''){
      setActiveTestsLoader(true);
      DashBoardApi.getactivetestlinechart({
        system_website_id : selectedMemberWebsite,
        active_test_page_id : selectedActiveTest,
        goal_type : GoalTypevalue,
        report_type : ActiveTestReportTypeValue,
        goal_id : selectedGoal
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setActiveTestsLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        let BarData = [
          [""]
        ];
        let BarStats = [];
        let BarColor = [];
        if(data.status === 1){
          if(data.data.datasets && data.data.datasets.length > 0){
            data.data.datasets.map(Bar => (
              BarData[0].push(Bar.label),
              BarStats = Object.entries(Bar.data),
              BarColor = Bar.backgroundColor
            ));
            if(BarStats.length > 0){
              BarData = BarData.concat(BarStats);
            }
            setTestBarChartData(BarData);
            setTestBarCharColors(BarColor);
            setActiveTestsLoader(false);
          }
          setActiveTestsLoader(false);
        }
        setActiveTestsLoader(false);
      })
      .catch(error => {
        console.log(error);
        setActiveTestsLoader(false);
      });
    }
  }, [selectedMemberWebsite, selectedActiveTest, selectedGoal, GoalTypevalue, ActiveTestReportTypeValue,navigate]);

  useDocumentTitle("Visiopt - DashBoard");

  return (
    <>
      <VisiLoader IsLoaderVisible={IsVisiLoaderVisible}/>
      <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
      <Container size="xl">
        <PermissionWrapper permission="operational_access">
          <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
            <Card.Section>
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>QUICK LINKS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
            </Group>
            <Flex
              direction={{ base: 'column', sm: 'row' }}
              gap={{ base: 'sm', sm: 'lg' }}
              justify={{ sm: 'center' }}
            >
              <Button component="a" href="create_funnel">Create a New Funnel</Button>
              <Button component="a" href="create_test">Create a New Test</Button>
              <Button component="a" href="create_campaign">Create a New Campaign</Button>
              <Button component="a" href="create_landing_page">Create a New Landing Page</Button>
            </Flex>
          </Card>
          <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
            <Card.Section>
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>GET STARTED IN 4 SIMPLE STEPS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
            </Group>
            <Container size="xl" px={0}>
              <Accordion chevronPosition="right" defaultValue="step-1" variant="separated">
                <Accordion.Item className={classes.item} value="step-1">
                  <Accordion.Control><Text component="span" color="red" weight={500}>Step 1</Text><Text component="span" weight={500}> - Add a new site</Text></Accordion.Control>
                  <Accordion.Panel>
                    <Flex
                      direction={{ base: 'column', sm: 'row' }}
                      gap={{ base: 'sm', sm: 'lg' }}
                      justify={{ sm: 'center' }}
                    >
                      <Button component="a" href="#">Add A Domain For Tracking & Testing Only</Button>
                      <Button component="a" href="#">Add A Domain For Tracking , Testing & Publishing</Button>
                    </Flex>
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item className={classes.item} value="step-2">
                  <Accordion.Control><Text component="span" color="red" weight={500}>Step 2</Text><Text component="span" weight={500}> - Create a Funnel</Text></Accordion.Control>
                  <Accordion.Panel>
                    In Progress
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item className={classes.item} value="step-3">
                  <Accordion.Control><Text component="span" color="red" weight={500}>Step 3</Text><Text component="span" weight={500}> - Add a Page</Text></Accordion.Control>
                  <Accordion.Panel>
                    In Progress
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item className={classes.item} value="step-4">
                  <Accordion.Control><Text component="span" color="red" weight={500}>Step 4</Text><Text component="span" weight={500}> - Create a Test</Text></Accordion.Control>
                  <Accordion.Panel>
                    In Progress
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Container>
          </Card>
        </PermissionWrapper>
        <Grid>
          <Grid.Col span={6}>
            <Box pos="relative">
              <VisiLoader VisiLoaderVisible={SystemUsageLoader} />
              <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
                <Card.Section>
                </Card.Section>
                <Group position="apart" mt="md" mb="xs">
                  <Text weight={500}>SYSTEM USAGE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
                </Group>
                {(selectedFilterType === '1' || selectedFilterType === '2' || selectedFilterType === '3') && (
                  <>
                    <Grid justify="space-between">
                      <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Total Account Visits : </Text></Grid.Col>
                      <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalVisitCount}</Text></Grid.Col>
                    </Grid>
                    <Divider my={10} />
                    <Grid justify="space-between">
                      <Grid.Col span={8} style={{ textAlign: 'left' }}>
                        <Group>
                          <Text weight={500}>Domain Visits : </Text>
                          <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange} SelectMemberWebsiteListWidth={140}/>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={2} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageVisitDomainCount}</Text></Grid.Col>
                    </Grid>
                  </>
                )}
                {selectedFilterType === '4' && (
                  <>
                    <Grid justify="space-between">
                      <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Clicks : </Text></Grid.Col>
                      <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalHeatmapCount}</Text></Grid.Col>
                    </Grid>
                    <Divider my={10} />
                    <Grid justify="space-between">
                      <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Scrollmap : </Text></Grid.Col>
                      <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalScrollmapCount}</Text></Grid.Col>
                    </Grid>
                    <Divider my={10} />
                    <Grid justify="space-between">
                      <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Recording : </Text></Grid.Col>
                      <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalRecordingCount}</Text></Grid.Col>
                    </Grid>
                    <Divider my={10} />
                    <Grid justify="space-between">
                      <Grid.Col span={8} style={{ textAlign: 'left' }}>
                        <Group>
                          <Text weight={500}>Domain Visits : </Text>
                          <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange} SelectMemberWebsiteListWidth={140}/>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={2} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageVisitDomainCount}</Text></Grid.Col>
                    </Grid>
                    <Grid justify="space-between">
                      <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Clicks : </Text></Grid.Col>
                      <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageHeatmapsDomainCount}</Text></Grid.Col>
                    </Grid>
                    <Divider my={10} />
                    <Grid justify="space-between">
                      <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Scrollmap : </Text></Grid.Col>
                      <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageScrollmapDomainCount}</Text></Grid.Col>
                    </Grid>
                    <Divider my={10} />
                    <Grid justify="space-between">
                      <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Recording : </Text></Grid.Col>
                      <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageRecordingsDomainCount}</Text></Grid.Col>
                    </Grid>
                  </>
                )}
                {selectedFilterType === '5' && (
                  <>
                    <Grid justify="space-between">
                      <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Clicks : </Text></Grid.Col>
                      <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalHeatmapCount}</Text></Grid.Col>
                    </Grid>
                    <Divider my={10} />
                    <Grid justify="space-between">
                      <Grid.Col span={8} style={{ textAlign: 'left' }}>
                        <Group>
                          <Text weight={500}>Domain Visits : </Text>
                          <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange} SelectMemberWebsiteListWidth={140}/>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={2} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageHeatmapsDomainCount}</Text></Grid.Col>
                    </Grid>
                  </>
                )}
                {selectedFilterType === '6' && (
                  <>
                    <Grid justify="space-between">
                      <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Scrollmap : </Text></Grid.Col>
                      <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalScrollmapCount}</Text></Grid.Col>
                    </Grid>
                    <Divider my={10} />
                    <Grid justify="space-between">
                      <Grid.Col span={8} style={{ textAlign: 'left' }}>
                        <Group>
                          <Text weight={500}>Domain Visits : </Text>
                          <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange} SelectMemberWebsiteListWidth={140}/>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={2} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageScrollmapDomainCount}</Text></Grid.Col>
                    </Grid>
                  </>
                )}
                {selectedFilterType === '7' && (
                  <>
                    <Grid justify="space-between">
                      <Grid.Col span={6} style={{ textAlign: 'left' }}><Text weight={500}>Recording : </Text></Grid.Col>
                      <Grid.Col span={6} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageTotalRecordingCount}</Text></Grid.Col>
                    </Grid>
                    <Divider my={10} />
                    <Grid justify="space-between">
                      <Grid.Col span={8} style={{ textAlign: 'left' }}>
                        <Group>
                          <Text weight={500}>Domain Visits : </Text>
                          <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange} SelectMemberWebsiteListWidth={140}/>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={2} style={{ textAlign: 'right' }}><Text>{DashboardSystemUsageRecordingsDomainCount}</Text></Grid.Col>
                    </Grid>
                  </>
                )}
                <Grid justify="space-between" h={118}>
                </Grid>
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}>
                    <SelectFilterType SelectFilterTypeValue={selectedFilterType} SelectFilterTypeOnChange={handleselectedFilterTypeComponentChange}/>
                  </Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}>
                    <Group>
                      <Grid justify="space-between">
                        <Grid.Col span={2} style={{ textAlign: 'left' }}>
                          <ActionIcon
                            variant="white"
                            size="lg"
                          >
                            <IconClockHour4 size="1.1rem" />
                          </ActionIcon>
                        </Grid.Col>
                        <Grid.Col span={10} style={{ textAlign: 'right' }}>
                          <SelectTimePicker SelectTimePickerValue={selectedTimePicker} SelectTimePickerOnChange={handleselectedTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedTimePickerToDateChange}/>
                        </Grid.Col>
                      </Grid>
                    </Group>
                  </Grid.Col>
                </Grid>
              </Card>
            </Box>
          </Grid.Col>
          <Grid.Col span={6}>
            <Box pos="relative">
              <VisiLoader VisiLoaderVisible={SourcesLoader} />
              <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
                <Card.Section>
                </Card.Section>
                <Group position="apart" mt="md" mb="xs">
                  <Text weight={500}>SOURCES <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
                </Group>
                {PieChartData.length > 1 && (
                <Chart
                  chartType="PieChart"
                  data={PieChartData}
                  options={
                    {
                      colors: PieChartColors
                    }
                  }
                />
                )}
                <Grid justify="space-between">
                  <Grid.Col span={6} style={{ textAlign: 'left' }}>
                    <SelectWebsitePages SelectWebsitePagesValue={selectedWebsitePage} SelectWebsitePagesOnChange={handleselectedWebsitePageComponentChange} SelectWebsitePagesSelectedMemberWebsite={selectedPieChartMemberWebsite}/>
                  </Grid.Col>
                  <Grid.Col span={6} style={{ textAlign: 'right' }}>
                    <Group>
                      <Grid justify="space-between">
                        <Grid.Col span={2} style={{ textAlign: 'left' }}>
                          <ActionIcon
                            variant="white"
                            size="lg"
                          >
                            <IconClockHour4 size="1.1rem" />
                          </ActionIcon>
                        </Grid.Col>
                        <Grid.Col span={10} style={{ textAlign: 'right' }}>
                          <SelectTimePicker SelectTimePickerValue={selectedPieChartTimePicker} SelectTimePickerOnChange={handleselectedPieChartTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedPieChartTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedPieChartTimePickerToDateChange}/>
                        </Grid.Col>
                      </Grid>
                    </Group>
                  </Grid.Col>
                </Grid>
              </Card>
            </Box>
          </Grid.Col>
        </Grid>
        <>
        {selectedActiveTestMemberWebsite && activeTests.length > 0 && (
        <Box pos="relative">
          <VisiLoader VisiLoaderVisible={ActiveTestsLoader} />
          <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
            <Card.Section>
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>ACTIVE TESTS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
            </Group>
            <Group mb={15}>
              <Text>Select Test: </Text>
              <SelectActiveTest SelectActiveTestValue={selectedActiveTest} SelectActiveTestOnChange={handleselectedActiveTestComponentChange} SelectActiveTestSelectedMemberWebsite={selectedActiveTestMemberWebsite} setActiveTests={setActiveTests}/>
            </Group>
            <Group mb={15}>
              <Text>Report Type: </Text>
              <Select
                data={[
                  { value: '1', label: 'Conversion Sum (#)' },
                  { value: '2', label: 'Conversion Rate (%)' }
                ]}
                value={ActiveTestReportTypeValue}
                onChange={handleActiveTestReportTypeChange}
                searchable
              />
              <Radio.Group
                value={GoalTypevalue}
                onChange={ChangeGoalTypeValue}
                name="GoalType"
              >
                <Group mt="xs">
                  <Radio value="1" label="Goal" />
                  <Radio value="2" label="Button" />
                  <Radio value="3" label="Postback" />
                </Group>
              </Radio.Group>
              <SelectGetGoal SelectGetGoalValue={selectedGoal} SelectGetGoalOnChange={handleselectedGoalComponentChange} SelectGetGoalSelectedMemberWebsite={selectedGoalMemberWebsite} SelectGetGoalSelectedActiveTestPage={selectedGoalActiveTest} SelectGetGoalSelectedGoalType={GoalTypevalue} />
            </Group>
            <Grid justify="space-between">
              <Grid.Col span={3} style={{ textAlign: 'left' }}>
                <BoxPageThumb BoxPageThumbSelectedMemberWebsite={selectedBoxPageThumbMemberWebsite} BoxPageThumbSelectedActiveTestPage={selectedBoxPageThumbActiveTest} />
              </Grid.Col>
              <Grid.Col span={9} style={{ textAlign: 'right' }}>
                {TestBarChartData.length > 1 && (
                <Chart
                  chartType="Bar"
                  data={TestBarChartData}
                  options={
                    {
                      colors: TestBarCharColors
                    }
                  }
                />
                )}
              </Grid.Col>
            </Grid>
          </Card>
        </Box>
        )}
        </>
        <Box pos="relative">
          <VisiLoader VisiLoaderVisible={ActiveCampaignsLoader} />
          <Card shadow="sm" padding="lg" radius="md" withBorder mb={10}>
            <Card.Section>
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>ACTIVE CAMPAIGNS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
            </Group>
            <Group mb={15}>
              <Text>Select Campaign: </Text>
              <Select
                data={[
                    { value: '1', label: 'Campaign 1' },
                    { value: '2', label: 'Campaign 2' }
                  ]}
                value="1"
                mr={20}
              />
              <ActionIcon
                variant="white"
              >
                <IconClockHour4 size="1.1rem" />
              </ActionIcon>
              <Select
                data={[
                    { value: '-1', label: 'All time' },
                    { value: '1', label: 'Today' },
                    { value: '2', label: 'Yesterday' },
                    { value: '3', label: 'Last 24 hours' },
                    { value: '4', label: 'This week' },
                    { value: '5', label: 'Last 7 days' },
                    { value: '6', label: 'Last 30 days' },
                    { value: '7', label: 'This month' },
                    { value: '8', label: 'Last month' },
                    { value: '9', label: 'This year' },
                    { value: '10', label: 'Last year' },
                    { value: '-2', label: 'Time Range...' },
                    { value: '-3', label: 'Current Billing Period' },
                  ]}
                value="1"
              />
            </Group>
            <Group mb={15}>
              <Text>Report Type: </Text>
              <Select
                data={[
                    { value: '1', label: 'Type 1' },
                    { value: '2', label: 'Type 2' }
                  ]}
                value="1"
              />
            </Group>
            <Grid justify="space-between">
              <Grid.Col span={3} style={{ textAlign: 'left' }}>
                <div className="visi-page">
                  <div className="page">
                    <a href="">
                      <span className="page-url">imres-test-site/upsell/index.html</span>
                    </a>
                    <div className="thumbnail">
                    </div>
                    <span className="page-title">Home - VSL</span>
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col span={9} style={{ textAlign: 'right' }}>
                <Chart
                  chartType="Line"
                  /*width="100%"
                  height="400px"*/
                  data={[
                    [
                      "Day",
                      "Total ROAS",
                      "Selected Date Ranges"
                    ],
                    ["10/15/2023", 37.8, 80.8],
                    ["10/29/2023", 30.9, 69.5],
                    ["11/12/2023", 25.4, 57],
                    ["11/26/2023", 11.7, 18.8]
                  ]}
                />
              </Grid.Col>
            </Grid>
          </Card>
        </Box>
      </Container>
    </>
  );
}