import React, { useState, useMemo } from 'react';
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Modal, Container, Card, Group, Text, Select, Button, Space, Grid, Table, ActionIcon, Radio, Box, Anchor, Tooltip, MultiSelect } from '@mantine/core';
import { IconHelp, IconInfoCircle } from '@tabler/icons-react';
import './ReviewingTest.css';

import { Chart } from "react-google-charts";
import { useEffect } from 'react';
import { visiUserData } from '../../GeneralFunction';
import { useNavigate,useLocation } from "react-router-dom";
import { IconX } from '@tabler/icons-react';
import { ServerAPI } from '../../GeneralFunction';
import { notifications } from '@mantine/notifications';
import { VisiLoader } from "../../components/GeneralComponent/GeneralComponent";
import TestsApi from "../../api/TestsApi";

export function TestHistoryReport() {
	const navigate = useNavigate();
  const location = useLocation();
  const { pageId, historyId } = location.state || {};
	useEffect(() => {
	  if(!visiUserData()){
	    navigate("/login");
	  }
	}, [navigate]);

	useDocumentTitle("Visiopt - Test History Report");

	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};

	const [convRedioBtn, setConvRedioBtn] = useState('1');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);
  const [testInfoModalOpen, setTestInfoModalOpen] = useState(false);
  const [testInfoTestName, setTestInfoTestName] = useState('');
  const [testInfoPageLink, setTestInfoPageLink] = useState('');
  const [testInfoPageTitle, setTestInfoPageTitle] = useState('');
  const [testInfoTestTime, setTestInfoTestTime] = useState('');
  const [testInfoTestEndTime, setTestInfoTestEndTime] = useState('');
  const [testInfoTestType, setTestInfoTestType] = useState('');

  const [variationsBtn, setVariationsBtn] = useState('1');
  const [variablesBtn, setVariablesBtn] = useState('0');
  const [variablesOptimumResponse, setVariablesOptimumResponse] = useState('');
  const [variablesVariablesTab, setVariablesVariablesTab] = useState([]);
  const [SystemUsageLoader, setSystemUsageLoader] = useState(false);

  const [convRadioFunnelsGoals, setConvRadioFunnelsGoals] = useState<any[]>([]);
  const [convRadioPostbackOptions, setConvRadioPostbackOptions] = useState<any[]>([]);
  const [convRadioButtonOptions, setConvRadioButtonOptions] = useState<any[]>([]);
  const [funnelsGoals, setFunnelsGoals] = useState('');
  const [selectedButton, setSelectedButton] = useState('');
  const [postbackOptions, setPostbackOptions] = useState('');

  const [historyMasterId, setHistoryMasterId] = useState('');
  const [testNameShow, setTestNameShow] = useState('');
  const [testPageLinkShow, setTestPageLinkShow] = useState('');
  const [testPageTitleShow, setTestPageTitleShow] = useState('');
  const [totalUniquesShow, setTotalUniquesShow] = useState('');
  const [totalVisitsShow, setTotalVisitsShow] = useState('');
  const [totalConversionsShow, setTotalConversionsShow] = useState('');
  const [testVariationsData, setTestVariationsData] = useState([]);
  const [dataColumnChart, setDataColumnChart] = useState([]);
  const [dataColumnPercentageChart, setDataColumnPercentageChart] = useState([]);
  const [selectedValue, setSelectedValue] = useState('1');
  const [variablesTabShow, setVariablesTabShow] = useState('');
  const [conversionTypeShow, setConversionTypeShow] = useState('');
  const [conversionTypeValueSelected, setConversionTypeValueSelected] = useState('');
  const [convtopageNameShow, setConvtopageNameShow] = useState('');
  const [marksalestime, setMarksalestime] = useState(0);

/* TEST INFORMATION */
  const handleTestInfoOpenModal = (value:any) => {
    if (!pageId) {
      notifications.show({
        color: 'red',
        icon: <IconX size="1.1rem" />,
        title: 'Error',
        message: "Page id is a required field.",
      });
      navigate('/review-tests');
      return;
    }
    TestsApi.gettestinfo({
      page_id:pageId,
      type:"TestHistory",
      test_id:historyId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setTestInfoTestName(data.data.test_name);
        setTestInfoPageLink(data.data.page_link);
        setTestInfoPageTitle(data.data.page_title);
        setTestInfoTestTime(data.data.test_time);
        setTestInfoTestEndTime(data.data.test_ended);
        setTestInfoTestType(data.data.test_type);
        setTestInfoModalOpen(true);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
        setShowMessageHeader(false);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleTestInfoCloseModal = () => {
    setTestInfoModalOpen(false);
  };
/* END TEST INFORMATION */

/* VARIATIONS & VARIABLES*/
  const handleOptimize = (value:any) => {
    if(value === 'Variations'){
      setVariationsBtn('1');
      setVariablesBtn('0');
    } else if(value === 'Variables'){
      setVariationsBtn('0');
      setVariablesBtn('1');
      handleGetVariables();
    }
  };

  const handleGetVariables = () => {
    setSystemUsageLoader(true);
    TestsApi.gettesthistoryvariabletab({
      history_master_id: historyMasterId
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        setVariablesOptimumResponse(data.data.optimum_response);
        setVariablesVariablesTab(data.data.variables_tab || []);
        setSystemUsageLoader(false);
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
      setSystemUsageLoader(false);
    });
  };

  const testVariablesRows = variablesVariablesTab.map((element, index) => (
    <tr key={index}>
      <td>
        {element.variables_name !== '' && (
        <Tooltip label={element.variables_name}>
          <Text size="sm">{element.variables_name_complete}</Text>
        </Tooltip>
        )}
        {element.versions_name !== '' && (
        <Text size="sm" ml={20}>{element.versions_name}</Text>
        )}
      </td>
      <td>
        {element.variables_name !== '' && (
          <div>
            <Text fw={500} ta="center" ml={20}>{element.statistical_significance}</Text>
            {element.statistical_significance !== '-' && element.statistical_significance !== undefined ? (
            <progress value={element.statistical_significance} 
                      className={element.ss_class} 
                      max="100" 
                      style={{width:"100%"}}>
            </progress>) : (
              ''
            )}
          </div>
        )} {element.variables_name !== '' && (
          <Text size="sm" ml={20} ta="center">{element.best_option}</Text>
          )}
      </td>
    </tr>
  ));
/* END VARIATIONS & VARIABLES*/

/* CONVERSIONS TO*/
  const handleConvRadioChange = (value) => {
    setConvRedioBtn(value);
    setConversionTypeShow(value === '1' ? 'goal' : value === '2' ? 'button' : 'postback');
    setConversionTypeValueSelected('');
  };

  useEffect(() => {
    if(selectedMemberWebsite !== ''){
      if (!pageId) {
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: "Page id is a required field.",
        });
        navigate('/review-tests');
        return;
      }
      TestsApi.gettesthistoryconversionsdropdown({
        history_id:historyId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setConvRadioFunnelsGoals(data.data.goal_options || []);
          setConvRadioPostbackOptions(data.data.postback_options || []);
          setConvRadioButtonOptions(data.data.button_options || []);
        } else {
          console.log("Error:","Error");
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  }, [selectedMemberWebsite,marksalestime, pageId, navigate, historyId]);

  const funnelGoal = useMemo(() => [
    { value: '', label: 'Select a Goal' },
    ...convRadioFunnelsGoals.map((FunnelsGoals) => ({
      value: FunnelsGoals.goal_id,
      label: FunnelsGoals.goal_name,
    })),
  ], [convRadioFunnelsGoals]);

  const postbackOption = useMemo(() => [
    { value: '', label: 'Select Postback' },
    ...convRadioPostbackOptions.map((PostbackOptions) => ({
      value: PostbackOptions.postback_id,
      label: PostbackOptions.postback_name,
    })),
  ], [convRadioPostbackOptions]);

  const buttonOptions = useMemo(() => [
    { value: '', label: 'Select a Button' },
    ...Object.values(convRadioButtonOptions).map((ButtonOptions) => ({
      value: ButtonOptions.button_id,
      label: ButtonOptions.button_name,
    })),
  ], [convRadioButtonOptions]);

  useEffect(() => {
    if(conversionTypeShow === 'goal'){
      setConvRedioBtn('1');
    }
    if(conversionTypeShow === 'button'){
      setConvRedioBtn('2');
    }
    if(conversionTypeShow === 'postback'){
      setConvRedioBtn('3');
    }

    if (convRedioBtn === '1') {
      const selectedOption = funnelGoal.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setFunnelsGoals(selectedOption.value);
      }
    } else if (convRedioBtn === '2') {
      const selectedOption = buttonOptions.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setSelectedButton(selectedOption.value);
      }
    } else if (convRedioBtn === '3') {
      const selectedOption = postbackOption.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setPostbackOptions(selectedOption.value);
      }
    }
  }, [conversionTypeValueSelected,marksalestime]);

  const handleSelectConvChange = (value) => {
    setConversionTypeValueSelected(value);
    if (convRedioBtn === '1') {
      setFunnelsGoals(value);
    } else if (convRedioBtn === '2') {
      setSelectedButton(value);
    } else if (convRedioBtn === '3') {
      setPostbackOptions(value);
    }
  };
/* END CONVERSIONS TO*/

/* TEST OPTIMIZE REPORT*/
  useEffect(() => {
    if(selectedMemberWebsite !== ''){
      setSystemUsageLoader(true);
      TestsApi.testhistoryoptimizereport({
        system_website_id:selectedMemberWebsite,
        test_history_id:historyId,
        test_goal_type_opt_his: conversionTypeShow,
        convtogoal:convRedioBtn === '1' ? funnelsGoals : '',
        convtobutton:convRedioBtn === '2' ? selectedButton : '',
        convtopostback:convRedioBtn === '3' ? postbackOptions : '',
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setSystemUsageLoader(false);
          setTestVariationsData(data.data.test_history_data);
          setDataColumnChart(data.data.conversion_chart_column);
          setDataColumnPercentageChart(data.data.conversion_percentage_chart_column);
          setHistoryMasterId(data.data.history_master_id);
          setTestNameShow(data.data.test_name);
          setTestPageLinkShow(data.data.test_page_link);
          setTestPageTitleShow(data.data.page_title);
          setTotalUniquesShow(data.data.total_uniques);
          setTotalVisitsShow(data.data.total_visits);
          setTotalConversionsShow(data.data.total_conversions);
          setConversionTypeShow(data.data.conversion_type);
          setConversionTypeValueSelected(data.data.conversion_type_value_selected);
          setConvtopageNameShow(data.data.convtopage_name);
          setVariablesTabShow(data.data.hide_variables_tab);
          setMarksalestime(data.data.updated_time);
        } else {
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setSystemUsageLoader(false);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
        setSystemUsageLoader(false);
      });
    }
  }, [selectedMemberWebsite,marksalestime,funnelsGoals,postbackOptions,selectedButton]);

  const handleConversionRadioChange = (value) => {
    setSelectedValue(value);
  };

  const dataColumnChartRows = [
    ['Variation', 'Value', { role: 'style' }],
    ...dataColumnChart,
  ];

  const optionsColumnChart = {
    legend: { position: 'none' },
    chartArea: { width: '90%', height: '80%' },
    vAxis: {
      title: 'Conversions',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
    },
  };

  const dataColumnPercentageChartRows = [
    ['Variation', 'Value', { role: 'style' }],
    ...dataColumnPercentageChart,
  ];

  const optionsColumnPercentageChart = {
    legend: { position: 'none' },
    chartArea: { width: '90%', height: '80%' },
    vAxis: {
      title: 'Conversions %',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
    },
  };

  const getBackgroundColor = (highlight) => {
    if (highlight === 'red') {
      return '#ffd9d9';
    }
    if (highlight === 'yellow') {
      return '#fff7c6';
    }
    return 'transparent';
  };

  const allOptions = [
    { value: 'Uniques', label: 'Uniques' },
    { value: 'Visits', label: 'Visits' },
    { value: 'Conv. # to page', label: 'Conv. # to page' },
    { value: 'Conv. % to page', label: 'Conv. % to page' },
    { value: 'Percentage Improvement', label: 'Percentage Improvement' },
    { value: 'Statistical Significance', label: 'Statistical Significance' },
    { value: 'Total Revenue', label: 'Total Revenue' },
    { value: 'AOV', label: 'AOV' },
  ];

  const defaultSelectedOptions = [
    'Uniques',
    'Visits',
    'Conv. # to page',
    'Conv. % to page',
    'Percentage Improvement',
    'Statistical Significance'
  ];

  const [selectedfilteredOptions, setSelectedfilteredOptions] = useState<string[]>(defaultSelectedOptions);

  const handleSelectFilteredChange = (values: string[]) => {
    setSelectedfilteredOptions(values);
  };

  const testVariationsRows = testVariationsData.map((element,index) => (
    <tr key={index}
        style={{ backgroundColor: getBackgroundColor(element.highlight_row) }}
        onMouseEnter={(e) => {
          if(element.highlight_row !== 'red'){
            e.currentTarget.style.backgroundColor = '#fff7c6';
          }
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = getBackgroundColor(element.highlight_row);
        }}
      >
      <td>{element.variation_number}</td>
      <td>
        <div style={{ display: 'flex'}}>
          <Text mt={6} w={80}>{element.variations}</Text>
          {element.is_winner !== '' ? <Text mt={6} w={80} fz={12} weight={800} c="red">! winner</Text> : "" }
        </div>
      </td>
      {selectedfilteredOptions.includes('Uniques') && <td>{element.uniques}</td>}
      {selectedfilteredOptions.includes('Visits') && <td>{element.visits}</td>}
      {selectedfilteredOptions.includes('Conv. # to page') && <td>{element.convert_to_page}</td>}
      {selectedfilteredOptions.includes('Conv. % to page') && <td>{element.conversion_to_page_percent}</td>}
      {selectedfilteredOptions.includes('Percentage Improvement') && 
      <td>
        {element.pi_per === 'Not Enough Data' ? (
          <Text c="red" fw={500} ta="center">{element.pi_per}</Text>
        ) : (
          <div style={{ display: 'flex' }}>
            <Text fw={500} w={40}>{Math.round(element.pi_per)}%</Text>
            <div className="pi_graph pi_cf">
              <div className={`pi_ext ${element.pi_class}`}>
                <div style={{ width: `${Math.min(Math.round(element.pi_per_value), 100)}%` }} className={`pi_bar ${element.pi_class}`}></div>
              </div>
            </div>
          </div>
        )}
      </td>
      }
      {selectedfilteredOptions.includes('Statistical Significance') &&
      <>
        {(element.ss_class !== '' || element.ss_per !== 0) ? (
        <td><div style={{ display: 'flex' }}><Text fw={500} style={{width:"5.5rem"}}>{element.ss_per}%</Text>{element.ss_per !== ''?<progress value={element.ss_per} style={{width:"208%",marginTop:"3px"}} className={element.ss_class} max="100"></progress>:''}</div></td>
        ) : (
          <td></td>
        )}
      </>
      }
      {selectedfilteredOptions.includes('Total Revenue') && <td>{element.revenue}</td>}
      {selectedfilteredOptions.includes('AOV') && <td>{element.aov}</td>}
    </tr>
  ));
/* END TEST OPTIMIZE REPORT*/ 

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

	return (
	  <>
    <VisiLoader VisiLoaderVisible={SystemUsageLoader}/>
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
	  {/*test Infomation Model*/}
	  <Modal.Root opened={testInfoModalOpen} onClose={handleTestInfoCloseModal}>
	    <Modal.Overlay />
	    <Modal.Content>
	      <Modal.Header>
	        <Modal.Title><Text weight={500}>TEST INFORMATION <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text></Modal.Title>
	        <Modal.CloseButton />
	      </Modal.Header>
	      <Modal.Body>
	        <form>
	        	<div style={{ display: 'flex'}}>
	        		<Text size="sm" fz={14} fw={500} mr={5}>Test Name:</Text>
	        		<Text size="sm" fz={14}> {testInfoTestName}</Text>
	        	</div>
	        	<div style={{ display: 'flex'}}>
	        		<Text size="sm" fz={14} fw={500} mr={5}>Page Name:</Text>
	        		<Anchor href={testInfoPageLink} target="_blank" fw={500} mr={5} mt={-2}>{testInfoPageTitle} </Anchor>
	        	</div>
	        	<div style={{ display: 'flex'}}>
	        		<Text size="sm" fz={14} fw={500} mr={5}>Test Type:</Text>
	        		<Text size="sm" fz={14} mr={5}>{testInfoTestType}</Text>
	        	</div>
	          <div style={{ display: 'flex'}}>
			      	<Text size="sm" fz={14} fw={500} mr={5}>Date the test started:</Text>
			      	<Text size="sm" fz={14}>{testInfoTestTime}</Text>
			      </div>
             <div style={{ display: 'flex'}}>
              <Text size="sm" fz={14} fw={500} mr={5}>Date the test is finished:</Text>
              <Text size="sm" fz={14}>{testInfoTestEndTime}</Text>
            </div>
	        </form>
	      </Modal.Body>
	    </Modal.Content>
	  </Modal.Root>

	  <Container size="xxl">
      <div style={{ display: 'flex',marginBottom:"10px"}}>
        <Box w={130}>
          <Button rightIcon={<IconHelp size="1.1rem" color="red"/>} variant="default" bg={variationsBtn === '1' ? '#e7fcf5' : "" } type="submit" onClick={() => handleOptimize('Variations')}>
            Variations
          </Button>
        </Box>
        {variablesTabShow === 'show' &&(
          <Box w={130}>
            <Button rightIcon={<IconHelp size="1.1rem" color="red"/>} variant="default" bg={variablesBtn === '1' ? '#e7fcf5' : "" } type="submit" onClick={() => handleOptimize('Variables')}>
              Test Variable Results
            </Button>
          </Box>
        )}
      </div>
      <div style={{ display: variationsBtn === '1' ? "block" : "none" }}>
  	  	<Card shadow="sm" padding="sm" radius="md" withBorder>
  	  		<div style={{ display: 'flex',marginBottom: '5px' }}>
  		  		<Group position="apart" mb="xs">
  		        <Text weight={500}>TEST HISTORY - {testPageTitleShow} <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
  		      </Group>
  		      <div style={{ display: 'flex',marginLeft:"auto"}}>
  		     		<ActionIcon
  		     		  variant="default"
  		     		  size="lg"
  		     		  sx={(theme) => ({
  	               backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
  	             })}
  		     		  title="Test Info"
  		     		  ml={5}
  		     		  onClick={() => handleTestInfoOpenModal(1)}
  		     		>
  		     		  <IconInfoCircle size="1.1rem" />
  		     		</ActionIcon>
  		     	</div>
  		    </div>
  		    <Group>
  		      <Text mb={6}><IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/> Conversions to:</Text>
  		      <Radio.Group
  		        name="GoalType"
  		        mb={10}
  		        value={convRedioBtn}
              onChange={handleConvRadioChange}
  		      >
  		        <Group mt="xs">
  		          <Radio value="1" label="Goals" />
  		          <Radio value="2" label="Buttons" />
  		          <Radio value="3" label="Postbacks" />
  		        </Group>
  		      </Radio.Group>
  		      <Select
              value={conversionTypeValueSelected}
              onChange={handleSelectConvChange}
              data={convRedioBtn === '1' ? funnelGoal : convRedioBtn === '2' ? buttonOptions : postbackOption}
            />
  		    </Group>
  		    <Group mt={10}>
    	      <MultiSelect
              data={allOptions}
              onChange={handleSelectFilteredChange} 
              value={selectedfilteredOptions}
              multiple
              placeholder="Select Metrics"
            />
  		    </Group>
  		    <Group>
  			  	<Table striped highlightOnHover withBorder my={15} fontSize="xs">
             <thead>
               <tr>
                 <th>ID</th>
                 <th>Variations</th>
                  {selectedfilteredOptions.includes('Uniques') && <th>Uniques</th>}
                  {selectedfilteredOptions.includes('Visits') && <th>Visits</th>}
                  {selectedfilteredOptions.includes('Conv. # to page') && <th>Conv. # to page</th>}
                  {selectedfilteredOptions.includes('Conv. % to page') && <th>Conv. % to page</th>}
                  {selectedfilteredOptions.includes('Percentage Improvement') &&<th>Percentage Improvement</th>}
                  {selectedfilteredOptions.includes('Statistical Significance') &&<th>Statistical Significance</th>}
                  {selectedfilteredOptions.includes('Total Revenue') && <th>Total Revenue</th>}
                  {selectedfilteredOptions.includes('AOV') && <th>AOV</th>}
               </tr>
             </thead>
             <tbody>{testVariationsRows}</tbody>
  	  	    </Table>
  		    </Group>
  			</Card>
  			<Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
  				<Group position="apart" mb="xs">
  		      <Text weight={500}>CONVERSION TO PAGE/GOALS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3,marginRight:15}}/>   {convtopageNameShow}</Text>
  		    </Group>
  		    <Radio.Group
  		      name="Conversions"
  		      mb={10}
  		      value={selectedValue}
            onChange={handleConversionRadioChange}
  		    >
  		      <Group mt="md">
  		        <Radio value="1" label="Conversions" />
  		        <Radio value="2" label="Conversion %" />
  		      </Group>
  		    </Radio.Group>
  		    <Grid mb={15}>
  			    <Grid.Col span={9} style={{ textAlign: 'left' }}>
              {dataColumnChartRows.length > 1 && dataColumnPercentageChartRows.length > 1 && (
    				   	<Chart
    					   	chartType="ColumnChart"
    					   	width="100%"
    					   	height="200px"
    					   	data={selectedValue === "1" ? dataColumnChartRows : dataColumnPercentageChartRows}
                  options={selectedValue === "1" ? optionsColumnChart : optionsColumnPercentageChart}

    					  />
              )}
  			    </Grid.Col>
  			    <Grid.Col span={3} style={{ textAlign: 'left' }} mt={20}>
  				    <Box
  				      sx={(theme) => ({
  				        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
  				        padding: "10px",
  				        borderRadius: "4px",
  				        width:"auto"
  				      })}
  				    >
  				      {/*<div style={{ display: 'flex'}}>
  				      	<Text size="sm" fz={14} fw={500} mr={5}>Test Start on:</Text>
  				      	<Text size="sm" fz={14}>12-14-2023</Text>
  				      </div>*/}
  				      <div style={{ display: 'flex'}}>
  				      	<Text size="sm" fz={14} fw={500} mr={5}>Test Totals</Text>
                  <div style={{ display: 'inline-block'}}>
                    <Text size="sm" fz={14} mr={5}>({testNameShow} - <Anchor href={testPageLinkShow} target="_blank" fw={500} mr={5} mt={-2}>{testPageTitleShow} </Anchor>)</Text>
                  </div>
  				      </div>
  				      <Space h="xs" />
  			        <div style={{ display: 'flex'}}>
  			        	<Text size="sm" fz={14} fw={500} mr={5}>Uniques:</Text>
  			        	<Text size="sm" fz={14}>{totalUniquesShow}</Text>
  			        </div>
  							<div style={{ display: 'flex'}}>
  								<Text size="sm" fz={14} fw={500} mr={5}>Visits:</Text>
  								<Text size="sm" fz={14}>{totalVisitsShow}</Text>
  							</div>
  							<div style={{ display: 'flex'}}>
  								<Text size="sm" fz={14} fw={500} mr={5}>Conversions:</Text>
  								<Text size="sm" fz={14}>{totalConversionsShow}</Text>
  							</div>
  				    </Box>
  			    </Grid.Col>
  			  </Grid>
  			</Card>
      </div>
      <div style={{ display: variablesBtn === '1' ? "block" : "none" }}>
        <Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
            <Table highlightOnHover withColumnBorders withBorder my={15} fontSize="xs" maw={500}>
             <thead>
               <tr>
                 <th>Variable / Version</th>
                 <th>Variable influence</th>
               </tr>
             </thead>
             <tbody>{testVariablesRows}</tbody>
            </Table>
        </Card>
        <Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
          <Group position="apart" mb="xs">
            <Text weight={500}>OPTIMUM EXPECTED CONVERSION RATE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3,marginRight:15}}/></Text>
          </Group>
          <Text weight={500} mt={20}>Optimum Expected Conversion Rate: {variablesOptimumResponse}%</Text>
          <Space h="xs" />
          <Text weight={500}>How To Use This Data:</Text>
          <Text>The above chart details which variables had the most influence on making a conversion. Above you will find the best variable options to use.Create a new page using the above influential variables.</Text>
          <Space h="xs" />
          <Text>Important Note:</Text>
          <Space h="xs" />
          <Text weight={500}>What about the Non-Influential Variables?</Text>
          <Text>You can not ignore them.</Text>
          <Text>Choose the option that is least expensive or most convenient. In most cases we recommend choosing the control version of the variable for non-influential variables.</Text>
          <Space h="xs" />
          <Text weight={500}>Optimum Expected Conversion Rate</Text>
          <Text>This is the estimated best expected conversion rate if you create a new page version using the data as described.</Text>
          <Text>Please note this is not a guarantee of a specific conversion rate as there are too many other factors in the marketplace that affect conversion.</Text>
          <Text>We highly recommend you test this new page against the best page from your last test.</Text>
          <Space h="xs" />
          <Text>Please also note that if you use the repeat experiment option variable influence data becomes more accurate.</Text>
        </Card>
      </div>
      {/*Error Model*/}
      {errorModelOpen && (
      <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
        <Modal.Overlay />
        <Modal.Content>
          {showMessageHeader ? (
            <Modal.Header>
              <Modal.Title>MESSAGE</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ) : showErrorHeader ? (
            <Modal.Header>
              <Modal.Title>ERROR</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ) : (
            <Modal.Header>
            </Modal.Header>
          )}
          <Modal.Body>
            <form>
              <div style={{ display: 'flex'}}>
                  <Text weight={500}>{errorMessage}</Text>
              </div>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      )}
	  </Container>
	  </>
	);
}