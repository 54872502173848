import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Container, Skeleton, Card, Group, Text, Select, Button, Input, Space, Grid, Pagination, Accordion, Flex, Table, Image, Modal, Anchor } from '@mantine/core';
import { IconSearch, IconCheck } from '@tabler/icons-react';
import classes from '../DashBoard/DashBoard.module.css';
import './ReviewingTest.css';
import { SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import TestsApi from "../../api/TestsApi";

export function CreateTest() {
	const navigate = useNavigate();
	useDocumentTitle("Visiopt - Create Test");
	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
	const [selectTestType, setSelectTestType] = useState('');
	const [accordionItemDefaultValue, setAccordionItemDefaultValue] = useState("step-1");
	const [step2, setStep2] = useState("none");
	const [step3, setStep3] = useState("none");
	const [step4, setStep4] = useState("none");

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};

	const ContinueTestEditor = (pageId:any) => {
		if(selectTestType === ''){
			setErrorMessage("Please select a test!");
			seterrorCloseModal(true);
		} else {
			navigate(`/test-editor-visual/${selectedThumbnail}/${selectTestType}`);
		}
	};
  const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errorModelOpen, seterrorCloseModal] = useState(false);
	const [showErrorHeader, setShowErrorHeader] = useState(false);
	const [showMessageHeader, setShowMessageHeader] = useState(false);
	const [selectedAllCreateTestPageValue, setSelectedAllCreateTestPageValue] = useState('0');
	const [activePageNo, setActivePageNo] = useState(1);
	const [keyword, setKeyword] = useState('');
	const [selectPageCreateTests, setSelectPageCreateTests] = useState([]);
	const [modernabSplitTest, setModernabSplitTest] = useState('');
	const [singleVariableTest, setSingleVariableTest] = useState('');
	const [classicabSplitTest, setClassicabSplitTest] = useState('');
	const [totalPages, setTotalPages] = useState(10);
	const [totalPaginationNo, setTotalPaginationNo] = useState(1);
	const [selectedThumbnail, setSelectedThumbnail] = useState(null);
	const [selectedDraftThumbnail, setSelectedDraftThumbnail] = useState(null);
	const [isThumbnailSelected, setIsThumbnailSelected] = useState(false);
	const [isDraftThumbnailSelected, setIsDraftThumbnailSelected] = useState(false);
	const [selectedDraftPageId, setSelectedDraftPageId] = useState('');
	const [selectedDraftTaguchiTestId, setSelectedDraftTaguchiTestId] = useState('');
  const [viewSelect, setViewSelect] = useState('grid');
  const [showNumber, setShowNumber] = useState(25);
  const [selectedThumbnails, setSelectedThumbnails] = useState([]);

  const [funnelRotationOptions, setFunnelRotationOptions] = useState([]);
  const [selectedFunnelRotation, setSelectedFunnelRotation] = useState('');
  const [testName, setTestName] = useState('');

  const [selectSmartRelationData, setSelectSmartRelationData] = useState([]);
  const [marksalestime, setMarksalestime] = useState(0);

	const AllCreateTestPagesoptions = [
    { value: '0', label: 'All Pages' },
    { value: '1', label: 'Pages with No Tests' },
    { value: '2', label: 'Pages with "Draft Tests"' }
  ];

	useEffect(() => {
		if(selectedMemberWebsite !== ''){
      setLoading(true);
	    seterrorCloseModal(false);
	    TestsApi.getcreatetestpagelist({
	      system_website_id: selectedMemberWebsite,
	      filter: selectedAllCreateTestPageValue,
	      layout: viewSelect,
	      number: showNumber,
	      page: activePageNo,
	      keyword: keyword
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {	          
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
          setLoading(false);
	        if (data.data.test_pages_list) {
	          setSelectPageCreateTests(data.data.test_pages_list);
	          setModernabSplitTest(data.data.modern_ab_split_test);
	          setSingleVariableTest(data.data.single_variable_test);
	          setClassicabSplitTest(data.data.classic_ab_split_test);
	          if (data.data.active_page_no !== activePageNo) {
	            setActivePageNo(data.data.active_page_no);
	            setActivePageNo(1);
	          }
	          if (data.data.total_pages !== totalPages) {
	            setTotalPages(data.data.total_pages);
	          }
	          if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
	            setTotalPaginationNo(data.data.total_pagination_no);
	          }
	        } else{
	          console.log("No data received");
	          setSelectPageCreateTests([]);
	        }
	      }else{
	        setSelectedAllCreateTestPageValue('0');
	        setErrorMessage(data.message);
	        seterrorCloseModal(true);
          setLoading(false);
	      }	      
	    })
	    .catch(error => {
        setLoading(false);
	      console.error("API Error:", error);	      
	    });
	  }
  }, [selectedAllCreateTestPageValue, activePageNo, selectedMemberWebsite, viewSelect, showNumber, keyword, marksalestime]);

  const handleThumbnailClick = (pageId,taguchiTestId,funnelId,testsId) => {
    if (isThumbnailSelected && selectedThumbnail === pageId) {
      setSelectedThumbnail(null);
      setIsThumbnailSelected(false);
    } else {
      setSelectedThumbnail(pageId);
      setIsThumbnailSelected(true);
      setSelectedDraftThumbnail(null);  
    }
  };

  const handleDraftThumbnailClick = (pageId,taguchiTestId,funnelId,testsId) => {
    if (isDraftThumbnailSelected && selectedDraftThumbnail === pageId) {
      setSelectedDraftThumbnail(null);
      setIsDraftThumbnailSelected(false);
    } else {
    	setSelectedThumbnail(null);  
      setSelectedDraftThumbnail(pageId);
      setIsDraftThumbnailSelected(true);
      setSelectedDraftPageId(pageId);
      setSelectedDraftTaguchiTestId(taguchiTestId);
    }
  };

	const handleSelectAllCreateTestPagesValueChange = (selectedAllCreateTestPageOption) => {
    setSelectedAllCreateTestPageValue(selectedAllCreateTestPageOption);
    setActivePageNo(1);
  };

  const handleSelectView = (selectedViewOption) => {
    setViewSelect(selectedViewOption);
  };

  const handleShowNumberChange = (selectedShowNumberOption) => {
    setShowNumber(selectedShowNumberOption);
    setActivePageNo(1);
  };

  const handlePageChange = (newPage) => {
    setActivePageNo(newPage);
  };

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
    setActivePageNo(1);
  };

  const handleAddtoFunnel = async () => {
  	try {
  		if(selectedDraftThumbnail !== null){
  			const APIResponse = await TestsApi.checkpageinfunnelvalidation({
	        system_website_id : selectedMemberWebsite,
	        page_id: selectedDraftThumbnail
	      });
	      const APIResultJSON = await APIResponse.json();
	      if (APIResultJSON.status === 1) {
	      	if(singleVariableTest === selectedDraftTaguchiTestId){
	      		navigate(`/test-editor-visual/${selectedDraftPageId}/${selectedDraftTaguchiTestId}`);
	      	} else if(modernabSplitTest === selectedDraftTaguchiTestId){
	      		navigate(`/test-editor-visual-modern/${selectedDraftPageId}/${selectedDraftTaguchiTestId}`);
	      	} else {
	      		navigate(`/test-editor-visual/${selectedDraftPageId}/${selectedDraftTaguchiTestId}`);
	      	}
	      }else{
	        setErrorMessage(APIResultJSON.message);
	        seterrorCloseModal(true);
	        setShowErrorHeader(true);
	        setShowMessageHeader(false);
	      }
  		} 

  		if (selectedThumbnail !== null) {
  			const APIResponse = await TestsApi.checkpageinfunnelvalidation({
	        system_website_id : selectedMemberWebsite,
	        page_id: selectedThumbnail
	      });
	      const APIResultJSON = await APIResponse.json();
	      if (APIResultJSON.status === 1) {
	      	setStep2("block");
					setAccordionItemDefaultValue("step-2");
	      }else{
	        setErrorMessage(APIResultJSON.message);
	        seterrorCloseModal(true);
	        setShowErrorHeader(true);
	        setShowMessageHeader(false);
	      }
  		}
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  const listRows = selectPageCreateTests.map((item,index) => (
    <tr key={index} 
    		style={{ backgroundColor: (item.selected || selectedThumbnail === item.page_id || selectedDraftThumbnail === item.page_id) ? '#00b975' : '',
					transition: 'background-color 0.1s' 
				}} 
				onClick={item.selected ? undefined : () => {
          if (item.draft_test_type_name === "Single Variable Test") {
              handleDraftThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id);
          } else if (item.draft_test_type_name === "Modern AB") {
              handleDraftThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id);
          } else {
              handleThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id);
          }
				}}
		>
			<td><a href={item.page_url} target="_blank" rel="noreferrer">Preview</a></td>
      <td><Text><Anchor href={item.page_url} target="_blank" color="inherit">{item.page_url}</Anchor></Text></td>
      <td><Text>{item.draft_test_name}</Text></td>
      <td><Text>{item.draft_test_type_name}</Text></td>
    </tr>
  ));

  const listRows3 = selectPageCreateTests.map((item,index) => (
    <tr key={index} 
    		style={{
        cursor: 'pointer',
        backgroundColor: (item.selected || selectedThumbnails.includes(item.page_id)) ? '#00b975' : '',
        transition: 'background-color 0.1s'
	      }}
	      onClick={item.selected ? undefined : () => handleMultipleThumbnailClick(item.page_id)}
			>
			<td><a href={item.page_url} target="_blank" rel="noreferrer">Preview</a></td>
      <td><Text><Anchor href={item.page_url} target="_blank" color="inherit">{item.page_url}</Anchor></Text></td>
      <td><Text>{item.page_title}</Text></td>
      <td><Text>{item.draft_test_name}</Text></td>
      <td><Text>{item.draft_test_type_name}</Text></td>
    </tr>
  ));

  const handleMultipleThumbnailClick = (pageId) => {
    setSelectedThumbnails((prevSelectedThumbnails) => {
      if (prevSelectedThumbnails.includes(pageId)) {
        return prevSelectedThumbnails.filter(id => id !== pageId);
      } else {
        return [...prevSelectedThumbnails, pageId];
      }
    });
  };

  const ContinueToRotataionPage = (pageId:any) => {
		setStep3("block");
		setAccordionItemDefaultValue("step-3");

		TestsApi.getfunnelinrotation({
    	system_website_id: selectedMemberWebsite,
      page_id:pageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setFunnelRotationOptions(data.data.funnel_list);
      } else {
        setErrorMessage(data.message);
        setShowErrorHeader(true);
        seterrorCloseModal(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
	};

	const funnelRotationData = [
    { value: '', label: 'Select Funnel' },
    ...funnelRotationOptions.map((FunnelRotation) => ({
      value: FunnelRotation.funnel_id,
      label: FunnelRotation.name,
    })),
  ];

	const handleFunnelRotationChange = (value) => {
    setSelectedFunnelRotation(value);
  };

  const handleSaveClassicRotationPage = (e) => {
    e.preventDefault();

    TestsApi.saveclassicabrotationpage({
    	system_website_id: selectedMemberWebsite,
      rotation_page: selectedThumbnails,
      control_page_id: selectedThumbnail,
      funnel_id: selectedFunnelRotation,
      test_name: testName,
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
      	navigate("/reviewing-test", { state: { pageId: selectedThumbnail, funnelId:selectedFunnelRotation, taguchiTestId:classicabSplitTest} });
      } else {
        setErrorMessage(data.message);
        setShowErrorHeader(true);
        seterrorCloseModal(true);
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  };

  const ContinueToSmartRelational = (value:any) => {
		setStep4("block");
		setAccordionItemDefaultValue("step-4");
    setLoading(true);
		TestsApi.getsmartrelationallist()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
				setSelectSmartRelationData(data.data.smart_relational);
        setLoading(false);
      } else {
        setErrorMessage(data.message);
        setShowErrorHeader(true);
        seterrorCloseModal(true);
        setLoading(false);
      }
    })
    .catch(error => {
      setLoading(false);
      console.error("API Error:", error);
    });
	};

	const SelectSmartRelationalTest = (value:any) => {
		setSelectTestType(value);
	};

	const handlerrorCloseModal = () => {
	  seterrorCloseModal(false);
	};

/* START DISCARD DRAFT TEST*/
	const discardDraftConfirm = (pageId:any) => modals.openConfirmModal({
    title: 'Discard Draft',
    children: (
      <Text size="sm">
        Are you sure you want to discard the "Draft test"? <br/>
				If you discard, all edits saved in the draft will be lost.
      </Text>
    ),
    labels: { confirm: 'Discard Draft', cancel: "Cancel" },
    confirmProps: { color: 'red' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => { 
      handleDiscarddraft(pageId);
    },
  });

	const handleDiscarddraft = (pageId:any) => {
    TestsApi.discarddraft({
      system_website_id: selectedMemberWebsite,
      page_id:selectedDraftPageId,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setSelectedDraftThumbnail(null);
        setMarksalestime(data.data.updated_time);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }
/* END DISCARD TEST*/
	return (
	  <>
	  {/*Error Model*/}
	  {errorModelOpen && (
	  <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
	    <Modal.Overlay />
	    <Modal.Content>
        {showMessageHeader ? (
          <Modal.Header>
            <Modal.Title>Select Smart Relational Test Alert</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
        ): showErrorHeader ? (
          <Modal.Header>
            <Modal.Title>ERROR</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
        ): (
          <Modal.Header>
          </Modal.Header>
        )}
	      <Modal.Body>
	        <form>
	          <div style={{ display: 'flex'}}>
	              <Text weight={500}>{errorMessage}</Text>
	          </div>
	        </form>
	      </Modal.Body>
	    </Modal.Content>
	  </Modal.Root>
	  )}
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
  	<Container size="xl">
  		<Card shadow="sm" padding="sm" radius="md" withBorder>
	  	  <Group position="apart" mb="xs">
	  	    <Text weight={500}>Create Test <SelectTooltip/></Text>
	  	  </Group>
	  	  <Container size="xl" px={0}>
	  	    <Accordion chevronPosition="right"  value={accordionItemDefaultValue} onChange={setAccordionItemDefaultValue} variant="separated">
	  	      <Accordion.Item className={classes.item} value="step-1">
	  	        <Accordion.Control><Text component="span" color="red" weight={500}>Step 1</Text><Text component="span" weight={500}> - Select a Page</Text></Accordion.Control>
	  	        <Accordion.Panel>
          	    <div style={{ display: 'flex'}}>
                  <Text mt={5} mr={5}>Configured pages:</Text>
          	     	<Select 
          	     	  w={200}
          	     	  defaultValue="0"
          	     	  data={AllCreateTestPagesoptions}
          	     	  onChange={(selectedAllCreateTestPageOption) => handleSelectAllCreateTestPagesValueChange(selectedAllCreateTestPageOption)}
          	     	  searchable
          	     	  nothingFound="No options"
          	     	  maxDropdownHeight={300}
          	     	  dropdownPosition="bottom"
          	     	/>
          	     	<Text mt={5} ml={20} mr={5}>View:</Text>
          	     	<Select
          	     		onChange={handleSelectView}
          	     		defaultValue="grid"
          	     		value={viewSelect}
        	     	  	data={[
        	     	      { value: 'grid', label: 'Grid' },
        	     	      { value: 'list', label: 'List' }
        	     	    ]}
          	     	  w={80}
          	     	/>
          	     	<Text mt={5} ml={20} mr={5}>Show: </Text>
          	     	<Select
          	     		onChange={handleShowNumberChange}
          	     		defaultValue="25"
        	     	  	data={[
        	     	      { value: '10', label: '10' },
        	     	      { value: '25', label: '25' },
        	     	      { value: '50', label: '50' },
        	     	      { value: '100', label: '100' }
        	     	    ]}
          	     	  w={80}
          	     	/>
        	     	  <Flex
        	     	  	style={{marginLeft:"auto"}}
        	     	    justify="flex-end"
        	     	    align="center"
        	     	    direction="row"
        	     	    wrap="wrap"
        	     	  >
        		     	  <Input icon={<IconSearch size={16} />} placeholder="Search"
        				     	maxLength={50}
        				     	autoFocus
        				     	onChange={handleKeywordChange}
        		     	   />
        	     	  </Flex>
          	    </div>
          	    <div style={{flex: "1 1 auto",overflowX: "hidden",marginTop:10}}>
          	    	<div style={{ display: viewSelect === "grid" ? "block" : "none"}}>
                    <Skeleton visible={loading}>
        					  <Grid ml={0} mt={10} mb={10} >
        					    {selectPageCreateTests.map((item, index) => (
        					      <div className="visi-page" key={index} style={{marginRight:10}} >
        					        <div className={item.pageClass}
		    					        			style={{ cursor: 'pointer', backgroundColor: (item.selected || selectedThumbnail === item.page_id || selectedDraftThumbnail === item.page_id) ? '#00b975' : '' }}
				        								onClick={item.selected ? undefined : () => {
											            if (item.pageClass === "page draft") {
											                handleDraftThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id);
											            } else {
											                handleThumbnailClick(item.page_id, item.taguchi_test_id, item.funnel_id, item.tests_id);
											            }
        												}}
				        							>
        					          <span title={item.page_url}>
									            <span className="page-url">{item.page_title}</span>
									          </span>
        					          <div className="thumbnail" style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.img_url})` }} title={item.page_url}>
        					            {(item.marge_img !== '' && (
						                      <Image
						                      id="merge_img"
						                      src={item.marge_img}
						                      title="This Page is merged with other links"
						                    />
						                    ))
						                  }
				                      {(item.track_check !== '' && (
				                        <Image
				                          id="track_img"
				                          src={item.track_check}
				                          title={item.track_check_title}
				                        />
				                        ))
				                      }
        					          </div>
        					          <span className="page-title">{item.custom_title}</span>
        					        </div>
        					      </div>
        					    ))}
        				  	</Grid>
                    </Skeleton>
                    {selectPageCreateTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
        					  <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
        					    <Group spacing={5} position="center">
        					      <Pagination.First />
        					      <Pagination.Previous />
        					      <Pagination.Items />
        					      <Pagination.Next />
        					      <Pagination.Last />
        					    </Group>
        					  </Pagination.Root>
                    )}
        				  </div>
        				  <div style={{ display: viewSelect === "list" ? "block" : "none"}}>
                    {selectPageCreateTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
                    <>
                    <Skeleton visible={loading}>
        				  	<Table striped highlightOnHover withBorder withColumnBorders mb={10}>
        	           <thead>
        	             <tr>
        	               <th>URL</th>
        	               <th>Page Title</th>
        	               <th>Draft Test name</th>
        	               <th>Draft Test Type</th>
        	             </tr>
        	           </thead>
        	           <tbody>{listRows}</tbody>
        		  	    </Table>
        		  	    <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
        		  	      <Group spacing={5} position="center">
        		  	        <Pagination.First />
        		  	        <Pagination.Previous />
        		  	        <Pagination.Items />
        		  	        <Pagination.Next />
        		  	        <Pagination.Last />
        		  	      </Group>
        		  	    </Pagination.Root>
                    </Skeleton>
                    </>
                    )}
        				  </div>
        			  </div>
        			  <div className="legend-holder">
        				  <Grid>
        		        <Grid.Col span={6}>
        		        	<Button type="button" onClick={() => handleAddtoFunnel()}>Continue</Button>
	        		        {selectedDraftThumbnail && (
								      	<Button type="button" style={{ marginLeft: 7, backgroundColor: '#ff8125' }} onClick={() => discardDraftConfirm(selectedDraftThumbnail)}>Discard Draft test</Button>
								    	)}
        		        </Grid.Col>  
	        		      <Grid.Col span={3} offset={3} mt={15}> 
	        		        	<Group spacing="xs" m={0}>
	        		        	  <span className="legend selected" />
	        		        	  <Text size="sm" ml={0} fz={12} className="legend-title">Selected</Text>
	        		        	  <span className="legend no-test" />
	        		        	  <Text size="sm" ml={0} fz={12} className="legend-title">No Test</Text>
	        		        	  <span className="legend draft" />
	        		        	  <Text size="sm" ml={0} fz={12} className="legend-title">Draft Test</Text>
	        		        	</Group>
	        		      </Grid.Col>
        		      </Grid>
        			  </div>
	  	        </Accordion.Panel>
	  	      </Accordion.Item>
	  	      <Accordion.Item className={classes.item} value="step-2" style={{display:step2}}>
	  	        <Accordion.Control><Text component="span" color="red" weight={500}>Step 2</Text><Text component="span" weight={500}> - Select Test Type</Text></Accordion.Control>
	  	        <Accordion.Panel>
	  	          <Grid>
	                <Grid.Col span={3}>
	                	<Card withBorder h={480}>
	                		<Text weight={500} fz={18} mb={20} ta="center">Classic A/B Split Test</Text>
	                		<Image src="./img/tests/test-ab.png"/>
	                		<Text mt={10}>Select this type of experiment if you want to test entirely different versions of a page.</Text>
	                		<Space h="md" />
	                		<Text>Make sure you have the rotation page ready and uploaded on your FTP server.</Text>
	                		<Space h="md" />
	                		<div style={{textAlign : "center"}}><Button type="button" mb={10} mt={65} onClick={() => ContinueToRotataionPage(selectedThumbnail)}>Continue</Button></div>
	                	</Card>
	                </Grid.Col>
	                <Grid.Col span={3}>
	                	<Card withBorder h={480}>
	                		<Text weight={500} fz={18} mb={20} ta="center">Modern A/B Split Test</Text>
	                		<Image src="./img/tests/test-ab-modern.png"/>
	                		<Text mt={10}>Select this type of experiment if you'd like to perform an A/B split test while ensuring that the same URL is displayed at all times. This rotates all items chosen on the original URL.</Text>
	                		<Space h="md" />
	                		<Text>This is helpful when running tests on some ad networks to preserve quality scores while testing.</Text>
	                		<Space h="md" />
	                		<div style={{textAlign : "center"}}><Button type="button" mb={10} mt={0}><Link to={`/test-editor-visual-modern/${selectedThumbnail}/${modernabSplitTest}`} style={{color:"white",textDecoration: "none"}}>Continue</Link></Button></div>
	                	</Card>
	                </Grid.Col>
	                <Grid.Col span={3}>
	                	<Card withBorder h={480}>
	                		<Text weight={500} fz={18} mb={20} ta="center">Single-Variable Test</Text>
	                		<Image src="./img/tests/test-single-variable.png"/>
	                		<Text mt={10}>Select this type of experiment for testing a single element (Ex: the headline, or background color, or a picture) on your webpage.</Text>
	                		<Space h="md" />
	                		<Text>There are maximum 10 versions which can be rotated.</Text>
	                		<Space h="md" />
	                		<div style={{textAlign : "center"}}><Button type="button" mb={10} mt={42}><Link to={`/test-editor-visual/${selectedThumbnail}/${singleVariableTest}`} style={{color:"white",textDecoration: "none"}}>Continue</Link></Button></div>
	                	</Card>
	                </Grid.Col>
	                <Grid.Col span={3}>
	                	<Card withBorder h={480}>
	                		<Text weight={500} fz={18} mb={20} ta="center">Smart Relational Testing™</Text>
	                		<Image src="./img/tests/test-multi-variable.png"/>
	                		<Text mt={10}>Select this type of experiment if you want to test several elements (Ex: the headline and background color and a picture) on your webpage.</Text>
	                		<Space h="md" />
	                		<div style={{textAlign : "center"}}><Button type="button" mb={10} mt={102} onClick={() => ContinueToSmartRelational(selectedThumbnail)}>Continue</Button></div>
	                	</Card>
	                </Grid.Col>
	              </Grid>
	  	        </Accordion.Panel>
	  	      </Accordion.Item>
	  	      <Accordion.Item className={classes.item} value="step-3" style={{display:step3}}>
	  	        <Accordion.Control><Text component="span" color="red" weight={500}>Step 3</Text><Text component="span" weight={500}> - Select Rotataion Page</Text></Accordion.Control>
 	  	        <Accordion.Panel>
           	    <div style={{ display: 'flex'}}>
                   <Text mt={5} mr={5}>Configured pages:</Text>
           	     	<Select 
           	     	  w={200}
           	     	  defaultValue="0"
           	     	  data={AllCreateTestPagesoptions}
          	     	  onChange={(selectedAllCreateTestPageOption) => handleSelectAllCreateTestPagesValueChange(selectedAllCreateTestPageOption)}
           	     	  searchable
           	     	  nothingFound="No options"
           	     	  maxDropdownHeight={300}
           	     	  dropdownPosition="bottom"
           	     	/>
           	     	<Text mt={5} ml={20} mr={5}>View:</Text>
           	     	<Select
           	     		onChange={handleSelectView}
           	     		defaultValue=""
           	     		value={viewSelect}
         	     	  	data={[
         	     	      { value: 'grid', label: 'Grid' },
         	     	      { value: 'list', label: 'List' }
         	     	    ]}
           	     	  w={80}
           	     	/>
           	     	<Text mt={5} ml={20} mr={5}>Show: </Text>
           	     	<Select
           	     		onChange={handleShowNumberChange}
           	     		defaultValue="25"
         	     	  	data={[
         	     	      { value: '10', label: '10' },
         	     	      { value: '25', label: '25' },
         	     	      { value: '50', label: '50' },
         	     	      { value: '100', label: '100' }
         	     	    ]}
           	     	  w={80}
           	     	/>
         	     	  <Flex
         	     	  	style={{marginLeft:"auto"}}
         	     	    justify="flex-end"
         	     	    align="center"
         	     	    direction="row"
         	     	    wrap="wrap"
         	     	  >
         		     	  <Input icon={<IconSearch size={16} />} placeholder="Search"
         				     	maxLength={50}
         				     	autoFocus
         				     	onChange={handleKeywordChange}
         		     	   />
         	     	  </Flex>
           	    </div>
           	    <div style={{flex: "1 1 auto",overflowX: "hidden",marginTop:10}}>
           	    	<div style={{ display: viewSelect === "grid" ? "block" : "none"}}>
         					  <Grid ml={0} mt={10} mb={10} >
                      <Skeleton visible={loading}>
         					    {selectPageCreateTests.map((item, index) => (
        					      <div className="visi-page" key={index} style={{marginRight:10}} >
        					        <div className={item.pageClass}
			        					        style={{ cursor: 'pointer', backgroundColor: (item.selected || selectedThumbnails.includes(item.page_id)) ? '#00b975' : '' }} 
			                      		onClick={item.selected ? undefined : () => handleMultipleThumbnailClick(item.page_id)}
				        							>
        					          <span title={item.page_url}>
									            <span className="page-url">{item.page_title}</span>
									          </span>
         					          <div className="thumbnail" style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.img_url})` }} title={item.page_url}>
        					            {(item.marge_img !== '' && (
						                      <Image
						                      id="merge_img"
						                      src={item.marge_img}
						                      title="This Page is merged with other links"
						                    />
						                    ))
						                  }
				                      {(item.track_check !== '' && (
				                        <Image
				                          id="track_img"
				                          src={item.track_check}
				                          title={item.track_check_title}
				                        />
				                        ))
				                      }
        					          </div>
        					          <span className="page-title">{item.custom_title}</span>
         					        </div>
         					      </div>
         					    ))}
                      </Skeleton>
         				  	</Grid>
                    {selectPageCreateTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
         					  <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
         					    <Group spacing={5} position="center">
         					      <Pagination.First />
         					      <Pagination.Previous />
         					      <Pagination.Items />
         					      <Pagination.Next />
         					      <Pagination.Last />
         					    </Group>
         					  </Pagination.Root>
                    )}
         				  </div>
         				  <div style={{ display: viewSelect === "list" ? "block" : "none"}}>
                    {selectPageCreateTests.some(item => Array.isArray(item) ? item.length > 0 : true) && (
                    <>
                    <Skeleton visible={loading}>
         				  	<Table striped highlightOnHover withBorder withColumnBorders mb={10}>
         	           <thead>
         	             <tr>
         	             	 <th>Preview</th>
         	               <th>URL</th>
         	               <th>Page Title</th>
         	               <th>Draft Test name</th>
         	               <th>Draft Test Type</th>
         	             </tr>
         	           </thead>
         	           <tbody>{listRows3}</tbody>
         		  	    </Table>
         		  	    <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
         		  	      <Group spacing={5} position="center">
         		  	        <Pagination.First />
         		  	        <Pagination.Previous />
         		  	        <Pagination.Items />
         		  	        <Pagination.Next />
         		  	        <Pagination.Last />
         		  	      </Group>
         		  	    </Pagination.Root>
                    </Skeleton>
                    </>
                    )}
         				  </div>
         			  </div>
           	    <form onSubmit={handleSaveClassicRotationPage}>
         				<div style={{ display: 'flex',marginTop:15}}>
			  	        <Text mt={5} mr={5}>Select Funnel:</Text><div style={{color:"red",marginTop:5}}>*</div>
			  		     	<Select 
			  		     	  w={200}
			  		     	  ml={10}
			  		     	  defaultValue={selectedFunnelRotation}
			  		     	  value={selectedFunnelRotation}
              			onChange={handleFunnelRotationChange}
			  		     	  data={funnelRotationData}
			  		     	  searchable
			  		     	  nothingFound="No options"
			  		     	  maxDropdownHeight={300}
			  		     	/>
		     	        <Text mt={5} mr={5} ml={15}>Test Name:</Text><div style={{color:"red",marginTop:5}}>*</div>
		     		     	<Input w={200} ml={10} value={testName} onChange={(e) => setTestName(e.target.value)} />
       		        <Button type="submit" ml={10} style={{color:"white",textDecoration: "none"}}>Continue</Button>
       		      </div>
       		      </form>
 	  	        </Accordion.Panel>
	  	      </Accordion.Item>
	  	      <Accordion.Item className={classes.item} value="step-4" style={{display:step4}}>
	  	        <Accordion.Control><Text component="span" color="red" weight={500}>Step 3</Text><Text component="span" weight={500}> - Select Smart Relational Test</Text></Accordion.Control>
  	        	<Accordion.Panel>
                <Skeleton visible={loading}>
  	        		<Group>
  	        			{selectSmartRelationData.map((item) => (
						        <Card
						          key={item.taguchi_type_id}
						          withBorder
						          h={130}
						          py={6}
						          w={232}
						          className={item.taguchi_type_id === selectTestType ? "test_type_clicked" : ""}
						          onClick={() => SelectSmartRelationalTest(item.taguchi_type_id)}
                      style={{cursor: 'pointer'}}
						        >
						          <Text weight={700}>{item.title}</Text>
						          <Text my={8} fz={12} weight={700}>{item.variables} VARIABLES</Text>
						          {item.numberofcount.map((count, index) => (
						            <div key={index} style={{ display: "flex" }}>
						              <Text fz={12} weight={700} mr={5}>{count.numberVars}</Text>
						              <Text fz={12} mr={5}>variables with</Text>
						              <Text fz={12} weight={700} mr={5}>{count.numberVers}</Text>
						              <Text fz={12}>versions</Text>
						            </div>
						          ))}
						        </Card>
						      ))}
              	</Group>
                </Skeleton>
              	<Group>
              		<Button type="button" mt={10} onClick={() => ContinueTestEditor(selectedThumbnail)}>Continue</Button>
              	</Group>
  	        	</Accordion.Panel>
  	      	</Accordion.Item>
  	    	</Accordion>
	  	  </Container>
	  	</Card>
	  </Container>
	  </>
	);
}