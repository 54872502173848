import { Text, Group, Pagination, Select, Input, Grid, Flex, Image, Modal } from '@mantine/core';
import { IconHelp, IconSearch, IconCheck } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './ManageYourPages.css';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import SettingsApi from "../../../api/SettingsApi";

function chunk<T>(array: T[], size: number): T[][] {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);
  return [head, ...chunk(tail, size)];
}

export function ManageYourPages({ SelectMemberWebsiteListValue, SelectMemberWebsiteListOnChange }:any) {
  const navigate = useNavigate();
  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);
  const [manageYourPagesData, setManageYourPagesData] = useState([]);
  const [showNumber, setShowNumber] = useState(25);
  const [totalPages, setTotalPages] = useState(10);
  const [totalPaginationNo, setTotalPaginationNo] = useState(1);
  const [activePageNo, setActivePageNo] = useState(1);
  const [keyword, setKeyword] = useState('');
  const selectedOption = '';
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [marksalestime, setMarksalestime] = useState(0);

  /*const data = chunk(
    Array(30)
      .fill(0)
      .map((_, index) => ({ id: index, name: randomId() })),
    Number(showNumber)
  );*/

  const handleChooseActionChange = (selectedPageId:any,value:any) => {
    setSelectedPageId(selectedPageId);
    if(value === "delete"){
      modals.openConfirmModal({
        title: 'Delete Page',
        children: (
          <Text size="sm">
            Are you sure you want to remove this page?
          </Text>
        ),
        labels: { confirm: 'Delete the Page', cancel: "No don't Delete it" },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => handleDeletePage(selectedPageId),
      });
    }else if(value === "refresh_screenshot"){
      modals.openConfirmModal({
        title: 'Refresh Screenshot',
        children: (
          <Text size="sm">
            Your screenshot will be updated soon!!!
          </Text>
        ),
        labels: { confirm: 'Ok', cancel: "Close" },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => handleRefreshScreenshot(selectedPageId),
      });
    }
  };

  /* Delete Page */
  const handleDeletePage = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await SettingsApi.managedeletepage({
        system_website_id: SelectMemberWebsiteListValue,
        page_id: selectedPageId
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        setMarksalestime(APIResultJSON.data.updated_time);
      } else {
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowErrorHeader(false); 
        setShowMessageHeader(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  /* Refresh Screenshot */
  const handleRefreshScreenshot = async (selectedPageId) =>{
    setSelectedPageId(selectedPageId);
    try {
      const APIResponse = await SettingsApi.refreshscreenshot({
        system_website_id: SelectMemberWebsiteListValue,
        page_id: selectedPageId
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        setMarksalestime(APIResultJSON.data.updated_time);
      } else {
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const funnelBoxOption = [
    { value: '', label: 'Choose Action', hidden:'hidden' },
    { value: 'delete', label: 'Delete Page' },
    { value: 'refresh_screenshot', label: 'Refresh Screenshot' }
  ];

  useEffect(() => {
    if(SelectMemberWebsiteListValue !== ''){
      seterrorCloseModal(false);
      SettingsApi.getmanageyourpageslist({
        site_id: SelectMemberWebsiteListValue,
        number: showNumber,
        page: activePageNo,
        keyword: keyword
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setManageYourPagesData(data.data.pages_list || []);
          if (data.data.active_page_no !== activePageNo) {
            setActivePageNo(data.data.active_page_no);
            setActivePageNo(1);
          }
          if (data.data.total_pages !== totalPages) {
            setTotalPages(data.data.total_pages);
          }
          if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
            setTotalPaginationNo(data.data.total_pagination_no);
          }
        }else{
          setErrorMessage(data.message);
          seterrorCloseModal(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  }, [SelectMemberWebsiteListValue, marksalestime, activePageNo, showNumber, keyword,navigate, totalPages,totalPaginationNo]);

  const handleShowNumberChange = (selectedShowNumberOption) => {
    setShowNumber(selectedShowNumberOption);
    setActivePageNo(1);
  };

  const handlePageChange = (newPage) => {
    setActivePageNo(newPage);
  };

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
    setActivePageNo(1);
  };

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

	return (
		<>
      {/*Error Model*/}
      {errorModelOpen && (
      <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
        <Modal.Overlay />
        <Modal.Content>
          {showMessageHeader ? (
            <Modal.Header>
              <Modal.Title>Select Smart Relational Test Alert</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ): showErrorHeader ? (
            <Modal.Header>
              <Modal.Title>ERROR</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ): (
            <Modal.Header>
            </Modal.Header>
          )}
          <Modal.Body>
            <form>
              <div style={{ display: 'flex'}}>
                  <Text weight={500}>{errorMessage}</Text>
              </div>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      )}
			<Group position="apart" mt="md" mb="xs">
	      <Text weight={500}>MANAGE YOUR PAGES <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	    </Group>
      <div style={{ display: 'flex'}}>
        <Text mt={5} ml={20} mr={5}>Show: </Text>
        <Select
          onChange={handleShowNumberChange}
          defaultValue="25"
          data={[
            { value: '10', label: '10' },
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
          ]}
          w={80}
        />
        <Flex
          style={{marginLeft:"auto"}}
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Input icon={<IconSearch size={16} />} placeholder="Search"
            maxLength={50}
            autoFocus
            onChange={handleKeywordChange}
           />
        </Flex>
      </div>
      <div style={{flex: "1 1 auto",overflowX: "hidden",marginTop:10}}>
        <Grid ml={0} mt={10} mb={10} >
          {manageYourPagesData.map((item,index) => (
            <div className="visi-page" key={index} style={{marginRight:10}}>
              <div className={item.pageClass}>
                <span title={item.page_url}>
                  <span className="page-url">{item.page_title}</span>
                </span>
                <div className="thumbnail" style={{ backgroundImage: `url(${'https://static.visiopt.com/page-thumb/'+item.img_url})` }} title={item.page_url}>
                {(item.marge_img !== '' && (
                    <Image
                    id="merge_img"
                    src={item.marge_img}
                    title="This Page is merged with other links"
                  />
                  ))
                }
                {(item.track_check !== '' && (
                  <Image
                    id="track_img"
                    src={item.track_check}
                    title={item.track_check_title}
                  />
                  ))
                }
                </div>
                <span className="page-title">{item.custom_title}</span>
              </div>
              <Select
                placeholder="Choose Action"
                defaultValue=""
                onChange={(value) => {
                  handleChooseActionChange(item.page_id,value);
                }}
                value={selectedOption[item.page_id]}
                data={funnelBoxOption}
                maxDropdownHeight={250}
                maw={152}
                size="xs"
                dropdownPosition="top"
              />
            </div>
          ))}
        </Grid>
        <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
          <Group spacing={5} position="center">
            <Pagination.First />
            <Pagination.Previous />
            <Pagination.Items />
            <Pagination.Next />
            <Pagination.Last />
          </Group>
        </Pagination.Root>
      </div>
      <Flex
        gap="md"
        justify="flex-start"
        align="center"
        direction="row"
        wrap="wrap"
        mt={50}
        mb={10}
      >
      <Group>
        <span className="active_test"></span>
        <Text size="sm">Active Test</Text>
        <span className="no_test"></span>
        <Text size="sm">No Test</Text>
        <span className="test_completed"></span>
        <Text size="sm">Test Completed</Text>
        <span className="winner_set"></span>
        <Text size="sm">Winner Set</Text>
        <span className="draft_test"></span>
        <Text size="sm">Draft Test</Text>
      </Group>
      </Flex>
		</>
	);
}