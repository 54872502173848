// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
	font-family: "Segoe UI", "DejaVu Sans", "Trebuchet MS", Verdana, "sans-serif"
}
.email-thumb {
	display: inline-block;
	width: 140px;
	padding: 18px 0px 5px 0;
	border-radius: 8px;
	border: 1px solid #dee2e6;
	text-align: center;
	cursor: pointer;
}
.email-thumb:hover {
	background-color: #f0f0f0;
}
.thumb-holder {
	padding: 10px 0px 10px 0px;
	width: 100%;
}
.thumb-holder img{
	height: 64px;
	display: block;
	margin: 0 auto;
}	
.email-thumb span {
	color: #495057;
	font-size: 14px;
	line-height: 14px;
}
.active-email {
	background-color: #e6fcf5;
	border: 1px solid #38d9a9;
}`, "",{"version":3,"sources":["webpack://./src/components/Settings/EmailIntegration/EmailIntegration.css"],"names":[],"mappings":"AAAA;CACC;AACD;AACA;CACC,qBAAqB;CACrB,YAAY;CACZ,uBAAuB;CACvB,kBAAkB;CAClB,yBAAyB;CACzB,kBAAkB;CAClB,eAAe;AAChB;AACA;CACC,yBAAyB;AAC1B;AACA;CACC,0BAA0B;CAC1B,WAAW;AACZ;AACA;CACC,YAAY;CACZ,cAAc;CACd,cAAc;AACf;AACA;CACC,cAAc;CACd,eAAe;CACf,iBAAiB;AAClB;AACA;CACC,yBAAyB;CACzB,yBAAyB;AAC1B","sourcesContent":["* {\n\tfont-family: \"Segoe UI\", \"DejaVu Sans\", \"Trebuchet MS\", Verdana, \"sans-serif\"\n}\n.email-thumb {\n\tdisplay: inline-block;\n\twidth: 140px;\n\tpadding: 18px 0px 5px 0;\n\tborder-radius: 8px;\n\tborder: 1px solid #dee2e6;\n\ttext-align: center;\n\tcursor: pointer;\n}\n.email-thumb:hover {\n\tbackground-color: #f0f0f0;\n}\n.thumb-holder {\n\tpadding: 10px 0px 10px 0px;\n\twidth: 100%;\n}\n.thumb-holder img{\n\theight: 64px;\n\tdisplay: block;\n\tmargin: 0 auto;\n}\t\n.email-thumb span {\n\tcolor: #495057;\n\tfont-size: 14px;\n\tline-height: 14px;\n}\n.active-email {\n\tbackground-color: #e6fcf5;\n\tborder: 1px solid #38d9a9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
