import { Group, Text, Button, Box, Grid, ActionIcon, Flex, Container, Card } from '@mantine/core';
import { IconHelp, IconTrash, IconPencil, IconCheck, IconX } from '@tabler/icons-react';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { VisiLoader } from "../../components/GeneralComponent/GeneralComponent";
import CampaignApi from "../../api/CampaignApi";

export function ManageCampaigns() {
  const navigate = useNavigate();
  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

  const handleHeaderMenuComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  useDocumentTitle("Visiopt - Manage Campaigns");

  const updateCampaign = (campaign_id:any) => {
    navigate("/create-campaign", { state: { campaign_id: campaign_id} });
  }

  const deleteCampaignConfirm = (deleteCampaignId:any) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure you want to delete this campaign?
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {},
    onConfirm: () => {
      setCampaignLoader(true);
      CampaignApi.deletecampaign({
        c_id : deleteCampaignId,
        system_website_id : selectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setCampaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setCampaignList(campaignList.filter(item => item.campaign_id !== deleteCampaignId));
          notifications.show({
            color: 'teal',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: data.message,
          });
        }
        setCampaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        setCampaignLoader(false);
      });
    } 
  });
  
  const [campaignLoader, setCampaignLoader] = useState(false);
  const [campaignList, setCampaignList] = useState([]);

  useEffect(() => {
    if(selectedMemberWebsite !== ""){
      setCampaignLoader(true);
      CampaignApi.getcampaignlist({
        system_website_id : selectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setCampaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1 && data.data && data.data.length > 0){
          setCampaignList(data.data);
        }else{
          setCampaignList([]);
        }
        setCampaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        setCampaignLoader(false);
      });
    }
  }, [selectedMemberWebsite]);

	return (
		<>
    <VisiLoader VisiLoaderVisible={campaignLoader} />
    <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
    <Container size="xl">
      <Card shadow="sm" padding="sm" radius="md" withBorder>
  			<Group position="apart" mb="xs">
          <Text weight={500}>MANAGE YOUR CAMPAIGNS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
        </Group>
        <Button type="button">
          <Link to="/create-campaign" style={{color:"white",textDecoration: "none"}}>
            Create a New Campaign
          </Link>
        </Button>
        {campaignList.map(Campaign =>
          <Box
            p={4}
            pl={20}
            mt={10}
            sx={(theme) => ({
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
              padding: "10px",
              borderRadius: theme.radius.md,
              border: "0.0625rem solid transparent",
              borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
            })}
          >
            <Grid gutter="xs">
              <Grid.Col span={6}>
                <Text mt={3}>
                  {Campaign.campaign_name}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Flex
                  justify="flex-end"
                  align="center"
                  direction="row"
                  wrap="wrap"
                >
                <ActionIcon
                  variant="default"
                  size="lg"
                  mx={3}
                  onClick={() => updateCampaign(Campaign.campaign_id)}
                >
                  <IconPencil size="1.1rem" />
                </ActionIcon>
                <ActionIcon
                  variant="default"
                  size="lg"
                  mx={3}
                  onClick={() => deleteCampaignConfirm(Campaign.campaign_id)}
                >
                  <IconTrash size="1.1rem" />
                </ActionIcon>
                </Flex>
              </Grid.Col>
            </Grid>
          </Box>
        )}
      </Card>
    </Container>
		</>
	);
}