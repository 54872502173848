import { ServerAPI } from '../GeneralFunction';

export default {
	login: (data) => {
		let url = "login";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	logout: () => {
		let url = "logout";
		const requestOptions = ServerAPI(url, 'GET');
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	resetpassword: (data) => {
		let url = "resetpassword";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getmemberlist: (data) => {
		let url = "get_member_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	forgotpassword: (data) => {
		let url = "forgot_password";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
};