import { Group, Text, Select, Button, Divider, Textarea, CopyButton, Anchor, TextInput, PasswordInput } from '@mantine/core';
import { IconHelp, IconX, IconCheck } from '@tabler/icons-react';
import { useState, useEffect } from 'react';
import PermissionWrapper from '../../../PermissionWrapper';
import { VisiLoader } from "../../GeneralComponent/GeneralComponent";
import { useNavigate } from "react-router-dom";
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import SettingsApi from "../../../api/SettingsApi";

export function MyAccount() {

	const navigate = useNavigate();

	const [authorizationBearerToken, setAuthorizationBearerToken] = useState('');
  const [apiDocumentation, setApiDocumentation] = useState('https://documenter.getpostman.com/view/14485376/2s93zB62M5');

  const [myAccountLoader, setMyAccountLoader] = useState(false);
  const [countryList, setCountryList] = useState([{ country_id : '', country_name : 'Please Select Country' }]);
  const [stateList, setStateList] = useState([{ state_id : '', state_name : 'Please Select State' }]);
  const [timeZoneList, setTimeZoneList] = useState([{ zone : '', zone_time : 'Please Select Timezone' }]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setphone] = useState('');

  const account_detail_form = useForm({
    initialValues: { first_name: '', last_name: '', country_id: '', state_id: '', city: '', zip: '', phone: '', timezone: '' },
    validate: {
    	first_name: (val) => (val === '' ? 'First Name is Required' : null),
    	last_name: (val) => (val === '' ? 'Last Name is Required' : null),
    	country_id: (val) => (val === '' ? 'Country is Required' : null),
    	state_id: (val) => (val === '' ? 'State is Required' : null),
    	city: (val) => (val === '' ? 'City is Required' : null),
    	zip: (val) => (val === '' ? 'Zip is Required' : null),
    	phone: (val) => (val === '' ? 'Mobile Number is Required' : null),
    	timezone: (val) => (val === '' ? 'Timezone is Required' : null),
    },
  });

  const change_password_form = useForm({
    initialValues: { password: '', password2: '' },
    validate: {
    	password: (val) => (val.length < 6 ? 'Password must be at least 6 characters' : null),
      password2: (value, values) => value !== values.password ? 'Password and Re-type Password not match' : null,
    },
  });

  useEffect(() => {
  	setMyAccountLoader(true);
  	SettingsApi.getcountrylist()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setMyAccountLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data && data.data.length > 0){
        setCountryList(data.data);
        setMyAccountLoader(false);
      }
      setMyAccountLoader(false);
    })
    .catch(error => {
      console.log(error);
      setMyAccountLoader(false);
    });

    setMyAccountLoader(true);
  	SettingsApi.gettimezonelist()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setMyAccountLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data && data.data.length > 0){
        setTimeZoneList(data.data);
        setMyAccountLoader(false);
      }
      setMyAccountLoader(false);
    })
    .catch(error => {
      console.log(error);
      setMyAccountLoader(false);
    });

    setMyAccountLoader(true);
  	SettingsApi.getmyaccountdatails()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setMyAccountLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	if(data.data.first_name){
      		account_detail_form.setFieldValue('first_name', data.data.first_name);
      	}
      	if(data.data.last_name){
      		account_detail_form.setFieldValue('last_name', data.data.last_name);
      	}
      	if(data.data.country_id){
      		account_detail_form.setFieldValue('country_id', data.data.country_id);
      	}
      	if(data.data.state_id){
      		account_detail_form.setFieldValue('state_id', data.data.state_id);
      	}
      	if(data.data.city){
      		account_detail_form.setFieldValue('city', data.data.city);
      	}
      	if(data.data.zip){
      		account_detail_form.setFieldValue('zip', data.data.zip);
      	}
      	if(data.data.phone){
      		account_detail_form.setFieldValue('phone', data.data.phone);
      	}
      	if(data.data.timezone){
      		account_detail_form.setFieldValue('timezone', data.data.timezone);
      	}
      	if(data.data.password){
      		change_password_form.setFieldValue('password', data.data.password);
      	}
      	if(data.data.password2){
      		change_password_form.setFieldValue('password2', data.data.password2);
      	}
      	if(data.data.api_key){
      		setAuthorizationBearerToken(data.data.api_key);
      	}
        setMyAccountLoader(false);
      }
      setMyAccountLoader(false);
    })
    .catch(error => {
      console.log(error);
      setMyAccountLoader(false);
    });
  }, []);

  useEffect(() => {
  	if(account_detail_form.values.country_id !== ''){
  		setMyAccountLoader(true);
	  	SettingsApi.getstatelist({
	  		country_id : account_detail_form.values.country_id
	  	})
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          setMyAccountLoader(false);
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1 && data.data && data.data.length > 0){
	        setStateList(data.data);
	        setMyAccountLoader(false);
	      }
	      setMyAccountLoader(false);
	    })
	    .catch(error => {
	      console.log(error);
	      setMyAccountLoader(false);
	    });
  	}
  }, [account_detail_form.values.country_id]);

  const selectedCountryChange = (countryId) => {
  	account_detail_form.setFieldValue('country_id', countryId);
  	account_detail_form.setFieldValue('state_id', '');
  }

  const selectedStateChange = (stateId) => {
  	account_detail_form.setFieldValue('state_id', stateId);
  }

  const selectedTimeZoneChange = (timezone) => {
  	account_detail_form.setFieldValue('timezone', timezone);
  }

  const handleAccountDetailSubmit = async (formData:any) => {
  	setMyAccountLoader(true);
    SettingsApi.savemyaccountdetail(formData)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setMyAccountLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setMyAccountLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setMyAccountLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setMyAccountLoader(false);
    });
  }

  const handleChangePasswordSubmit = async (formData:any) => {
  	setMyAccountLoader(true);
    SettingsApi.changepassword(formData)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setMyAccountLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setMyAccountLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setMyAccountLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setMyAccountLoader(false);
    });
  }

  const generateNewAPIToken = async () => {
  	setMyAccountLoader(true);
    SettingsApi.apitokengenerate({
    	visiopt_api_access : "1"
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setMyAccountLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
    	console.log(data);
      if(data.status === 1 && data.data && data.data.token){
      	setAuthorizationBearerToken(data.data.token);
        setMyAccountLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setMyAccountLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setMyAccountLoader(false);
    });
  }

  return (
    <>
    	<VisiLoader VisiLoaderVisible={myAccountLoader} />
    	<PermissionWrapper permission="operational_access">
	      <Group position="apart" mb="xs">
	        <Text weight={500}>MY ACCOUNT <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
	      <form onSubmit={account_detail_form.onSubmit((formData) => { handleAccountDetailSubmit(formData); })}>
		      <Group spacing="xs" mb={5}>
		        <Text size="sm" fz={14} mb={10} ml={53}>First Name:</Text>
			     	<TextInput {...account_detail_form.getInputProps('first_name')} w={250} ml={0} value={account_detail_form.values.first_name} onChange={(event) => account_detail_form.setFieldValue('first_name', event.currentTarget.value)}/>
			     	<Text size="sm" fz={14} mb={10} ml={13}>Last Name:</Text>
			     	<TextInput {...account_detail_form.getInputProps('last_name')} w={250} ml={0} value={account_detail_form.values.last_name} onChange={(event) => account_detail_form.setFieldValue('last_name', event.currentTarget.value)}/>
			    </Group>
			    <Group spacing="xs" mb={5}>
		     		<Text size="sm" fz={14} mb={10} ml={70}>Country:</Text>
			     	<Select
			     		w={250}
			     		{...account_detail_form.getInputProps('country_id')}
			     		value={account_detail_form.values.country_id}
			     		onChange={selectedCountryChange}
			     	  data={countryList.map(country => ({
		            value: country.country_id,
		            label: country.country_name
		        	}))}
			     	  dropdownPosition="bottom"
			     	/>
			     	<Text size="sm" fz={14} mb={10} ml={48}>State:</Text>
			     	<Select
			     		w={250}
			     		{...account_detail_form.getInputProps('state_id')}
			     		value={account_detail_form.values.state_id}
			     		onChange={selectedStateChange}
			     	  data={stateList.map(state => ({
		            value: state.state_id,
		            label: state.state_name
		        	}))}
			     	  dropdownPosition="bottom"
			     	/>
			    </Group>
			    <Group spacing="xs" mb={5}>
			    	<Text size="sm" fz={14} mb={10} ml={95}>City:</Text>
			      <TextInput {...account_detail_form.getInputProps('city')} w={250} ml={0} value={account_detail_form.values.city} onChange={(event) => account_detail_form.setFieldValue('city', event.currentTarget.value)}/>
			      <Text size="sm" fz={14} mb={10} ml={60}>Zip:</Text>
			      <TextInput {...account_detail_form.getInputProps('zip')} w={250} ml={0} value={account_detail_form.values.zip} onChange={(event) => account_detail_form.setFieldValue('zip', event.currentTarget.value)}/>
			    </Group>
			    <Group spacing="xs" mb={5}>
			    	<Text size="sm" fz={14} mb={10} ml={20}>phone Number:</Text>
			      <TextInput {...account_detail_form.getInputProps('phone')} w={250} ml={0} value={account_detail_form.values.phone} onChange={(event) => account_detail_form.setFieldValue('phone', event.currentTarget.value)}/>
			      <Text size="sm" fz={14} mb={10} ml={20}>Timezone:</Text>
			      <Select
			     		w={250}
			     		{...account_detail_form.getInputProps('timezone')}
			     		value={account_detail_form.values.timezone}
			     		onChange={selectedTimeZoneChange}
			     	  data={timeZoneList.map(timezone => ({
		            value: timezone.zone,
		            label: timezone.zone_time
		        	}))}
			     	  dropdownPosition="bottom"
			     	/>
			    </Group>
			    <Group mt={5} mb={20}>
			    	<Button type="submit" size="sm" w={70} ml={130}>
			        Save
			      </Button>
			    </Group>
		    </form>
		    <Divider my="md" />
		    <Group position="apart" mb="xs">
		      <Text weight={500}>CHANGE PASSWORD:</Text>
		    </Group>
		    <form onSubmit={change_password_form.onSubmit((formData) => { handleChangePasswordSubmit(formData); })}>
		      <Group spacing="xs" mb={5}>
		        <Text size="sm" fz={14} mb={10} ml={59}>Password:</Text>
			     	<PasswordInput w={250} ml={0} {...change_password_form.getInputProps('password')} value={change_password_form.values.password} onChange={(event) => change_password_form.setFieldValue('password', event.currentTarget.value)}/>
			    </Group>
			   	<Group spacing="xs" mb={5}>
			     	<Text size="sm" fz={14} mb={10} ml={7}>Re-type Password:</Text>
			     	<PasswordInput w={250} ml={0} {...change_password_form.getInputProps('password2')} value={change_password_form.values.password2} onChange={(event) => change_password_form.setFieldValue('password2', event.currentTarget.value)}/>
			    </Group>
			    <Group mt={5} mb={20}>
			    	<Button type="submit" size="sm" w={70} ml={130}>
			        Save
			      </Button>
			    </Group>
		    </form>
		    {/*<PermissionWrapper permission="visiopt_api_access">*/}
			    <Divider my="md" />
			    <Group position="apart" mb="xs">
			      <Text weight={500}>API AUTHORIZATION BEARER TOKEN:</Text>
			    </Group>
			    <div style={{ display: 'flex'}}>
			      <Text size="sm" fz={14} fw={500}>Last refresh time:</Text>
			      <Text size="sm" fz={14} ml={5}>2023-10-09 02:41:14</Text>
			    </div>
			    <Textarea
			    	value={authorizationBearerToken}
			    	mt={10}
			    	minRows={3}
			    	maxRows={4}
			    	maw={380}
			    	variant="filled"
			 		/>
			 		<CopyButton  timeout={2000} value={authorizationBearerToken}>
			      {({ copied, copy }) => (
			        <Button color={copied ? 'teal' : 'blue'} onClick={copy} mt={5}>
			          {copied ? 'Copied' : 'Copy'}
			        </Button>
			      )}
			    </CopyButton>
			    <br/>
		      <Button type="button" size="sm" w={165} mt={20} mb={10} color="teal" onClick={generateNewAPIToken}>
		        Regenerate API Key
		      </Button>
		      <div style={{ display: 'flex'}}>
		        <Text size="sm" fz={16} mr={5}>Api documentation :</Text>
		        <Anchor href={apiDocumentation} target="_blank" fw={500}>
		        {apiDocumentation}
		        </Anchor>
		      </div>
	      {/*</PermissionWrapper>*/}
      </PermissionWrapper>
    </>
  );
}