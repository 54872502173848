// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { rolePermissions } from './rolesPermissions';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const login = () => setIsAuthenticated(true);
    const logout = () => setIsAuthenticated(false);

    const [Permissions, setPermissions] = useState([]);

    useEffect(() => {
        setPermissions(rolePermissions);
    }, []);

    const userRolePermissions = (Permissions) => {
        setPermissions(Permissions);
    };

    const hasPermission = (permissions) => {
        const permissionsArray = permissions.split(',').map(permission => permission.trim());
        return permissionsArray.every(permission => Permissions?.includes(permission));
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, hasPermission, userRolePermissions }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
