import { ServerAPI } from '../GeneralFunction';

export default {
	getdashboardsystemusage: (data) => {
		let url = "get_dashboard_system_usage";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getsourcepia: (data) => {
		let url = "get_source_pia";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getactivetestlinechart: (data) => {
		let url = "get_active_test_line_chart";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
};