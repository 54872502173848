import { Group, Text, Radio, Divider, Slider, Box, Checkbox, Button } from '@mantine/core';
import { IconHelp, IconX, IconCheck } from '@tabler/icons-react';
import { useState, useEffect } from 'react';
import { VisiLoader } from "../../GeneralComponent/GeneralComponent";
import { useNavigate } from "react-router-dom";
import { notifications } from '@mantine/notifications';
import SettingsApi from "../../../api/SettingsApi";

export function TestControl() {

  const navigate = useNavigate();

  const [testControlLoader, setTestControlLoader] = useState(false);
  const [manageWinner, setManageWinner] = useState('auto');
  const [desiredWinningSS, setDesiredWinningSS] = useState(0);
  const [emailNotification, setEmailNotification] = useState(false);

  useEffect(() => {
    setTestControlLoader(true);
    SettingsApi.gettestcontroldata()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setTestControlLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
        if(data.data.manage_winner_auto && data.data.manage_winner_auto === 1){
          setManageWinner('auto');
        }
        if(data.data.manage_winner_manually && data.data.manage_winner_manually === 1){
          setManageWinner('manually');
        }
        if(data.data.desired_winning_ss){
          setDesiredWinningSS(data.data.desired_winning_ss);
        }
        if(data.data.email_notification){
          setEmailNotification(data.data.email_notification);
        }
        setTestControlLoader(false);
      }
      setTestControlLoader(false);
    })
    .catch(error => {
      console.log(error);
      setTestControlLoader(false);
    });
  }, []);

  const submitTestControlSetting = () => {
    setTestControlLoader(true);
    let email_notification = emailNotification ? '1' : '0';
    let dataObject = {
      manage_winner : manageWinner,
      desired_winning_ss : desiredWinningSS,
      email_notification : email_notification
    };
    SettingsApi.savetestcontrolsettings(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setTestControlLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setTestControlLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setTestControlLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setTestControlLoader(false);
    });
  }

  return (
    <>
      <VisiLoader VisiLoaderVisible={testControlLoader} />
      <Group position="apart" mb="xs">
        <Text weight={500}>TEST CONTROL <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
      </Group>
      <Text fw={500} mb={10}>How would you like to manage winner variations/versions? <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
      
      <Radio.Group
          value={manageWinner}
          onChange={setManageWinner}
          name="manage_winner"
        >
        <div style={{ display: 'flex'}}>
          <Radio label="Automatically - " value="auto" mb={5} fw={500} />
          <Text ml={5} mt={-2} >
            Once a winner is chosen the test will end and only the winning page/version will show to your traffic
          </Text>
        </div>
        <div style={{ display: 'flex', marginBottom:15}}>
          <Radio label="Manually - " value="manually" mb={5} fw={500} />
          <Text ml={5} mt={-2}>
            User selects winners manually after satistical significance is reached
          </Text>
        </div>
      </Radio.Group>
      <Text mb={15} c="red">
        Warning: This action can not be reversed. Once a winner is selected the test will be over and you must start a new test. You can not restart a test once it has ended.
      </Text>
      <Text mb={15} c="red">
        NOTICE: Whenever you log out make sure the page and goals in your test dropdown is the conversion page you wish to use for this test. This will set the winner automatically based upon the goal page you choose in your test.
      </Text>
      <Divider my="md" />
      <Text fw={500} mb={5}>
        Set the desired winning statistical significance: <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/>
      </Text>
      <Text mb={50}>
        Drag the slider to the % amount for statistical significance (To set statistical significance % for version to be considered as a winner).
      </Text>
      <Box maw={400}>
        <Slider value={desiredWinningSS} onChange={setDesiredWinningSS} label={(value) => `${value}%`}/>
      </Box>
      <Divider my="md" />
      <Text fw={500} mb={5}>Email notification: <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
      <div style={{ display: 'flex'}}>
      <Checkbox label="Send me email notification if statistical significance is reached" checked={emailNotification} onChange={(event) => setEmailNotification(event.currentTarget.checked)} />
      </div>
      <Button type="button" onClick={submitTestControlSetting} size="sm" w={150} mt={25} mb={100}>
        Save Settings
      </Button>
    </>
  );
}