import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Modal, Container, Card, Group, Text, Select, Button, Input, Space, Grid, Pagination, Accordion, Flex, Table, Image, Radio, CopyButton, ActionIcon, Checkbox, NumberInput, Tabs } from '@mantine/core';
import { IconSearch, IconHelp, IconPencil, IconTrash, IconBrandFacebook, IconBrandGoogle, IconBrandWindows, IconBrandTiktok, IconWebhook, IconPlus,
IconCircleMinus, IconX, IconCheck } from '@tabler/icons-react';
import classes from '../DashBoard/DashBoard.module.css';

import { modals } from '@mantine/modals';
import { useListState } from '@mantine/hooks';
import { useEffect } from 'react';
import { visiUserData } from '../../GeneralFunction';
import { SelectTimePicker, SelectTooltip } from "../../components/GeneralComponent/GeneralComponent";
import PermissionWrapper from '../../PermissionWrapper';
import { SelectFunnelList } from "../../components/GeneralComponent/GeneralComponent";
import { visiUserId } from '../../GeneralFunction';
import { VisiLoader } from "../../components/GeneralComponent/GeneralComponent";
import { notifications } from '@mantine/notifications';
import CampaignApi from "../../api/CampaignApi";
import TestsApi from "../../api/TestsApi";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useGoogleLogin } from '@react-oauth/google';
import MicrosoftLogin from "react-microsoft-login";

export function CreateCampaign() {

  let memberId = visiUserId() ? visiUserId() : '';

	const navigate = useNavigate();
	useDocumentTitle("Visiopt - Create Campaign");
	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

  const location = useLocation();
  const { campaign_id } = location.state || {};

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};
	const [campaignLPPageValue, setCampaignLPPage] = useState('');
  const handleCampaignLPPageChange = (value:any) => {
    if(value !== ""){
	    setCampaignLoader(true);
	    CampaignApi.checkexistingcampaignpage({
	      campaign_page_id : value,
	      c_id : campaignId
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          setCampaignLoader(false);
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	      	setCampaignLPPage(value);
	      }else{
	        notifications.show({
	          color: 'red',
	          icon: <IconX size="1.1rem" />,
	          title: 'Error',
	          message: data.message,
	        });
	      }
	      setCampaignLoader(false);
	    })
	    .catch(error => {
	      console.log(error);
	      setCampaignLoader(false);
	    });
	  }
  }
  const [shoppingCartProcessorModalOpen, setShoppingCartProcessorModalOpen] = useState(false);
  const [convRedioTypeBtn, setConvRedioTypeBtn] = useState('1');
  const [shoppingCartProcessorList, setShoppingCartProcessorList] = useState(true);
  const [testShoppingCartProcessorWebhookData, settestShoppingCartProcessorWebhookData] = useState([]);
  const [testShoppingCartProcessorShoppingData, settestShoppingCartProcessorShoppingData] = useState([]);
  const [testShoppingCartProcessorFunnelsGoals, settestShoppingCartProcessorFunnelsGoals] = useState([]);
  const [testShoppingCartProcessorPaymentProcessor, settestShoppingCartProcessorPaymentProcessor] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

  const handleShoppingCartProcessorOpenModal = (value:any) => {
    CampaignApi.getconnectshoppingcartandprocessor({
      system_website_id: selectedMemberWebsite,
      page_id:campaignLPPageValue,
      funnel_id:SelectedFunnelValue
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        settestShoppingCartProcessorWebhookData(data.data.webhook_list);
        settestShoppingCartProcessorShoppingData(data.data.shopping_cart);
        settestShoppingCartProcessorFunnelsGoals(data.data.funnels_goals);
        settestShoppingCartProcessorPaymentProcessor(data.data.payment_processor);
        setShoppingCartProcessorModalOpen(true);
        setShoppingCartProcessorList(true);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };
  const handleShoppingCartProcessorCloseModal = () => {
    setSelectedWebhookURL('');
    setSelectedSecretKey('');
    setSelectedPage('');
    setConvRedioTypeBtn('1');
    setSelectedPaymentProcessor('');
    setSelectedShoppingCart('');
    setSelectedWebhookId('');
    setShoppingCartProcessorModalOpen(false);
    setShoppingCartProcessorList(true);
  };
	const testShoppingCartProcessorRows = testShoppingCartProcessorWebhookData.map((element) => (
    <tr key={element.webhook_id}>
      <td>{element.processor}</td>
      <td>{element.page_name}</td>
      <td>{element.webhook_url}</td>
      <td>
      	<ActionIcon
	   		  variant="default"
	   		  size="md"
	   		  onClick={() => EditWebhook(element.webhook_id)}
	   		>
	   		  <IconPencil size="1.1rem" />
	   		</ActionIcon>
	   	</td>
      <td>
      	<ActionIcon
	   		  variant="default"
	   		  size="md"
	   		  onClick={() => deleteWebhookConfirm(element.webhook_id)}
	   		>
	   		  <IconTrash size="1.1rem" />
	   		</ActionIcon>
	   	</td>
    </tr>
  ));

	const shoppingCartData = [
    { value: '', label: 'Select Shopping Cart' },
    ...testShoppingCartProcessorShoppingData.map((ShoppingData) => ({
      value: ShoppingData.shopping_cart_id,
      label: ShoppingData.shopping_cart_name,
    })),
  ];

	const paymentProcessorData = [
    { value: '', label: 'Select Payment Processor' },
    ...testShoppingCartProcessorPaymentProcessor.map((PaymentProcessor) => ({
      value: PaymentProcessor.payment_processor_id,
      label: PaymentProcessor.payment_processor_name,
    })),
  ];

	const pageData = [
    { value: '', label: 'Select a Page' },
    ...testShoppingCartProcessorFunnelsGoals.map((FunnelsGoals) => ({
      value: FunnelsGoals.page_id,
      label: FunnelsGoals.page_name,
    })),
  ];

  const AddWebhook  = () => {
  	setShoppingCartProcessorList(false);
  }
  const EditWebhook = (webhookId) => {
    setSelectedWebhookId(webhookId);
    TestsApi.getsinglewebhookprocessordetail({
      webhook_id:webhookId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        if (data.data.payment_type === 'payment_processor') {
          setSelectedPaymentProcessor(data.data.cart_type);
        } else if (data.data.payment_type === 'shopping_cart') {
          setSelectedShoppingCart(data.data.cart_type);
        }
        setSelectedProcessor(data.data.processor);
        setSelectedPage(data.data.page_id);
        setSelectedWebhookURL(data.data.webhook_url);
        setSelectedSecretKey(data.data.secret_key);
        setConvRedioTypeBtn(data.data.payment_type === 'payment_processor' ? '2' : '1');
        setShoppingCartProcessorList(false);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };
	const deleteWebhookConfirm = async (webhookId:any) => {
    try {
      const APIResponse = await CampaignApi.validatedeletecampaignpaymentprocessorcart({
        campaign_id: campaignId,
        webhook_id : webhookId
      });
       const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        modals.openConfirmModal({
        title: 'Delete Webhook',
        children: (
          <Text size="sm">
            {APIResultJSON.data.msg}
          </Text>
        ),
        labels: { confirm: 'Delete the Webhook', cancel: "No don't delete it" },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          handleDeleteWebhook(webhookId);
        },
      });
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const handleDeleteWebhook = async (webhookId:any) =>{
    try {
      const APIResponse = await TestsApi.deletepaymentprocessorcart({
        webhook_id : webhookId
      });
       const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: APIResultJSON.message,
        });
        handleShoppingCartProcessorCloseModal();
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

	const [isMessageModelOpen, setIsMessageModelOpen] = useState(false);
	const [isModelMessage, setIsModelMessage] = useState('');

	// State to manage the checkbox
	const [isClassicChecked, setIsClassicChecked] = useState(true);
  const [isFacebookChecked, setIsFacebookChecked] = useState(false);
  const [isGoogleChecked, setIsGoogleChecked] = useState(false);
  const [isMicrosoftChecked, setIsMicrosoftChecked] = useState(false);
  const [isTiktokChecked, setIsTiktokChecked] = useState(false);
  const [isYoutubeChecked, setIsYoutubeChecked] = useState(false);
  const [isTaboolaChecked, setIsTaboolaChecked] = useState(false);
  const [isOutbrainChecked, setIsOutbrainChecked] = useState(false);

  const [facebookLoginButton, setFacebookLoginButton] = useState(false);
  const [facebookLogoutButton, setFacebookLogoutButton] = useState(false);
  const [facebookAppId, setFacebookAppId] = useState('');
  const [facebookElements, setFacebookElements] = useState([]);
  const [taboolaClientId, setTaboolaClientId] = useState('');
  const [googleLoginButton, setGoogleLoginButton] = useState(false);
  const [googleLogoutButton, setGoogleLogoutButton] = useState(false);
  const [googleElements, setGoogleElements] = useState([]);
  const [youtubeElements, setYoutubeElements] = useState([]);
  const [tiktokLoginButton, setTiktokLoginButton] = useState(false);
  const [tiktokLogoutButton, setTiktokLogoutButton] = useState(false);
  const [outbrainLoginButton, setOutbrainLoginButton] = useState(false);
  const [outbrainLogoutButton, setOutbrainLogoutButton] = useState(false);
  const [outbrainElements, setOutbrainElements] = useState([]);
  const [taboolaLoginButton, setTaboolaLoginButton] = useState(false);
  const [taboolaLogoutButton, setTaboolaLogoutButton] = useState(false);
  const [taboolaElements, setTaboolaElements] = useState([]);
  const [microsoftLoginButton, setMicrosoftLoginButton] = useState(false);
  const [microsoftLogoutButton, setMicrosoftLogoutButton] = useState(false);
  const [microsoftElements, setMicrosoftElements] = useState([]);

  // Handler for checkbox change
  const handleSocialMediaCheckboxChange = (event,value) => {
  	if(value === 'Classic'){
  		setIsClassicChecked(event.target.checked);
  	} else if(value === 'Facebook'){
  		setIsFacebookChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Facebook Data be sure to add the following parameter either direct to your url or in Facebook's URL Parameters.\nvisi_source=facebook&ad_id={{ad.id}}");
      	setIsMessageModelOpen(true);
        setCampaignLoader(true);
        CampaignApi.getcampaigntypeaduserdata({
          campaign_type: 'facebook',
          campaign_id: campaignId
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          setCampaignLoader(false);
          if(data){
            if(data.data){
              if(data.data.show_fb_ad_account_login_logout_button){
                if(data.data.show_fb_ad_account_login_logout_button == 'login'){
                  setFacebookLoginButton(true);
                  setFacebookLogoutButton(false);
                }else if(data.data.show_fb_ad_account_login_logout_button == 'logout'){
                  setFacebookLogoutButton(true);
                  setFacebookLoginButton(false);
                }
              }
              if(data.data.facebook_app_id){
                setFacebookAppId(data.data.facebook_app_id);
              }
              if(data.data.fb_ad_accounts){
                setFacebookElements(data.data.fb_ad_accounts);
              }
            }
          }
        })
        .catch(error => {
          setCampaignLoader(false);
          console.error("API Error:", error);
        });
  		}
  	} else if(value === 'Google'){
  		setIsGoogleChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Google Data be sure to add the following parameter either direct to your url or in Google's URL Tracking Template.\n{lpurl}?visi_source=google&creative={creative}\n\nIf you also want to track keywords than use the follow Tracking Template:\n{lpurl}?visi_source=google&creative={creative}&keyword={keyword}\n\nThe above will automatically pass campaign, ad group, ads and cost information. To add additional parameters' such as keyword, etc. Visit the URL builder in Settings page.");
      	setIsMessageModelOpen(true);
        setCampaignLoader(true);
        CampaignApi.getcampaigntypeaduserdata({
          campaign_type: 'google',
          campaign_id: campaignId
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          setCampaignLoader(false);
          if(data){
            if(data.data){
              if(data.data.show_gl_ad_account_login_logout_button){
                if(data.data.show_gl_ad_account_login_logout_button == 'login'){
                  setGoogleLoginButton(true);
                  setGoogleLogoutButton(false);
                }else if(data.data.show_gl_ad_account_login_logout_button == 'logout'){
                  setGoogleLogoutButton(true);
                  setGoogleLoginButton(false);
                }
              }
              if(data.data.gl_adaccount_details){
                setGoogleElements(data.data.gl_adaccount_details);
              }
            }
          }
        })
        .catch(error => {
          setCampaignLoader(false);
          console.error("API Error:", error);
        });
  		}
  	} else if(value === 'Microsoft'){
  		setIsMicrosoftChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Microsoft Data be sure to add the following parameter either direct to your url or in Microsoft's URL Tracking Template.\n{lpurl}?visi_source=microsoft&AdGroupID={AdGroupID}");
      	setIsMessageModelOpen(true);
        setCampaignLoader(true);
        CampaignApi.getcampaigntypeaduserdata({
          campaign_type: 'microsoft',
          campaign_id: campaignId
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          setCampaignLoader(false);
          if(data){
            if(data.data){
              if(data.data.show_ms_ad_account_login_logout_button){
                if(data.data.show_ms_ad_account_login_logout_button == 'login'){
                  setMicrosoftLoginButton(true);
                  setMicrosoftLogoutButton(false);
                }else if(data.data.show_ms_ad_account_login_logout_button == 'logout'){
                  setMicrosoftLogoutButton(true);
                  setMicrosoftLoginButton(false);
                }
              }
              if(data.data.ms_ad_accounts){
                setMicrosoftElements(data.data.ms_ad_accounts);
              }
            }
          }
        })
        .catch(error => {
          setCampaignLoader(false);
          console.error("API Error:", error);
        });
  		}
  	} else if(value === 'Tiktok'){
  		setIsTiktokChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Tiktok Data be sure to add the following parameter either direct to your url or in Tiktok's URL Tracking Template.\n{lpurl}?visi_source=tiktok&adid=__ADID__");
      	setIsMessageModelOpen(true);
        setCampaignLoader(true);
        CampaignApi.getcampaigntypeaduserdata({
          campaign_type: 'tiktok',
          campaign_id: campaignId
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          setCampaignLoader(false);
          if(data){
            if(data.data){
              if(data.data.show_tk_ad_account_login_logout_button){
                if(data.data.show_tk_ad_account_login_logout_button == 'login'){
                  setTiktokLoginButton(true);
                  setTiktokLogoutButton(false);
                }else if(data.data.show_tk_ad_account_login_logout_button == 'logout'){
                  setTiktokLogoutButton(true);
                  setTiktokLoginButton(false);
                }
              }
              /*if(data.data.gl_adaccount_details){
                setGoogleElements(data.data.gl_adaccount_details);
              }*/
            }
          }
        })
        .catch(error => {
          setCampaignLoader(false);
          console.error("API Error:", error);
        });
  		}
  	} else if(value === 'Youtube'){
  		setIsYoutubeChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Youtube Data be sure to add the following parameter either direct to your url or in Youtube's URL Tracking Template.\n{lpurl}?visi_source=youtube&creative={creative}\n\nIf you also want to track keywords than use the follow Tracking Template:\n{lpurl}?visi_source=youtube&creative={creative}&keyword={keyword}\n\nThe above will automatically pass campaign, ad group, ads and cost information. To add additional parameters' such as keyword, etc. Visit the URL builder in Settings page.");
      	setIsMessageModelOpen(true);
        setCampaignLoader(true);
        CampaignApi.getcampaigntypeaduserdata({
          campaign_type: 'google',
          campaign_id: campaignId
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          setCampaignLoader(false);
          if(data){
            if(data.data){
              if(data.data.show_gl_ad_account_login_logout_button){
                if(data.data.show_gl_ad_account_login_logout_button == 'login'){
                  setGoogleLoginButton(true);
                  setGoogleLogoutButton(false);
                }else if(data.data.show_gl_ad_account_login_logout_button == 'logout'){
                  setGoogleLogoutButton(true);
                  setGoogleLoginButton(false);
                }
              }
              if(data.data.yt_adaccount_details){
                setYoutubeElements(data.data.yt_adaccount_details);
              }
            }
          }
        })
        .catch(error => {
          setCampaignLoader(false);
          console.error("API Error:", error);
        });
  		}
  	} else if(value === 'Taboola'){
  		setIsTaboolaChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Taboola Data be sure to add the following parameter either direct to your url or in Taboola's URL Tracking Template.\n{lpurl}?visi_source=taboola&campaign_id={campaign_id}\n\nIf you also want to track country and platform and site use the follow Tracking Template:\n{lpurl}?visi_source=taboola&campaign_id={campaign_id}&country={country}&platform={platform}&site={site}");
      	setIsMessageModelOpen(true);
        getTaboolaAdUserData();
  		}
  	} else if(value === 'Outbrain'){
  		setIsOutbrainChecked(event.target.checked);
  		if(event.target.checked === true){
  			setIsModelMessage("In order for this campaign to track Outbrain Data be sure to add the following parameter either direct to your url or in Outbrain's URL Tracking Template.\n{lpurl}?visi_source=outbrain&campaign_id={campaign_id}");
      	setIsMessageModelOpen(true);
        getOutbrainAdUserData();
    	}
  	}
  };
  const handleCloseModal = () => {
    setIsMessageModelOpen(false);
  };

	const facebookRows = facebookElements.map((element) => (
    <tr key={element.account_name}>
      <td>{element.ad_account_id}</td>
      <td>{element.account_name}</td>
      <td>{element.currency}</td>
      <td>{element.selected !== '' ? <Checkbox checked/> : <Checkbox/>}</td>
    </tr>
  ));

	const googleRows = googleElements.map((element) => (
    <tr key={element.subcustomer_id}>
      <td>{element.customer_id}</td>
      <td>{element.subcustomer_id}</td>
      <td>{element.subcustomer_name}</td>
      <td>{element.currency}</td>
      <td>{element.selected !== '' ? <Radio checked name="googleIntegrationAccount" value={element.subcustomer_id+'_'+element.customer_id}/> : <Radio name="googleIntegrationAccount" value={element.subcustomer_id+'_'+element.customer_id}/>}</td>
    </tr>
  ));

	const microsoftRows = microsoftElements.map((element) => (
    <tr key={element.account_name}>
      <td>{element.ads_account_id}</td>
      <td>{element.account_name}</td>
      <td>{element.currency}</td>
      <td>{element.selected !== '' ? <Radio name="microsoftIntegrationAccount" checked/> : <Radio name="microsoftIntegrationAccount"/>}</td>
    </tr>
  ));

  const tiktokElements = [
	  { adAccountId:'1516596245346468', accountName: 'Visiopt', currency:'USD', accountSelected:'checked'},
	  { adAccountId:'487396469595577', accountName: 'Shailesh Patel', currency:'INR', accountSelected:''},
	  { adAccountId:'620625419451374', accountName: 'visiopt ads', currency:'USD', accountSelected:''},
	];
	const tiktokRows = tiktokElements.map((element) => (
    <tr key={element.accountName}>
      <td>{element.adAccountId}</td>
      <td>{element.accountName}</td>
      <td>{element.currency}</td>
      <td>{element.accountSelected !== '' ? <Radio checked/> : <Radio/>}</td>
    </tr>
  ));

	const youtubeRows = youtubeElements.map((element) => (
    <tr key={element.subcustomer_id}>
      <td>{element.customer_id}</td>
      <td>{element.subcustomer_id}</td>
      <td>{element.subcustomer_name}</td>
      <td>{element.currency}</td>
      <td>{element.selected !== '' ? <Radio checked name="youtubeIntegrationAccount" value={element.subcustomer_id+'_'+element.customer_id}/> : <Radio name="youtubeIntegrationAccount" value={element.subcustomer_id+'_'+element.customer_id}/>}</td>
    </tr>
  ));

	const taboolaRows = taboolaElements.map((element) => (
    <tr key={element.account_name}>
      <td>{element.ad_account_id}</td>
      <td>{element.account_name}</td>
      <td>{element.currency}</td>
      <td>{element.selected !== '' ? <Radio name="taboolaIntegrationAccount" checked/> : <Radio name="taboolaIntegrationAccount"/>}</td>
    </tr>
  ));

	const outbrainRows = outbrainElements.map((element) => (
    <tr key={element.account_name}>
      <td>{element.ads_account_id}</td>
      <td>{element.account_name}</td>
      <td>{element.currency}</td>
      <td>{element.selected !== '' ? <Radio name="outbrainIntegrationAccount" checked/> : <Radio name="outbrainIntegrationAccount"/>}</td>
    </tr>
  ));

	const [isTrackingType, setIsTrackingType] = useState('1');
	const handleTrackingTypeChange = (value:any) => {
	  setIsTrackingType(value);
	}

  const [convAttributionModel, setConvAttributionModel] = useState('1');
  const handleConvAttributionModelChange = (value:any) => {
    setConvAttributionModel(value);
  }

	const [isConversionApiChecked, setIsConversionApiChecked] = useState(false);
	const handleIsConversionApiChange = (event) => {
		setIsConversionApiChecked(event.target.checked);
	};

	const facebookConvElements = [
	  { adAccountId:'1516596245346468', accountName: 'Visiopt', pixelID:'4152587691516818', accessToken:'EAAFG8Qxnb8UBAOpNm1X3qIQHX1DWWQpR1ZAXXh786'},
	  { adAccountId:'487396469595577', accountName: 'Shailesh Patel', pixelID:'9152587691516818', accessToken:'amtx1v8tnyvArpadPZCvdU33z5rEQ0j5b1h6Sl7eCfYc3R'},
	  { adAccountId:'620625419451374', accountName: 'visiopt ads', pixelID:'8152587691516818', accessToken:'QpR1ZAXXh786yrIVLctCrJ6zeAQ0KmWN4yMonqHWDWjeMdJ'},
	];
	const facebookConvRows = facebookConvElements.map((element) => (
    <tr key={element.accountName}>
      <td>{element.adAccountId} ({element.accountName}) </td>
      <td><Input value={element.pixelID} /></td>
      <td><Input value={element.accessToken} /></td>
    </tr>
  ));
	
  const [isFbConversionApiChecked, setIsFbConversionApiChecked] = useState(true);
  const handleFbConversionApiChange = (event) => {
    setIsFbConversionApiChecked(event.target.checked);
  };

	const [SelectedFunnelValue, setSelectedFunnelValue] = useState(null);
	const [campaignLoader, setCampaignLoader] = useState(false);
	const [campaignLPPageList, setCampaignLPPageList] = useState([]);
	const [step1Disabled, setStep1Disabled] = useState(false);
	const [step2Disabled, setStep2Disabled] = useState(true);
	const [step3Disabled, setStep3Disabled] = useState(true);
	const [step4Disabled, setStep4Disabled] = useState(true);
	const [step5Disabled, setStep5Disabled] = useState(true);
  const [step5Show, setStep5Show] = useState('0');
	const [campaignName, setCampaignName] = useState('');
	const [stepValue, setStepValue] = useState('step-1');
	const [campaignId, setCampaignId] = useState('');
  const [activeTab, setActiveTab] = useState('Facebook');
  const [selectedPage, setSelectedPage] = useState('');

  const [customerPath, setCustomerPath] = useState('');
  const [normalTracking, setNormalTracking] = useState('');
  const [cpc, setCpc] = useState('');
  const [glConvCustomerId, setGlConvCustomerId] = useState('');
  const [glConvSubCustomerId, setGlConvSubCustomerId] = useState('');
  const [ytAdAccountId, setYtAdAccountId] = useState('');
  const [ytConvCustomerId, setYtConvCustomerId] = useState('');
  const [ytConvSubCustomerId, setYtConvSubCustomerId] = useState('');
  const [tbAdAccountId, setTbAdAccountId] = useState('');
  const [campaignGoalResult, setCampaignGoalResult] = useState([]);
  const [goalOptions, setGoalOptions] = useState([]);
  const [postbackOptions, setPostbackOptions] = useState([]);
  const [buttonOptions, setButtonOptions] = useState([]);
  const [goals, setGoals] = useState(Array.from({ length: 7 }, (_, i) => i + 1));
  const [totalgoalsShow, setTotalGoalsShow] = useState(0);
  const [optionsBtn, setOptionsBtn] = useState(Array(7).fill('1'));
  const [goalAction, setGoalAction] = useState(Array(7).fill(''));
  const [goalNames, setGoalNames] = useState(Array(7).fill(''));
  const [salesValues, setSalesValues] = useState(Array(7).fill(0));
  const [excludeTopline, setExcludeTopline] = useState(Array(7).fill(0));
  const [includeAov, setIncludeAov] = useState(Array(7).fill(0));
  const [excludeUndefinedConversions, setExcludeUndefinedConversions] = useState(Array(7).fill(0));
  const [checkedGoals, setCheckedGoals] = useState([]);
  const [checkedCustomerPath, setCheckedCustomerPath] = useState([]);
  const [selectedWebhookURL, setSelectedWebhookURL] = useState('');
  const [selectedSecretKey, setSelectedSecretKey] = useState('');

  const [fbConversionGoal, setFbConversionGoal] = useState([]);
  const [fbConversionGoalValue, setFbConversionGoalValue] = useState('');
  const [fbConversionEvent, setFbConversionEvent] = useState([]);
  const [fbConversionEventValue, setFbConversionEventValue] = useState('');
  const [fbConversionCurrency, setFbConversionCurrency] = useState([]);
  const [fbConversionCurrencyValue, setFbConversionCurrencyValue] = useState('');
  const [fbPixelIdValue, setFbPixelIdValue] = useState('');
  const [fbAccessTokenValue, setFbAccessTokenValue] = useState('');
  const [selectedShoppingCart, setSelectedShoppingCart] = useState('');

  const [fbConversionEventMP, setFbConversionEventMP] = useState([]);
  const [fbConversionEventValueMP, setFbConversionEventValueMP] = useState('');
  const [fbConversionCurrencyMP, setFbConversionCurrencyMP] = useState([]);
  const [fbConversionCurrencyValueMP, setFbConversionCurrencyValueMP] = useState('');
  const [facebookConversionPageview, setFacebookConversionPageview] = useState(false);
  const [goalGroups, setGoalGroups] = useState([{ id: 1, goal: '', event: '' }]);
  const [fbAdAccountDetails, setFbAdAccountDetails] = useState([]);
  const [selectedShoppingCartName, setSelectedShoppingCartName] = useState('');

  const [glCustomer, setGlCustomer] = useState([]);
  const [glCustomerValue, setGlCustomerValue] = useState('');
  const [glSubCustomer, setGlSubCustomer] = useState([]);
  const [glSubCustomerValue, setGlSubCustomerValue] = useState('');
  const [glConvPageGoal, setGlConvPageGoal] = useState([]);
  const [glConvPageGoalValue, setGlConvPageGoalValue] = useState('');
  const [glConversionAction, setGlConversionAction] = useState([]);
  const [glConversionActionValue, setGlConversionActionValue] = useState('');
  const [glConversionActionMP, setGlConversionActionMP] = useState([]);
  const [glConversionActionMPValue, setGlConversionActionMPValue] = useState('');
  const [glConvAction, setGlConvAction] = useState([]);
  const [glConvActionValue, setGlConvActionValue] = useState('');
  const [googleConversionPageview, setGoogleConversionPageview] = useState(false);
  const [glgoalGroups, setGlGoalGroups] = useState([{ id: 1, action: '', page: '' }]);
  const [selectedPaymentProcessor, setSelectedPaymentProcessor] = useState('');

  const [ytCustomer, setYtCustomer] = useState([]);
  const [ytCustomerValue, setYtCustomerValue] = useState('');
  const [ytSubCustomer, setYtSubCustomer] = useState([]);
  const [ytSubCustomerValue, setYtSubCustomerValue] = useState('');
  const [ytConvPageGoal, setYtConvPageGoal] = useState([]);
  const [ytConvPageGoalValue, setYtConvPageGoalValue] = useState('');
  const [ytConversionAction, setYtConversionAction] = useState([]);
  const [ytConversionActionValue, setYtConversionActionValue] = useState('');
  const [ytConversionActionMP, setYtConversionActionMP] = useState([]);
  const [ytConversionActionMPValue, setYtConversionActionMPValue] = useState('');
  const [ytConvAction, setYtConvAction] = useState([]);
  const [ytConvActionValue, setYtConvActionValue] = useState('');
  const [youtubeConversionPageview, setYoutubeConversionPageview] = useState(false);
  const [ytgoalGroups, setYtGoalGroups] = useState([{ id: 1, action: '', page: '' }]);

  const [tbConvPageDetail, setTbConvPageDetail] = useState([]);
  const [tbConvPageDetailValue, setTbConvPageDetailValue] = useState('');
  const [tbConversionAction, setTbConversionAction] = useState([]);
  const [tbConversionActionValue, setTbConversionActionValue] = useState('');
  const [tbConvActionValue, setTbConvActionValue] = useState('');
  const [tbAdAccountDetails, setTbAdAccountDetails] = useState([]);
  const [tbAdAccountDetailsValue, setTbAdAccountDetailsValue] = useState('');
  const [tbgoalGroups, setTbGoalGroups] = useState([{ id: 1, action: '', page: '' }]);
  const [tbConvMessageRow, setTbConvMessageRow] = useState([]);
  const [tbConvPageGoal, setTbConvPageGoal] = useState([]);
  const [tbConversionActionMr, setTbConversionActionMr] = useState([]);
  const [taboolaConversionPageview, setTaboolaConversionPageview] = useState(false);
  const [tbConversionActionMP, setTbConversionActionMP] = useState([]);
  const [selectChange, setSelectChange] = useState('');
  const [selectedProcessor, setSelectedProcessor] = useState('');
  const [selectedPaymentProcessorName, setSelectedPaymentProcessorName] = useState('');
  const [selectedWebhookId, setSelectedWebhookId] = useState('');
  const [selectedPaymentType, setSelectedPaymentType] = useState('');

	const handleSelectFunnelListComponentChange = (Value:any) => {
		if(Value !== ""){
	    setCampaignLoader(true);
	    CampaignApi.checkexistingcampaign({
	      funnel_id : Value,
	      c_id : campaignId
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          setCampaignLoader(false);
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	      	setSelectedFunnelValue(Value);
	      }else{
	        notifications.show({
	          color: 'red',
	          icon: <IconX size="1.1rem" />,
	          title: 'Error',
	          message: data.message,
	        });
	      }
	      setCampaignLoader(false);
	    })
	    .catch(error => {
	      console.log(error);
	      setCampaignLoader(false);
	    });
	  }
  };

  useEffect(() => {
    if(SelectedFunnelValue !== "" && selectedMemberWebsite !== ""){
      setCampaignLoader(true);
      CampaignApi.getcampaignpagebyfunnelid({
        funnel_id : SelectedFunnelValue,
        system_website_id : selectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setCampaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1 && data.data && data.data.length > 0){
          setCampaignLPPageList(data.data);
          setCampaignLPPage('');
        }else{
          setCampaignLPPageList([]);
        }
        setCampaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        setCampaignLoader(false);
      });
    }
  }, [SelectedFunnelValue]);

  const submitStep1 = () => {
  	if(SelectedFunnelValue === "" || SelectedFunnelValue === null){
  		notifications.show({
        color: 'red',
        icon: <IconX size="1.1rem" />,
        title: 'Error',
        message: "Please Select a Funnel",
      });
  	}else if(campaignName === "" || campaignName === null){
  		notifications.show({
        color: 'red',
        icon: <IconX size="1.1rem" />,
        title: 'Error',
        message: "Please Enter Campaign Name",
      });
  	}else if(campaignLPPageValue === "" || campaignLPPageValue === null){
  		notifications.show({
        color: 'red',
        icon: <IconX size="1.1rem" />,
        title: 'Error',
        message: "Please Select Campaign Page",
      });
  	}else{
  		setCampaignLoader(true);
      CampaignApi.addcampaignstepfirst({
        funnel_id : SelectedFunnelValue,
        campaign_name : campaignName,
        campaign_page_id : campaignLPPageValue,
        system_website_id : selectedMemberWebsite,
        c_id : campaign_id
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setCampaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1 && data.data && data.data.campaign_id){
        	setStep2Disabled(false);
        	setStep3Disabled(false);
        	setStep4Disabled(false);
        	setStepValue('step-2');
        	setCampaignId(data.data.campaign_id);
        }else{
        	notifications.show({
		        color: 'red',
		        icon: <IconX size="1.1rem" />,
		        title: 'Error',
		        message: data.message,
		      });
        }
        setCampaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        setCampaignLoader(false);
      });
  	}
  };

  const submitStep2 = () => {
    setStepValue('step-3');
  };

  useEffect(() => {
    if (convRedioTypeBtn === '1') {
      setSelectedPaymentType('shopping_cart');
    } else if (convRedioTypeBtn === '2') {
      setSelectedPaymentType('payment_processor');
    }
  }, [convRedioTypeBtn]);

  const isShoppingCart = convRedioTypeBtn === '1';
  const isPaymentProcessor = convRedioTypeBtn === '2';

  const handleAddPaymentProcessorCart = (e) => {
    e.preventDefault();
    CampaignApi.addcampaignpaymentprocessorcart({
      system_website_id: selectedMemberWebsite,
      page_id:selectedPage,
      campaign_id:campaignId,
      webhook_id:selectedWebhookId,
      payment_type:selectedPaymentType,
      shopping_cart: isShoppingCart ? selectedShoppingCart : '',
      shopping_cart_text: isShoppingCart ? selectedShoppingCartName : '',
      shopping_webhook_url: isShoppingCart ? selectedWebhookURL : '',
      payment_processor: isPaymentProcessor ? selectedPaymentProcessor : '',
      payment_processor_text: isPaymentProcessor ? selectedPaymentProcessorName : '',
      payment_webhook_url: isPaymentProcessor ? selectedWebhookURL : '',
      secret_key: selectedSecretKey ? selectedSecretKey : '',
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleShoppingCartProcessorCloseModal();
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleConvRedioTypeBtn = (value) => {
    setConvRedioTypeBtn(value);
    setSelectedPage('');
    setSelectedWebhookURL('');
    setSelectedSecretKey('');
  };

  const handleSelectChange = (value) => {
    setSelectChange(value);
    const selectedOption = convRedioTypeBtn === '1' ? shoppingCartData.find(option => option.value === value) : paymentProcessorData.find(option => option.value === value);
    const label = selectedOption ? selectedOption.label : '';
    setSelectedProcessor(label);
    if (convRedioTypeBtn === '1') {
      setSelectedShoppingCart(value);
      setSelectedShoppingCartName(label);
      setSelectedPage('');
      setSelectedWebhookURL('');
      setSelectedSecretKey('');
    } else if (convRedioTypeBtn === '2') {
      setSelectedPaymentProcessor(value);
      setSelectedPaymentProcessorName(label);
      setSelectedPage('');
      setSelectedWebhookURL('');
      setSelectedSecretKey('');
    }
  };

  const selectData = convRedioTypeBtn === '1' ? shoppingCartData : paymentProcessorData;
  const labelText = convRedioTypeBtn === '1' ? 'Select a Shopping Cart' : 'Select Payment Processor';

  const handleSelectPageChange = (value) => {
    const selectedsOption = selectData.find(option => option.value === selectChange);
    const label = selectedsOption ? selectedsOption.label : '';
    setSelectedPage(value);
    GetWebhookUrl(value,selectedProcessor);
  };

  const GetWebhookUrl = (selectedPage,processor) => {
    TestsApi.getwebhookurl({
      system_website_id: selectedMemberWebsite,
      page_id:selectedPage,
      processor:selectedProcessor
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setSelectedWebhookURL(data.data.webhook_url);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  useEffect(() => {
    if(campaign_id !== ""){
      setCampaignLoader(true);
      CampaignApi.getcampaigndetail({
        c_id:campaign_id
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setCampaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setStep2Disabled(false);
          setStep3Disabled(false);
          setStep4Disabled(false);
          setStep5Disabled(false);
          setSelectedFunnelValue(data.data.campaign_detail.funnel_id);
          setCampaignName(data.data.campaign_detail.campaign_name);
          setCampaignLPPage(data.data.campaign_detail.campaign_page);
          setIsClassicChecked(data.data.campaign_detail.campaign_type_classic);
          setIsFacebookChecked(data.data.campaign_detail.campaign_type_facebook);
          setIsGoogleChecked(data.data.campaign_detail.campaign_type_google);
          setIsMicrosoftChecked(data.data.campaign_detail.campaign_type_microsoft);
          setIsOutbrainChecked(data.data.campaign_detail.campaign_type_outbrain);
          setIsTaboolaChecked(data.data.campaign_detail.campaign_type_taboola);
          setIsTiktokChecked(data.data.campaign_detail.campaign_type_tiktok);
          setIsYoutubeChecked(data.data.campaign_detail.campaign_type_youtube);
          setIsConversionApiChecked(data.data.campaign_detail.is_conversion_api !== "0");
          setCustomerPath(data.data.campaign_detail.customer_path);
          setNormalTracking(data.data.campaign_detail.normal_tracking);
          if (data.data.campaign_detail.customer_path === "true") {
            setIsTrackingType('2');
          } else if (data.data.campaign_detail.normal_tracking === "true") {
            setIsTrackingType('1');
          }
          setCpc(data.data.campaign_detail.cpc);
          setGlConvCustomerId(data.data.campaign_detail.gl_conv_customer_id);
          setGlConvSubCustomerId(data.data.campaign_detail.gl_conv_subcustomer_id);
          setYtAdAccountId(data.data.campaign_detail.yt_ad_account_id);
          setYtConvCustomerId(data.data.campaign_detail.yt_conv_customer_id);
          setYtConvSubCustomerId(data.data.campaign_detail.yt_conv_subcustomer_id);
          setTbAdAccountId(data.data.campaign_detail.tb_ad_account_id);
          setCampaignGoalResult(data.data.campaign_goal_result.get_goal);
        }
        setCampaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        setCampaignLoader(false);
      });
    }
  }, [campaign_id]);

/*Start Step 4*/
  useEffect(() => {
    if(campaignLPPageValue !== ''){
      CampaignApi.getgoalsdropdown({
        system_website_id : selectedMemberWebsite,
        funnel_id : SelectedFunnelValue,
        page_id : campaignLPPageValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setGoalOptions(data.data.goal_options);
          setPostbackOptions(data.data.postback_options);
          setButtonOptions(data.data.button_options);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [campaignLPPageValue]);

  const goalOptionsData = [
    { value: '', label: 'Select a goal' },
    ...goalOptions.map((goalOptions) => ({
      value: goalOptions.page_id,
      label: goalOptions.title,
    })),
  ];

  const postbackOptionsData = [
    { value: '', label: 'Select a postback' },
    ...postbackOptions.map((postbackOptions) => ({
      value: postbackOptions.page_id,
      label: postbackOptions.title,
    })),
  ];

  const buttonOptionsData = [
    { value: '', label: 'Select a goal' },
    ...buttonOptions.map((buttonOptions) => ({
      value: buttonOptions.page_id,
      label: buttonOptions.title,
    })),
  ];

  const optionsData = [
    { value: '1', label: 'Goal' },
    { value: '2', label: 'Postback' },
    { value: '3', label: 'Button' },
  ];

  useEffect(() => {
    if (campaignGoalResult) {
      const goalsData = campaignGoalResult;
      const updatedOptionsBtn = [...optionsBtn];
      const updatedGoalAction = [...goalAction];
      const updatedGoalNames = [...goalNames];
      const updatedSalesValues = [...salesValues];
      const updatedExcludeTopline = [...excludeTopline];
      const updatedIncludeAov = [...includeAov];
      const updatedExcludeUndefinedConversions = [...excludeUndefinedConversions];
      const updatedCheckedGoals = [...checkedGoals];
      const updatedCheckedCustomerPath = [...checkedCustomerPath];
      let highestGoal = 7;

      goalsData.forEach((goal) => {
        const goalIndex = parseInt(goal.goal, 10) - 1;
        updatedCheckedGoals[goalIndex] = goal.goal;
        updatedOptionsBtn[goalIndex] = goal.type === "goal" ? "1" : goal.type === "postback" ? "2" : "3";
        updatedGoalAction[goalIndex] = goal.goal_page_id;
        updatedGoalNames[goalIndex] = goal.goal_name;
        updatedSalesValues[goalIndex] = parseInt(goal.sales_value, 10);
        updatedExcludeTopline[goalIndex] = goal.exclude_topline_revenue_val === "1";
        updatedIncludeAov[goalIndex] = goal.include_aov_val === "1";
        updatedExcludeUndefinedConversions[goalIndex] = goal.exclude_undefined_conversions_val === "1";
        updatedCheckedCustomerPath[goalIndex] = goal.customer_path_val === "1";
        if (goalIndex + 1 > highestGoal) {
          highestGoal = goalIndex + 1;
        }
      });
      if (goals.length < highestGoal) {
        const newGoals = Array.from({ length: highestGoal }, (_, i) => i + 1);
        setGoals(newGoals);
      }
      setCheckedGoals(updatedCheckedGoals);
      setCheckedCustomerPath(updatedCheckedCustomerPath);
      setOptionsBtn(updatedOptionsBtn);
      setGoalAction(updatedGoalAction);
      setGoalNames(updatedGoalNames);
      setSalesValues(updatedSalesValues);
      setExcludeTopline(updatedExcludeTopline);
      setIncludeAov(updatedIncludeAov);
      setExcludeUndefinedConversions(updatedExcludeUndefinedConversions);
    }
  }, [campaignGoalResult, goals.length]);

  const handleCheckboxChange = (index) => {
    const updatedCheckedGoals = [...checkedGoals];
    if (updatedCheckedGoals[index]) {
      updatedCheckedGoals[index] = undefined;
    } else {
      updatedCheckedGoals[index] = (index + 1).toString();
    }
    setCheckedGoals(updatedCheckedGoals);
  };

  const handleCustomerPathChange = (index) => {
    const updatedCheckedCustomerPath = [...checkedCustomerPath];
    if (updatedCheckedCustomerPath[index]) {
      updatedCheckedCustomerPath[index] = undefined;
    } else {
      updatedCheckedCustomerPath[index] = (index + 1).toString();
    }
    setCheckedCustomerPath(updatedCheckedCustomerPath);
  };

  const handleOptionsBtnChange = (value,index) => {
    const updatedOptionsBtns = [...optionsBtn];
    updatedOptionsBtns[index] = value;
    setOptionsBtn(updatedOptionsBtns);
  };

  const handleGoalOptionsChange = (value,index) => {
    const updatedGoalActions = [...goalAction];
    updatedGoalActions[index] = value;
    setGoalAction(updatedGoalActions);
  };

  const handleGoalNameChange = (value, index) => {
    const updatedGoalNames = [...goalNames];
    updatedGoalNames[index] = value;
    setGoalNames(updatedGoalNames);
  };

  const handleSalesValueChange = (value, index) => {
    const updatedSalesValues = [...salesValues];
    updatedSalesValues[index] = value;
    setSalesValues(updatedSalesValues);
  }; 

  const handleExcludeToplineChange = (value, index) => {
    const updatedExcludeTopline = [...excludeTopline];
    updatedExcludeTopline[index] = value;
    setExcludeTopline(updatedExcludeTopline);
  };

  const handleIncludeAovChange = (value, index) => {
    const updatedIncludeAov = [...includeAov];
    updatedIncludeAov[index] = value;
    setIncludeAov(updatedIncludeAov);
  };

  const handleExcludeUndefinedConversionsChange = (value, index) => {
    const updatedExcludeUndefinedConversions = [...excludeUndefinedConversions];
    updatedExcludeUndefinedConversions[index] = value;
    setExcludeUndefinedConversions(updatedExcludeUndefinedConversions);
  };

  const addGoal = () => {
    const newTotalGoals = [...goals, goals.length + 1];
    setGoals(newTotalGoals);
    setTotalGoalsShow(newTotalGoals.length);
    setOptionsBtn([...optionsBtn, '1']);
    setGoalAction([...goalAction, goalOptionsData[0].value]);
    setGoalNames([...goalNames, '']);
    setSalesValues([...salesValues, 0]);
    setExcludeTopline([...excludeTopline, 0]);
    setIncludeAov([...includeAov, 0]);
    setCheckedGoals([...checkedGoals, undefined]);
    setCheckedCustomerPath([...checkedCustomerPath, undefined]);
  };
/*End Step 4*/

/*Start Step 5*/
  useEffect(() => {
      if(step5Disabled !== true){
        /*start facebook*/
          if (activeTab === 'Facebook') {
            CampaignApi.getconversiondata({
              campaign_id : campaign_id
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setFbConversionGoal(data.data.message_row.fb_conversion_goal || []);
                setFbConversionEvent(data.data.message_row.fb_conversion_event || []);
                setFbConversionCurrency(data.data.message_row.fb_conversion_currency || []);

                setFacebookConversionPageview(data.data.message_pageview.facebook_conversion_pageview);
                setFbConversionCurrencyMP(data.data.message_pageview.fb_conversion_currency || []);
                setFbConversionEventMP(data.data.message_pageview.fb_conversion_event || []);
              }
            })
            .catch(error => {
              console.log(error);
            });

            CampaignApi.getfbpixeldetails({
              campaign_id : campaign_id
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setFbAdAccountDetails(data.data.adaccount_details || []);
              }
            })
            .catch(error => {
              console.log(error);
            });
          }
        /*End facebook*/

        /*start google*/
          if (activeTab === 'Google') {
            CampaignApi.getgoogleconvadcustomer({
              campaign_id : campaign_id,
              gl_conv_customer_id : glConvCustomerId
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setGlCustomer(data.data.adaccount_details);
              }
            })
            .catch(error => {
              console.log(error);
            });

            CampaignApi.getgoogleconvadsubcustomer({
              customer_id : glCustomerValue,
              gl_conv_subcustomer_id : glConvSubCustomerId
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setGlSubCustomer(data.data.adaccount_details);
              }
            })
            .catch(error => {
              console.log(error);
            });

            CampaignApi.getgoogleconversiondata({
              campaign_id : campaign_id
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setGlConvPageGoal(data.data.message_row.gl_conv_page_id || []);
                setGlConversionAction(data.data.message_row.gl_conv_action_resource_name || []);
                setGoogleConversionPageview(data.data.message_pageview_gl.google_conversion_pageview);
                setGlConversionActionMP(data.data.message_pageview_gl.gl_conv_action_resource_name_pageview || []);
              }
            })
            .catch(error => {
              console.log(error);
            });

            CampaignApi.getgoogleconversionpagedetail({
              campaign_id : campaign_id,
              customer_id : glCustomerValue,
              subcustomer_id : glSubCustomerValue
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setGlConvAction(data.data.adaccount_details || []);
              }
            })
            .catch(error => {
              console.log(error);
            });
          }
        /*end google*/

        /*start youtube*/
          if (activeTab === 'Youtube') {
            CampaignApi.getgoogleconvadcustomer({
              campaign_id : campaign_id,
              yt_conv_customer_id : ytConvCustomerId
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setYtCustomer(data.data.adaccount_details || []);
              }
            })
            .catch(error => {
              console.log(error);
            });

            CampaignApi.getgoogleconvadsubcustomer({
              customer_id : ytCustomerValue,
              yt_conv_subcustomer_id : ytSubCustomerValue
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setYtSubCustomer(data.data.adaccount_details || []);
              }
            })
            .catch(error => {
              console.log(error);
            });

            CampaignApi.getgoogleconversiondata({
              campaign_id : campaign_id
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setYtConvPageGoal(data.data.message_yt_row.yt_conv_page_id || []);
                setYtConversionAction(data.data.message_yt_row.yt_conv_action_resource_name || []);
                setYoutubeConversionPageview(data.data.message_pageview_yt.youtube_conversion_pageview);
                setYtConversionActionMP(data.data.message_pageview_yt.yt_conv_action_resource_name_pageview || []);
              }
            })
            .catch(error => {
              console.log(error);
            });

            CampaignApi.getgoogleconversionpagedetail({
              campaign_id : campaign_id,
              customer_id : ytCustomerValue,
              subcustomer_id : ytSubCustomerValue
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setYtConvAction(data.data.adaccount_details || []);
              }
            })
            .catch(error => {
              console.log(error);
            });
          }
        /*end youtube*/

        /*start taboola*/
          if (activeTab === 'Taboola') {
            CampaignApi.taboolaconvaduserdata({
              tb_ad_account_id : tbAdAccountId
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setTbAdAccountDetails(data.data.adaccount_details || []);
              }
            })
            .catch(error => {
              console.log(error);
            });

            if (tbAdAccountDetailsValue !== ''){
              CampaignApi.gettaboolaconversionpagedetail({
                campaign_id : campaign_id,
                tb_conv_ad_account_dd : tbAdAccountId
              })
              .then(response => {
                if (response.ok) {
                  return response.json();
                } else {
                  if (response.status === 401 || response.status === 400) {
                    navigate("/logout");
                  }
                }
              })
              .then(data => {
                if(data.status === 1){
                  setTbConversionAction(data.data.adaccount_details || []);
                  setTbConvPageDetail(data.data.tb_conv_page_detail || []);
                }
              })
              .catch(error => {
                console.log(error);
              });
            }

            CampaignApi.gettaboolaconversiondata({
              campaign_id : campaign_id
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if(data.status === 1){
                setTbConvMessageRow(data.data.message_row || []);
                setTbConvPageGoal(data.data.message_row.tb_conv_page_id || []);
                setTbConversionActionMr(data.data.message_row.tb_conv_action_resource_name || []);
                setTaboolaConversionPageview(data.data.message_pageview_tb.taboola_conversion_pageview);
                setTbConversionActionMP(data.data.message_pageview_tb.tb_conv_action_resource_name_pageview || []);
              }
            })
            .catch(error => {
              console.log(error);
            });
          }
        /*end taboola*/
      }
  }, [campaign_id,activeTab,glConvCustomerId,glCustomerValue,glConvSubCustomerId,tbAdAccountId,tbAdAccountDetailsValue,glSubCustomerValue,ytCustomerValue,ytSubCustomerValue]);

  /*Facebook Step5*/
    const fbConversionGoalData = [
      { value: '', label: 'Select Goal' },
      ...fbConversionGoal.map((fbConversionGoal) => ({
        value: fbConversionGoal.key,
        label: fbConversionGoal.value,
        selected: fbConversionGoal.selected,
      })),
    ];

    const fbConversionEventData = [
      { value: '', label: 'Select Event' },
      ...fbConversionEvent.map((fbConversionEvent) => ({
        value: fbConversionEvent.key,
        label: fbConversionEvent.value,
        selected: fbConversionEvent.selected,
      })),
    ];

    const fbConversionCurrencyData = [
      { value: '', label: 'Select Currency' },
      ...fbConversionCurrency.map((fbConversionCurrency) => ({
        value: fbConversionCurrency.key,
        label: fbConversionCurrency.value,
        selected: fbConversionCurrency.selected,
      })),
    ];

    const handlefbConversionGoalChange = (value,id) => {
      setFbConversionGoalValue(value);
    };

    const handlefbConversionEventChange = (value,id) => {
      setFbConversionEventValue(value);
    };

    const handlefbConversionCurrencyChange = (value,id) => {
      setFbConversionCurrencyValue(value);
    };
    
    const handleFacebookConversionPageviewChange = (event) => {
      setFacebookConversionPageview(event.target.checked);
    };

    const fbConversionEventMPData = [
      { value: '', label: 'Select Event' },
      ...fbConversionEventMP.map((fbConversionEventMP) => ({
        value: fbConversionEventMP.key,
        label: fbConversionEventMP.value,
        selected: fbConversionEventMP.selected,
      })),
    ];

    const fbConversionCurrencyMPData = [
      { value: '', label: 'Select Currency' },
      ...fbConversionCurrencyMP.map((fbConversionCurrencyMP) => ({
        value: fbConversionCurrencyMP.key,
        label: fbConversionCurrencyMP.value,
        selected: fbConversionCurrencyMP.selected,
      })),
    ];

    const handlefbConversionEventMPChange = (value) => {
      setFbConversionEventValueMP(value);
    };

    const handlefbConversionCurrencyMPChange = (value) => {
      setFbConversionCurrencyValueMP(value);
    };

    const addfbGoals = () => {
      setGoalGroups(groups => [
        ...groups,
        { id: groups.length + 1, goal: '', event: '', currency: '' }
      ]);
    };

    const removefbGoals = (id) => {
      setGoalGroups(groups => groups.filter(group => group.id !== id));
    };
  /*End Facebook Step5*/

  /*Google Step5*/
    const glCustomerData = [
      { value: '', label: 'Select Customer ID' },
      ...glCustomer.map((glCustomer) => ({
        value: glCustomer.key,
        label: glCustomer.value,
        selected: glCustomer.selected,
      })),
    ];

    const glSubCustomerData = [
      { value: '', label: 'Select Sub Customer ID' },
      ...glSubCustomer.map((glSubCustomer) => ({
        value: glSubCustomer.key,
        label: glSubCustomer.value,
        selected: glSubCustomer.selected,
      })),
    ];

    const handleglCustomerChange = (value) => {
      setGlCustomerValue(value);
    };

    const handleglSubCustomerChange = (value) => {
      setGlSubCustomerValue(value);
    };

    const glConvPageGoalData = [
      { value: '', label: 'Select Conversion Page' },
      ...glConvPageGoal.map((glConvPageGoal) => ({
        value: glConvPageGoal.key_goal_gl,
        label: glConvPageGoal.value_goal_gl,
        selected: glConvPageGoal.selected,
      })),
    ];

    const glConversionActionData = [
      { value: '', label: 'Select Conversion Action' },
      ...glConversionAction.map((glConversionAction) => ({
        value: glConversionAction.key_resource,
        label: glConversionAction.value_resource,
        selected: glConversionAction.selected,
      })),
    ];

    const glConversionActionMPData = [
      { value: '', label: 'Select Conversion Action' },
      ...glConversionActionMP.map((glConversionActionMP) => ({
        value: glConversionActionMP.key_resource,
        label: glConversionActionMP.value_resource,
        selected: glConversionActionMP.selected,
      })),
    ];

    const glConvActionData = [
      { value: '', label: 'Select Conversion Action' },
      ...glConvAction.map((glConvAction) => ({
        value: glConvAction.key,
        label: glConvAction.value,
      })),
    ];

    const handleglConversionActionChange = (value,id) => {
      setGlConversionActionValue(value);
    };

    const handleglConversionActionMPChange = (value) => {
      setGlConversionActionMPValue(value);
    };

    const handleglConvActionChange = (value) => {
      setGlConvActionValue(value);
    };

    const handleglConvPageGoalChange = (value,id) => {
      setGlConvPageGoalValue(value);
    };

    const handleGoogleConversionPageviewChange = (event) => {
      setGoogleConversionPageview(event.target.checked);
    };
    
    const addglGoals = () => {
      setGlGoalGroups(groups => [
        ...groups,
        { id: groups.length + 1, action: '', page: '' }
      ]);
    };

    const removeglGoals = (id) => {
      setGlGoalGroups(groups => groups.filter(group => group.id !== id));
    };
  /*End Google Step5*/

  /*Youtube Step5*/
    const ytCustomerData = [
      { value: '', label: 'Select Customer ID' },
      ...ytCustomer.map((ytCustomer) => ({
        value: ytCustomer.key,
        label: ytCustomer.value,
        selected: ytCustomer.selected,
      })),
    ];

    const ytSubCustomerData = [
      { value: '', label: 'Select Sub Customer ID' },
      ...ytSubCustomer.map((ytSubCustomer) => ({
        value: ytSubCustomer.key,
        label: ytSubCustomer.value,
        selected: ytSubCustomer.selected,
      })),
    ];

    const handleytCustomerChange = (value) => {
      setYtCustomerValue(value);
    };

    const handleytSubCustomerChange = (value) => {
      setYtSubCustomerValue(value);
    };

    const ytConvPageGoalData = [
      { value: '', label: 'Select Conversion Page' },
      ...ytConvPageGoal.map((ytConvPageGoal) => ({
        value: ytConvPageGoal.key_goal_yt,
        label: ytConvPageGoal.value_goal_yt,
        selected: ytConvPageGoal.selected,
      })),
    ];

    const ytConversionActionData = [
      { value: '', label: 'Select Conversion Action' },
      ...ytConversionAction.map((ytConversionAction) => ({
        value: ytConversionAction.key_resource,
        label: ytConversionAction.value_resource,
        selected: ytConversionAction.selected,
      })),
    ];

    const ytConversionActionMPData = [
      { value: '', label: 'Select Conversion Action' },
      ...ytConversionActionMP.map((ytConversionActionMP) => ({
        value: ytConversionActionMP.key_resource,
        label: ytConversionActionMP.value_resource,
        selected: ytConversionActionMP.selected,
      })),
    ];

    const ytConvActionData = [
      { value: '', label: 'Select Conv Action' },
      ...ytConvAction.map((ytConvAction) => ({
        value: ytConvAction.key,
        label: ytConvAction.value,
      })),
    ];

    const handleytConversionActionChange = (value,id) => {
      // setYtConversionActionValue(value);
      setYtGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, action: value } : group
        )
      );
    };

    const handleytConversionActionMPChange = (value) => {
      setYtConversionActionMPValue(value);
    };

    const handleytConvActionChange = (value) => {
      setYtConvActionValue(value);
    };

    const handleytConvPageGoalChange = (value,id) => {
      // setYtConvPageGoalValue(value);
      setYtGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, page: value } : group
        )
      );
    };

    const handleYoutubeConversionPageviewChange = (event) => {
      setYoutubeConversionPageview(event.target.checked);
    };
    
    const addytGoals = () => {
      setYtGoalGroups(groups => [
        ...groups,
        { id: groups.length + 1, action: '', page: '' }
      ]);
    };

    const removeytGoals = (id) => {
      setYtGoalGroups(groups => groups.filter(group => group.id !== id));
    };
  /*End Youtube Step5*/

  /*Taboola Step5*/
    const tbAdAccountDetailsData = [
      { value: '', label: 'Select Ads Account ID' },
      ...tbAdAccountDetails.map((tbAdAccountDetails) => ({
        value: tbAdAccountDetails.key,
        label: tbAdAccountDetails.value,
        selected: tbAdAccountDetails.selected,
      })),
    ];

    const tbConversionActionData = [
      { value: '', label: 'Select Conversion Action' },
      ...tbConversionAction.map((tbConversionAction) => ({
        value: tbConversionAction.event_name,
        label: tbConversionAction.display_name,
      })),
    ];

    const tbConversionActionMrData = [
      { value: '', label: 'Select Conversion Action' },
      ...tbConversionActionMr.map((tbConversionActionMr) => ({
        value: tbConversionActionMr.event_name,
        label: tbConversionActionMr.display_name,
        selected: tbConversionActionMr.selected,
      })),
    ];

    const tbConvPageDetailData = [
      { value: '', label: 'Select Conversion Page' },
      ...tbConvPageDetail.map((tbConvPageDetail) => ({
        value: tbConvPageDetail.key,
        label: tbConvPageDetail.value,
      })),
    ];


    const handletbAdAccountDetailsChange = (value) => {
      setTbAdAccountDetailsValue(value);
      setTbConvPageDetailValue('');
      setTbConversionActionValue('');
      setTbConvActionValue('');
    };

    const handletbConvPageDetailChange = (value,id) => {
      // setTbConvPageDetailValue(value);
      setTbGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, page: value } : group
        )
      );
    };

    const handletbConversionActionChange = (value,id) => {
      // setTbConversionActionValue(value);
      setTbGoalGroups(groups => 
        groups.map(group => 
          group.id === id ? { ...group, action: value } : group
        )
      );
    };

    const handletbConvActionChange = (value) => {
      setTbConvActionValue(value);
    };

    const addtbGoals = () => {
      setTbGoalGroups(groups => [
        ...groups,
        { id: groups.length + 1, action: '', page: '' }
      ]);
    };

    const removetbGoals = (id) => {
      setTbGoalGroups(groups => groups.filter(group => group.id !== id));
    };
  /*End Taboola Step5*/

  useEffect(() => {
    const selectedEvent = fbConversionEventMP.find(event => event.selected === 'SELECTED');
    if (selectedEvent) {
      setFbConversionEventValueMP(selectedEvent.key);
    }

    const selectedCurrency = fbConversionCurrencyMP.find(currency => currency.selected === 'SELECTED');
    if (selectedCurrency) {
      setFbConversionCurrencyValueMP(selectedCurrency.key);
    }

    const selectedfbConversionGoal = fbConversionGoal.find(goal => goal.selected === 'selected');
    if (selectedfbConversionGoal) {
      setFbConversionGoalValue(selectedfbConversionGoal.key);
    }

    const selectedfbConversionEvent = fbConversionEvent.find(event => event.selected === 'SELECTED');
    if (selectedfbConversionEvent) {
      setFbConversionEventValue(selectedfbConversionEvent.key);
    }

    const selectedglCustomer = glCustomer.find(event => event.selected === 'selected');
    if (selectedglCustomer) {
      setGlCustomerValue(selectedglCustomer.key);
    }

    const selectedglSubCustomer = glSubCustomer.find(event => event.selected === 'selected');
    if (selectedglSubCustomer) {
      setGlSubCustomerValue(selectedglSubCustomer.key);
    }

    const selectedglConvPageGoal = glConvPageGoal.find(event => event.selected === 'selected');
    if (selectedglConvPageGoal) {
      setGlConvPageGoalValue(selectedglConvPageGoal.key_goal_gl);
    }

    const selectedglConversionAction = glConversionAction.find(event => event.selected === 'selected');
    if (selectedglConversionAction) {
      setGlConversionActionValue(selectedglConversionAction.key_resource);
    }

    const selectedglConversionActionMP = glConversionActionMP.find(event => event.selected === 'selected');
    if (selectedglConversionActionMP) {
      setGlConversionActionMPValue(selectedglConversionActionMP.key_resource);
    }
  }, [fbConversionEventMP,fbConversionCurrencyMP,fbConversionGoal,fbConversionEvent,glCustomer,glSubCustomer,glConvPageGoal,glConversionAction,glConversionActionMP]);
/*End Step 5*/

/*Start Save Campaign*/
  const handleSaveCampaign = () => {
    const noGoalsSelected = !checkedGoals.some(goal => goal);

    const goalsWithoutAction = checkedGoals
    .map((goal, index) => goal && !goalAction[index] ? index + 1 : null)
    .filter(goalNumber => goalNumber !== null);

    if(noGoalsSelected && goalsWithoutAction){
      modals.open({
        title: 'Message',
        children: (
        <>
          <Text size="sm">
            Please select Goal checkbox.<br/>
            Please select Goal value.
          </Text>
          <Group position="right" mt="sm">
            <Button onClick={() => modals.closeAll()}>OK</Button>
          </Group>
        </>
        ),
      });
      return;
    } 

    if (goalsWithoutAction.length > 0) {
      modals.open({
        title: 'Message',
        children: (
          <>
            <Text size="sm">
              {`Please select Goal ${goalsWithoutAction.join(', ')} value`}
            </Text>
            <Group position="right" mt="sm">
              <Button onClick={() => modals.closeAll()}>OK</Button>
            </Group>
          </>
        ),
      });
      return;
    }

    const selectedTypes = optionsBtn.map(btn =>
      btn === "1" ? "goal" : btn === "2" ? "postback" : btn === "3" ? "button" : "goal"
    );

    const selectedGoals = checkedGoals
      .map((goal, index) => goal !== undefined && goal ? goals[index] : null)
      .filter((goal) => goal !== null);
    
    const checkedCustomerPathRevenue = {};
    const excludeToplineRevenue = {};
    const includeAovRevenue = {};
    const excludeUndefinedConversionsRevenue = {};

    selectedGoals.forEach((goal, index) => {
      const goalIndex = goals.indexOf(goal);
      checkedCustomerPathRevenue[goal] = checkedCustomerPath[goalIndex] ? 1 : 0;
      excludeToplineRevenue[goal] = excludeTopline[goalIndex] ? 1 : 0;
      includeAovRevenue[goal] = includeAov[goalIndex] ? 1 : 0;
      excludeUndefinedConversionsRevenue[goal] = excludeUndefinedConversions[goalIndex] ? 1 : 0;
    });

    CampaignApi.savecampaigndetail({
      system_website_id : selectedMemberWebsite,
      c_id : campaign_id || campaignId,
      campaign_name : campaignName,
      page_id : campaignLPPageValue,
      tracking_type : isTrackingType,
      cpc : cpc,
      ADDED_TOTAL_GOAL : totalgoalsShow,
      is_conversion_api : isConversionApiChecked,
      goal : selectedGoals,
      customer_path_goal : checkedCustomerPathRevenue,
      type : selectedTypes,
      goal_action : goalAction,
      goal_name : goalNames,
      goal_sales_val : salesValues,
      exclude_topline_revenue : excludeToplineRevenue,
      include_aov : includeAovRevenue,
      exclude_undefined_conversions : excludeUndefinedConversionsRevenue,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        if(isConversionApiChecked !== false){
          setStep5Disabled(false);
          setStepValue('step-5');
          setStep5Show('1');
        } else{
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
          navigate("/manage-campaigns");
        }
      }
    })
    .catch(error => {
      console.log(error);
    });
  }
/*End Save Campaign*/

/*Start Save Conversion Data*/
  const handleSaveConversionData = () => {
    const ytConversionActionValue = ytgoalGroups.map(group => group.action);
    const ytConvPageGoalValue = ytgoalGroups.map(group => group.page);
    CampaignApi.saveconversiondata({
      system_website_id : selectedMemberWebsite,
      campaign_id : campaign_id || campaignId,

      yt_conv_customer_id: ytCustomerValue,
      yt_conv_subcustomer_id: ytSubCustomerValue,
      yt_conv_action_resource_name_pageview: ytConvActionValue,
      yt_conv_action_resource_name: ytConversionActionValue.join(','),
      yt_conv_page_id: ytConvPageGoalValue.join(','),
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        navigate("/manage-campaigns");
      }
    })
    .catch(error => {
      console.log(error);
    });
  }
/*End Save Conversion Data*/

  const handleFacebookCallback = (response) => {
    console.log(response);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      setCampaignLoader(true);
      CampaignApi.savegoogleadaccountcredentials({
        code : codeResponse.code
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setCampaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setGoogleLoginButton(false);
          setGoogleLogoutButton(true);
        }else{
          console.log(data);
        }
        setCampaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        setCampaignLoader(false);
      });
    },
    onError: codeError => console.log(codeError),
    flow: 'auth-code',
  });

  const removeSocialMediaIntegration = (integrationType) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <>
      <Text size="sm">
        Are you sure you want to remove this integration? Any feature relying on it will stop working immediately.
      </Text>
      <br/>
      <Text size="sm">
        If you don�t want to remove the integration, you can just close this message. Otherwise click below to remove the integration now.
      </Text>
      </>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => {},
    onConfirm: () => {
      setCampaignLoader(true);
      CampaignApi.deleteadsintegrations({
        c_id : campaignId,
        ads_type : integrationType
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            setCampaignLoader(false);
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          if(integrationType == 'google'){
            setGoogleLoginButton(true);
            setGoogleLogoutButton(false);
          }else if(integrationType == 'outbrain'){
            setOutbrainLoginButton(true);
            setOutbrainLogoutButton(false);
          }else if(integrationType == 'taboola'){
            setTaboolaLoginButton(true);
            setTaboolaLogoutButton(false);
          }else if(integrationType == 'facebook'){
            setFacebookLoginButton(true);
            setFacebookLogoutButton(false);
          }else if(integrationType == 'microsoft'){
            setMicrosoftLoginButton(true);
            setMicrosoftLogoutButton(false);
          }
        }
        setCampaignLoader(false);
      })
      .catch(error => {
        console.log(error);
        setCampaignLoader(false);
      });
    } 
  });

  const loginTiktok = () => {
    alert("Work In Progress");
  };

  const loginOutBrain = () => {
    let outbrainWin = window.open(process.env.REACT_APP_BACKEND_API_PATH+'outbrainloginpopup.php?member_id='+memberId, 'formpopup', 'width=450,height=600,resizeable,scrollbars');
    let popupTick = setInterval(function () {
      if (outbrainWin.closed) {
        clearInterval(popupTick);
        getOutbrainAdUserData();
      }
    }, 2);
  };

  const loginTaboola = () => {
    let outbrainWin = window.open(process.env.REACT_APP_BACKEND_API_PATH+'taboolaloginpopup.php?taboola_client_id='+taboolaClientId+'&taboola_redirect_uri='+process.env.REACT_APP_BACKEND_API_PATH+'taboolaloginpopup.php', 'formpopup', 'width=450,height=600,resizeable,scrollbars');
    let popupTick = setInterval(function () {
      if (outbrainWin.closed) {
        clearInterval(popupTick);
        // getOutbrainAdUserData();
      }
    }, 2);
  };

  const getOutbrainAdUserData = () => {
    setCampaignLoader(true);
    CampaignApi.getcampaigntypeaduserdata({
      campaign_type: 'outbrain',
      campaign_id: campaignId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      setCampaignLoader(false);
      if(data){
        if(data.data){
          if(data.data.show_ob_ad_account_login_logout_button){
            if(data.data.show_ob_ad_account_login_logout_button == 'login'){
              setOutbrainLoginButton(true);
              setOutbrainLogoutButton(false);
            }else if(data.data.show_ob_ad_account_login_logout_button == 'logout'){
              setOutbrainLogoutButton(true);
              setOutbrainLoginButton(false);
            }
          }
          if(data.data.ob_ad_accounts){
            setOutbrainElements(data.data.ob_ad_accounts);
          }
        }
      }
    })
    .catch(error => {
      setCampaignLoader(false);
      console.error("API Error:", error);
    });
  }

  const getTaboolaAdUserData = () => {
    setCampaignLoader(true);
    CampaignApi.getcampaigntypeaduserdata({
      campaign_type: 'taboola',
      campaign_id: campaignId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      setCampaignLoader(false);
      if(data){
        if(data.data){
          if(data.data.show_tb_ad_account_login_logout_button){
            if(data.data.show_tb_ad_account_login_logout_button == 'login'){
              setTaboolaLoginButton(true);
              setTaboolaLogoutButton(false);
            }else if(data.data.show_tb_ad_account_login_logout_button == 'logout'){
              setTaboolaLogoutButton(true);
              setTaboolaLoginButton(false);
            }
          }
          if(data.data.taboola_client_id){
            setTaboolaClientId(data.data.taboola_client_id);
          }
          if(data.data.tb_ad_accounts){
            setTaboolaElements(data.data.tb_ad_accounts);
          }
        }
      }
    })
    .catch(error => {
      setCampaignLoader(false);
      console.error("API Error:", error);
    });
  }

  const authHandler = (err:any, data:any) => {
    console.log(err, data);
  };

	return (
	  <>
	  <VisiLoader VisiLoaderVisible={campaignLoader} />
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
	  <Modal opened={isMessageModelOpen} onClose={handleCloseModal} withCloseButton={false} size="60%" closeOnEscape={false} closeOnClickOutside={false}>
      {isModelMessage.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
      <div style={{float: 'right', margin: '10px'}}>
        <Button onClick={() => setIsMessageModelOpen(false)}>OK</Button>
      </div>
    </Modal>
	  {/*Connect Shopping Cart & Processor Model*/}
    <Modal.Root opened={shoppingCartProcessorModalOpen} onClose={handleShoppingCartProcessorCloseModal} size="80%">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>CONNECT SHOPPING CART & PROCESSOR (OPTIONAL) <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
        {shoppingCartProcessorList === false ?
          <form onSubmit={handleAddPaymentProcessorCart}>
            <Text fw="500">
              You can connect your payment processor and shopping cart. This step is optional but will enable more accurate tracking.
            </Text>
            <div style={{ display: 'flex' }}>
              <Text mt={13} mr={10} fw="500" fz={14}>
                Select a Processor:
              </Text>
              <Radio.Group name="ShoppingType" mb={10} value={convRedioTypeBtn} onChange={(value) => handleConvRedioTypeBtn(value)}>
                <Group mt="md">
                  <Radio value="1" label="Shopping Cart" />
                  <Radio value="2" label="Payment Processor" />
                </Group>
              </Radio.Group>
            </div>
            <Group spacing="xs" mb={5}>
              <IconHelp size="1.1rem" color="red" style={{ marginTop: -6 }} />
              <Text fz={14} mb={10} ml={5} fw="500">
                {labelText} :
              </Text>
              <Select
                w={250}
                value={convRedioTypeBtn === '1' ? selectedShoppingCart : selectedPaymentProcessor}
                data={convRedioTypeBtn === '1' ? shoppingCartData : paymentProcessorData}
                dropdownPosition="bottom"
                onChange={(value) => handleSelectChange(value)}
              />
              <Text fz={14} mb={10} ml={5} fw="500">
                Select Page :
              </Text>
              <Select
                w={250}
                value={selectedPage}
                data={pageData}
                dropdownPosition="bottom"
                onChange={(value) => handleSelectPageChange(value)}
                disabled={convRedioTypeBtn === '1' ? !selectedShoppingCart : !selectedPaymentProcessor}
              />
            </Group>
            <Group spacing="xs" mb={5}>
              <Text fz={14} mb={10} ml={95} fw="500">
                Webhook URL :
              </Text>
              <Input
                w={602}
                value={selectedWebhookURL}
                onChange={(e) => setSelectedWebhookURL(e.target.value)}
                disabled={!selectedPage}
              />
              <CopyButton timeout={2000} value={selectedWebhookURL}>
                {({ copied, copy }) => (
                  <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                    {copied ? 'Copied Webhook URL' : 'Copy Webhook URL'}
                  </Button>
                )}
              </CopyButton>
            </Group>
            {selectedProcessor === "ClickBank" &&(
              <Group spacing="xs" mb={5}>
                <Text fz={14} mb={10} ml={120} fw="500">
                  Secret Key :
                </Text>
                <Input
                  w={602}
                  value={selectedSecretKey}
                  onChange={(e) => setSelectedSecretKey(e.target.value)}
                />
              </Group>
            )}
            <Text fw="500" c="red" fz={14}>
              Please note: The revenue generating page is usually a thank you or confirmation page. The final conversion page in your funnel.
            </Text>
            <Text fw="500" c="red" fz={14}>
              It is important to note that even if your buyer does not make it all the way to the end of your funnel, Visiopt will still collect the total order amount inclusive of any upsells or downsells.
            </Text>
            <Group position="left">
              <Button type="submit" size="sm" w={140} mt={10}>
                Save Webhook
              </Button>
            </Group>
          </form>
          :
  		    <Group>
  		    	<Button type="submit" size="sm" w={165} onClick={() => AddWebhook()}>
  		    	  Add New Webhook
  		    	</Button>
  			  	<Table striped highlightOnHover withBorder fontSize="xs">
             <thead>
               <tr>
                 <th>Processor</th>
                 <th>Webhook Page</th>
                 <th>Webhook Url</th>
                 <th>Edit</th>
                 <th>Delete</th>
               </tr>
             </thead>
             <tbody>{testShoppingCartProcessorRows}</tbody>
  	  	    </Table>
  		    </Group>
  		  }
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>

  	<Container size="xl">
  		<Card shadow="sm" padding="sm" radius="md" withBorder>
	  	  <Group position="apart" mb="xs">
	  	    <Text weight={500}>Campaign <SelectTooltip/></Text>
	  	  </Group>
	  	  <Container size="xl" px={0}>
	  	    <Accordion chevronPosition="right" defaultValue="step-1" variant="separated" value={stepValue} onChange={setStepValue}>
	  	      <Accordion.Item className={classes.item} value="step-1">
	  	        <Accordion.Control disabled={step1Disabled}><Text component="span" color="red" weight={500}>Step 1</Text><Text component="span" weight={500}> - Select Campaign Page </Text><SelectTooltip/></Accordion.Control>
	  	        <Accordion.Panel>
		  	        <Group spacing="xs" mb={5}>
		  	          <Text mr={20} weight={500}>Select a Funnel:</Text>
	  	            <SelectFunnelList updated_time={0} Value={SelectedFunnelValue} OnChange={handleSelectFunnelListComponentChange} MemberWebsiteId={selectedMemberWebsite} Width={250} IsSelected={false}/>
		  	        </Group>
		  	        <Group spacing="xs" mb={5}>
		  	          <Text mr={10} weight={500}>Campaign Name:</Text>
		  	          <Input w={250} value={campaignName} onChange={(e) => setCampaignName(e.target.value)}/>
		  	        </Group>
		  	        <Group spacing="xs" mb={5}>
	  	            <Text mr={17} weight={500}>Campaign Page:</Text>
	  	            <Select
	  	              data={campaignLPPageList.map(page => ({
					            value: page.page_id,
					            label: page.title
				        		}))}
	  	              value={campaignLPPageValue}
	  	              name="landing_page"
	  	              onChange={handleCampaignLPPageChange}
	  	              w={250}
	  	              placeholder="Select Campaign Page"
	  	            />
		  	        </Group>
		  	        <Text weight={500}>All goal calculations will be based upon unique visits to this page.</Text>
		  	        <Space h="md" />
		  	        <Text weight={500}>For example: Conversion rates are calculated by dividing the number of conversions (actions) by the total number of uniques. Conversions / Uniques</Text>
		  	        <Space h="md" />
		  	        <Text weight={500}>The most common choice is the first page in your funnel but there are times when you want to choose a different page in your funnel for these calculations.</Text>
	  	        	<Button type="button" mt={10} onClick={() => submitStep1()}>Continue</Button>
	  	        </Accordion.Panel>
	  	      </Accordion.Item>
	  	      <Accordion.Item className={classes.item} value="step-2">
	  	        <Accordion.Control disabled={step2Disabled}><Text component="span" color="red" weight={500}>Step 2</Text><Text component="span" weight={500}> - Payment Processors & Carts </Text><SelectTooltip/></Accordion.Control>
	  	        <Accordion.Panel>
	  	        	<Text weight={500}>For better accuracy you can connect a payment processor and carts.</Text>
	  	        	<Group>
	  	        	<Button type="button" mt={10} color="teal" onClick={() => handleShoppingCartProcessorOpenModal(1)}>Connect</Button>
	  	        	<Button type="button" mt={10} onClick={() => submitStep2()}>Next</Button>
		  	        </Group>
	  	        </Accordion.Panel>
	  	      </Accordion.Item>
	  	      <Accordion.Item className={classes.item} value="step-3">
	  	        <Accordion.Control disabled={step3Disabled}><Text component="span" color="red" weight={500}>Step 3</Text><Text component="span" weight={500}> - Campaign Type </Text><SelectTooltip/></Accordion.Control>
 	  	        <Accordion.Panel>
 	  	        	<div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3A - </strong><strong>Select Campaign type :</strong></span></div>
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_classic"
 	  	          	checked={isClassicChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Classic')}
 	  	            label={<span><strong>Classic - </strong> You choose goals. (pages, postback or button type) </span>}
 	  	          />
 	  	          {/*Facebook*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_facebook"
 	  	          	checked={isFacebookChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Facebook')}
 	  	            label={<span><strong>Facebook - </strong> Connect and pull data from your Facebook campaign.</span>}
 	  	          />
 	  	          {isFacebookChecked ? (
 	  	          <>
                {facebookLoginButton &&
   	  	          <Group mb={3} ml={35}>
                  <FacebookLogin
                    appId={facebookAppId}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={handleFacebookCallback}
                    render={renderProps => (
                      <Button onClick={renderProps.onClick} leftIcon={<IconBrandFacebook stroke={2} />} style={{backgroundColor:"#0c6ec2"}}>
                    Login with Facebook
                    </Button>
                    )}
                  />
  	 	  	        </Group>
                }
                {facebookLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('facebook')}>
  	                Remove Facebook Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Ad Account ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{facebookRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Google*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_google"
 	  	          	checked={isGoogleChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Google')}
 	  	            label={<span><strong>Google - </strong> Connect and pull data from your Google campaign.</span>}
 	  	          />
 	  	          {isGoogleChecked ? (
 	  	          <>
                {googleLoginButton &&
   	  	          <Group mb={5} ml={35}>
                   <Button onClick={() => googleLogin()} leftIcon={<IconBrandGoogle stroke={2} />}>
                    Sign in with Google
                   </Button>
  	 	  	        </Group>
                }
                {googleLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('google')}>
  	                Remove Google Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  		                		<th>Customer ID</th>
  	              	      <th>Subcustomer ID</th>
  	              	      <th>Subcustomer Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Subcustomer Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{googleRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Microsoft*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_microsoft"
 	  	          	checked={isMicrosoftChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Microsoft')}
 	  	            label={<span><strong>Microsoft - </strong> Connect and pull data from your Microsoft campaign.</span>}
 	  	          />
 	  	          {isMicrosoftChecked ? (
 	  	          <>
                {microsoftLoginButton &&
   	  	          <Group mb={5} ml={35}>
  	 	  	          {/*<Button variant="outline" color="dark" leftIcon={<IconBrandWindows stroke={2} />}>
  	 	  	          Sign in with Microsoft
  	 	  	          </Button>*/}
                    <MicrosoftLogin clientId="df964e45-d6e9-4385-8a2a-ff1982fd9d4a" authCallback={authHandler}>
                      <button>Login with Microsoft</button>
                    </MicrosoftLogin>
  	 	  	        </Group>
                }
                {microsoftLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('microsoft')}>
  	                Remove Microsoft Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Ad Account ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{microsoftRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Tiktok*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_tiktok"
 	  	          	checked={isTiktokChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Tiktok')}
 	  	            label={<span><strong>Tiktok - </strong> Connect and pull data from your Tiktok campaign.</span>}
 	  	          />
 	  	          {isTiktokChecked ? (
 	  	          <>
                {tiktokLoginButton &&
   	  	          <Group mb={5} ml={35}>
  	 	  	          <Button onClick={() => loginTiktok()} leftIcon={<IconBrandTiktok stroke={2} />} style={{backgroundColor:"black"}}>
  	 	  	          Login with Tiktok
  	 	  	          </Button>
  	 	  	        </Group>
                }
                {tiktokLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35}>
  	                Remove Tiktok Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Ad Account ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{tiktokRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Youtube*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_youtube"
 	  	          	checked={isYoutubeChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Youtube')}
 	  	            label={<span><strong>Youtube - </strong> Connect and pull data from your Youtube campaign.</span>}
 	  	          />
 	  	          {isYoutubeChecked ? (
 	  	          <>
                {googleLoginButton &&
   	  	          <Group mb={5} ml={35}>
  	 	  	          <Button onClick={() => googleLogin()} leftIcon={<IconBrandGoogle stroke={2} />}>
                      Sign in with Google
                    </Button>
  	 	  	        </Group>
                }
                {googleLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('google')}>
                    Remove Google Integration
                  </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Customer ID</th>
  	              	      <th>Subcustomer ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{youtubeRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Taboola*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_taboola"
 	  	          	checked={isTaboolaChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Taboola')}
 	  	            label={<span><strong>Taboola - </strong> Connect and pull data from your Taboola campaign.</span>}
 	  	          />
 	  	          {isTaboolaChecked ? (
 	  	          <>
                {taboolaLoginButton &&
   	  	          <Group mb={5} ml={35}>
  	 	  	          <Button onClick={() => loginTaboola()} variant="outline" color="blue.9">
  	 	  	            <Image src="img/social-media/taboola-icon.png" mr={5}/>Login with Taboola
  	 	  	          </Button>
  	 	  	        </Group>
                }
                {taboolaLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('taboola')}>
  	                Remove Taboola Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Ad Account ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{taboolaRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

	              {/*Outbrain*/}
 	  	          <Checkbox mb={5}
 	  	          	name="campaign_type_outbrain"
 	  	          	checked={isOutbrainChecked}
 	  	          	onChange={(event) => handleSocialMediaCheckboxChange(event,'Outbrain')}
 	  	            label={<span><strong>Outbrain - </strong> Connect and pull data from your Outbrain campaign.</span>}
 	  	          />
 	  	          {isOutbrainChecked ? (
 	  	          <>
                {outbrainLoginButton &&
   	  	          <Group mb={5} ml={35}>
  	 	  	          <Button onClick={() => loginOutBrain()} variant="outline" color="orange">
  	 	  	            <Image src="img/social-media/outbrain-icon.png" mr={5}/>Connect Outbrain
  	 	  	          </Button>
  	 	  	        </Group>
                }
                {outbrainLogoutButton && (<>
   	  	          <Button color="red" mb={5} ml={35} onClick={() => removeSocialMediaIntegration('outbrain')}>
  	                Remove Outbrain Integration
  	              </Button>
  	              <Group spacing="xs" ml={35} mb={15}>
  	 	  	          <div style={{ marginBottom:5}}><span style={{ marginLeft:2 }}><strong style={{ color: 'red'}}>3B - </strong><strong>Select Ad Account :</strong></span></div>
  	 	  	          <Table striped highlightOnHover withBorder>
  		                <thead>
  		                	<tr>
  	              	      <th>Ad Account ID</th>
  	              	      <th>Account Name</th>
  	              	      <th>Currency</th>
  	              	      <th>Select Ad Account</th>
  	              	    </tr>
  		                </thead>
  		                <tbody>{outbrainRows}</tbody>
  		              </Table>
  		            </Group>
                </>)}
		            </>)
		            : <></>}

		            <Button type="button" mt={5}>Continue</Button>
 	  	        </Accordion.Panel>
	  	      </Accordion.Item>
	  	      <Accordion.Item className={classes.item} value="step-4">
	  	        <Accordion.Control disabled={step4Disabled}><Text component="span" color="red" weight={500}>Step 4</Text><Text component="span" weight={500}> - Goals </Text><SelectTooltip/></Accordion.Control>
  	        	<Accordion.Panel>
		  	        <Group mb={5}>
		  	          <Text weight={500}><SelectTooltip/>  Tracking Type:</Text>
	  	            <Select
	  	              data={[
	  	                  { value: '1', label: 'Normal Tracking' },
	  	                  { value: '2', label: 'Customer Path' }
	  	                ]}
	  	              value={isTrackingType}
	  	              name="tracking_type"
	  	              onChange={handleTrackingTypeChange}
	  	              w={250}
	  	            />
		  	        </Group>
		  	        <Group mb={5}>
		  	        	<div style={{ width: 78 }}></div>
		  	        	<div style={{ width: 22, display: isTrackingType === '1' ? "none" : "block"}}></div>
		  	        	<div style={{ width: 120 }}></div>
		  	        	<div style={{ width: 230 }}></div>
		  	        	<div style={{ width: 230 }}></div>
		  	        	<div style={{ width: 80 }}></div>
		  	        	<Text style={{ width: 105, textAlign: 'center', fontWeight: 500 }}><SelectTooltip/> Exclude from topline revenue</Text>
		  	        	<Text style={{ width: 80, textAlign: 'center', fontWeight: 500}}><SelectTooltip/> Include in AOV</Text>
		  	        	<Text style={{ width: 141, fontWeight: 500 }}><SelectTooltip/> Exclude Undefined Conversions </Text>
		  	        </Group>

                {goals.map((goalNumber, index) => (
  		  	        <Group key={goalNumber} mb={3}>
  		  	        	<Checkbox w={78}
  		  	        	  label={<strong>{`Goal ${goalNumber}:`}</strong>}
                      checked={!!checkedGoals[index]}
                      onChange={() => handleCheckboxChange(index)} 
  		  	        	/>
  		  	        	<Checkbox w={22} 
                      style={{ display: isTrackingType === '1' ? "none" : "block" }}
                      checked={!!checkedCustomerPath[index]}
                      onChange={() => handleCustomerPathChange(index)}
                    />
  		  	        	<Select
                      data={optionsData}
                      value={optionsBtn[index]}
                      onChange={(value) => handleOptionsBtnChange(value, index)}
  		  	        	  name="tracking_type"
  		  	        	  w={120}
  		  	        	/>
  		  	        	<Select
  		  	        	  value={goalAction[index]}
                      data={optionsBtn[index] === '1' ? goalOptionsData : optionsBtn[index] === '2' ? postbackOptionsData : buttonOptionsData}
                      onChange={(value) => handleGoalOptionsChange(value, index)}
  		  	        	  w={240}
  		  	        	/>
                    {optionsBtn[index] === '3' ? (
                      <div style={{ width: 240, display: "flex" }}>
                        <Button w={120}>Track Button</Button>
                        <Text w={110} ml={10} mt={8} lineClamp={1}>AAAAAAAAAAAAAAAAAAAAAAAAA</Text>
                      </div>
                    ) : (
                      <Input 
                        placeholder="Name your Goal" 
                        value={goalNames[index]}
                        onChange={(event) => handleGoalNameChange(event.target.value, index)} 
                        w={240}
                      />
                    )}
                    {goalAction[index] === 'ZGJvS1lhRHQyVTJ4R1U5RXhwL2tBQT09' ?(
                      <div style={{ width:105}}>
                        <ActionIcon size="lg" variant="default" title="Webhook Connected"><IconWebhook stroke={2} color="black"/></ActionIcon>
                      </div>
                    ) : (
  		  	        	<NumberInput 
                      placeholder="Goal Sales" 
                      value={salesValues[index]}
                      onChange={(value) => handleSalesValueChange(value, index)}
                      w={105} 
                      min={0}
                    />
                    )}
  		  	        	<Checkbox
                      checked={excludeTopline[index]}
                      onChange={(event) => handleExcludeToplineChange(event.target.checked, index)}
                      w={50} ml={20}
                    />
                    <Checkbox
                      checked={includeAov[index]}
                      onChange={(event) => handleIncludeAovChange(event.target.checked, index)}
                      w={50} ml={20}
                    />
                    <Checkbox
                      checked={excludeUndefinedConversions[index]}
                      onChange={(event) => handleExcludeUndefinedConversionsChange(event.target.checked, index)}
                      w={50} ml={60}
                    />
  		  	        </Group>
                ))}

		  	        <Button color="teal" mt={10} onClick={addGoal}>
		  	          <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
		  	        </Button>
		  	        <Group mb={5} mt={10}>
		  	          <Text weight={500}>CPC :</Text>
		  	          <Input value={cpc} onChange={(e) => setCpc(e.target.value)}/>
		  	        </Group>
		  	        <Checkbox
		  	        	name="is_conversion_api"
 	  	          	checked={isConversionApiChecked}
 	  	          	onChange={(event) => handleIsConversionApiChange(event)}
 	  	          	label={<strong>Conversion API</strong>}/>
		  	        <Button mt={10} onClick={handleSaveCampaign}>{isConversionApiChecked ? 'Save & Continue To API Set Up' : 'Save Campaign'}</Button>
		  	        <Button color="red" mt={10} ml={10} onClick={() => navigate("/manage-campaigns")}>Cancel</Button>
	  	        </Accordion.Panel>
	  	      </Accordion.Item>
            {(step5Show !== '0' &&
    	      <Accordion.Item className={classes.item} value="step-5">
    	        <Accordion.Control disabled={step5Disabled}><Text component="span" color="red" weight={500}>Step 5</Text><Text component="span" weight={500}> - API Set Up </Text><SelectTooltip/></Accordion.Control>
    	        <Accordion.Panel>
		  	        <Group spacing="xs" mb={5}>
		  	          <Text weight={500}>Conversion Attribution:</Text>
	  	            <Select
	  	              data={[
	  	                  { value: '1', label: 'First Click' },
	  	                  { value: '2', label: 'Last Click' },
	  	                  { value: '3', label: 'Full Impact' }
	  	                ]}
                    value={convAttributionModel}
                    onChange={handleConvAttributionModelChange}
	  	              name="conv_attribution_model"
	  	              w={200}
	  	            />
		  	        </Group>
    	        	<Tabs value={activeTab} onTabChange={setActiveTab}>
        	      <Tabs.List grow>
        	        <Tabs.Tab value="Facebook">Facebook</Tabs.Tab>
        	        <Tabs.Tab value="Google">Google</Tabs.Tab>
        	        <Tabs.Tab value="Microsoft">Microsoft</Tabs.Tab>
        	        <Tabs.Tab value="Tiktok">Tiktok</Tabs.Tab>
        	        <Tabs.Tab value="Youtube">Youtube</Tabs.Tab>
        	        <Tabs.Tab value="Taboola">Taboola</Tabs.Tab>
        	        <Tabs.Tab value="Outbrain">Outbrain</Tabs.Tab>
        	      </Tabs.List>

        	      <Tabs.Panel value="Facebook" pt="xs">
        	        <Table striped highlightOnHover withBorder mb={10}>
		                <thead>
		                	<tr>
	              	      <th>Ad Account ID</th>
	              	      <th>Pixel ID</th>
	              	      <th>Access Token</th>
	              	    </tr>
		                </thead>
		                <tbody>
                      {
                        Array.isArray(fbAdAccountDetails) && fbAdAccountDetails.length > 0 ? (
                        <>
                          {fbAdAccountDetails.map((element) => (
                            <tr key={element.fb_account_name}>
                              <td>{element.fb_account_name_id} </td>
                              <td><Input value={element.fb_pixel_id} onChange={(e) => setFbPixelIdValue(e.target.value)}/></td>
                              <td><Input value={element.fb_access_token} onChange={(e) => setFbAccessTokenValue(e.target.value)}/></td>
                            </tr>
                          ))}
                        </>
                        ) : (
                          <tr>
                            <td> </td>
                            <td style={{ textAlign: 'center' }}>No Data Found</td>
                            <td> </td>
                          </tr>
                        )
                      }
                    </tbody>
		              </Table>
	              	<Checkbox
			  	        	name="send_all_conversion_fb"
	 	  	          	checked={isFbConversionApiChecked}
	 	  	          	onChange={(event) => handleFbConversionApiChange(event)}
	 	  	          	label={<strong>Send All conversion to Facebook <SelectTooltip/></strong>}
	 	  	          />
		              <Group mt={10}>
		              	<Checkbox
				  	        	name="facebook_conversion_pageview"
		 	  	          	label={<strong>LP visit push as pageview:</strong>}
                      checked={facebookConversionPageview}
                      onChange={(event) => handleFacebookConversionPageviewChange(event)}
		 	  	          />
	 	  	          	<Select
                      data={fbConversionEventMPData}
                      value={fbConversionEventValueMP}
                      onChange={handlefbConversionEventMPChange}
	 	  	          	  name="fb_conversion_event_pageview"
	 	  	          	  w={250}
	 	  	          	/>
                    {fbConversionEventValueMP === 'Purchase' && (
                      <Select
                        data={fbConversionCurrencyMPData}
                        value={fbConversionCurrencyValueMP}
                        onChange={handlefbConversionCurrencyMPChange}
                        name="fb_conversion_currency_pageview[1][]"
                        w={250}
                      />
                    )}
 	  	          	</Group>
 	  	          	<Text mt={10}>Select the goals you want to pass to Facebook. Then select desired Event to pass for each of your campaign goals.</Text>
  		            {goalGroups.map(group => (
                    <Group key={group.id} mt={10}>
  		              	<Select
  		 	  	          	  data={fbConversionGoalData}
                          value={fbConversionGoalValue}
                          onChange={(value) => handlefbConversionGoalChange(value, group.id)}
                          name={`fb_conversion_goal[${group.id}][]`}
  		 	  	          	  w={250}
  		 	  	          	/>
  		 	  	          	<Select
  		 	  	          	  data={fbConversionEventData}
                          value={fbConversionEventValue}
                          onChange={(value) => handlefbConversionEventChange(value, group.id)}
                          name={`fb_conversion_event[${group.id}][]`}
  		 	  	          	  w={250}
  		 	  	          	/>
                        {fbConversionEventValue === 'Purchase' && (
                          <Select
                            data={fbConversionCurrencyData}
                            value={fbConversionCurrencyValue}
                            onChange={(value) => handlefbConversionCurrencyChange(value, group.id)}
                            name={`fb_conversion_currency[${group.id}][]`}
                            w={250}
                          />
                        )}
  		 	  	          	<IconCircleMinus size="1.1rem" color="red" onClick={() => removefbGoals(group.id)}/>
   	  	          	</Group>
                  ))}
 	  	          	<Button color="teal" mt={10} onClick={addfbGoals}>
 	  	          	  <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
 	  	          	</Button>
        	      </Tabs.Panel>

        	      <Tabs.Panel value="Google" pt="xs">
        	        <Group spacing="xs" mb={5}>
  		  	          <Text weight={500}>Customer ID:</Text>
  	  	            <Select
  	  	              data={glCustomerData}
  	  	              value={glCustomerValue}
                      onChange={handleglCustomerChange}
  	  	              name="gl_conv_customer_id"
  	  	              w={200}
  	  	              ml={28}
  	  	            />
  		  	        </Group>
        	        <Group spacing="xs" mb={5}>
  		  	          <Text weight={500}>Sub Customer ID:</Text>
  	  	            <Select
  	  	              data={glSubCustomerData}
                      value={glSubCustomerValue}
                      onChange={handleglSubCustomerChange}
  	  	              name="gl_conv_subcustomer_id"
  	  	              w={200}
  	  	            />
  		  	        </Group>
		              <Group mt={10}>
		              	<Checkbox
				  	        	name="google_conversion_pageview"
		 	  	          	label={<strong>LP visit push as pageview:</strong>}
                      checked={googleConversionPageview}
                      onChange={(event) => handleGoogleConversionPageviewChange(event)}
		 	  	          />
	 	  	          	<Select
	 	  	          	  data={glConvActionData}
                      value={glConvActionValue}
                      onChange={handleglConvActionChange}
	 	  	          	  name="gl_conv_action_resource_name_pageview"
	 	  	          	  w={250}
	 	  	          	/>
 	  	          	</Group>
                  {glgoalGroups.map(group => (
  		              <Group key={group.id} mt={10}>
  		              	<Select
  		 	  	          	  data={glConversionActionData}
                          value={glConversionActionValue}
                          onChange={(value) => handleglConversionActionChange(value, group.id)}
                          name={`gl_conv_action_resource_name[]`}
  		 	  	          	  w={250}
  		 	  	          	/>
  		 	  	          	<Select
  		 	  	          	  data={glConvPageGoalData}
                          value={glConvPageGoalValue}
                          onChange={(value) => handleglConvPageGoalChange(value, group.id)}
                          name={`gl_conv_page_id[]`}
  		 	  	          	  w={250}
  		 	  	          	/>
  		 	  	          	<IconCircleMinus size="1.1rem" color="red" onClick={() => removeglGoals(group.id)}/>
   	  	          	</Group>
                  ))}
 	  	          	<Button color="teal" mt={10} onClick={addglGoals}>
 	  	          	  <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
 	  	          	</Button>
        	      </Tabs.Panel>

        	      <Tabs.Panel value="Microsoft" pt="xs">
        	        <Group spacing="xs" mb={5}>
  		  	          <Text weight={500}>Ads Account ID:</Text>
  	  	            <Select
  	  	              data={[
  	  	              		{ value: '', label: 'Select Ads Account ID' },
  	  	                  { value: '3236613117', label: '3236613117' },
  	  	                  { value: '7508245756', label: '7508245756' },
  	  	                  { value: '4164011403', label: '4164011403' }
  	  	                ]}
  	  	              value=""
  	  	              name="ms_conv_ads_account_id"
  	  	              w={200}
  	  	              ml={28}
  	  	            />
  		  	        </Group>
		              <Group mt={10}>
		              	<Checkbox
				  	        	name="microsoft_conversion_pageview"
		 	  	          	label={<strong>LP visit push as pageview:</strong>}
		 	  	          />
	 	  	          	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Conversion Action'},
	 	  	          	      { value: 'customers/3293652962/conversionActions/1045724388', label: 'Converted lead' },
	 	  	          	      { value: 'customers/3293652962/conversionActions/1046368751', label: 'Converted lead demo' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="ms_conv_goal_name_pageview"
	 	  	          	  w={250}
	 	  	          	/>
 	  	          	</Group>
		              <Group mt={10}>
		              	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Conversion Action'},
	 	  	          	      { value: 'customers/3293652962/conversionActions/1045724388', label: 'Converted lead' },
	 	  	          	      { value: 'customers/3293652962/conversionActions/1046368751', label: 'Converted lead demo' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="ms_conv_goal_name[]"
	 	  	          	  w={250}
	 	  	          	/>
	 	  	          	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Conversion Page'},
	 	  	          	      { value: '7', label: 'Thankyou' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="ms_conv_page_id[]"
	 	  	          	  w={250}
	 	  	          	/>
	 	  	          	<IconCircleMinus size="1.1rem" color="red"/>
 	  	          	</Group>
 	  	          	<Button color="teal" mt={10}>
 	  	          	  <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
 	  	          	</Button>
        	      </Tabs.Panel>

        	      <Tabs.Panel value="Tiktok" pt="xs">
        	        <Group spacing="xs" mb={5}>
  		  	          <Text weight={500}>Advertiser Account:</Text>
  	  	            <Select
  	  	              data={[
  	  	              		{ value: '', label: 'Select Advertiser Account' },
  	  	                  { value: '3236613117', label: '3236613117' },
  	  	                  { value: '7508245756', label: '7508245756' },
  	  	                  { value: '4164011403', label: '4164011403' }
  	  	                ]}
  	  	              value=""
  	  	              name="tk_conv_ads_account_id"
  	  	              w={200}
  	  	              ml={28}
  	  	            />
  		  	        </Group>
		              <Group mt={10}>
		              	<Checkbox
				  	        	name="tiktok_conversion_pageview"
		 	  	          	label={<strong>LP visit push as pageview:</strong>}
		 	  	          />
	 	  	          	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Pixel'},
	 	  	          	      { value: 'customers/3293652962/conversionActions/1045724388', label: 'Converted lead' },
	 	  	          	      { value: 'customers/3293652962/conversionActions/1046368751', label: 'Converted lead demo' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="tk_conv_pixel_id_pageview"
	 	  	          	  w={250}
	 	  	          	/>
	 	  	          	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Event Name'},
	 	  	          	      { value: 'customers/3293652962/conversionActions/1045724388', label: 'Converted lead' },
	 	  	          	      { value: 'customers/3293652962/conversionActions/1046368751', label: 'Converted lead demo' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="tk_conv_event_name_pageview"
	 	  	          	  w={250}
	 	  	          	/>
 	  	          	</Group>
		              <Group mt={10}>
		              	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Pixel'},
	 	  	          	      { value: 'customers/3293652962/conversionActions/1045724388', label: 'Converted lead' },
	 	  	          	      { value: 'customers/3293652962/conversionActions/1046368751', label: 'Converted lead demo' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="tk_conv_pixel_id[]"
	 	  	          	  w={250}
	 	  	          	/>
	 	  	          	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Event Name'},
	 	  	          	      { value: 'Search', label: 'Search' },
	 	  	          	      { value: 'PageView', label: 'Page View' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="tk_conv_event_name[]"
	 	  	          	  w={250}
	 	  	          	/>
	 	  	          	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Conversion Page'},
	 	  	          	      { value: '7', label: 'Thankyou' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="tk_conv_page_id[]"
	 	  	          	  w={250}
	 	  	          	/>
	 	  	          	<IconCircleMinus size="1.1rem" color="red"/>
 	  	          	</Group>
 	  	          	<Button color="teal" mt={10}>
 	  	          	  <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
 	  	          	</Button>
        	      </Tabs.Panel>

        	      <Tabs.Panel value="Youtube" pt="xs">
        	        <Group spacing="xs" mb={5}>
  		  	          <Text weight={500}>Customer ID:</Text>
  	  	            <Select
  	  	              data={ytCustomerData}
                      value={ytCustomerValue}
                      onChange={handleytCustomerChange}
  	  	              name="gl_conv_customer_id"
  	  	              w={200}
  	  	              ml={28}
  	  	            />
  		  	        </Group>
        	        <Group spacing="xs" mb={5}>
  		  	          <Text weight={500}>Sub Customer ID:</Text>
  	  	            <Select
  	  	              data={ytSubCustomerData}
                      value={ytSubCustomerValue}
                      onChange={handleytSubCustomerChange}
  	  	              name="gl_conv_subcustomer_id"
  	  	              w={200}
  	  	            />
  		  	        </Group>
		              <Group mt={10}>
		              	<Checkbox
				  	        	name="google_conversion_pageview"
		 	  	          	label={<strong>LP visit push as pageview:</strong>}
                      checked={youtubeConversionPageview}
                      onChange={(event) => handleYoutubeConversionPageviewChange(event)}
		 	  	          />
	 	  	          	<Select
	 	  	          	  data={ytConvActionData}
                      value={ytConvActionValue}
                      onChange={handleytConvActionChange}
	 	  	          	  name="gl_conv_action_resource_name_pageview"
	 	  	          	  w={250}
	 	  	          	/>
 	  	          	</Group>
                  {ytgoalGroups.map(group => (
  		              <Group mt={10}>
  		              	<Select
  	 	  	          	  data={ytConvActionData}
                        // value={ytConversionActionValue}
                        value={group.action}
                        onChange={(value) => handleytConversionActionChange(value, group.id)}
  	 	  	          	  name="gl_conv_action_resource_name[]"
  	 	  	          	  w={250}
  	 	  	          	/>
  	 	  	          	<Select
  	 	  	          	  data={ytConvPageGoalData}
                        // value={ytConvPageGoalValue}
                        value={group.page}
                        onChange={(value) => handleytConvPageGoalChange(value, group.id)}
  	 	  	          	  name="gl_conv_page_id[]"
  	 	  	          	  w={250}
  	 	  	          	/>
  	 	  	          	<IconCircleMinus size="1.1rem" color="red" onClick={() => removeytGoals(group.id)}/>
   	  	          	</Group>
                  ))}
 	  	          	<Button color="teal" mt={10} onClick={addytGoals}>
 	  	          	  <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
 	  	          	</Button>
        	      </Tabs.Panel>

        	      <Tabs.Panel value="Taboola" pt="xs">
        	        <Group spacing="xs" mb={5}>
  		  	          <Text weight={500}>Ad Account ID:</Text>
  	  	            <Select
  	  	              data={tbAdAccountDetailsData}
                      value={tbAdAccountDetailsValue}
                      onChange={handletbAdAccountDetailsChange}
  	  	              name="tb_conv_ad_account_dd"
  	  	              w={200}
  	  	              ml={28}
  	  	            />
  		  	        </Group>
		              <Group mt={10}>
		              	<Checkbox
				  	        	name="taboola_conversion_pageview"
		 	  	          	label={<strong>LP visit push as pageview:</strong>}
		 	  	          />
	 	  	          	<Select
	 	  	          	  data={tbConversionActionData}
                      value={tbConvActionValue}
                      onChange={handletbConvActionChange}
	 	  	          	  name="tb_conv_action_resource_name_pageview"
	 	  	          	  w={250}
	 	  	          	/>
 	  	          	</Group>

                  {tbgoalGroups.map(group => (
  		              <Group key={group.id} mt={10}>
  		              	<Select
  	 	  	          	  data={tbConversionActionMrData}
                        // value={tbConversionActionValue}
                        value={group.action}
                        onChange={(value) => handletbConversionActionChange(value, group.id)}
                        name={`tb_conv_action_resource_name[]`}
  	 	  	          	  w={250}
  	 	  	          	/>
  	 	  	          	<Select
  	 	  	          	  data={tbConvPageDetailData}
                        // value={tbConvPageDetailValue}
                        value={group.page}
                        onChange={(value) => handletbConvPageDetailChange(value, group.id)}
                        name={`tb_conv_page_id[]`}
  	 	  	          	  w={250}
  	 	  	          	/>
  	 	  	          	<IconCircleMinus size="1.1rem" color="red" onClick={() => removetbGoals(group.id)}/>
   	  	          	</Group>
                  ))}
 	  	          	<Button color="teal" mt={10} onClick={addtbGoals}>
 	  	          	  <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
 	  	          	</Button>
        	      </Tabs.Panel>

        	      <Tabs.Panel value="Outbrain" pt="xs">
        	        <Group spacing="xs" mb={5}>
  		  	          <Text weight={500}>Ad Account ID:</Text>
  	  	            <Select
  	  	              data={[
  	  	              		{ value: '', label: 'Select Ads Account ID' },
  	  	                  { value: '3236613117', label: '3236613117' },
  	  	                  { value: '7508245756', label: '7508245756' },
  	  	                  { value: '4164011403', label: '4164011403' }
  	  	                ]}
  	  	              value=""
  	  	              name="ob_conv_ad_account_dd"
  	  	              w={200}
  	  	              ml={28}
  	  	            />
  		  	        </Group>
		              <Group mt={10}>
		              	<Checkbox
				  	        	name="outbrain_conversion_pageview"
		 	  	          	label={<strong>LP visit push as pageview:</strong>}
		 	  	          />
	 	  	          	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Conversion Action'},
	 	  	          	      { value: 'customers/3293652962/conversionActions/1045724388', label: 'Converted lead' },
	 	  	          	      { value: 'customers/3293652962/conversionActions/1046368751', label: 'Converted lead demo' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="ob_conv_action_resource_name_pageview"
	 	  	          	  w={250}
	 	  	          	/>
 	  	          	</Group>
		              <Group mt={10}>
		              	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Conversion Action'},
	 	  	          	      { value: 'customers/3293652962/conversionActions/1045724388', label: 'Converted lead' },
	 	  	          	      { value: 'customers/3293652962/conversionActions/1046368751', label: 'Converted lead demo' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="ob_conv_action_resource_name[]"
	 	  	          	  w={250}
	 	  	          	/>
	 	  	          	<Select
	 	  	          	  data={[
	 	  	          	  		{ value: '', label: 'Select Conversion Page'},
	 	  	          	      { value: '7', label: 'Thankyou' }
	 	  	          	    ]}
	 	  	          	  value=""
	 	  	          	  name="ob_conv_page_id[]"
	 	  	          	  w={250}
	 	  	          	/>
	 	  	          	<IconCircleMinus size="1.1rem" color="red"/>
 	  	          	</Group>
 	  	          	<Button color="teal" mt={10}>
 	  	          	  <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Goal</Text>
 	  	          	</Button>
        	      </Tabs.Panel>
        	    </Tabs>
        	    <Button mt={20} onClick={handleSaveConversionData}>Save Campaign 111</Button>
        	    <Button color="red" mt={20} ml={10}>Cancel</Button>
    	        </Accordion.Panel>
    	      </Accordion.Item>
            )}
	  	    </Accordion>
	  	  </Container>
	  	</Card>
	  	{/*Error Model*/}
      {errorModelOpen && (
      <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
        <Modal.Overlay />
        <Modal.Content>
          {showMessageHeader ? (
            <Modal.Header>
              <Modal.Title>MESSAGE</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ) : showErrorHeader ? (
            <Modal.Header>
              <Modal.Title>ERROR</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ) : (
            <Modal.Header>
            </Modal.Header>
          )}
          <Modal.Body>
            <form>
              <div style={{ display: 'flex'}}>
                  <Text weight={500}>{errorMessage}</Text>
              </div>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      )}
	  </Container>
	  </>
	);
}