import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Container, Card, Group, Text, Button, Input, Grid, Pagination, Table, Flex, Modal } from '@mantine/core';
import { IconHelp, IconSearch } from '@tabler/icons-react';
import { VisiLoader } from "../../components/GeneralComponent/GeneralComponent";
import TestsApi from "../../api/TestsApi";

export function TestHistory() {
	const navigate = useNavigate();
	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};

	useDocumentTitle("Visiopt - Test History");

	const [SystemUsageLoader, setSystemUsageLoader] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errorModelOpen, seterrorCloseModal] = useState(false);
	const [showErrorHeader, setShowErrorHeader] = useState(false);
	const [showMessageHeader, setShowMessageHeader] = useState(false);
	const [activePageNo, setActivePageNo] = useState(1);
	const [keyword, setKeyword] = useState('');
	const [testHistoryList, setTestHistoryList] = useState([]);
	const [totalPages, setTotalPages] = useState(10);
	const [totalPaginationNo, setTotalPaginationNo] = useState(1);
	const [selectedThumbnail, setSelectedThumbnail] = useState(null);
	const [selectedPageId, setSelectedPageId] = useState(null);
	const [isThumbnailSelected, setIsThumbnailSelected] = useState(false);

  useEffect(() => {
		if(selectedMemberWebsite !== ''){
	    setSystemUsageLoader(true);
	    seterrorCloseModal(false);
	    TestsApi.gettesthistorylist({
	      system_website_id: selectedMemberWebsite,
	      number:25,
	      page: activePageNo,
	      keyword: keyword
	    })
	    .then(response => {
	      if (response.ok) {
	        return response.json();
	      } else {
	        if (response.status === 401 || response.status === 400) {
	          setSystemUsageLoader(false);
	          navigate("/logout");
	        }
	      }
	    })
	    .then(data => {
	      if(data.status === 1){
	        if (data.data.test_history_list) {
	          setTestHistoryList(data.data.test_history_list);
	          if (data.data.active_page_no !== activePageNo) {
	            setActivePageNo(data.data.active_page_no);
	            setActivePageNo(1);
	          }
	          if (data.data.total_pages !== totalPages) {
	            setTotalPages(data.data.total_pages);
	          }
	          if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
	            setTotalPaginationNo(data.data.total_pagination_no);
	          }
	        } else{
	          console.log("No data received");
	          setTestHistoryList([]);
	        }
	      }else{
	        setErrorMessage(data.message);
	        seterrorCloseModal(true);
	      }
	      setSystemUsageLoader(false);
	    })
	    .catch(error => {
	      console.error("API Error:", error);
	      setSystemUsageLoader(false);
	    });
	  }
  }, [activePageNo, selectedMemberWebsite, keyword]);

  const handleThumbnailClick = (historyId,pageId) => {
    if (isThumbnailSelected && selectedThumbnail === historyId) {
      setSelectedThumbnail(null);
      setSelectedPageId(null);
      setIsThumbnailSelected(false);
    } else {
      setSelectedThumbnail(historyId);
      setSelectedPageId(pageId);
      setIsThumbnailSelected(true);
    }
  };

  const handlePageChange = (newPage) => {
    setActivePageNo(newPage);
  };

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
    setActivePageNo(1);
  };

  const handleAddtoFunnel = async () => {
  	try {
      const APIResponse = await TestsApi.checktesthistorydata({
        tests_history_id : selectedThumbnail
      });
      const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
      	navigate('/test-history-report', { state: { historyId: selectedThumbnail, pageId:selectedPageId} });
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
        setShowMessageHeader(false);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  }

	const listRows = testHistoryList.map((item) => (
    <tr key={item.history_id} 
    		style={{ backgroundColor: (item.selected || selectedThumbnail === item.history_id) ? '#00b975' : '',
    						 transition: 'background-color 0.1s' 
    					}} 
				onClick={item.selected ? undefined : () => handleThumbnailClick(item.history_id,item.page_id)}
		>
			{item.preview_btn !== 'hide' ?(
      	<td><a href={item.page_url} target="_blank" rel="noreferrer">Preview</a></td>
			) : (
				<td></td>
			)}
      <td><Text>{item.page_url}</Text></td>
      <td><Text>{item.page_title}</Text></td>
      <td><Text>{item.test_name}</Text></td>
      <td><Text>{item.taguchi_name}</Text></td>
      <td><Text>{item.test_date}</Text></td>
      <td><Text>{item.test_ended}</Text></td>
    </tr>
  ));

	const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

	return (
	  <>
	  <VisiLoader VisiLoaderVisible={SystemUsageLoader}/>
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
	  <Container size="xl" mb={10}>
	  	<Card shadow="sm" padding="sm" radius="md" withBorder>
	  		<Group position="apart" mb="xs">
	        <Text weight={500}>TEST HISTORY <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
			  <Grid>
	        <Grid.Col span={6}>
	        	  <Text fz={14} weight={500}>The following are tests that you have run in the past.</Text>
	        	  <Text fz={14} weight={500}>These tests have ended. This section allows you to go back and review past test results.</Text>
	        </Grid.Col>
	        <Grid.Col span={3} offset={3}>
          	<div style={{ display: 'flex'}}>
          		<Flex
			     	  	style={{marginLeft:"auto"}}
			     	    justify="flex-end"
			     	    align="center"
			     	    direction="row"
			     	    wrap="wrap"
			     	  >
				     	  <Input icon={<IconSearch size={16} />} placeholder="Search"
						     	maxLength={50}
						     	autoFocus
						     	onChange={handleKeywordChange}
				     	   />
			     	  </Flex>
          	</div>
	        </Grid.Col>
	      </Grid>
  	    <div style={{flex: "1 1 auto",overflowX: "hidden",marginTop:20}}>
		  	    {testHistoryList.some(item => Array.isArray(item) ? item.length > 0 : true) && (
		  	    <>
				  	<Table striped highlightOnHover withBorder withColumnBorders mb={10}>
	           <thead>
	             <tr>
	               <th>Preview</th>
	               <th>URL</th>
	               <th>Page Title</th>
	               <th>Test name</th>
	               <th>Test Type</th>
	               <th>Date Started</th>
	               <th>Date Ended</th>
	             </tr>
	           </thead>
	           <tbody>{listRows}</tbody>
		  	    </Table>
		  	    <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange} >
		  	      <Group spacing={5} position="center" mb={20}>
		  	        <Pagination.First />
		  	        <Pagination.Previous />
		  	        <Pagination.Items />
		  	        <Pagination.Next />
		  	        <Pagination.Last />
		  	      </Group>
		  	    </Pagination.Root>
		  	    </>
		  	    )}
			  </div>
			  <div className="legend-holder">
				  <Grid>
		        <Grid.Col span={6} mt={15}>
		        	<Group spacing="xs" m={0}>
		        	  <span className="legend selected" />
		        	  <Text size="sm" ml={0} fz={12} className="legend-title">Selected</Text>
		        	</Group>
		        </Grid.Col>
		        <Grid.Col span={3} offset={3}>
	          	<div style={{ display: 'flex'}}>
	          		<Button type="button" onClick={() => handleAddtoFunnel()} style={{marginLeft:"auto"}}>Select</Button>
	          	</div>
		        </Grid.Col>
		      </Grid>
			  </div>
				{/*Error Model*/}
	      {errorModelOpen && (
	      <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
	        <Modal.Overlay />
	        <Modal.Content>
	          {showMessageHeader ? (
	            <Modal.Header>
	              <Modal.Title>MESSAGE</Modal.Title>
	              <Modal.CloseButton />
	            </Modal.Header>
	          ) : showErrorHeader ? (
	            <Modal.Header>
	              <Modal.Title>ERROR</Modal.Title>
	              <Modal.CloseButton />
	            </Modal.Header>
	          ) : (
	            <Modal.Header>
	            </Modal.Header>
	          )}
	          <Modal.Body>
	            <form>
	              <div style={{ display: 'flex'}}>
	                  <Text weight={500}>{errorMessage}</Text>
	              </div>
	            </form>
	          </Modal.Body>
	        </Modal.Content>
	      </Modal.Root>
	      )}
	    </Card>
	  </Container>
	  </>
	);
}