import { RichTextEditor, Link } from '@mantine/tiptap';


export function TextEditorToolbar() {

	return (
	  <>
	  <RichTextEditor.Toolbar sticky stickyOffset={60}>
			<RichTextEditor.ControlsGroup>
			  <RichTextEditor.Bold />
			  <RichTextEditor.Italic />
			  <RichTextEditor.Underline />
			  <RichTextEditor.Strikethrough />
			  <RichTextEditor.ClearFormatting />
			  <RichTextEditor.Highlight />
			  <RichTextEditor.Code />
			</RichTextEditor.ControlsGroup>

			<RichTextEditor.ControlsGroup>
			  <RichTextEditor.H1 />
			  <RichTextEditor.H2 />
			  <RichTextEditor.H3 />
			  <RichTextEditor.H4 />
			</RichTextEditor.ControlsGroup>

			<RichTextEditor.ControlsGroup>
			  <RichTextEditor.Blockquote />
			  <RichTextEditor.Hr />
			  <RichTextEditor.BulletList />
			  <RichTextEditor.OrderedList />
			  <RichTextEditor.Subscript />
			  <RichTextEditor.Superscript />
			</RichTextEditor.ControlsGroup>

			<RichTextEditor.ControlsGroup>
			  <RichTextEditor.Link />
			  <RichTextEditor.Unlink />
			</RichTextEditor.ControlsGroup>

			<RichTextEditor.ControlsGroup>
			  <RichTextEditor.AlignLeft />
			  <RichTextEditor.AlignCenter />
			  <RichTextEditor.AlignJustify />
			  <RichTextEditor.AlignRight />
			</RichTextEditor.ControlsGroup>

			<RichTextEditor.ColorPicker
	          colors={[
	            '#25262b',
	            '#868e96',
	            '#fa5252',
	            '#e64980',
	            '#be4bdb',
	            '#7950f2',
	            '#4c6ef5',
	            '#228be6',
	            '#15aabf',
	            '#12b886',
	            '#40c057',
	            '#82c91e',
	            '#fab005',
	            '#fd7e14',
	          ]}
	        />
		</RichTextEditor.Toolbar>
		</>
	 );
}