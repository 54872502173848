import {
  TextInput,
  Paper,
  Text,
  Container,
  Button,
  Center,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';
import bigLogo from '../../img/big-logo.png';
import { notifications } from '@mantine/notifications';
import {
  IconX,
  IconCheck
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import LoginLogoutApi from "../../api/LoginLogoutApi";

export const ForgotPassword = () => {

  useDocumentTitle("Visiopt - Forgot Password");

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: ''
    },

    validate: {
      email: (val) => (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val) ? null : 'Invalid email')
    },
  });

  const handleForgotPasswordSubmit = async (formData:any) => {
    try {
      const ForgotPasswordAPIResponse = await LoginLogoutApi.forgotpassword(formData)
      .then(ForgotPasswordAPIResponse => ForgotPasswordAPIResponse.text())
      .then(ForgotPasswordAPIResult => {
        const ForgotPasswordAPIResultJSON = JSON.parse(ForgotPasswordAPIResult);
        if(ForgotPasswordAPIResultJSON.status === 1){
          notifications.show({
            color: 'teal',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: ForgotPasswordAPIResultJSON.message,
          });
          navigate('/login');
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: ForgotPasswordAPIResultJSON.message,
          })
        }
      })
      .catch(error => console.log('error', error));
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <Container size={800} my={40}>
      <Paper p={30} mt={30} radius="8px" style={{ border : '1px solid #dee2e6' }}>
        <Paper w="60%" mx="auto">
          <Center>
            <img src={bigLogo} alt="Company Logo"/>
          </Center>
          <Text mt={10} mb={10}>Please provide the email address that you used to create the account.</Text>
          <form onSubmit={form.onSubmit((formData) => { handleForgotPasswordSubmit(formData); })}>
            <TextInput placeholder="Email" value={form.values.email} onChange={(event) => form.setFieldValue('email', event.currentTarget.value)} error={form.errors.email && 'Invalid email'}/>
            <Button fullWidth mt="xl" type="submit">
              Send
            </Button>
          </form>
        </Paper>
      </Paper>
    </Container>
  );
}