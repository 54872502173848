import { Group, Text, Button, Box, Grid, Flex, ActionIcon, Modal, Input, Radio, Switch } from '@mantine/core';
import { IconHelp, IconPencil, IconTrash } from '@tabler/icons-react';
import React, { useState } from 'react';

export function AccessManagement() {

  const [AddUserOpened, setAddUser] = useState(false);

  const [UsernameValue, setUsernameValue] = useState('');

  const [PasswordValue, setPasswordValue] = useState('');

  const [RetypePasswordValue, setRetypePasswordValue] = useState('');

  const [adminChecked, setadminChecked] = useState(false);
  const [editorChecked, seteditorChecked] = useState(false);
  const [viewerChecked, setViewerChecked] = useState(false);
  const [customChecked, setCustomChecked] = useState(false);

  const [LandingPageTabchecked, setLandingPageTabchecked] = useState(false);
  const [CampaignTabchecked, setCampaignTabchecked] = useState(false);
  const [ABTestchecked, setABTestchecked] = useState(false);
  const [ModernABTestchecked, setModernABTestchecked] = useState(false);
  const [SingleVariableTestchecked, setSingleVariableTestchecked] = useState(false);
  const [MultiVariableTestchecked, setMultiVariableTestchecked] = useState(false);
  const [BehavioralDimensionschecked, setdimensionschecked] = useState(false);
  const [HeatMapschecked, setHeatMapschecked] = useState(false);
  const [ScrollMapschecked, setScrollMapschecked] = useState(false);
  const [Recordingschecked, setRecordingschecked] = useState(false);
  const [SettingsGAIntegrationchecked, setSettingsGAIntegrationchecked] = useState(false);
  const [SettingsEmailIntegrationchecked, setSettingsEmailIntegrationchecked] = useState(false);
  const [SettingsNotificationSettingschecked, setSettingsNotificationSettingschecked] = useState(false);
  const [SettingsURLBuilderCustomTagschecked, setSettingsURLBuilderCustomTagschecked] = useState(false);
  const [SettingsPostbackschecked, setSettingsPostbackschecked] = useState(false);
  const [SettingsPaymentsProcessingCartschecked, setSettingsPaymentsProcessingCartschecked] = useState(false);
  const [SettingsAccessManagementchecked, setSettingsAccessManagementchecked] = useState(false);

  const closeUserModal = () => {
    setAddUser(false);
  }

  const [UserData, setUserData] = useState([
    { id: 1, email: 'scott@visiopt.com', role: 'Admin'},
    { id: 2, email: 'pankaj@visiopt.com', role: 'Admin'},
    { id: 3, email: 'shailesh@visiopt.com', role: 'Admin'},
  ]);

  const updateUser = (Id:any) => {

  }

  const deleteUserConfirm = (Id:any) => {
    
  }

  const handleAddUserSubmit = (Id:any) => {
    
  }
  
  return (
    <>
      <Modal.Root size={1100} opened={AddUserOpened} onClose={closeUserModal}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title><Text weight={500}>ACCESS MANAGEMENT <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleAddUserSubmit}>
              <Text weight={500} mb={10}>Enter new account credentials:</Text>
              <Grid>
                <Grid.Col span={2}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    <Text>Email:</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Input
                    name="username"
                    onChange={(event) => setUsernameValue(event.currentTarget.value)}
                    value={UsernameValue}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={2}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    <Text>Password:</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Input
                    name="password"
                    onChange={(event) => setPasswordValue(event.currentTarget.value)}
                    value={PasswordValue}
                  />
                </Grid.Col>
              </Grid>
              <Grid mb={5}>
                <Grid.Col span={2}>
                  <Flex
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    <Text>Re-type Password:</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Input
                    name="retype_password"
                    onChange={(event) => setRetypePasswordValue(event.currentTarget.value)}
                    value={RetypePasswordValue}
                  />
                </Grid.Col>
              </Grid>
              <Text weight={500} mb={10}>Choose a role:</Text>
              <Group>
                <Radio name="role" checked={adminChecked} onChange={(event) => setadminChecked(event.currentTarget.checked)} />
                <Text weight={500}>Admin</Text>
                <Text>- Full access.</Text>
              </Group>
              <Group>
                <Radio name="role" checked={editorChecked} onChange={(event) => seteditorChecked(event.currentTarget.checked)} />
                <Text weight={500}>Editor</Text>
                <Text>- Full access except can't add users and can't get access to billing portal.</Text>
              </Group>
              <Group>
                <Radio name="role" checked={viewerChecked} onChange={(event) => setViewerChecked(event.currentTarget.checked)} />
                <Text weight={500}>Viewer</Text>
                <Text>- Can't make any changes just view reports from dashboard, active tests, insights and campaigns.</Text>
              </Group>
              <Group mb={10}>
                <Radio name="role" checked={customChecked} onChange={(event) => setCustomChecked(event.currentTarget.checked)} />
                <Text weight={500}>Custom</Text>
                <Text>- Select the options below you want to allow for the custom role.</Text>
              </Group>
              {customChecked && <div>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Landing Page tab</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={LandingPageTabchecked} onChange={(event) => setLandingPageTabchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Campaign tab</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={CampaignTabchecked} onChange={(event) => setCampaignTabchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>AB test</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={ABTestchecked} onChange={(event) => setABTestchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Modern AB test</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={ModernABTestchecked} onChange={(event) => setModernABTestchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Single Variable Test</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SingleVariableTestchecked} onChange={(event) => setSingleVariableTestchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Multi Variable Test</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={MultiVariableTestchecked} onChange={(event) => setMultiVariableTestchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Behavioral dimensions</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={BehavioralDimensionschecked} onChange={(event) => setdimensionschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Heat Maps</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={HeatMapschecked} onChange={(event) => setHeatMapschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Scroll Maps</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={ScrollMapschecked} onChange={(event) => setScrollMapschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Recordings</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={Recordingschecked} onChange={(event) => setRecordingschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Settings : GA integration</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsGAIntegrationchecked} onChange={(event) => setSettingsGAIntegrationchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Settings : Email integration</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsEmailIntegrationchecked} onChange={(event) => setSettingsEmailIntegrationchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Settings : Notification Settings</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsNotificationSettingschecked} onChange={(event) => setSettingsNotificationSettingschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Settings : URL Builder & Custom Tags</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsURLBuilderCustomTagschecked} onChange={(event) => setSettingsURLBuilderCustomTagschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Settings : Postbacks</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsPostbackschecked} onChange={(event) => setSettingsPostbackschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Text>Settings : Payments processing & carts</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsPaymentsProcessingCartschecked} onChange={(event) => setSettingsPaymentsProcessingCartschecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
                <Grid mb={10}>
                  <Grid.Col span={3}>
                    <Text>Settings : Access Management</Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Switch checked={SettingsAccessManagementchecked} onChange={(event) => setSettingsAccessManagementchecked(event.currentTarget.checked)} />
                  </Grid.Col>
                </Grid>
              </div>}
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Group position="apart" mb="xs">
        <Text weight={500}>ACCESS MANAGEMENT <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
      </Group>
      <Button onClick={() => setAddUser(true)}>
        Add a New User
      </Button>
      {UserData.map((item,index) => (
        <Box
         key={index}
          p={4}
          pl={20}
          mt={10}
          sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#f8f9fa',
            padding: "10px",
            borderRadius: theme.radius.md,
            border: "0.0625rem solid transparent",
            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[1] : '#dee2e6'
          })}
        >
          <Grid gutter="xs">
            <Grid.Col span={3}>
              <Text mt={3}>
                {item.email}
              </Text>
            </Grid.Col>
            <Grid.Col span={2}>
              <Text mt={3}>
                {item.role}
              </Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Text mt={3} weight={500} c="blue">
                Preview
              </Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <Flex
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <ActionIcon
                  variant="default"
                  size="lg"
                  mx={3}
                  onClick={() => updateUser(item.id)}
                >
                  <IconPencil size="1.1rem" />
                </ActionIcon>
                <ActionIcon
                  variant="default"
                  size="lg"
                  mx={3}
                  onClick={() => deleteUserConfirm(item.id)}
                >
                  <IconTrash size="1.1rem" />
                </ActionIcon>
              </Flex>
            </Grid.Col>
          </Grid>
        </Box>
      ))}
    </>
  );
}