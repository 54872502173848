import { Group, Text, Input, Button, Anchor } from '@mantine/core';
import { IconHelp } from '@tabler/icons-react';
import { useState } from 'react';
import { modals } from '@mantine/modals';
import '../EmailIntegration/EmailIntegration.css';

export function PaymentProcessingAndCarts() {
	const [activePPC, setActivePPC] = useState("payment-processing-carts");
	const [activeId, setActiveId] = useState(null);
	const [activePPCClass, setActivePPCClass] = useState("");
	const [customTextPopup, setcustomTextPopup] = useState('{ "transaction_id": "1111111", "sales_amount": "52.50", "email": "test@gmail.com" }');

	const handleAction = (id:any) => {
		if(id === "cancel"){
			setActiveId(id);
			setActivePPC("payment-processing-carts");
		} else if(id === "chargebee" || id === "easypaydirect" || id === "hitpay" || id === "housecallpro" || id === "merchantx" || id === "paypalclassicIPN" || id === "recurly"){
			modals.openConfirmModal({
			  title: 'Refresh Screenshot',
			  children: (
			    <Text size="sm">
			      This payment processor is now activated and available for selection and use in both tests and campaigns. To confirm activated you will see a green border. To deactivate simply click again and green border will be removed.
			    </Text>
			  ),
			  labels: { confirm: 'Ok', cancel: "Close" },
			  onCancel: () => console.log('Cancel'),
			  onConfirm: () => console.log('Confirmed',setActivePPCClass(id)),
			});
		} else if(id === "buygoods" || id === "clickfunnels" || id === "gohighlevel" || id === "katra" || id === "konnektive" || id === "ontraport" || id === "paykickstart" || id === "samcart" || id === "shopify" || id === "ultraCart" || id === "thrivecart" || id === "woocommerce"){
			modals.openConfirmModal({
			  title: 'Refresh Screenshot',
			  children: (
			    <Text size="sm">
			      This shopping cart is now activated and available for selection and use in both tests and campaigns. To confirm activated you will see a green border. To deactivate simply click again and green border will be removed.
			    </Text>
			  ),
			  labels: { confirm: 'Ok', cancel: "Close" },
			  onCancel: () => console.log('Cancel'),
			  onConfirm: () => console.log('Confirmed',setActivePPCClass(id)),
			});
		} else {
			setActiveId(id);
			setActivePPC('');
		}
	};
  
  const [paymentProcessorData, setPaymentProcessorData] = useState([
    { id: 1, action_name: 'authorizenet' ,img: 'img/cart-icons/authorize.png', name:'authorize.net' },
    { id: 2, action_name: 'chargebee' ,img: 'img/cart-icons/chargebee.png', name:'Chargebee' },
    { id: 3, action_name: 'easypaydirect' ,img: 'img/cart-icons/easypaydirect.png', name:'Easy Pay Direct' },
    { id: 4, action_name: 'hitpay' ,img: 'img/cart-icons/hitpay.png', name:'Hit Pay' },
    { id: 5, action_name: 'housecallpro' ,img: 'img/cart-icons/housecallpro.png', name:'Housecall pro' },
    { id: 6, action_name: 'gocardless' ,img: 'img/cart-icons/gocardless.png', name:'Go Cardless' },
    { id: 7, action_name: 'merchantx' ,img: 'img/cart-icons/merchantx.png', name:'Merchantx' },
    { id: 8, action_name: 'paypalclassicIPN' ,img: 'img/cart-icons/paypal.png', name:'Paypal Classic IPN' },
    { id: 9, action_name: 'recurly' ,img: 'img/cart-icons/recurly.png', name:'Recurly' },
    { id: 10, action_name: 'stripe' ,img: 'img/cart-icons/stripe.png', name:'Stripe' },
  ]);

  const [shoppingCartsData, setShoppingCartsData] = useState([
    { id: 1, action_name: 'buygoods' ,img: 'img/cart-icons/buygoods.png', name:'Buy Goods' },
    { id: 2, action_name: 'clickbank' ,img: 'img/cart-icons/clickbank.png', name:'Click Bank' },
    { id: 3, action_name: 'clickfunnels' ,img: 'img/cart-icons/clickfunnels.png', name:'ClickFunnels' },
    { id: 4, action_name: 'gohighlevel' ,img: 'img/cart-icons/gohighlevel.png', name:'Go High Level' },
    { id: 5, action_name: 'kajabi' ,img: 'img/cart-icons/kajabi.png', name:'Kajabi' },
    { id: 6, action_name: 'katra' ,img: 'img/cart-icons/kartra.png', name:'Katra' },
    { id: 7, action_name: 'konnektive' ,img: 'img/cart-icons/konnektive.png', name:'Konnektive' },
    { id: 8, action_name: 'ontraport' ,img: 'img/cart-icons/ontraport.png', name:'Ontraport' },
    { id: 9, action_name: 'paykickstart' ,img: 'img/cart-icons/paykickstart.png', name:'Paykickstart' },
    { id: 10, action_name: 'samcart' ,img: 'img/cart-icons/samcart.png', name:'Sam Cart' },
    { id: 11, action_name: 'shopify' ,img: 'img/cart-icons/shopify.png', name:'Shopify' },
    { id: 12, action_name: 'ultraCart' ,img: 'img/cart-icons/ultraCart.png', name:'UltraCart' },
    { id: 13, action_name: 'thrivecart' ,img: 'img/cart-icons/thrivecart.png', name:'Thrive Cart' },
    { id: 14, action_name: 'woocommerce' ,img: 'img/cart-icons/woocommerce.png', name:'Woocommerce' },
    { id: 15, action_name: 'customcart' ,img: 'img/cart-icons/customcart.png', name:'Custom Cart' },
  ]);

  return (
    <>
    	<div style={{ display: activePPC === "payment-processing-carts" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500} fz={14}>PAYMENT PROCESSING AND CARTS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
	      <div style={{display:"flex"}}>
	      	<Text fw={500} mr={5} fz={14}> Step 1: </Text>
	      	<Text fz={14}>Activate any payment processor or shopping cart you want to connect via web hook by clicking on the desired icon below.</Text>
	      </div>
	      <Text mb={15} fz={14}>Note: You will use either a single cart or a single processor in a campaign or test. There is no need to set up both a processor and a cart.</Text>
	      <div style={{display:"flex"}}>
	      	<Text fw={500} mr={5} fz={14}> Step 2: </Text>
	      	<Text fz={14}>Get the proper web hook link inside of Visiopt.</Text>
	      </div>
	      <Text mb={15} fz={14}>Note: This can be accomplished in either testing or campaigns. Once completed it will work for both testing and campaigns</Text>
	      <div style={{display:"flex"}}>
	      	<Text fw={500} mr={5} fz={14}> Step 3: </Text>
	      	<Text fz={14}>Place the web hook in your cart or processor.</Text>
	      </div>
	      <Text mb={15} fz={14}>Note : A new unique Webhook must be created for each test or campaign created.</Text>
	      <Text mb={15} fz={14}>When a campaign and test share the same funnel then the same Webhook may be used for both.</Text>
	      <div style={{display:"flex"}}>
		      <Text mb={30} mr={5} fz={14}>To learn how to get the proper webhook link in Visiopt, and how to connect it to various</Text>
		      <Anchor href="https://visiopt.com/help-videos/connect-carts.html" target="_blank" td="none" fw={700} fz={14}>
			      Carts and Payment Processors, visit our Cart and Payment Processor Connection Help Page.
		      </Anchor>
		    </div>
        <Text fw={500} mb={10}>Payment Processors:</Text>
        <Group spacing="xs" mb={25}>
        	{paymentProcessorData.map((item,index) => (
	  	      <div key={index} className={activePPCClass === item.action_name ? "email-thumb active-email" : "email-thumb"} onClick={() => handleAction(item.action_name)}>
	  	      	<div className="thumb-holder">
	  	      		<img src={item.img} />
	  	      	</div>
	  	      	<span>{item.name}</span>
	  	      </div>
  	      ))}
        </Group>
        <Text fw={500} mb={10}>Shopping Carts:</Text>
        <Group spacing="xs" mb={15}>
        	{shoppingCartsData.map((item,index) => (
  	      	<div key={index} className={activePPCClass === item.action_name ? "email-thumb active-email" : "email-thumb"} onClick={() => handleAction(item.action_name)}>
	  	      	<div className="thumb-holder">
	  	      		<img src={item.img} />
	  	      	</div>
	  	      	<span>{item.name}</span>
	  	      </div>
  	      ))}
        </Group>
	    </div>
      <div style={{ display: activeId === "authorizenet" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500}>AUTHORIZE.NET <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
  	    <Group spacing="xs" mb={10}>
  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10} mr={45}>Login ID:</Text>
  	      <Input w={250} ml={0}/>
  	    </Group>
  	    <Group spacing="xs" mb={10}>
  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Transaction Key:</Text>
  	      <Input w={250} ml={0}/>
  	    </Group>
  	    <Group spacing="xs" mb={10}>
	  	    <Button type="submit" size="sm" w={90} mt={10}>
	  	      Connect
	  	    </Button>
	  	    <Button type="submit" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
	  	      Cancel
	  	    </Button>
  	    </Group>
	    </div>
      <div style={{ display: activeId === "gocardless" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500}>GOCARDLESS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
  	    <Group spacing="xs" mb={10}>
  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10} mr={3}>Access Token:</Text>
  	      <Input w={250} ml={0}/>
  	    </Group>
  	    <Group spacing="xs" mb={10}>
  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Account Type:</Text>
  	      <Input w={250} ml={0}/>
  	    </Group>
  	    <Group spacing="xs" mb={10}>
	  	    <Button type="submit" size="sm" w={90} mt={10}>
	  	      Connect
	  	    </Button>
	  	    <Button type="submit" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
	  	      Cancel
	  	    </Button>
  	    </Group>
	    </div>
      <div style={{ display: activeId === "stripe" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500}>STRIPE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
	      <Text fw={500} mb={20}>The stripe API key is not required. But if you add it, you will get accuracy in the data.</Text>
  	    <Group spacing="xs" mb={10}>
  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Secret key:</Text>
  	      <Input w={250} ml={0}/>
  	    </Group>
  	    <Group spacing="xs" mb={10}>
	  	    <Button type="submit" size="sm" w={90} mt={10}>
	  	      Connect
	  	    </Button>
	  	    <Button type="submit" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
	  	      Cancel
	  	    </Button>
  	    </Group>
	    </div>
      <div style={{ display: activeId === "clickbank" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500}>CLICKBANK <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
  	    <Group spacing="xs" mb={10}>
  	    <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Secret Key:</Text>
  	      <Input w={250} ml={0}/>
  	    </Group>
  	    <Group spacing="xs" mb={10}>
	  	    <Button type="submit" size="sm" w={90} mt={10}>
	  	      Connect
	  	    </Button>
	  	    <Button type="submit" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
	  	      Cancel
	  	    </Button>
  	    </Group>
	    </div>
      <div style={{ display: activeId === "kajabi" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500}>KAJABI <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
  	    <Text size="sm" fz={14} fw={500} mb={10}>Kajabi does not pass revenue data via web hooks. Kajabi has only 2 payment processors. Paypal and Stripe. So, Instead we recommend you connect both Stripe and Paypal Processors to track conversions and revenue.</Text>
  	    <Group spacing="xs" mb={10}>
	  	    <Button type="submit" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
	  	      Cancel
	  	    </Button>
  	    </Group>
	    </div>
      <div style={{ display: activeId === "customcart" ? "block" : "none"}}>
	      <Group position="apart" mb="xs">
	        <Text weight={500}>CUSTOM <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
	      </Group>
  	    <Text size="sm" fz={14} fw={500} mb={10}>To use a custom shopping cart, you needs pass data in JSON format using POST method :</Text>
  	    <Text>{customTextPopup}</Text>
  	    <Group spacing="xs" mb={10}>
	  	    <Button type="submit" size="sm" w={120} mt={10}>
	  	      Continue
	  	    </Button>
	  	    <Button type="submit" style={{backgroundColor: "red"}} size="sm" w={80} mt={10} onClick={() => handleAction('cancel')}>
	  	      Cancel
	  	    </Button>
  	    </Group>
	    </div>
    </>
  );
}