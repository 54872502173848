// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DoubleNavbar_aside__DZWio {
  flex: 0 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid var(--mantine-color-gray-3);
}

.DoubleNavbar_mainLink__U9FIO {
  width: 44px;
  height: 44px;
  border-radius: var(--mantine-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mantine-color-gray-7);

  &:hover {
    background-color: var(--mantine-color-gray-0);
  }

  &[data-active] {
    &,
    &:hover {
      background-color: var(--mantine-color-blue-light);
      color: var(--mantine-color-blue-light-color);
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/DoubleNavbar.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mDAAmD;AACrD;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,uCAAuC;EACvC,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kCAAkC;;EAElC;IACE,6CAA6C;EAC/C;;EAEA;IACE;;MAEE,iDAAiD;MACjD,4CAA4C;IAC9C;EACF;AACF","sourcesContent":[".aside {\n  flex: 0 0 60px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  border-right: 1px solid var(--mantine-color-gray-3);\n}\n\n.mainLink {\n  width: 44px;\n  height: 44px;\n  border-radius: var(--mantine-radius-md);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: var(--mantine-color-gray-7);\n\n  &:hover {\n    background-color: var(--mantine-color-gray-0);\n  }\n\n  &[data-active] {\n    &,\n    &:hover {\n      background-color: var(--mantine-color-blue-light);\n      color: var(--mantine-color-blue-light-color);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aside": `DoubleNavbar_aside__DZWio`,
	"mainLink": `DoubleNavbar_mainLink__U9FIO`
};
export default ___CSS_LOADER_EXPORT___;
