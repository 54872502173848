import {
  PasswordInput,
  Paper,
  Text,
  Container,
  Button,
  Center,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';
import bigLogo from '../../img/big-logo.png';
import { notifications } from '@mantine/notifications';
import {
  IconX,
  IconCheck
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import LoginLogoutApi from "../../api/LoginLogoutApi";

export const ResetPassword = () => {

  useDocumentTitle("Visiopt - Reset Password");

  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      password: '',
      confirm_password: ''
    },

    validate: {
      password: (val) => (val === '' ? 'Invalid Password' : null),
      confirm_password: (val) => (val === '' ? 'Invalid Password' : null)
    },
  });

  const handleResetPasswordSubmit = async (formData:any) => {
    try {
      const queryParameters = new URLSearchParams(window.location.search);
      const ResetPasswordAPIResponse = await LoginLogoutApi.resetpassword({
        password : formData.password,
        confirm_password : formData.confirm_password,
        token : queryParameters.get("token")
      })
      .then(ResetPasswordAPIResponse => ResetPasswordAPIResponse.text())
      .then(ResetPasswordAPIResult => {
        const ResetPasswordAPIResultJSON = JSON.parse(ResetPasswordAPIResult);
        if(ResetPasswordAPIResultJSON.status === 1){
          notifications.show({
            color: 'teal',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: ResetPasswordAPIResultJSON.message,
          });
          navigate('/login');
        }else{
          notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            title: 'Error',
            message: ResetPasswordAPIResultJSON.message,
          })
        }
      })
      .catch(error => console.log('error', error));
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <Container size={800} my={40}>
      <Paper p={30} mt={30} radius="8px" style={{ border : '1px solid #dee2e6' }}>
        <Paper w="60%" mx="auto">
          <Center>
            <img src={bigLogo} alt="Company Logo"/>
          </Center>
          <Text mt={10} mb={10}>Please enter the new password that you can use to login.</Text>
          <form onSubmit={form.onSubmit((formData) => { handleResetPasswordSubmit(formData); })}>
            <PasswordInput placeholder="Password" mt="md" value={form.values.password} onChange={(event) => form.setFieldValue('password', event.currentTarget.value)} error={form.errors.password && 'Password Required'}/>
            <PasswordInput placeholder="Confirm Password" mt="md" value={form.values.confirm_password} onChange={(event) => form.setFieldValue('confirm_password', event.currentTarget.value)} error={form.errors.confirm_password && 'Confirm Password Required'}/>
            <Button fullWidth mt="xl" type="submit">
              Send
            </Button>
          </form>
        </Paper>
      </Paper>
    </Container>
  );
}