import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useDocumentTitle } from '@mantine/hooks';
import { HeaderMenu } from "../../components/HeaderMenu/HeaderMenu";
import { Modal, Skeleton, Container, Card, Group, Text, Select, Button, Input, Space, Grid, Pagination, Table, ActionIcon, Radio, Box, MultiSelect, Anchor, Menu, CopyButton, Divider, Slider, Checkbox, Textarea, Tooltip } from '@mantine/core';
import { IconHelp, IconClockHour4, IconSettings, IconTrash, IconRefresh, IconPlayerPause, IconInfoCircle, IconPencil, IconPlayerPlay, IconPlus, IconCircleMinus, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import './ReviewingTest.css';

import { modals } from '@mantine/modals';
import { Chart } from "react-google-charts";
import { SelectTimePicker } from "../../components/GeneralComponent/GeneralComponent";

import { TextEditorToolbar } from '../../components/GeneralComponent/TextEditorToolbar';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import PermissionWrapper from '../../PermissionWrapper';
import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { VisiLoader } from "../../components/GeneralComponent/GeneralComponent";
import TestsApi from "../../api/TestsApi";

export function ReviewingTest() {

  const navigate = useNavigate();
	useDocumentTitle("Visiopt - Reviewing Test");

	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

	const handleHeaderMenuComponentChange = (Value:any) => {
	  setSelectedMemberWebsite(Value);
	};

	const testId = '1';

  const location = useLocation();
  const { pageId, taguchiTestId, funnelId, testsId } = location.state || {};
  const [SystemUsageLoader, setSystemUsageLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorModelOpen, seterrorCloseModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [showMessageHeader, setShowMessageHeader] = useState(false);

  const [audienceSizeModalOpen, setAudienceSizeModalOpen] = useState(false);
  const [audienceSizeOptions, setAudienceSizeOptions] = useState([]);
  const [selectedAudienceSize, setSelectedAudienceSize] = useState('');
  const [audienceSizeInput, setAudienceSizeInput] = useState('');

  const [trafficControlModalOpen, setTrafficControlModalOpen] = useState(false);
  const [valueTrafficControl, setValueTrafficControl] = useState(10);
  const [trafficControlValues, setTrafficControlValues] = useState([]);

  const [testNotesModalOpen, setTestNotesModalOpen] = useState(false);
  const [noteTitle, setNoteTitle] = useState('');
  const [noteContent, setNoteContent] = useState('');

  const [testInfoModalOpen, setTestInfoModalOpen] = useState(false);
  const [testInfoPageLink, setTestInfoPageLink] = useState('');
  const [testInfoPageTitle, setTestInfoPageTitle] = useState('');
  const [testInfoTestTime, setTestInfoTestTime] = useState('');
  const [testInfoTestType, setTestInfoTestType] = useState('');
  const [testInfoVisioptTestId, setTestInfoVisioptTestId] = useState('');

  const [shoppingCartProcessorModalOpen, setShoppingCartProcessorModalOpen] = useState(false);
  const [shoppingCartProcessorList, setShoppingCartProcessorList] = useState(true);
  const [convRedioTypeBtn, setConvRedioTypeBtn] = useState('1');
  const [testShoppingCartProcessorWebhookData, settestShoppingCartProcessorWebhookData] = useState([]);
  const [testShoppingCartProcessorShoppingData, settestShoppingCartProcessorShoppingData] = useState([]);
  const [testShoppingCartProcessorFunnelsGoals, settestShoppingCartProcessorFunnelsGoals] = useState([]);
  const [testShoppingCartProcessorPaymentProcessor, settestShoppingCartProcessorPaymentProcessor] = useState([]);
  const [selectedWebhookURL, setSelectedWebhookURL] = useState('');
  const [selectedSecretKey, setSelectedSecretKey] = useState('');
  const [selectedWebhookId, setSelectedWebhookId] = useState('');
  const [selectedPaymentType, setSelectedPaymentType] = useState('');
  const [selectedShoppingCart, setSelectedShoppingCart] = useState('');
  const [selectedShoppingCartName, setSelectedShoppingCartName] = useState('');
  const [selectedPaymentProcessor, setSelectedPaymentProcessor] = useState('');
  const [selectedPaymentProcessorName, setSelectedPaymentProcessorName] = useState('');
  const [selectedPage, setSelectedPage] = useState('');
  const [selectedProcessor, setSelectedProcessor] = useState('');
  const [selectChange, setSelectChange] = useState('');

  const [googleAnalyticsIntegrationModalOpen, setGoogleAnalyticsIntegrationModalOpen] = useState(false);
  const [gaAccountPrefix, setGaAccountPrefix] = useState('');
  const [gaDimension, setGaDimension] = useState('');
  const [taguchiTypeId, setTaguchiTypeId] = useState('');
  const [testDimIndex, setTestDimIndex] = useState('');
  const [testDimName, setTestDimName] = useState('');
  const [, setTestName] = useState('');
  const [varDimIndex, setVarDimIndex] = useState('');
  const [varDimName, setVarDimName] = useState('');
  const [, setVariationNumber] = useState('');
  const [visiTestName, setVisiTestName] = useState('');
  const [isCheckboxTestNameChecked, setIsCheckboxTestNameChecked] = useState(false);
  const [isCheckboxVariationNumberChecked, setIsCheckboxVariationNumberChecked] = useState(false);

  const [, setIsTestPaused] = useState(false);
  const [testPauseResume, setTestPauseResume] = useState('');
  const [testPauseResumeTitle, setTestPauseResumeTitle] = useState('Pause Test');
  const [testPauseResumeIcon, setTestPauseResumeIcon] = useState(<IconPlayerPause size="1.1rem" />);

  const [, setTestModes] = useState('');
  
  const [attributionModalOpen, setAttributionModalOpen] = useState(false);
  const [attributionModalOptions, setAttributionModalOptions] = useState([]);
  const [selectedAttributionModal, setSelectedAttributionModal] = useState('');

  const [visiSmartCodeModalOpen, setVisiSmartCodeModalOpen] = useState(false);
  const [visiSmartAdvancedOptions, setVisiSmartAdvancedOptions] = useState('Show');
  const [hideAdvancedOptions, setHideAdvancedOptions] = useState('');
  const [downloadCookiesUrl, setDownloadCookiesUrl] = useState('');
  const [testingCode, setTestingCode] = useState([]);

  const [convRedioBtn, setConvRedioBtn] = useState('1');
  const [convRadioFunnelsGoals, setConvRadioFunnelsGoals] = useState<any[]>([]);
  const [convRadioPostbackOptions, setConvRadioPostbackOptions] = useState<any[]>([]);
  const [convRadioButtonOptions, setConvRadioButtonOptions] = useState<any[]>([]);
  const [funnelsGoals, setFunnelsGoals] = useState('');
  const [selectedButton, setSelectedButton] = useState('');
  const [postbackOptions, setPostbackOptions] = useState('');

  const [conversionLogsModalOpen, setConversionLogsModalOpen] = useState(false);
  const [conversionLogsConversionType, setConversionLogsConversionType] = useState([]);
  const [conversionLogsGoalList, setConversionLogsGoalList] = useState([]);
  const [conversionLogsPostbackList, setConversionLogsPostbackList] = useState([]);
  const [conversionType, setConversionType] = useState('');
  const [conversionTypeName, setConversionTypeName] = useState('');
  const [goalList, setGoalList] = useState('');
  const [goalListName, setGoalListName] = useState('');
  const [postbackList, setPostbackList] = useState('');
  const [, setPostbackListName] = useState('');
  const [convLogRedioBtn, setConvLogRedioBtn] = useState('goal');
  const [filterConversionLog, setFilterConversionLog] = useState('no');
  const [undefinedConversionTableField, setUndefinedConversionTableField] = useState<string[]>([]);
  const [undefinedConversionTableData, setUndefinedConversionTableData] = useState<any[]>([]);
  const [conversionTypeStatus, setConversionTypeStatus] = useState('');
  const [definedConversionTableField, setDefinedConversionTableField] = useState<string[]>([]);
  const [definedConversionTableData, setDefinedConversionTableData] = useState<any[]>([]);
  const [resetConversionTableField, setResetConversionTableField] = useState<string[]>([]);
  const [resetConversionTableData, setResetConversionTableData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(15);
  const [activePageNo, setActivePageNo] = useState(1);
  const [totalPaginationNo, setTotalPaginationNo] = useState(1);

  const [selectedTimePicker, setSelectedTimePicker] = useState('');
	const [selectedTimePickerToDate, setSelectedTimePickerToDate] = useState('');
	const [selectedTimePickerFromDate, setSelectedTimePickerFromDate] = useState('');

  const [behavioralFilterModalOpen, setBehavioralFilterModalOpen] = useState(false);
  const [behavioralFilterFirstDropdown, setBehavioralFilterFirstDropdown] = useState([]);
  const [behavioralFilterSecondDropdown, setBehavioralFilterSecondDropdown] = useState({});
  const [behavioralFilterThirdDropdown, setBehavioralFilterThirdDropdown] = useState({});
  const [firstDropdown, setFirstDropdown] = useState('');
  const [, setSecondDropdown] = useState('');
  const [, setThirdDropdown] = useState('');
  const [showFirstDropdown, setShowFirstDropdown] = useState(false);
  const [showSecondDropdown, setShowSecondDropdown] = useState({});
  const [showThirdDropdown, setShowThirdDropdown] = useState({});
  const [dropdownSets, setDropdownSets] = useState([]);

  const [variationsBtn, setVariationsBtn] = useState('1');
  const [variablesBtn, setVariablesBtn] = useState('0');
  const [variablesMessageShow, setVariablesMessageShow] = useState('');
  const [variablesOptimumResponse, setVariablesOptimumResponse] = useState('');
  const [variablesVariablesTab, setVariablesVariablesTab] = useState([]);

  const [behavioralDimensionTrackingTags, setBehavioralDimensionTrackingTags] = useState({});
  const [behavioralDimensionNetworkParameters, setBehavioralDimensionNetworkParameters] = useState({});
  const [behavioralDimensionCustomTags, setBehavioralDimensionCustomTags] = useState({});
  const [behavioralDimensionSource, setBehavioralDimensionSource] = useState([]);
  const [displayFilterButton, setDisplayFilterButton] = useState(0);

  const [setWinnerModalOpen, setSetWinnerModalOpen] = useState(false);
  const [setWinnerData, setSetWinnerData] = useState([]);
  const [varIdshow, setVarId] = useState('');

  const [editingVarId, setEditingVarId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [editClickVarId, setEditClickVarId] = useState(null);
  const [editClickVerId, setEditClickVerId] = useState(null);

  const [behavioralFilterArrCount, setBehavioralFilterArrCount] = useState(0);
  const [testModeShow, setTestModeShow] = useState(0);
  const [webhookSetShow, setWebhookSetShow] = useState(0);
  const [hidePauseShow, setHidePauseShow] = useState('');
  const [testNameShow, setTestNameShow] = useState('');
  const [pageIdShow, setPageIdShow] = useState('');
  const [tagichiTypeIdShow, setTagichiTypeIdShow] = useState('');
  const [testPageLinkShow, setTestPageLinkShow] = useState('');
  const [testPageTitleShow, setTestPageTitleShow] = useState('');
  const [totalUniquesShow, setTotalUniquesShow] = useState('');
  const [totalVisitsShow, setTotalVisitsShow] = useState('');
  const [totalConversionsShow, setTotalConversionsShow] = useState('');
  const [testVariationsData, setTestVariationsData] = useState([]);
  const [dataColumnChart, setDataColumnChart] = useState([]);
  const [dataColumnPercentageChart, setDataColumnPercentageChart] = useState([]);
  const [pageBottomMsgShow, setPageBottomMsgShow] = useState('');
  const [selectedValue, setSelectedValue] = useState('1');
  const [testTypeShow, setTestTypeShow] = useState('');
  const [variablesTabShow, setVariablesTabShow] = useState('');
  const [testsIdShow, setTestsIdShow] = useState('');
  const [testStartDate, setTestStartDate] = useState('');
  const [conversionTypeShow, setConversionTypeShow] = useState('');
  const [conversionTypeValueSelected, setConversionTypeValueSelected] = useState('');
  const [convtopageNameShow, setConvtopageNameShow] = useState('');
  const [marksalestime, setMarksalestime] = useState(0);

  const [resetStatsValue, setResetStatsValue] = useState<string>('');
  const [resetStatsModalIsOpen, setResetStatsModalIsOpen] = useState(false);
  const [error, setError] = useState('');
/* TIME PICKER AND DATE PICKER  */
  const handleselectedTimePickerComponentChange = (Value:any) => {
    setSelectedTimePicker(Value);
  }

  useEffect(() => {
    if (selectedTimePicker !== null) {
      TestOptimizeReport();
    }
  }, [selectedTimePicker]); 

  const handleselectedTimePickerToDateChange = (Value:any) => {
    setSelectedTimePickerToDate(Value);
  }

  const handleselectedTimePickerFromDateChange = (Value:any) => {
    setSelectedTimePickerFromDate(Value);
  }

  useEffect(() => {
    if (selectedTimePickerFromDate && selectedTimePickerToDate) {
      TestOptimizeReport();
    }
  }, [selectedTimePickerFromDate, selectedTimePickerToDate]); 
/* END TIME PICKER AND DATE PICKER  */

/* AUDIENCE SIZE */
  const handleAudienceSizeOpenModal = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    }
    TestsApi.getaudiancesize({
      page_id:pageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setAudienceSizeModalOpen(true);
        setAudienceSizeOptions(data.data);
        const defaultSelectedOption = data.data.find(option => option.selected === "true");
        if (defaultSelectedOption) {
          setSelectedAudienceSize(defaultSelectedOption.option_value);
          if (defaultSelectedOption.option_name === "Please Select") {
            setAudienceSizeInput(defaultSelectedOption.option_value);
          }
        } else {
          setSelectedAudienceSize('');
        }
      } else {
        setErrorMessage(data.message);
        setShowErrorHeader(true);
        seterrorCloseModal(true);
        setShowMessageHeader(false);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleAudienceSizeCloseModal = () => {
    setAudienceSizeModalOpen(false);
  };

  const handleAudienceSizeChange = (value) => {
    const selectedOption = audienceSizeOptions.find(option => option.option_value === value);
    if (selectedOption.option_name === "Please Select") {
      setSelectedAudienceSize(value);
      setAudienceSizeInput(value);
    } else {
      setSelectedAudienceSize(selectedOption.option_value);
      setAudienceSizeInput('');
    }
  };



  const handleSaveAudienceSize = (e) => {
    e.preventDefault();
    const audienceSizeToSave = audienceSizeOptions.find(option => option.option_value === selectedAudienceSize)?.option_name === "Please Select"
    ? audienceSizeInput
    : selectedAudienceSize;
    TestsApi.saveaudiancesizenum({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      winner_num_un:audienceSizeToSave
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        setMarksalestime(data.data.updated_time);
        handleAudienceSizeCloseModal();
        TestOptimizeReport();
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }
/* END AUDIENCE SIZE */

/* TRAFFIC CONTROL */
  const handleTrafficControlOpenModal = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    }
    TestsApi.gettrafficcontrol({
      page_id:pageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        const transformedData = data.data.test_row_data.map(test => ({
          variableId: test.test_id,
          value: parseFloat(test.test_value),
        }));
        const controlValue = Math.max(10, Math.min(90, parseFloat(data.data.control)));
        setValueTrafficControl(controlValue);
        setTrafficControlValues(transformedData);
        setTrafficControlModalOpen(true);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleTrafficControlResult = useCallback(() => {
    const remainingTraffic = 100 - valueTrafficControl;
    const newValues = trafficControlValues.map((item) => ({
      ...item,
      value: parseFloat((remainingTraffic / trafficControlValues.length).toFixed(2)),
    }));
    setTrafficControlValues(newValues);
  }, [valueTrafficControl, trafficControlValues.length,trafficControlValues ]);

  useEffect(() => {
    const updateValuesOnMouseUp = () => {
      handleTrafficControlResult();
    };
    window.addEventListener('mouseup', updateValuesOnMouseUp);
    return () => {
      window.removeEventListener('mouseup', updateValuesOnMouseUp);
    };
  }, [handleTrafficControlResult]);

  const handleSaveTrafficControl = (e) => {
    e.preventDefault();
    TestsApi.savetrafficcontrol({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      control:valueTrafficControl
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleTrafficControlCloseModal();
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }
  
  const handleTrafficControlCloseModal = () => {
    setTrafficControlModalOpen(false);
  };
/* END TRAFFIC CONTROL */

/* TEST NOTES */
  const handleTestNotesOpenModal = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    } else {
      TestsApi.gettestnote({
        page_id:pageId,
        taghchi_test_id:taguchiTestId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setNoteTitle(data.data.tests_note_title);
          setNoteContent(data.data.tests_note);
          setTestNotesModalOpen(true);
        } else {
          setErrorMessage(data.message);
          seterrorCloseModal(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  };

  const testnotesEditor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      Color,
      TextStyle,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: '',
    onUpdate: ({ editor }) => {
      setNoteContent(editor.getHTML());
    },
  });

  const saveSelection = (editor) => {
	  const { from, to } = editor.state.selection;
	  return { from, to };
	};

  const restoreSelection = (editor, selection) => {
	  editor.commands.setTextSelection(selection);
	};

	useEffect(() => {
	  if (testnotesEditor && noteContent !== '') {
	    const selection = saveSelection(testnotesEditor);
	    testnotesEditor.commands.setContent(noteContent, true);
	    restoreSelection(testnotesEditor, selection);
	  }
	}, [noteContent, testnotesEditor]);

  const handleSaveTestNotes = (e) => {
  e.preventDefault();
  TestsApi.savetestsnote({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      tests_note_title:noteTitle,
      tests_note:noteContent
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleTestNotesCloseModal();
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const handleTestNotesCloseModal = () => {
    setTestNotesModalOpen(false);
  };
/* END TEST NOTES */

/* RESET STATS */
  const handleResetStatsTest = (pageIdShow: any) => {
    if (resetStatsValue.trim() === 'RESET') {
      TestsApi.testresetstats({
        system_website_id: selectedMemberWebsite,
        page_id: pageIdShow,
        taguchi_id: taguchiTestId
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setResetStatsModalIsOpen(false);
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
          setMarksalestime(data.data.updated_time);
          TestOptimizeReport();
          setResetStatsValue('');
          setError('');
        } else {
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setShowErrorHeader(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    } else {
      setError('Word does not match.');
    }
  };
/* END RESET STATS */

/* TEST INFORMATION */
  const handleTestInfoOpenModal = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    }
    TestsApi.gettestinfo({
      page_id:pageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setTestInfoPageLink(data.data.page_link);
        setTestInfoPageTitle(data.data.page_title);
        setTestInfoTestTime(data.data.test_time);
        setTestInfoTestType(data.data.test_type);
        setTestInfoVisioptTestId(data.data.visiopt_test_id);
        setTestInfoModalOpen(true);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleTestInfoCloseModal = () => {
    setTestInfoModalOpen(false);
  };
/* END TEST INFORMATION */

/* CONNECT SHOPPING CART & PROCESSOR (OPTIONAL) */
  const handleShoppingCartProcessorOpenModal = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    }
    TestsApi.getconnectshoppingcartandprocessor({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      funnel_id:funnelId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        settestShoppingCartProcessorWebhookData(data.data.webhook_list);
        settestShoppingCartProcessorShoppingData(data.data.shopping_cart);
        settestShoppingCartProcessorFunnelsGoals(data.data.funnels_goals);
        settestShoppingCartProcessorPaymentProcessor(data.data.payment_processor);
        setShoppingCartProcessorModalOpen(true);
        setShoppingCartProcessorList(true);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const testShoppingCartProcessorRows = testShoppingCartProcessorWebhookData.map((element) => (
    <tr key={element.webhook_id} style={{ cursor: 'pointer' }}>
      <td>{element.processor}</td>
      <td>{element.page_name}</td>
      <td>{element.webhook_url}</td>
      <td>
        <ActionIcon
          variant="default"
          size="md"
          onClick={() => EditWebhook(element.webhook_id)}
        >
          <IconPencil size="1.1rem" />
        </ActionIcon>
      </td>
      <td>
        <ActionIcon
          variant="default"
          size="md"
          onClick={() => deleteWebhookConfirm(element.webhook_id)}
        >
          <IconTrash size="1.1rem" />
        </ActionIcon>
      </td>
    </tr>
  ));

  const shoppingCartData = [
    { value: '', label: 'Select Shopping Cart' },
    ...testShoppingCartProcessorShoppingData.map((ShoppingData) => ({
      value: ShoppingData.shopping_cart_id,
      label: ShoppingData.shopping_cart_name,
    })),
  ];

  const paymentProcessorData = [
    { value: '', label: 'Select Payment Processor' },
    ...testShoppingCartProcessorPaymentProcessor.map((PaymentProcessor) => ({
      value: PaymentProcessor.payment_processor_id,
      label: PaymentProcessor.payment_processor_name,
    })),
  ];

  const pageData = [
    { value: '', label: 'Select a Page' },
    ...testShoppingCartProcessorFunnelsGoals.map((FunnelsGoals) => ({
      value: FunnelsGoals.page_id,
      label: FunnelsGoals.page_name,
    })),
  ];

  const selectData = convRedioTypeBtn === '1' ? shoppingCartData : paymentProcessorData;
  const labelText = convRedioTypeBtn === '1' ? 'Select a Shopping Cart' : 'Select Payment Processor';

  const handleShoppingCartProcessorCloseModal = () => {
    setSelectedWebhookURL('');
    setSelectedSecretKey('');
    setSelectedPage('');
    setConvRedioTypeBtn('1');
    setSelectedPaymentProcessor('');
    setSelectedShoppingCart('');
    setSelectedWebhookId('');
    setShoppingCartProcessorModalOpen(false);
    setShoppingCartProcessorList(true);
  };

  const AddWebhook  = () => {
    setShoppingCartProcessorList(false);
  };

  const handleSelectChange = (value) => {
  	setSelectChange(value);
  	const selectedOption = convRedioTypeBtn === '1' ? shoppingCartData.find(option => option.value === value) : paymentProcessorData.find(option => option.value === value);
	  const label = selectedOption ? selectedOption.label : '';
	  setSelectedProcessor(label);
	  if (convRedioTypeBtn === '1') {
	    setSelectedShoppingCart(value);
	    setSelectedShoppingCartName(label);
	    setSelectedPage('');
			setSelectedWebhookURL('');
      setSelectedSecretKey('');
	  } else if (convRedioTypeBtn === '2') {
	    setSelectedPaymentProcessor(value);
	    setSelectedPaymentProcessorName(label);
	    setSelectedPage('');
			setSelectedWebhookURL('');
      setSelectedSecretKey('');
	  }
	};

	const handleConvRedioTypeBtn = (value) => {
		setConvRedioTypeBtn(value);
		setSelectedPage('');
		setSelectedWebhookURL('');
    setSelectedSecretKey('');
	};

	const handleSelectPageChange = (value) => {
	  const selectedsOption = selectData.find(option => option.value === selectChange);
	  const label = selectedsOption ? selectedsOption.label : '';
	  setSelectedPage(value);
	  GetWebhookUrl(value,selectedProcessor);
	};

  const GetWebhookUrl = (selectedPage,processor) => {
  	TestsApi.getwebhookurl({
      system_website_id: selectedMemberWebsite,
      page_id:selectedPage,
      processor:selectedProcessor
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setSelectedWebhookURL(data.data.webhook_url);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  useEffect(() => {
    if (convRedioTypeBtn === '1') {
      setSelectedPaymentType('shopping_cart');
    } else if (convRedioTypeBtn === '2') {
      setSelectedPaymentType('payment_processor');
    }
  }, [convRedioTypeBtn]);

  const isShoppingCart = convRedioTypeBtn === '1';
  const isPaymentProcessor = convRedioTypeBtn === '2';

  const handleAddPaymentProcessorCart = (e) => {
	  e.preventDefault();
	  TestsApi.addpaymentprocessorcart({
	    system_website_id: selectedMemberWebsite,
	    page_id:selectedPage,
	    tests_id:testsId,
	    webhook_id:selectedWebhookId,
	    payment_type:selectedPaymentType,
	    shopping_cart: isShoppingCart ? selectedShoppingCart : '',
	    shopping_cart_text: isShoppingCart ? selectedShoppingCartName : '',
	    shopping_webhook_url: isShoppingCart ? selectedWebhookURL : '',
	    payment_processor: isPaymentProcessor ? selectedPaymentProcessor : '',
	    payment_processor_text: isPaymentProcessor ? selectedPaymentProcessorName : '',
	    payment_webhook_url: isPaymentProcessor ? selectedWebhookURL : '',
      secret_key: selectedSecretKey ? selectedSecretKey : '',
	  })
	  .then(response => {
	    if (response.ok) {
	      return response.json();
	    } else {
	      if (response.status === 401 || response.status === 400) {
	        navigate("/logout");
	      }
	    }
	  })
	  .then(data => {
	    if(data.status === 1){
	      notifications.show({
	        color: 'green',
	        icon: <IconCheck size="1.1rem" />,
	        title: 'Success',
	        message: data.message,
	      });
        setMarksalestime(data.data.updated_time);
	      handleShoppingCartProcessorCloseModal();
	    } else {
	      setErrorMessage(data.message);
	      seterrorCloseModal(true);
	      setShowErrorHeader(true);
	    }
	  })
	  .catch(error => {
	    console.error("API Error:", error);
	  });
  }

  const EditWebhook = (webhookId) => {
  	setSelectedWebhookId(webhookId);
    TestsApi.getsinglewebhookprocessordetail({
      webhook_id:webhookId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
	      if (data.data.payment_type === 'payment_processor') {
	        setSelectedPaymentProcessor(data.data.cart_type);
	      } else if (data.data.payment_type === 'shopping_cart') {
	        setSelectedShoppingCart(data.data.cart_type);
	      }
	      setSelectedProcessor(data.data.processor);
	      setSelectedPage(data.data.page_id);
        setSelectedWebhookURL(data.data.webhook_url);
        setSelectedSecretKey(data.data.secret_key);
	      setConvRedioTypeBtn(data.data.payment_type === 'payment_processor' ? '2' : '1');
      	setShoppingCartProcessorList(false);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const deleteWebhookConfirm = async (webhookId:any) => {
    try {
      const APIResponse = await TestsApi.validatedeletepaymentprocessorcart({
        tests_id: testsId,
        webhook_id : webhookId
      });
       const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
        modals.openConfirmModal({
        title: 'Delete Webhook',
        children: (
          <Text size="sm">
            {APIResultJSON.data.msg}
          </Text>
        ),
        labels: { confirm: 'Delete the Webhook', cancel: "No don't delete it" },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          handleDeleteWebhook(webhookId);
        },
      });
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  const handleDeleteWebhook = async (webhookId:any) =>{
    try {
      const APIResponse = await TestsApi.deletepaymentprocessorcart({
        webhook_id : webhookId
      });
       const APIResultJSON = await APIResponse.json();
      if (APIResultJSON.status === 1) {
      	notifications.show({
	        color: 'green',
	        icon: <IconCheck size="1.1rem" />,
	        title: 'Success',
	        message: APIResultJSON.message,
	      });
        handleShoppingCartProcessorCloseModal();
      }else{
        setErrorMessage(APIResultJSON.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
/* END CONNECT SHOPPING CART & PROCESSOR (OPTIONAL) */ 

/* GOOGLE ANALYTICS */
  const handleGoogleAnalyticsIntegrationOpenModal = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    }
    TestsApi.getgoogleanalytics({
      system_website_id: selectedMemberWebsite,
      page_id:pageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setGaAccountPrefix(data.data.ga_account_prefix);
        setGaDimension(data.data.ga_dimension);
        setTaguchiTypeId(data.data.taguchi_type_id);
        setTestDimIndex(data.data.test_dim_index);
        setTestDimName(data.data.test_dim_name);
        setVarDimIndex(data.data.var_dim_index);
        setVarDimName(data.data.var_dim_name);
        setVisiTestName(data.data.visi_test_name);
        setTestName(data.data.test_name === "checked" ? "checked" : "");
        setIsCheckboxTestNameChecked(data.data.test_name === "checked");
        setVariationNumber(data.data.variation_number === "checked" ? "checked" : "");
        setIsCheckboxVariationNumberChecked(data.data.variation_number === "checked");
        setGoogleAnalyticsIntegrationModalOpen(true);
      } else {
        setErrorMessage(data.message);
        setShowErrorHeader(true);
        seterrorCloseModal(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleCheckboxChange = (event, checkboxType) => {
    const isChecked = event.target.checked;

    if (checkboxType === 'testName') {
      setIsCheckboxTestNameChecked(isChecked);
      setTestName(isChecked ? "checked" : "");
    } else if (checkboxType === 'variationNumber') {
      setIsCheckboxVariationNumberChecked(isChecked);
      setVariationNumber(isChecked ? "checked" : "");
    }
  };

  const handleSaveGoogleAnalytics = (e) => {
    e.preventDefault();
    TestsApi.savegoogleanalytics({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      taguchi_type_id:taguchiTypeId,
      visi_test_name:visiTestName,
      test_name:isCheckboxTestNameChecked ? 1 : 0,
      test_dim_name:testDimName,
      test_dim_index:testDimIndex,
      variation_number:isCheckboxVariationNumberChecked ? 1 : 0,
      var_dim_name:varDimName,
      var_dim_index:varDimIndex
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleGoogleAnalyticsIntegrationCloseModal();
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const handleGoogleAnalyticsIntegrationCloseModal = () => {
    setGoogleAnalyticsIntegrationModalOpen(false);
  };
/* END GOOGLE ANALYTICS */

/* PAUSE RESUME TEST*/
  const handlePauseResumeTest = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    }
    setSystemUsageLoader(true);
    TestsApi.pauseresumetest({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      taguchi_id:taguchiTestId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setSystemUsageLoader(false);
      	const newStatus = data.data.teststatus;
        setIsTestPaused(newStatus === 'pause');
        setTestPauseResume(newStatus);
        setTestPauseResumeTitle(newStatus === 'pause' ? 'Resume Test' : 'Pause Test');
        setTestPauseResumeIcon(
          newStatus === 'pause' ? (
            <IconPlayerPlay size="1.1rem" />
          ) : (
            <IconPlayerPause size="1.1rem" />
          )
        );
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
        setSystemUsageLoader(false);
      }
    })
    .catch(error => {
      setSystemUsageLoader(false);
      console.error("API Error:", error);
    });
  };
/* END PAUSE RESUME TEST*/

/* TEST MODE*/
  const handleTestMode = () => {
    setSystemUsageLoader(true);
  	TestsApi.savetestmode({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      taguchi_type_id:taguchiTestId,
      testmode:testModeShow
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setSystemUsageLoader(false);
        setTestModes(data.data.testmode);
        setMarksalestime(data.data.updated_time);
        TestOptimizeReport();
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
        setSystemUsageLoader(false);
      }
    })
    .catch(error => {
      setSystemUsageLoader(false);
      console.error("API Error:", error);
    });
  };
/* END TEST MODE*/

/* ATTRIBUTION MODEL*/
  const handleAttributionModelOpenModal = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    }
    TestsApi.getattributionmodel({
      page_id:pageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        const options = data.data.map(item => ({
          value: item.attribution_name,
          label: item.attribution_name
        }));
        setAttributionModalOptions(options);
        const selectedOptions = data.data.find(item => item.selected === "true");
        if (selectedOptions) {
          setSelectedAttributionModal(selectedOptions.attribution_name);
        }
        setAttributionModalOpen(true);
      } else {
        setErrorMessage(data.message);
        setShowErrorHeader(true);
        seterrorCloseModal(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };
  
  const handleSaveAttributionModel = (e) => {
  e.preventDefault();
  TestsApi.saveattributionmodel({
      page_id:pageId,
      attribution_name:selectedAttributionModal
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setMarksalestime(data.data.updated_time);
        TestOptimizeReport();
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleAttributionModelCloseModal();
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  }

  const handleAttributionModelCloseModal = () => {
    setAttributionModalOpen(false);
  };
/* END ATTRIBUTION MODEL*/

/* VISISMART CODE*/
  const handleVisiSmartCodeOpenModal = (value:any) => {
    if (!pageId) {
      navigate('/review-tests');
      return;
    }
    TestsApi.getvisismarttestingcode({
      system_website_id: selectedMemberWebsite,
      page_id:pageId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setTestingCode(data.data.testing_code);
        setDownloadCookiesUrl(data.data.download_cookies_url);
        setHideAdvancedOptions(data.data.hide_advanced_options);
        setVisiSmartCodeModalOpen(true);
      } else {
        setErrorMessage(data.message);
        setShowErrorHeader(true);
        seterrorCloseModal(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };
  
  const handleAdvancedOptionsVisiSmart = (value:any) => {
    if(visiSmartAdvancedOptions === 'Show'){
      setVisiSmartAdvancedOptions('Hide');
    } else {
      setVisiSmartAdvancedOptions('Show');
    }
  };

  const hideAdvancedOptionsVisiSmartCode = [
	  { id: 1, title:'Javascript',smartCode: "<script type='text/javascript'>var vtss = setInterval(function(){if (typeof _kx_tid != 'undefined') {console.log('_kx_tid: '+_kx_tid);/*Here will get the variation no in JS variable _kx_tid */clearInterval(vtss);}}, 50);</script>"},
	  { id: 2, title:'Javascript',smartCode: "<script type='text/javascript'>function getVisiCookie(name) {var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');return v ? v[2] : null;}var v_kx_tid = getVisiCookie('_kx_tid');/*Here will get the variation no in JS variable v_kx_tid */console.log(v_kx_tid);</script>"},
	  { id: 3, title:'PHP',smartCode: "<?php if (isset($_COOKIE) && isset($_COOKIE['_kx_tid'])) {$v_kx_tid = $_COOKIE['_kx_tid'];/*Here will get the variation no in PHP variable $v_kx_tid */}?>"},
	  { id: 4, title:'Javascript',smartCode: "<script type='text/javascript'>function getVisiCookie2(name) {var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');return v ? v[2] : null;}var visiopt_test_name = getVisiCookie2('visiopt_test_name');/*Here will get the variation no in JS variable visiopt_test_name */console.log(visiopt_test_name);</script>"},
	  { id: 5, title:'PHP',smartCode: "<?php if (isset($_COOKIE) && isset($_COOKIE['visiopt_test_name'])) {$visiopt_test_name = $_COOKIE['visiopt_test_name'];/*Here will get the Test name in PHP variable $visiopt_test_name */}?>"},
	  { id: 6, title:'Javascript',smartCode: "<script type='text/javascript'>function getVisiCookie2(name) {var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');return v ? v[2] : null;}var v_kx_var_name = getVisiCookie2('_kx_var_name');/*Here will get the Variation name in JS variable v_kx_var_name */console.log(v_kx_var_name);</script>"},
	  { id: 7, title:'PHP',smartCode: "<?php if (isset($_COOKIE) && isset($_COOKIE['_kx_var_name'])) {$v_kx_var_name = $_COOKIE['_kx_var_name'];/*Here will get the Variation name in PHP variable $v_kx_var_name */}?>"},
	];

  const handleVisiSmartCodeCloseModal = () => {
    setVisiSmartCodeModalOpen(false);
  };
/* END VISISMART CODE*/

/* DISCARD TEST*/
  const discardTestConfirm = (testId:any) => modals.openConfirmModal({
    title: 'Discard the Test',
    children: (
      <Text size="sm">
        This will discard your current test so you will loose all data. Do you wish to continue? Then you should remove the webhook from the shopping cart or payment processor after delete campaign.
      </Text>
    ),
    labels: { confirm: 'Discard the Test', cancel: "No don't discard it" },
    confirmProps: { color: 'red' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => { 
      handleDiscardTest(pageId);
    },
  });

  const handleDiscardTest = (pageId:any) => {
    setSystemUsageLoader(true);
    TestsApi.discardtest({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      taguchi_test_id:taguchiTestId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setSystemUsageLoader(false);
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        navigate('/review-tests');
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
        setSystemUsageLoader(false);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
      setSystemUsageLoader(false);
    });
  }
/* END DISCARD TEST*/

/* CONVERSIONS TO*/
  const handleConvRadioChange = (value) => {
    setConvRedioBtn(value);
    setConversionTypeShow(value === '1' ? 'goal' : value === '2' ? 'button' : 'postback');
    setConversionTypeValueSelected('');

  };

  useEffect(() => {
    if(selectedMemberWebsite !== ''){
  	  if (!pageId) {
  	    navigate('/review-tests');
  	    return;
  	  }
  	  TestsApi.getconversionsdropdown({
  	    system_website_id: selectedMemberWebsite,
  	    page_id:pageId,
  	    taguchi_id:taguchiTestId
  	  })
  	  .then(response => {
  	    if (response.ok) {
  	      return response.json();
  	    } else {
  	      if (response.status === 401 || response.status === 400) {
  	        navigate("/logout");
  	      }
  	    }
  	  })
  	  .then(data => {
  	    if (data.status === 1) {
          setConvRadioFunnelsGoals(data.data.funnels_goals || []);
          setConvRadioPostbackOptions(data.data.postback_options || []);
          setConvRadioButtonOptions(data.data.button_options || []);
  	    } else {
  	      console.log("Error:","Error");
  	    }
  	  })
  	  .catch(error => {
  	    console.error("API Error:", error);
  	  });
    }
  }, [selectedMemberWebsite]);

  const funnelGoal = useMemo(() => [
    { value: '', label: 'Select a Goal' },
    ...convRadioFunnelsGoals.map((FunnelsGoals) => ({
      value: FunnelsGoals.goal_id,
      label: FunnelsGoals.goal_name,
    })),
  ], [convRadioFunnelsGoals]);

  const postbackOption = useMemo(() => [
    { value: '', label: 'Select Postback' },
    ...convRadioPostbackOptions.map((PostbackOptions) => ({
      value: PostbackOptions.postback_id,
      label: PostbackOptions.postback_name,
    })),
  ], [convRadioPostbackOptions]);

  const buttonOptions = useMemo(() => [
    { value: '', label: 'Select a Button' },
    ...Object.values(convRadioButtonOptions).map((ButtonOptions) => ({
      value: ButtonOptions.button_id,
      label: ButtonOptions.button_name,
    })),
  ], [convRadioButtonOptions]);

  const SelectedconversionTypeValue = () => {
    if(conversionTypeShow === 'goal'){
      setConvRedioBtn('1');
    }
    if(conversionTypeShow === 'button'){
      setConvRedioBtn('2');
    }
    if(conversionTypeShow === 'postback'){
      setConvRedioBtn('3');
    }

    if (convRedioBtn === '1') {
      const selectedOption = funnelGoal.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setFunnelsGoals(selectedOption.value);
      }
    } else if (convRedioBtn === '2') {
      const selectedOption = buttonOptions.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setSelectedButton(selectedOption.value);
      }
    } else if (convRedioBtn === '3') {
      const selectedOption = postbackOption.find(option => option.value === conversionTypeValueSelected);
      if (selectedOption) {
        setPostbackOptions(selectedOption.value);
      }
    };
  };

  useEffect(() => {
    SelectedconversionTypeValue();
  }, [conversionTypeValueSelected]);

  const handleSelectConvChange = (value) => {
    setConversionTypeValueSelected(value);
    if (convRedioBtn === '1') {
      setFunnelsGoals(value);
    } else if (convRedioBtn === '2') {
      setSelectedButton(value);
    } else if (convRedioBtn === '3') {
      setPostbackOptions(value);
    }
  };
/* END CONVERSIONS TO*/

/* CONVERSION LOG*/
  const handleConversionLogsOpenModal = (value:any) => {
  	if (!pageId) {
      navigate('/review-tests');
      return;
    }
    TestsApi.getconversionlogdetails({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      funnel_id:funnelId
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setConversionLogsConversionType(data.data.conversion_type);
        setConversionLogsGoalList(data.data.goal_list);
        setConversionLogsPostbackList(data.data.postback_list);
        setConversionLogsModalOpen(true);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const conversionTypeData = [
    { value: '', label: 'Select Conversion Type' },
    ...conversionLogsConversionType.map((ConversionTypeData) => ({
      value: ConversionTypeData.id,
      label: ConversionTypeData.name,
    })),
  ];

  const goalListData = [
  	{ value: '', label: 'Select Goal List' },
    ...conversionLogsGoalList.map((GoalListData) => ({
      value: GoalListData.page_id,
      label: GoalListData.page_title,
    })),
  ];

  const postbackListData = [
  	{ value: '', label: 'Select Postback List' },
    ...conversionLogsPostbackList.map((PostbackListData) => ({
      value: PostbackListData.postback_id,
      label: PostbackListData.postback_name,
    })),
  ];

  const handleConversionType = (value:any) => {
  	const selectedOption = conversionTypeData.find((option) => option.value === value);
  	setConversionType(value);
  	setConversionTypeName(selectedOption ? selectedOption.label : '');
  };

  const handleSelectListChange = (value) => {
    if (convLogRedioBtn === 'goal') {
    	const selectedOption = goalListData.find((option) => option.value === value);
      setGoalList(value);
      setGoalListName(selectedOption ? selectedOption.label : '');
    } else if (convLogRedioBtn === 'postback') {
    	const selectedOption = postbackListData.find((option) => option.value === value);
      setPostbackList(value);
      setPostbackListName(selectedOption ? selectedOption.label : '');
    }
	};

  const handleRadioChange = (value) => {
    setConvLogRedioBtn(value);
  };

  const handlePageChange = (newPage) => {
    setActivePageNo(newPage);
  };

  const handleConversionDataLog = (value:any) => {
  	TestsApi.getconversionlogfilter({
      system_website_id: selectedMemberWebsite,
      funnel_first_page_id:pageId,
      conv_type:conversionType,
      type:"tests",
      conversion_log_time:selectedTimePicker,
      goal_name:goalListName,
      goal_type:convLogRedioBtn,
      conv_goal:goalList,
      page:activePageNo,
      conv_postback:postbackList,
      conversion_log_from:selectedTimePickerFromDate,
      conversion_log_to:selectedTimePickerToDate
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setUndefinedConversionTableField(data.data.conversion_field || []);
        setUndefinedConversionTableData(data.data.conversion_data || []);
        setDefinedConversionTableField(data.data.conversion_field || []);
        setDefinedConversionTableData(data.data.conversion_data || []);
        setResetConversionTableField(data.data.conversion_field || []);
        setResetConversionTableData(data.data.conversion_data || []);
        setConversionTypeStatus(data.data.conversion_type_status);
  			setFilterConversionLog('yes');
        if (data.data.active_page_no !== activePageNo) {
          setActivePageNo(data.data.active_page_no);
          setActivePageNo(1);
        }
        if (data.data.total_pages !== totalPages) {
          setTotalPages(data.data.total_pages);
        }
        if (JSON.stringify(data.data.total_pagination_no) !== JSON.stringify(totalPaginationNo)) {
          setTotalPaginationNo(data.data.total_pagination_no);
        }
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleConversionLogsCloseModal = () => {
    setConversionLogsModalOpen(false);
    setConversionType('');
    setGoalList('');
    setPostbackList('');
    setConversionLogsGoalList([]);
    setConversionLogsPostbackList([]);
    setUndefinedConversionTableField([]);
    setUndefinedConversionTableData([]);
    setDefinedConversionTableField([]);
    setDefinedConversionTableData([]);
    setResetConversionTableField([]);
    setResetConversionTableData([]);
  };
/* END CONVERSION LOG*/

/* BEHAVIORAL DIMENSION*/
  const handleBehavioralFilterOpenModal = (value:any) => {
    setBehavioralFilterModalOpen(true);
    TestsApi.getbehavioraldimensions({
      page_id: pageId
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {

        const dropdownValues1 = data.data.behavioral_filter.map((filter, index) => ({
          id: index,
          firstDropdown: filter.device_filter || '',
          secondDropdown: filter.traffic_source_key || '',
          thirdDropdown: filter.ad_network_type || '',
        }));
        setDropdownSets(dropdownValues1);

        if(dropdownValues1){
          TestsApi.behavioraldimensionsaddfilterfirstdropdown()
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              if (response.status === 401 || response.status === 400) {
                navigate("/logout");
              }
            }
          })
          .then(data => {
            if (data.status === 1) {
              setBehavioralFilterFirstDropdown(data.data.first_dropdown);
              setShowFirstDropdown(true);
            }
          })
          .catch(error => {
            console.error("API Error:", error);
          });
        }

        const dropdownValues = data.data.behavioral_filter.forEach((filter, index) => {
          if(filter.device_type === "Tracking tags" || filter.device_type === "Network Paramters" || filter.device_type === "Custom tags"){
            TestsApi.behavioraldimensionsaddfilterseconddropdown({
              device_filter:filter.device_filter,
              page_id:pageId,
              ad_network_type:filter.ad_network_type,
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                if (response.status === 401 || response.status === 400) {
                  navigate("/logout");
                }
              }
            })
            .then(data => {
              if (data.status === 1) {
                setBehavioralFilterSecondDropdown(prev => ({ ...prev, [index]: data.data.second_dropdown }));
                setShowSecondDropdown(prev => ({ ...prev, [index]: true }));
              }
            })
            .catch(error => {
              console.error("API Error:", error);
            });

            if(filter.device_filter === 'VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09'){
              TestsApi.behavioraldimensionsaddfilterthirddropdown({
                device_filter:filter.device_filter
              })
              .then(response => {
                if (response.ok) {
                  return response.json();
                } else {
                  if (response.status === 401 || response.status === 400) {
                    navigate("/logout");
                  }
                }
              })
              .then(data => {
                if (data.status === 1) {
                  setBehavioralFilterThirdDropdown(prev => ({ ...prev, [index]: data.data.third_dropdown }));
                  setThirdDropdown(filter.traffic_source_key);
                  setShowThirdDropdown(prev => ({ ...prev, [index]: true }));
                  setShowSecondDropdown(prev => ({ ...prev, [index]: true }));
                }
              })
              .catch(error => {
                console.error("API Error:", error);
              });
            }
          }
        });

      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  };

  const handleAddFilter = () => {
    const newId = dropdownSets.length > 0 ? dropdownSets[dropdownSets.length - 1].id + 1 : 0;
    const newSet = { id: newId, firstDropdown: '', secondDropdown: '', thirdDropdown: '' };
    setDropdownSets([...dropdownSets, newSet]);

    setShowSecondDropdown(prev => ({ ...prev, [newId]: false }));
    setShowThirdDropdown(prev => ({ ...prev, [newId]: false }));

    TestsApi.behavioraldimensionsaddfilterfirstdropdown()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        const firstDropdownData = data.data.first_dropdown;
        setBehavioralFilterFirstDropdown(firstDropdownData);
        setDropdownSets(prevSets => prevSets.map(set => {
          if (set.id === newId) {
            return { ...set, firstDropdown: firstDropdownData.length > 0 ? firstDropdownData[0].key : null };
          }
          return set;
        }));
        setShowFirstDropdown(true);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleFirstDropdown = (value:any,setId) => {
    setFirstDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, firstDropdown: value } : set
      )
    );

    if(value === 'VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09'){
      TestsApi.behavioraldimensionsaddfilterthirddropdown({
        device_filter:value
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          const thirdDropdownData = data.data.third_dropdown;
          setBehavioralFilterThirdDropdown(prev => ({ ...prev, [setId]: thirdDropdownData }));
          setDropdownSets(prevSets => prevSets.map(set => {
            if (set.id === setId) {
              return { ...set, thirdDropdown: thirdDropdownData.length > 0 ? thirdDropdownData[0].key : null };
            }
            return set;
          }));
          setShowThirdDropdown(prev => ({ ...prev, [setId]: true }));
          setShowSecondDropdown(prev => ({ ...prev, [setId]: true }));
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }else{
      setShowThirdDropdown(prev => ({ ...prev, [setId]: false }));
      setShowSecondDropdown(prev => ({ ...prev, [setId]: false }));
    }
    const thirdDropdown = 'facebook';
    TestsApi.behavioraldimensionsaddfilterseconddropdown({
      device_filter:value,
      page_id:pageId,
      ad_network_type:thirdDropdown,
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        const secondDropdownData = data.data.second_dropdown;
        setBehavioralFilterSecondDropdown(prev => ({ ...prev, [setId]: secondDropdownData }));

        setDropdownSets(prevSets => prevSets.map(set => {
          if (set.id === setId) {
            return { ...set, secondDropdown: secondDropdownData.length > 0 ? secondDropdownData[0].key : null };
          }
          return set;
        }));
        setShowSecondDropdown(prev => ({ ...prev, [setId]: true }));
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleSecondDropdown = (value:any,setId) => {
    setSecondDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, secondDropdown: value } : set
      )
    );
  }

  const handleThirdDropdown = (value:any,setId) => {
    setThirdDropdown(value);
    setDropdownSets((prevSets) =>
      prevSets.map((set) =>
        set.id === setId ? { ...set, thirdDropdown: value } : set
      )
    );
    TestsApi.behavioraldimensionsaddfilterseconddropdown({
      device_filter: "VDMzenM4ZGxuUDVSYTJpTDRnUUk3UT09", //Only firstDropdown network parameter value
      page_id: pageId,
      ad_network_type:value,
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        const secondDropdownData = data.data.second_dropdown;
        setBehavioralFilterSecondDropdown(prev => ({ ...prev, [setId]: secondDropdownData }));

        setDropdownSets(prevSets => prevSets.map(set => {
          if (set.id === setId) {
            return { ...set, secondDropdown: secondDropdownData.length > 0 ? secondDropdownData[0].key : null };
          }
          return set;
        }));
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  }

  const handleRemoveFilter = (setId) => {
    const updatedDropdownSets = dropdownSets.filter(set => set.id !== setId);
    setDropdownSets(updatedDropdownSets);
  };

  const handleSaveBehavioralFilter = (e) => {
    e.preventDefault();
    const deviceFilters = [];
    const adNetworkTypes = [];
    const trafficSourceKeys = [];

    dropdownSets.forEach((set) => {
      deviceFilters.push(set.firstDropdown);
      adNetworkTypes.push(set.thirdDropdown);
      trafficSourceKeys.push(set.secondDropdown);
    });

    TestsApi.savebehavioraldimensions({
      page_id: pageId,
      device_filter: deviceFilters,
      ad_network_type: adNetworkTypes,
      traffic_source_key: trafficSourceKeys
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        TestOptimizeReport();
        getnetworkhierarchicaldata();
        getbehavioraldimensionfilterdata();
        setMarksalestime(data.data.updated_time);
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleBehavioralFilterCloseModal();
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  };

  const handleResetBehavioralFilter = () => {
    modals.openConfirmModal({
      title: 'Reset Filter',
      children: (
        <Text size="sm">
         Filter reset successfully!
        </Text>
      ),
      labels: { confirm: 'Reset Filter', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => {
        ResetBehavioralFilter();
      },
    });
  };

  const ResetBehavioralFilter = () => {
    TestsApi.resetfilterbehavioraldimensions({
      page_id: pageId
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        setMarksalestime(data.data.updated_time);
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        handleBehavioralFilterCloseModal();
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
    });
  };

  const handleBehavioralFilterCloseModal = () => {
    setBehavioralFilterModalOpen(false);
    setDropdownSets([]);
    setShowFirstDropdown(false);
    setShowSecondDropdown(false);
    setShowThirdDropdown(false); 
  };
/* END BEHAVIORAL DIMENSION*/

/* VARIATIONS & VARIABLES*/
  const handleOptimize = (value:any) => {
    if(value === 'Variations'){
      setVariationsBtn('1');
      setVariablesBtn('0');
    } else if(value === 'Variables'){
      setVariationsBtn('0');
      setVariablesBtn('1');
      handleGetVariables();
    }
  };

  const handleGetVariables = () => {
    setSystemUsageLoader(true);
    TestsApi.getvariablestab({
      page_id: pageId,
      time: selectedTimePicker,
      from: selectedTimePickerFromDate,
      to: selectedTimePickerToDate
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        setVariablesMessageShow(data.data.message_show);
        setVariablesOptimumResponse(data.data.optimum_response);
        setVariablesVariablesTab(data.data.variables_tab || []);
        setSystemUsageLoader(false);
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
      setSystemUsageLoader(false);
    });
  };

  const testVariablesRows = variablesVariablesTab.map((element, index) => (
    <tr key={index}>
      <td>
        {element.variables_name !== '' && (
          <Tooltip label={element.variables_name_complete}>
            <Text size="sm">{element.variables_name}</Text>
          </Tooltip>
        )} 
        {element.versions_name !== '' && (
          <Text size="sm" ml={20}>{element.versions_name}</Text>
        )}
      </td>
      <td>
        {element.variables_name !== '' && (
          <div>
            <Text fw={500} ta="center" ml={20}>{element.statistical_significance}</Text>
            {element.statistical_significance !== '-' && element.statistical_significance !== undefined ? (
              <progress
                value={element.statistical_significance.replace('%', '')}
                className={element.ss_class}
                max="100"
                style={{ width: "100%" }}
              ></progress>
            ) : (
              ''
            )}
          </div>
        )} {element.variables_name !== '' && (
          <Text size="sm" ml={20} ta="center">{element.best_option}</Text>
        )}
      </td>
    </tr>
  ));
/* END VARIATIONS & VARIABLES*/

/* BEHAVIORAL DIMENSION FILTER*/
    const getbehavioraldimensionfilterdata = () => {
        if(selectedMemberWebsite !== ''){
          setLoading(true);
          TestsApi.getbehavioraldimensionfilterdata({
          system_website_id: selectedMemberWebsite,
          page_id:pageId,
          taguchi_id:taguchiTestId
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 401 || response.status === 400) {
              navigate("/logout");
            }
          }
        })
        .then(data => {
          if (data.status === 1) {
            setLoading(false);
            setBehavioralDimensionTrackingTags(data.data.tracking_tags);
            setBehavioralDimensionCustomTags(data.data.custom_tags);
            setBehavioralDimensionNetworkParameters(data.data.network_parameters);
            setBehavioralDimensionSource(data.data.source);
            setDisplayFilterButton(data.data.display_filter_button);
          } else {
            setLoading(false);
            console.error("Error:", "Error");
          }
        })
        .catch(error => {
          setLoading(false);
          console.error("API Error:", error);
        });
      }
    };

    useEffect(() => {
      getbehavioraldimensionfilterdata();
  }, [selectedMemberWebsite]);
/* END BEHAVIORAL DIMENSION FILTER*/

/* NETWORK HIERARCHICAL*/
  const [selectedTrackingTagsValue, setSelectedTrackingTagsValue] = useState([]);
  const [selectedCustomTagsValue, setSelectedCustomTagsValue] = useState([]);
  const [selectedSourceValue, setSelectedSourceValue] = useState([]);
  const [selectedNetworkParameters, setSelectedNetworkParameters] = useState({});
  const [selectedNetworkParam, setSelectedNetworkParam] = useState({ads_network: {}});

  const handleSelectNetworkParameters = (values, network, parameterName, array_id) => {
    setSelectedNetworkParameters(prevState => ({
      ...prevState,
      [network]: {
        ...prevState[network],
        [parameterName]: values,
      },
    }));

    setSelectedNetworkParam(prevState => {
        return {
            ...prevState,
            ads_network: {
                ...prevState.ads_network,
                [array_id]: values
            }
        };
    });
  };

  const getnetworkhierarchicaldata = () => {
    if(selectedMemberWebsite !== ''){
        setSystemUsageLoader(true);
        const adsData = {};
        Object.keys(selectedNetworkParameters).forEach(network => {
          Object.keys(selectedNetworkParameters[network]).forEach((parameter, index) => {
            adsData[`ads_${index + 1}`] = selectedNetworkParameters[network][parameter];
          });
        });
        TestsApi.getnetworkhierarchicaldata({
        page_id:pageId,
        taguchi_id:taguchiTestId,
        hierarchical_type:"tests",
        ...adsData
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setBehavioralDimensionNetworkParameters(data.data.network_parameters);
        } else {
          setSystemUsageLoader(false);
          console.error("Error:", "Error");
        }
      })
      .catch(error => {
        setSystemUsageLoader(false);
        console.error("API Error:", error);
      });
      setSystemUsageLoader(false);
    }
  };

  useEffect(() => {
    getnetworkhierarchicaldata();
  }, [selectedNetworkParameters]);
/* END NETWORK HIERARCHICAL*/

/* SET WINNER*/
  const handleSetWinner = async (varId,PageUrlShow:any) => {
    setVarId(varId);
    if(testTypeShow === 'classic_test'){
      modals.openConfirmModal({
        title: 'Set Winner',
        children: (
          <Text size="sm">
            The winner has been set and the test discarded.<br/>
            Please change the links in your site to point to the winning page:{PageUrlShow}
          </Text>
        ),
        labels: { confirm: 'Set Winner', cancel: 'Cancel' },
        confirmProps: { color: 'red' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
          // Setwinner(varId);
          setSetWinnerModalOpen(false);
          handleSaveSetWinner(varId);
        },
      });
    } else {
      Setwinner(varId);
      setSetWinnerModalOpen(true);
    }
  };

  const Setwinner = async (varId) =>{
    setLoading(true);
    TestsApi.setwinnerpopupget({
      page_id: pageId,
      test_id: varId
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate('/logout');
        }
      }
    })
    .then((data) => {
      if (data.status === 1) {
        setLoading(false);
        setSetWinnerData(data.data.set_winner_data);
      }
    })
    .catch((error) => {
      console.error('API Error:', error);
      setLoading(false);
    });
  };

  const handleSaveSetWinner = (varId) => {
    setSetWinnerModalOpen(false);
    setSystemUsageLoader(true);
    TestsApi.discardtest({
      system_website_id: selectedMemberWebsite,
      page_id:pageId,
      taguchi_test_id:taguchiTestId,
      type:"winner",
      test_win_id:varIdshow
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setSystemUsageLoader(false);
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
        navigate('/review-tests');
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
        setSystemUsageLoader(false);
      }
    })
    .catch(error => {
      console.error("API Error:", error);
      setSystemUsageLoader(false);
    });
  }

  const handleSetWinnerCloseModal = () => {
    setSetWinnerModalOpen(false);
  };
/* END SET WINNER*/

/* CUSTOM TITLE*/
  const handleEditCustomTitle = (varId, verId, currentVariations, testnumber) => {
    setEditClickVarId(varId);
    setEditClickVerId(verId);
    setEditingVarId(testnumber);
    setInputValue(currentVariations);
  };

  const handleCustomTitleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = () => {
    setEditingVarId(null);
    TestsApi.savecustomtitleintest({
      taguchi_test_id:taguchiTestId,
      page_id:pageId,
      variable: editClickVarId,
      version: editClickVerId,
      tests_id: testsIdShow,
      custom_title:inputValue
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
      setMarksalestime(data.data.updated_time);
      notifications.show({
        color: 'green',
        icon: <IconCheck size="1.1rem" />,
        title: 'Success',
        message: data.message,
      });
      } else {
        console.log("Error:","Error");
      }
    })
    .catch(error => {
      console.error("API Error:", error);
    });
  };

  const handleCustomTitlePress = (event) => {
    if (event.key === 'Enter') {
      setEditingVarId(null);
      TestsApi.savecustomtitleintest({
        taguchi_test_id:taguchiTestId,
        page_id:pageId,
        variable: editClickVarId,
        version: editClickVerId,
        tests_id: testsIdShow,
        custom_title:inputValue
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setMarksalestime(data.data.updated_time);
          notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            title: 'Success',
            message: data.message,
          });
        } else {
          console.log("Error:","Error");
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  };
/* END CUSTOM TITLE*/

/* PAUSE RESUME VARIATION*/
  const handlePauseVariations = (varId, currentVariations) => { 
    setSystemUsageLoader(true);
    TestsApi.pauseresumevariation({
      system_website_id:selectedMemberWebsite,
      taguchi_test_id:taguchiTestId,
      page_id:pageId,
      variation_no: varId,
      tests_id: testsIdShow
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setSystemUsageLoader(false);
        setMarksalestime(data.data.updated_time);
        TestOptimizeReport();
        notifications.show({
          color: 'green',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setShowErrorHeader(true);
        setSystemUsageLoader(false);
      }
    })
    .catch(error => {
      setSystemUsageLoader(false);
      console.error("API Error:", error);
    });
  }
/* END PAUSE RESUME VARIATION*/

/* FILTER */
  const handleSelectTrackingTags = (parameterName, value, array_id) => {
    setSelectedTrackingTagsValue(prevState => ({
        ...prevState,
        [`${array_id}`]: value
    }));
  };

  const handleSelectCustomTags = (parameterName, value, array_id) => {
    setSelectedCustomTagsValue(prevState => ({
        ...prevState,
        [`${array_id}`]: value
    }));
  };

  const handleSelectSource = (value) => {
    setSelectedSourceValue(value);
  };

  const behavioralDimensionFilter = () => {
    if(selectedMemberWebsite !== ''){
      const formattedAdsTrackingNetwork = Object.keys(selectedTrackingTagsValue).reduce((acc, key) => {
          acc[key] = selectedTrackingTagsValue[key];
          return acc;
      }, {});

      const formattedAdsCustomTags = Object.keys(selectedCustomTagsValue).reduce((acc, key) => {
          acc[key] = selectedCustomTagsValue[key];
          return acc;
      }, {});

      TestsApi.testoptimizereport({
        system_website_id:selectedMemberWebsite,
        page_id:pageId,
        time: selectedTimePicker,
        test_goal_type: conversionTypeShow,
        convtogoal:convRedioBtn === '1' ? funnelsGoals : '',
        convtobutton:convRedioBtn === '2' ? selectedButton : '',
        convtopostback:convRedioBtn === '3' ? postbackOptions : '',
        ads_network: selectedNetworkParam.ads_network,
        ads_tracking_network:formattedAdsTrackingNetwork,
        ads_custom_network:formattedAdsCustomTags,
        source:selectedSourceValue,
        from:selectedTimePickerFromDate,
        to:selectedTimePickerToDate
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setTestVariationsData(data.data.test_data);
          setDataColumnChart(data.data.conversion_chart_column);
          setDataColumnPercentageChart(data.data.conversion_percentage_chart_column);
          setBehavioralFilterArrCount(data.data.behavioral_filter_arr_count);
          setTestModeShow(data.data.testmode);
          setWebhookSetShow(data.data.webhook_set);
          setHidePauseShow(data.data.hide_pause);                      
          setTestNameShow(data.data.test_name);
          setPageIdShow(data.data.page_id);
          setTagichiTypeIdShow(data.data.tagichi_type_id);
          setTestPageLinkShow(data.data.test_page_link);
          setTestPageTitleShow(data.data.test_page_title);
          setTotalUniquesShow(data.data.total_uniques);
          setTotalVisitsShow(data.data.total_visits);
          setTotalConversionsShow(data.data.total_conversions);
          setPageBottomMsgShow(data.data.page_bottom_msg);
          setConversionTypeShow(data.data.conversion_type);
          setConversionTypeValueSelected(data.data.conversion_type_value_selected);
          setConvtopageNameShow(data.data.convtopage_name);
          setTestTypeShow(data.data.test_type);
          setVariablesTabShow(data.data.hide_variables_tab);
          setTestsIdShow(data.data.tests_id);
          setTestStartDate(data.data.test_start_date);
          setMarksalestime(data.data.updated_time);
        } else {
          setErrorMessage(data.message);
          seterrorCloseModal(true);
        }
      })
      .catch(error => {
        console.error("API Error:", error);
      });
    }
  };
/* END FILTER */

/* TEST OPTIMIZE REPORT*/
  const TestOptimizeReport = () => {
    if(selectedMemberWebsite !== ''){
      setLoading(true);
      const formattedAdsTrackingNetwork = Object.keys(selectedTrackingTagsValue).reduce((acc, key) => {
        acc[key] = selectedTrackingTagsValue[key];
        return acc;
      }, {});

      const formattedAdsCustomTags = Object.keys(selectedCustomTagsValue).reduce((acc, key) => {
          acc[key] = selectedCustomTagsValue[key];
          return acc;
      }, {});
      TestsApi.testoptimizereport({
        system_website_id:selectedMemberWebsite,
        page_id:pageId,
        time: selectedTimePicker,
        test_goal_type: conversionTypeShow,
        convtogoal:convRedioBtn === '1' ? funnelsGoals : '',
        convtobutton:convRedioBtn === '2' ? selectedButton : '',
        convtopostback:convRedioBtn === '3' ? postbackOptions : '',
        ads_network: selectedNetworkParam.ads_network,
        ads_tracking_network:formattedAdsTrackingNetwork,
        ads_custom_network:formattedAdsCustomTags,
        source:selectedSourceValue,
        from:selectedTimePickerFromDate,
        to:selectedTimePickerToDate
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if (data.status === 1) {
          setLoading(false);
          setTestVariationsData(data.data.test_data);
          setDataColumnChart(data.data.conversion_chart_column);
          setDataColumnPercentageChart(data.data.conversion_percentage_chart_column);
          setBehavioralFilterArrCount(data.data.behavioral_filter_arr_count);
          setTestModeShow(data.data.testmode);
          setWebhookSetShow(data.data.webhook_set);
          setHidePauseShow(data.data.hide_pause);
          setTestNameShow(data.data.test_name);
          setPageIdShow(data.data.page_id);
          setTagichiTypeIdShow(data.data.tagichi_type_id);
          setTestPageLinkShow(data.data.test_page_link);
          setTestPageTitleShow(data.data.test_page_title);
          setTotalUniquesShow(data.data.total_uniques);
          setTotalVisitsShow(data.data.total_visits);
          setTotalConversionsShow(data.data.total_conversions);
          setPageBottomMsgShow(data.data.page_bottom_msg);
          setConversionTypeShow(data.data.conversion_type);
          setConversionTypeValueSelected(data.data.conversion_type_value_selected);
          setConvtopageNameShow(data.data.convtopage_name);
          setTestTypeShow(data.data.test_type);
          setVariablesTabShow(data.data.hide_variables_tab);
          setTestsIdShow(data.data.tests_id);
          setTestStartDate(data.data.test_start_date);
          setMarksalestime(data.data.updated_time);

          const teststatus = data.data.testpausestatus;
          setIsTestPaused(teststatus === 'pause');
          setTestPauseResume(teststatus);
          setTestPauseResumeTitle(teststatus === 'pause' ? 'Resume Test' : 'Pause Test');
          setTestPauseResumeIcon(
            teststatus === 'pause' ? (
              <IconPlayerPlay size="1.1rem" />
            ) : (
              <IconPlayerPause size="1.1rem" />
            )
          );
        } else {
          setErrorMessage(data.message);
          seterrorCloseModal(true);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.error("API Error:", error);
      });
    }
  };
  
  useEffect(() => {
    if(funnelsGoals || postbackOptions || selectedButton){
      behavioralDimensionFilter();
    }
  }, [selectedMemberWebsite,funnelsGoals,postbackOptions,selectedButton]);

  const handleConversionRadioChange = (value) => {
    setSelectedValue(value);
    behavioralDimensionFilter();
  };

  const dataColumnChartRows = [
    ['Variation', 'Value', { role: 'style' }],
    ...dataColumnChart,
  ];

  const optionsColumnChart = {
    legend: { position: 'none' },
    chartArea: { width: '90%', height: '80%' },
    vAxis: {
      title: 'Conversions',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
    },
  };

  const dataColumnPercentageChartRows = [
    ['Variation', 'Value', { role: 'style' }],
    ...dataColumnPercentageChart,
  ];

  const optionsColumnPercentageChart = {
    legend: { position: 'none' },
    chartArea: { width: '90%', height: '80%' },
    vAxis: {
      title: 'Conversions %',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
    },
  };

  const getBackgroundColor = (highlight) => {
    if (highlight === 'red') {
      return '#ffd9d9';
    }
    if (highlight === 'yellow') {
      return '#fff7c6';
    }
    return '';
  };

  const allOptions = [
    { value: 'Uniques', label: 'Uniques' },
    { value: 'Visits', label: 'Visits' },
    { value: 'Conv. # to page', label: 'Conv. # to page' },
    { value: 'Conv. % to page', label: 'Conv. % to page' },
    { value: 'Percentage Improvement', label: 'Percentage Improvement' },
    { value: 'Statistical Significance', label: 'Statistical Significance' },
    { value: 'Avg. Time Spent', label: 'Avg. Time Spent' },
    { value: 'Total Revenue', label: 'Total Revenue' },
    { value: 'AOV', label: 'AOV' },
    { value: 'Avg. Revenue per unique', label: 'Avg. Revenue per unique' },
  ];

  const defaultSelectedOptions = [
    'Uniques',
    'Visits',
    'Conv. # to page',
    'Conv. % to page',
    'Percentage Improvement',
    'Statistical Significance',
    'Total Revenue',
    'AOV',
    'Avg. Revenue per unique'
  ];
  const [selectedfilteredOptions, setSelectedfilteredOptions] = useState<string[]>(defaultSelectedOptions);
  
  const filteredOptions = webhookSetShow === 1
    ? allOptions
  : allOptions.filter(option => 
      option.value !== 'Total Revenue' && 
      option.value !== 'AOV' && 
      option.value !== 'Avg. Revenue per unique'
    );

  const handleSelectFilteredChange = (values: string[]) => {
    setSelectedfilteredOptions(values);
  };

  const testVariationsRows = testVariationsData.map((element,index) => (
      <tr key={element.test_number} 
        style={{ backgroundColor: getBackgroundColor(element.column_heighlite) }}
      >
      <td>{element.test_number}</td>
      <td style={{ width: '200px'}}>
        <div style={{ display: 'flex'}}>
          {editingVarId === element.test_number ? (
            <Input
              value={inputValue}
              onChange={handleCustomTitleChange}
              onBlur={handleInputBlur}
              onKeyPress={handleCustomTitlePress}
              autoFocus
              size="xs"
              w={150}
              mr={10}
            />
          ) : (
            <Text mt={6} w={150}><Anchor href={element.preview_adtest} target="_blank" color="inherit">{element.name}</Anchor></Text>
          )}
          {element.edit_variation_hide === "show" && (
            <ActionIcon
              variant="default"
              size="md"
              title="Edit Title"
              onClick={() => handleEditCustomTitle(element.variable, element.version, element.name, element.test_number)}
            >
              <IconPencil size="1.1rem" />
            </ActionIcon>
          )}
        </div>
      </td>
      {selectedfilteredOptions.includes('Uniques') && <td>{element.unique}</td>}
      {selectedfilteredOptions.includes('Visits') && <td>{element.visit}</td>}
      {selectedfilteredOptions.includes('Conv. # to page') && <td>{element.pageconvnum}</td>}
      {selectedfilteredOptions.includes('Conv. % to page') && <td>{element.pageconv} %</td>}
      {selectedfilteredOptions.includes('Percentage Improvement') && 
        <td>
        {element.pi_per === 'Not Enough Data' ? (
          <Text c="red" fw={500}>{element.pi_per}</Text>
        ) : (
          <div style={{ display: 'flex' }}>
            <Text fw={500} w={40}>{Math.round(element.pi_per)}%</Text>
            <div className="pi_graph pi_cf">
              <div className={`pi_ext ${element.pi_class}`}>
                <div style={{ width: `${Math.min(Math.round(element.pi_per_value), 100)}%` }} className={`pi_bar ${element.pi_class}`}></div>
              </div>
            </div>
          </div>
        )}
        </td>
      }
      <td><Text c="teal" fw={700} style={{cursor: 'pointer'}} onClick={() => handleSetWinner(element.test_number,element.page_url)}>Set Winner</Text></td>
      {selectedfilteredOptions.includes('Statistical Significance') &&
        <>
          {(element.ss_class !== '' || element.ss_per !== 0) ? (
          <td><div style={{ display: 'flex'}}><Text fw={500} style={{width:"5.5rem"}}>{element.ss_per}%</Text>{element.ss_per !== ''?<progress value={element.ss_per} style={{width:"208%",marginTop:"3px"}} className={element.ss_class} max="100"></progress>:''}</div></td>
          ) : (
            <td></td>
          )}
        </>
      }
      {webhookSetShow !== 0 && (
      <>
      {selectedfilteredOptions.includes('Total Revenue') && <td>{element.revenue}</td>}
      {selectedfilteredOptions.includes('AOV') && <td>{element.aov}</td>}
      {selectedfilteredOptions.includes('Avg. Revenue per unique') && <td>{element.avg_revenue_per_unique}</td>}
      </>
      )}
      {selectedfilteredOptions.includes('Avg. Time Spent') && <td>{element.time_spent}</td>}
      {hidePauseShow !== 'hide' && (
      <>
      <td>
      {element.paused_variation === 0 ? (
        <ActionIcon
          variant="default"
          size="md"
          title="Pause Test"
          onClick={() => handlePauseVariations(element.version, element.name)}
        >
          <IconPlayerPause size="1.1rem" />
        </ActionIcon>
       ) : (
          <ActionIcon
            variant="default"
            size="md"
            title="Resume Test"
            onClick={() => handlePauseVariations(element.version, element.name)}
          >
            <IconPlayerPlay size="1.1rem" />
          </ActionIcon>
        )}
      </td>
       </>
      )}
    </tr>
  ));
/* END TEST OPTIMIZE REPORT*/

  const behavioralDimensionClearFilter = () => {
    setLoading(true);
    setBehavioralDimensionTrackingTags({});
    setBehavioralDimensionCustomTags({});
    setBehavioralDimensionNetworkParameters({});
    setBehavioralDimensionSource([]);
    setSelectedTrackingTagsValue([]);
    setSelectedCustomTagsValue([]);
    setSelectedSourceValue([]);
    setSelectedNetworkParameters({});
    setSelectedNetworkParam({ads_network: {}});

    TestsApi.testoptimizereport({
      system_website_id:selectedMemberWebsite,
      page_id:pageId,
      time: selectedTimePicker,
      test_goal_type: conversionTypeShow,
      convtogoal:convRedioBtn === '1' ? funnelsGoals : '',
      convtobutton:convRedioBtn === '2' ? selectedButton : '',
      convtopostback:convRedioBtn === '3' ? postbackOptions : '',
      from:selectedTimePickerFromDate,
      to:selectedTimePickerToDate
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if (data.status === 1) {
        setLoading(false);
        setTestVariationsData(data.data.test_data);
        setDataColumnChart(data.data.conversion_chart_column);
        setDataColumnPercentageChart(data.data.conversion_percentage_chart_column);
        setBehavioralFilterArrCount(data.data.behavioral_filter_arr_count);
        setTestModeShow(data.data.testmode);
        setWebhookSetShow(data.data.webhook_set);
        setHidePauseShow(data.data.hide_pause);
        setTestNameShow(data.data.test_name);
        setPageIdShow(data.data.page_id);
        setTagichiTypeIdShow(data.data.tagichi_type_id);
        setTestPageLinkShow(data.data.test_page_link);
        setTestPageTitleShow(data.data.test_page_title);
        setTotalUniquesShow(data.data.total_uniques);
        setTotalVisitsShow(data.data.total_visits);
        setTotalConversionsShow(data.data.total_conversions);
        setPageBottomMsgShow(data.data.page_bottom_msg);
        setConversionTypeShow(data.data.conversion_type);
        setConversionTypeValueSelected(data.data.conversion_type_value_selected);
        setConvtopageNameShow(data.data.convtopage_name);
        setTestTypeShow(data.data.test_type);
        setVariablesTabShow(data.data.hide_variables_tab);
        setTestsIdShow(data.data.tests_id);
        setTestStartDate(data.data.test_start_date);
        setMarksalestime(data.data.updated_time);
      } else {
        setErrorMessage(data.message);
        seterrorCloseModal(true);
        setLoading(false);
      }
    })
    .catch(error => {
      setLoading(false);
      console.error("API Error:", error);
    });
  };

  const handleRefreshStats = () => {
    behavioralDimensionClearFilter();
  };

  const handlerrorCloseModal = () => {
    seterrorCloseModal(false);
  };

  const handleEditTest = (pageId:any,tagichiTypeId:any) => {
    if(testTypeShow === "modern_test"){
      const url = `/test-editor-visual-modern/${pageId}/${tagichiTypeId}`;
      window.open(url, '_blank');
    }else{
      const url = `/test-editor-visual/${pageId}/${tagichiTypeId}`;
      window.open(url, '_blank');
    }
  };

  const [selectedGraph, setSelectedGraph] = useState('0');

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Number of legend items per page

  const dataLineChart = [
    ['Day', 'Control', 'Variation 1', 'Variation 2', 'Variation 3', 'Variation 4', 'Variation 5', 'Variation 6', 'Variation 7', 'Variation 8', 'Variation 9', 'Variation 10'],
    [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
    [2, 15, 22, 32, 52, 62, 72, 82, 92, 102, 112, 122],
    [3, 20, 30, 50, 65, 70, 85, 90, 100, 110, 120, 133],
    [4, 35, 42, 60, 70, 80, 90, 107, 110, 123, 130, 144],
    [5, 48, 60, 70, 80, 95, 100, 110, 120, 130, 149, 155],
    [6, 55, 70, 80, 90, 100, 115, 120, 135, 146, 150, 166],
    [7, 60, 80, 90, 109, 110, 120, 130, 140, 150, 160, 177],
    [8, 78, 90, 100, 110, 120, 130, 140, 150, 169, 170, 188],
  ];

  // Colors for each line (25 variations)
  const colors = ['#f39c12', '#e74c3c', '#c0392b', '#743190', '#E5D82A', '#D23D6A', '#354B8C', '#8F4B37', '#429770', '#D91C49', '#FD5825'];
  const optionsLineChart = {
    legend: { position: 'none' },
    chartArea: { height: 'auto', width: '90%' },
    vAxis: {
      title: 'Cumulative Conversions',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
      minValue: 0,
    },
    hAxis: {
      title: 'Days',
      titleTextStyle: { fontFamily: 'Cursive', fontSize: 16 },
    },
    colors: colors, // We keep all colors visible in the chart itself
  }; 

  // Pagination for legend items (slicing colors and labels)
  const paginatedLabels = dataLineChart[0].slice(1).slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const paginatedColors = colors.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  // Calculate total pages for legend
  const charttotalPages = Math.ceil(colors.length / itemsPerPage);

	return (
	  <>
    <VisiLoader VisiLoaderVisible={SystemUsageLoader}/>
	  <HeaderMenu HeaderMenuValue={selectedMemberWebsite} HeaderMenuOnChange={handleHeaderMenuComponentChange}/>
	  {/*test Infomation Model*/}
	  <Modal.Root opened={testInfoModalOpen} onClose={handleTestInfoCloseModal}>
	    <Modal.Overlay />
	    <Modal.Content>
	      <Modal.Header>
	        <Modal.Title><Text weight={500}>TEST INFORMATION <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text></Modal.Title>
	        <Modal.CloseButton />
	      </Modal.Header>
	      <Modal.Body>
	        <form>
	        	<div style={{ display: 'flex'}}>
	        		<Text size="sm" fz={14} fw={500} mr={5}>Test ID:</Text>
	        		<Text size="sm" fz={14}>{testInfoVisioptTestId}</Text>
	        	</div>
	        	<div style={{ display: 'flex'}}>
	        		<Text size="sm" fz={14} fw={500} mr={5}>Tested Page:</Text>
	        		<Anchor href={testInfoPageLink} target="_blank" fw={500} mr={5} mt={-2}>{testInfoPageTitle} </Anchor>
	        	</div>
	        	<div style={{ display: 'flex'}}>
	        		<Text size="sm" fz={14} fw={500} mr={5}>Test Type:</Text>
	        		<Text size="sm" fz={14} mr={5}>{testInfoTestType}</Text>
	        	</div>
	          <div style={{ display: 'flex'}}>
			      	<Text size="sm" fz={14} fw={500} mr={5}>Test Start on:</Text>
			      	<Text size="sm" fz={14}>{testInfoTestTime}</Text>
			      </div>
	        </form>
	      </Modal.Body>
	    </Modal.Content>
	  </Modal.Root>
	  {/*Audience Size Model*/}
	  <Modal.Root opened={audienceSizeModalOpen} onClose={handleAudienceSizeCloseModal}>
	    <Modal.Overlay />
	    <Modal.Content>
	      <Modal.Header>
	        <Modal.Title>AUDIENCE SIZE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
	        <Modal.CloseButton />
	      </Modal.Header>
	      <Modal.Body h={250}>
	        <form onSubmit={handleSaveAudienceSize}>
            <Select
              label="Audience Size"
              value={selectedAudienceSize}
              onChange={handleAudienceSizeChange}
              data={audienceSizeOptions.map(option => ({
                value: option.option_value,
                label: option.option_name
              }))}
              searchable
              nothingFound="No options"
              maxDropdownHeight={180}
              size="sm"
              dropdownPosition="bottom"
              mb={10}
            />
            {audienceSizeOptions.find(option => option.option_value === selectedAudienceSize)?.option_name === "Please Select" ? (
              <Input.Wrapper label="Audience Size Value:">
                <Input
                  id="audience_value"
                  w="auto"
                  ml={0}
                  value={audienceSizeInput}
                  onChange={e => setAudienceSizeInput(e.target.value)}
                  placeholder="Enter a number"
                />
              </Input.Wrapper>
            ) : (
              <Input.Wrapper label="Audience Size Value:">
                <Input
                  id="audience_value"
                  w="auto"
                  ml={0}
                  value={selectedAudienceSize}
                  readOnly
                />
              </Input.Wrapper>
            )}
	          <Group position="right">
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Connect Shopping Cart & Processor Model*/}
    <Modal.Root opened={shoppingCartProcessorModalOpen} onClose={handleShoppingCartProcessorCloseModal} size="80%">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>CONNECT SHOPPING CART & PROCESSOR (OPTIONAL) <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
        {shoppingCartProcessorList === false ?
          <form onSubmit={handleAddPaymentProcessorCart}>
            <Text fw="500">
              You can connect your payment processor and shopping cart. This step is optional but will enable more accurate tracking.
            </Text>
            <div style={{ display: 'flex' }}>
              <Text mt={13} mr={10} fw="500" fz={14}>
                Select a Processor:
              </Text>
              <Radio.Group name="ShoppingType" mb={10} value={convRedioTypeBtn} onChange={(value) => handleConvRedioTypeBtn(value)}>
                <Group mt="md">
                  <Radio value="1" label="Shopping Cart" />
                  <Radio value="2" label="Payment Processor" />
                </Group>
              </Radio.Group>
            </div>
            <Group spacing="xs" mb={5}>
              <IconHelp size="1.1rem" color="red" style={{ marginTop: -6 }} />
              <Text fz={14} mb={10} ml={5} fw="500">
                {labelText} :
              </Text>
              <Select
							  w={250}
							  value={convRedioTypeBtn === '1' ? selectedShoppingCart : selectedPaymentProcessor}
							  data={convRedioTypeBtn === '1' ? shoppingCartData : paymentProcessorData}
							  dropdownPosition="bottom"
							  onChange={(value) => handleSelectChange(value)}
							/>
              <Text fz={14} mb={10} ml={5} fw="500">
                Select Page :
              </Text>
              <Select
			          w={250}
			          value={selectedPage}
			          data={pageData}
			          dropdownPosition="bottom"
			          onChange={(value) => handleSelectPageChange(value)}
			          disabled={convRedioTypeBtn === '1' ? !selectedShoppingCart : !selectedPaymentProcessor}
			        />
            </Group>
            <Group spacing="xs" mb={5}>
              <Text fz={14} mb={10} ml={95} fw="500">
                Webhook URL :
              </Text>
              <Input
                w={602}
                value={selectedWebhookURL}
                onChange={(e) => setSelectedWebhookURL(e.target.value)}
                disabled={!selectedPage}
              />
              <CopyButton timeout={2000} value={selectedWebhookURL}>
                {({ copied, copy }) => (
                  <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                    {copied ? 'Copied Webhook URL' : 'Copy Webhook URL'}
                  </Button>
                )}
              </CopyButton>
            </Group>
            {selectedProcessor === "ClickBank" &&(
              <Group spacing="xs" mb={5}>
                <Text fz={14} mb={10} ml={120} fw="500">
                  Secret Key :
                </Text>
                <Input
                  w={602}
                  value={selectedSecretKey}
                  onChange={(e) => setSelectedSecretKey(e.target.value)}
                />
              </Group>
            )}
            <Text fw="500" c="red" fz={14}>
              Please note: The revenue generating page is usually a thank you or confirmation page. The final conversion page in your funnel.
            </Text>
            <Text fw="500" c="red" fz={14}>
              It is important to note that even if your buyer does not make it all the way to the end of your funnel, Visiopt will still collect the total order amount inclusive of any upsells or downsells.
            </Text>
            <Group position="left">
              <Button type="submit" size="sm" w={140} mt={10}>
                Save Webhook
              </Button>
            </Group>
          </form>
          :
          <Group>
            <Button type="submit" size="sm" w={165} onClick={() => AddWebhook()}>
              Add New Webhook
            </Button>
            <Table striped highlightOnHover withBorder fontSize="xs">
             <thead>
               <tr>
                 <th>Processor</th>
                 <th>Webhook Page</th>
                 <th>Webhook Url</th>
                 <th>Edit</th>
                 <th>Delete</th>
               </tr>
             </thead>
             <tbody>{testShoppingCartProcessorRows}</tbody>
            </Table>
          </Group>
        }
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Behavioral Filters Model*/}
    <Modal.Root opened={behavioralFilterModalOpen} onClose={handleBehavioralFilterCloseModal} size="80%">
	    <Modal.Overlay />
	    <Modal.Content>
	      <Modal.Header>
	        <Modal.Title>BEHAVIORAL DIMENSIONS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
	        <Modal.CloseButton />
	      </Modal.Header>
	      <Modal.Body>
	        <form onSubmit={handleSaveBehavioralFilter}>
	        	<Group>
            <div>
              {dropdownSets.map((set) => (
                <div key={set.id} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  {showFirstDropdown && (
                  <Select
                    data={behavioralFilterFirstDropdown.map((item) => ({ value: item.key, label: item.value }))}
                    value={set.firstDropdown}
                    onChange={(value) => handleFirstDropdown(value, set.id)}
                    searchable
                    placeholder="Select value"
                    nothingFound="No options"
                    maxDropdownHeight={120}
                    size="sm"
                    dropdownPosition="bottom"
                    mb={10}
                    style={{marginRight:15}}
                  />
                  )}
                  {showThirdDropdown[set.id] && (
                  <Select
                    data={(behavioralFilterThirdDropdown[set.id] || []).map((item) => ({ value: item.key, label: item.value }))}
                    value={set.thirdDropdown}
                    onChange={(value) => handleThirdDropdown(value, set.id)}
                    searchable
                    nothingFound="No options"
                    maxDropdownHeight={120}
                    size="sm"
                    dropdownPosition="bottom"
                    mb={10}
                    style={{marginRight:15}}
                  />
                  )}
                  {showSecondDropdown[set.id] && (
                  <Select
                    data={(behavioralFilterSecondDropdown[set.id] || []).map((item) => ({ value: item.key, label: item.value }))}
                    value={set.secondDropdown}
                    onChange={(value) => handleSecondDropdown(value, set.id)}
                    searchable
                    nothingFound="No options"
                    maxDropdownHeight={120}
                    size="sm"
                    dropdownPosition="bottom"
                    mb={10}
                    style={{ marginRight: 15 }}
                  />
                  )}
                  {showFirstDropdown && (
    	            <IconCircleMinus size="1.1rem" color="red" style={{marginBottom:8}} onClick={() => handleRemoveFilter(set.id)}/>
                  )}
                </div>
              ))}
            </div>
            </Group>
            <Button onClick={handleAddFilter}>
              <IconPlus size="1.1rem" color="white"/><Text ml={5}>Add Filter</Text>
            </Button>
            <Divider my="sm" />  
	          <Group position="right">
	          	<Button type="button" size="sm" color="orange" onClick={handleResetBehavioralFilter}>
	          	  Reset Filter
	          	</Button>
              <Button type="submit" size="sm" w={80}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Attribution Model*/}
    <Modal.Root opened={attributionModalOpen} onClose={handleAttributionModelCloseModal}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>ATTRIBUTION MODEL <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body h={200}>
          <form onSubmit={handleSaveAttributionModel}>
            <Select
              label="Attribution Model"
              defaultValue={selectedAttributionModal}
              onChange={(value) => setSelectedAttributionModal(value)}
              data={attributionModalOptions}
              searchable
              nothingFound="No options"
              maxDropdownHeight={200}
              size="sm"
              dropdownPosition="bottom"
              mb={10}
            />
            <Group position="right">
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Traffic Control Model*/}
    <Modal.Root opened={trafficControlModalOpen} onClose={handleTrafficControlCloseModal} size="80%">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>TRAFFIC CONTROL <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSaveTrafficControl}>
            <Grid mt={20}>
            	<Grid.Col span={3}></Grid.Col>
              <Grid.Col span={1}>All Tests</Grid.Col>
              <Grid.Col span={4} mt={6}>
                <Slider
                  value={valueTrafficControl}
                  onChange={(value) => {
                    setValueTrafficControl(value);
                  }}
                  min={10}
                  max={90}
                />
              </Grid.Col>
              <Grid.Col span={1}>Control</Grid.Col>
              <Grid.Col span={3}></Grid.Col>
            </Grid>
            <Text mt={10} mb={20}>Percentage of traffic routed to:</Text>
            <Group>
		          <Text style={{ display: 'flex'}}>Control:<Input w={80} size="xs" value={valueTrafficControl} mr={5} ml={5} disabled/>%</Text>
              {trafficControlValues.map((item, index) => (
                <Text key={index} style={{ display: 'flex' }} ml={10} w={parseInt(item.variableId) >= 10 ? 160 : 150}>
                  Test {item.variableId}:
                  <Input w={80} size="xs" value={item.value} mr={5} ml={5} disabled />%
                </Text>
              ))}
	          </Group>
            <Group position="right">
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Google Analytics Integration Model*/}
    <Modal.Root opened={googleAnalyticsIntegrationModalOpen} onClose={handleGoogleAnalyticsIntegrationCloseModal} size="45%">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>GOOGLE ANALYTICS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSaveGoogleAnalytics}>
            <Text weight={500}>Traking ID: {gaAccountPrefix} {gaDimension}</Text>
            <Text weight={500} mt={4}>Note: Make sure you have added Google Tracking JS to your page.</Text>
            <Grid mt={15} justify="center">
            	<Grid.Col span={5}><Text mt={5}>Visiopt Test Name:</Text></Grid.Col>
            	<Grid.Col span={7}><Input value={visiTestName} onChange={(e) => setVisiTestName(e.target.value)}/></Grid.Col>
            </Grid>
            <Divider my="sm" />
            <Grid justify="center">
            	<Grid.Col span={5}><Text>Send Test Name to GA?</Text></Grid.Col>
            	<Grid.Col span={7}><Checkbox checked={isCheckboxTestNameChecked} onChange={(event) => handleCheckboxChange(event, 'testName')} /></Grid.Col>
            </Grid>
            <Grid justify="center">
            	<Grid.Col span={5}><Text mt={5}>Custom Dimension Name:</Text></Grid.Col>
            	<Grid.Col span={7}><Input value={testDimName} onChange={(e) => setTestDimName(e.target.value)}/></Grid.Col>
            </Grid>
            <Grid justify="center">
            	<Grid.Col span={5}><Text mt={5}>Custom Dimension Index:</Text></Grid.Col>
            	<Grid.Col span={7}><Input value={testDimIndex} onChange={(e) => setTestDimIndex(e.target.value)}/></Grid.Col>
            </Grid>
            <Divider my="sm" />
            <Grid justify="center">
            	<Grid.Col span={5}><Text>Send Variation Number to GA?</Text></Grid.Col>
            	<Grid.Col span={7}><Checkbox checked={isCheckboxVariationNumberChecked}  onChange={(event) => handleCheckboxChange(event, 'variationNumber')} /></Grid.Col>
            </Grid>
            <Grid justify="center">
            	<Grid.Col span={5}><Text mt={5}>Custom Dimension Name:</Text></Grid.Col>
            	<Grid.Col span={7}><Input value={varDimName} onChange={(e) => setVarDimName(e.target.value)}/></Grid.Col>
            </Grid>
            <Grid justify="center">
            	<Grid.Col span={5}><Text mt={5}>Custom Dimension Index:</Text></Grid.Col>
            	<Grid.Col span={7}><Input value={varDimIndex} onChange={(e) => setVarDimIndex(e.target.value)}/></Grid.Col>
            </Grid>
            <Group position="right">
              <Button type="submit" size="sm" w={80} mt={25}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Conversion Logs Model*/}
    <Modal.Root opened={conversionLogsModalOpen} onClose={handleConversionLogsCloseModal} size="70%">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>CONVERSION LOG <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body h={350}>
          <Group mt={15} >
          	<SelectTimePicker SelectTimePickerValue={selectedTimePicker} SelectTimePickerOnChange={handleselectedTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedTimePickerToDateChange} SelectTestStartDate={testStartDate}/>
          	<Text>Conversion Type:</Text>
          	<Select
          		dropdownPosition="bottom"
          		// maxDropdownHeight={100}
          		value={conversionType}
				      searchable
				      data={conversionTypeData}
				      onChange={handleConversionType}
				    />
				    <Text style={{ display: conversionTypeName === "Reset Conversion" ? "none" : "block" }}>Conversion to:</Text>
			      <Radio.Group
			        name="ConGoalType"
			        mb={10}
			        value={convLogRedioBtn}
			        onChange={(value) => handleRadioChange(value)}
			        style={{ display: conversionTypeName === "Reset Conversion" ? "none" : "block" }}
			      >
			        <Group mt="xs">
			          <Radio value="goal" label="Goals" />
			          <Radio value="postback" label="Postbacks" />
			        </Group>
			      </Radio.Group>
			      <Select
		          dropdownPosition="bottom"
		          // maxDropdownHeight={100}
		          value={convLogRedioBtn === 'goal' ? goalList : postbackList}
		          onChange={handleSelectListChange}
		          searchable
		          data={convLogRedioBtn === 'goal' ? goalListData : postbackListData}
		          style={{ display: conversionTypeName === "Reset Conversion" ? "none" : "block" }}
		        />
				    <Button type="submit" size="sm" w={80} onClick={handleConversionDataLog}>
				      Filter
				    </Button>
          </Group>
          <Divider my="sm" />
  		    <Group style={{ display: conversionTypeName === "Undefined Conversion" && filterConversionLog === 'yes' ? "block" : "none" }}>
  			  	<Table striped highlightOnHover withBorder my={15} fontSize="xs">
              <thead>
              <tr>
		            {undefinedConversionTableField.map((element, index) => (
		              <th key={index}>{element}</th>
		            ))}
		          </tr>
              </thead>
              <tbody>
              	{
								  undefinedConversionTableData.length > 0 ? (
								    <>
								      {conversionTypeStatus === "pixel_goal_conversion" && (
								        undefinedConversionTableData.map((element, index) => (
								          <tr key={index}>
								            <td>{element.goal_name}</td>
								            <td>{element.total_undefined_conv}</td>
								          </tr>
								        ))
								      )}
								      {conversionTypeStatus === "undefined_goal_webhook_conversion" && (
								        undefinedConversionTableData.map((element, index) => (
								          <tr key={index}>
								            <td>{element.date}</td>
								            <td>{element.email}</td>
								            <td>{element.transaction_id}</td>
								            <td>{element.sales_amount}</td>
								            <td>{element.reason}</td>
								          </tr>
								        ))
								      )}
								    </>
								  ) : (
								    <tr>
								      <td colSpan={undefinedConversionTableField.length} style={{ textAlign: 'center' }}>No Data Found</td>
								    </tr>
								  )
								}
              </tbody>
		  	    </Table>
			    </Group>
  		    <Group style={{ display: conversionTypeName === "Defined Conversion" && filterConversionLog === 'yes' ? "block" : "none" }}>
  			  	<Table striped highlightOnHover withBorder my={15} fontSize="xs">
              <thead>
                <tr>
                  {definedConversionTableField.map((element, index) => (
			              <th key={index}>{element}</th>
			            ))}
                </tr>
              </thead>
              <tbody>
                {
								  definedConversionTableData.length > 0 ? (
								    <>
								      {conversionTypeStatus === "pixel_goal_conversion" && (
								        definedConversionTableData.map((element, index) => (
								          <tr key={index}>
								            <td>{element.goal_name}</td>
								            <td>{element.total_undefined_conv}</td>
								          </tr>
								        ))
								      )}
								      {conversionTypeStatus === "defined_goal_webhook_conversion" && (
								        definedConversionTableData.map((element, index) => (
								          <tr key={index}>
								            <td>{element.date}</td>
								            <td>{element.email}</td>
								            <td>{element.transaction_id}</td>
								            <td>{element.sales_amount}</td>
								          </tr>
								        ))
								      )}
								    </>
								  ) : (
								    <tr>
								      <td colSpan={definedConversionTableField.length} style={{ textAlign: 'center' }}>No Data Found</td>
								    </tr>
								  )
								}
              </tbody>
		  	    </Table>
			    </Group>
  		    <Group style={{ display: conversionTypeName === "Reset Conversion" && filterConversionLog === 'yes' ? "block" : "none" }}>
  			  	<Table striped highlightOnHover withBorder my={15} fontSize="xs">
              <thead>
                <tr>
               		{resetConversionTableField.map((element, index) => (
			              <th key={index}>{element}</th>
			            ))}
                </tr>
              </thead>
              <tbody>
                {
                  resetConversionTableData.length > 0 ? (
                    <>
                      {conversionTypeStatus === "reset_conversion" && (
                        resetConversionTableData.map((element, index) => (
                          <tr key={index}>
                            <td>{element.action}</td>
                            <td>{element.date}</td>
                            <td>{element.email}</td>
                          </tr>
                        ))
                      )}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={resetConversionTableField.length} style={{ textAlign: 'center' }}>No Data Found</td>
                    </tr>
                  )
                }
             	</tbody>
		  	    </Table>
			    </Group>
			    {filterConversionLog === 'yes' && totalPages ?
			    <Pagination.Root total={totalPages} value={activePageNo} onChange={handlePageChange}>
			      <Group spacing={5} position="center">
			        <Pagination.First />
			        <Pagination.Previous />
			        <Pagination.Items />
			        <Pagination.Next />
			        <Pagination.Last />
			      </Group>
			    </Pagination.Root> : ''}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Test Notes Model*/}
    <Modal.Root opened={testNotesModalOpen} onClose={handleTestNotesCloseModal} size="80%">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>TEST NOTES <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body h={480}>
          <form onSubmit={handleSaveTestNotes}>
            <Input.Wrapper label="Note Title:" mb={10}>
              <Input value={noteTitle} onChange={(e) => setNoteTitle(e.target.value)} />
            </Input.Wrapper>
            <RichTextEditor editor={testnotesEditor}>
              <TextEditorToolbar />
              <div style={{height: '300px', overflowY: 'auto'}}>
                <RichTextEditor.Content />
              </div>
            </RichTextEditor>
            <Group position="right">
              <Button type="submit" size="sm" w={80} mt={10} mb={10}>
                Save
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*VisiSmart Code Model*/}
    <Modal.Root opened={visiSmartCodeModalOpen} onClose={handleVisiSmartCodeCloseModal} size="80%">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>VISISMART CODE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
        	{testingCode.map((element,index) => (
        		<Grid key={index} justify="space-around" align="flex-end">
        			<Grid.Col span={11}>
			          <Textarea
			          	label={`Please add the following code into the page `+element.funnel_page_url+`: ( VisiSmart Code )`}
			          	value={element.funnel_test_code_variant}
			          	minRows={2}
			          	maxRows={3}
			          	maw={1000}
			          	variant="filled"
			       		/>
			       	</Grid.Col>
			       	<Grid.Col span={1}>
	       				<CopyButton  timeout={2000} value={element.funnel_test_code_variant}>
	       		     	{({ copied, copy }) => (
	       		       	<Button color={copied ? 'teal' : 'blue'} onClick={copy}>
	       		         	{copied ? 'Copied' : 'Copy'}
	       		       	</Button>
	       		     	)}
	       		   	</CopyButton>
	       		  </Grid.Col>
	       		</Grid>
          ))}
          <Divider my="sm" />
          {hideAdvancedOptions === '' && (
	          <Button type="submit" size="sm" color="orange" onClick={handleAdvancedOptionsVisiSmart}>
		          {visiSmartAdvancedOptions} Advanced Options
		        </Button>
          )}
	        <Card style={{ display : visiSmartAdvancedOptions === 'Hide' ? "block": "none"}}>
	          <Text>Code snippet for get variation #no on LP page:</Text>
	          <Group>
	          {hideAdvancedOptionsVisiSmartCode.map((advancedOption,index) => (
		          <Grid key={index} justify="space-around" align="flex-end" w={340}>
		          	<Grid.Col span={9}>
				          <Textarea
				          	label={advancedOption.title}
				           	value={advancedOption.smartCode}
				           	mt={10}
				           	minRows={3}
				           	maxRows={4}
				           	maw={320}
				           	variant="filled"
				        		/>
			        	</Grid.Col>
				       	<Grid.Col span={3}>	
				        	<CopyButton  timeout={2000} value={advancedOption.smartCode}>
				            {({ copied, copy }) => (
				              <Button color={copied ? 'teal' : 'blue'} onClick={copy} mt={5}>
				                {copied ? 'Copied' : 'Copy'}
				              </Button>
				            )}
				          </CopyButton>
		          	</Grid.Col>
		       		</Grid>
	          ))}
	          </Group>
	          <Divider my="sm" />
	          <Text>Javascript</Text>
	          <Text fw={500} size={12}>Note : <Anchor target="_blank" href={downloadCookiesUrl}>Download reset_cookies.php </Anchor> File and upload in your server. Add below JavaScript before VisiSmart Code and replace CHANGE YOUR URL where you uploaded reset_cookies.php</Text>
		        <Grid justify="space-around" align="flex-end" gutter="xs">
		        	<Grid.Col span={6}>
				        <Textarea
				         	value="<script>var visi_cookie_track_url = 'CHANGE YOUR URL/reset_cookies.php';</script>"
				         	mt={10}
				         	minRows={1}
				         	variant="filled"
				      		/>
			      	</Grid.Col>
				      <Grid.Col span={1}>
				      	<CopyButton  timeout={2000} value="<script>var visi_cookie_track_url = 'CHANGE YOUR URL/reset_cookies.php';</script>">
				          {({ copied, copy }) => (
				            <Button color={copied ? 'teal' : 'blue'} onClick={copy} mt={5} w={82}>
				              {copied ? 'Copied' : 'Copy'}
				            </Button>
				          )}
				        </CopyButton>
				      </Grid.Col>
				      <Grid.Col span={5}>
	    	      	<Button component="a" href={downloadCookiesUrl} target="_blank" >
	                Download PHP File
	              </Button>
				      </Grid.Col>
			      </Grid>
			    </Card>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
    {/*Set Winner Model*/}
    <Modal.Root opened={setWinnerModalOpen} onClose={handleSetWinnerCloseModal} size="80%">
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            SET WINNER <IconHelp size="1.1rem" color="red" style={{ marginBottom: -3 }} />
          </Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Text weight={500} fz={14}>CONGRATULATIONS, YOU HAVE A WINNER!</Text>
          <Text mt={12} fz={14}>
            To ensure only the winning versions of the variables will show for your visitors in the future you have two choices:
          </Text>
          <Text mt={12} fz={14}>
            <Text fz={14} >1. Do nothing, simply leave the Visiopt test script on your page, and we will make sure only the winning versions show to visitors</Text>
            <Text fz={14} mt={12}>2. You can manually update the winning versions of the variables on your page. For your convenience we display the code that must be changed on your page in the table below.
              <Text fz={14}>To use this option, please update your page and then stop the test.</Text>
              <Text fz={14}>(To update the source code of your page to the winning versions of the variables you have to search for the text/code of the control versions of each variable and replace it with the text/code of the corresponding winning versions.)</Text>
            </Text>
          </Text>
          <form>
            <Skeleton visible={loading}>
              {setWinnerData.map((item,index) => (
                <>
                <Text fz={14} mt={15} weight={500}>Variable: {item.variable_title}</Text>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                  <div style={{ flex: 1, marginRight: 10 }}>
                    <Text fz={14}>Control Version: {item.control_title}</Text>
                    <Textarea
                      value={item.control_content}
                      minRows={5}
                      readOnly
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: 10 }}>
                    <Text fz={14} ml={10}>Winner Version: {item.ver_title}</Text>
                    <Textarea
                      value={item.ver_content}
                      minRows={5}
                      readOnly
                    />
                  </div>
                </div>
                </>
              ))}
            </Skeleton>
            <Group position="right">
              <Button type="button" size="sm" w={100} mt={10} mb={10} onClick={handleSaveSetWinner}>
                Continue
              </Button>
            </Group>
          </form>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>

	  <Container size="xxl">
      <Skeleton visible={loading}>
	  	<div style={{ display: 'flex',marginBottom:"10px"}}>
		    <Box w={130}>
  		    <Button rightIcon={<IconHelp size="1.1rem" color="red"/>} variant="default" bg={variationsBtn === '1' ? '#e7fcf5' : "" } type="submit" onClick={() => handleOptimize('Variations')}>
            Variations
          </Button>
        </Box>
        {variablesTabShow === 'show' &&( 
          <Box w={130}>
    		    <Button rightIcon={<IconHelp size="1.1rem" color="red"/>} variant="default" bg={variablesBtn === '1' ? '#e7fcf5' : "" } type="submit" onClick={() => handleOptimize('Variables')}>
              Variables
            </Button>
          </Box>
        )}
	  	</div>
      <div style={{ display: variationsBtn === '1' ? "block" : "none" }}>
  	  	<Card shadow="sm" padding="sm" radius="md" withBorder>
  	  		<div style={{ display: 'flex',marginBottom: '10px' }}>
  		  		<Group position="apart" mb="xs">
  		        <Text weight={500}>TEST - {testPageTitleShow} <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
  		      </Group>
  		      <div style={{ display: 'flex',marginLeft:"auto"}}>
  			     	<ActionIcon
  	            variant="white"
  	            size="lg"
  	          >
  	            <IconClockHour4 size="1.1rem" />
  	          </ActionIcon>
  	          <SelectTimePicker SelectTimePickerValue={selectedTimePicker} SelectTimePickerOnChange={handleselectedTimePickerComponentChange} SelectTimePickerFromDateOnChange={handleselectedTimePickerFromDateChange} SelectTimePickerToDateOnChange={handleselectedTimePickerToDateChange} SelectTestStartDate={testStartDate}/>
  		     		<ActionIcon
  		     		  variant="default"
  		     		  size="lg"
  		     		 	sx={(theme) => ({
  	               backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
  	             })}
  		     		  title="Refresh Stats"
  		     		  ml={5}
  		     		  onClick={handleRefreshStats}
  		     		>
  		     		  <IconRefresh size="1.1rem" />
  		     		</ActionIcon>
  		     		<ActionIcon
					      variant="default"
					      size="lg"
					      sx={(theme) => ({
					        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#fff',
					      })}
					      title={testPauseResumeTitle}
					      ml={5}
					      onClick={handlePauseResumeTest}
					    >
					      {testPauseResumeIcon}
					    </ActionIcon>
  		     		<ActionIcon
  		     		  variant="default"
  		     		  size="lg"
  		     		  sx={(theme) => ({
  	               backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
  	             })}
  		     		  title="Test Info"
  		     		  ml={5}
  		     		  onClick={() => handleTestInfoOpenModal(1)}
  		     		>
  		     		  <IconInfoCircle size="1.1rem" />
  		     		</ActionIcon>
  		     		<ActionIcon
  		     		  variant="default"
  		     		  size="lg"
  		     		  sx={(theme) => ({
  	               backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
  	             })}
  		     		  title="Discart Test"
  		     		  ml={5}
  		     		  onClick={() => discardTestConfirm(testId)}
  		     		>
  		     		  <IconTrash size="1.1rem" />
  		     		</ActionIcon>
  		     		
  		     		<Menu width={260} position="bottom-end" withinPortal>
  		     		  <Menu.Target>
  		     		    <ActionIcon
      		     		  variant="default"
      		     		  size="lg"
      		     		  sx={(theme) => ({
      	               backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#fff",
      	             })}
      		     		  title="Settings"
      		     		  ml={5}
      		     		>
      		     		  <IconSettings size="1.1rem" />
      		     		</ActionIcon>
  		     		  </Menu.Target>

  		     		  <Menu.Dropdown>
  		     		    <Menu.Item px={10} py={6} onClick={() => handleAudienceSizeOpenModal(1)}>Audience Size</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={() => handleShoppingCartProcessorOpenModal(1)}>Connect Shopping Cart & Processor</Menu.Item>
                  <PermissionWrapper permission="behavioral_dimensions_access">
                    <Menu.Item px={10} py={6} onClick={() => handleBehavioralFilterOpenModal(1)}>Behavioral Filters</Menu.Item>
                  </PermissionWrapper>
                  {testTypeShow !== "classic_test" && (
  		     		     <Menu.Item px={10} py={6} onClick={() => handleEditTest(pageIdShow,tagichiTypeIdShow)}>Edit Test</Menu.Item>
                  )}
  		     		    <Menu.Item px={10} py={6} onClick={() => setResetStatsModalIsOpen(true)}>Reset Stats</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={() => handleAttributionModelOpenModal(1)}>Attribution Model</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={() => handleTrafficControlOpenModal(1)}>Traffic Control</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={() => handleGoogleAnalyticsIntegrationOpenModal(1)}>Google Analytics Integration</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={() => handleConversionLogsOpenModal(1)}>Logs</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={() => handleTestNotesOpenModal(1)}>Test Notes</Menu.Item>
                  <Menu.Item px={10} py={6} onClick={() => handleTestMode()}>Test Mode</Menu.Item>
  		     		    <Menu.Item px={10} py={6} onClick={() => handleVisiSmartCodeOpenModal(1)}>VisiSmart Code</Menu.Item>
  		     		  </Menu.Dropdown>
  		     		</Menu> 
  		     	</div>
  		    </div>
  		    <Group>
  		      <Text mb={6}><IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/> Conversions to:</Text>
  		      <Radio.Group
  		        name="GoalType"
  		        mb={10}
  		        value={convRedioBtn}
              onChange={handleConvRadioChange}
  		      >
  		        <Group mt="xs">
  		          <Radio value="1" label="Goals" />
  		          <Radio value="2" label="Buttons" />
  		          <Radio value="3" label="Postbacks" />
  		        </Group>
  		      </Radio.Group>
  		      <Select
              value={conversionTypeValueSelected}
              onChange={handleSelectConvChange}
  			      data={convRedioBtn === '1' ? funnelGoal : convRedioBtn === '2' ? buttonOptions : postbackOption}
  			    />
            {testModeShow !== 0 && (
              <Text weight={500} mt={4} c="red">Test Mode Active</Text>
            )}
  		    </Group>
  		    <Group mt={10}>
            <MultiSelect
              data={filteredOptions}
              onChange={handleSelectFilteredChange} 
              value={selectedfilteredOptions}
              multiple
              placeholder="Select Metrics"
            />
    		    <Box w={200}>
              {behavioralFilterArrCount !== 0 && (
  	  		    <Button fullWidth variant="default" bg="#e7fcf5" onClick={() => handleBehavioralFilterOpenModal(1)}>
  	            Behavioral Filters Applied
  	          </Button>
              )}
  	        </Box>
  		    </Group>
            <div className="ads_network">

            {Object.keys(behavioralDimensionNetworkParameters).length > 0 && (
              <>
              <Text weight={500} mt={15}>Network Parameters</Text>
              {Object.keys(behavioralDimensionNetworkParameters).map(network => (
                <div key={network} style={{ marginBottom: '15px' }}>
                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <Text weight={500} mt={4} tt="capitalize">{network}:</Text>
                    <div style={{ display: 'flex', marginLeft: '10px' }}>
                      {Object.keys(behavioralDimensionNetworkParameters[network]).map(parameterName => {
                        const parameter = behavioralDimensionNetworkParameters[network][parameterName];
                        return (
                          <div key={`${network}-${parameterName}`} style={{ display: 'flex', marginRight: '15px' }}>
                            <Text color="red" mx={5} mt={4}>{parameterName}</Text>
                            <input type="hidden" name={`${network}-${parameterName}-id`} value={parameter.array_id} />
                            <MultiSelect
                              data={parameter.dropdown_data.map(item => ({
                                value: item.key,
                                label: item.value
                              }))}
                              onChange={(values) => handleSelectNetworkParameters(values, network, parameterName, parameter.array_id)}
                              name={`${network}-${parameterName}`}
                              nothingFound="No matches found"
                              searchable
                              radius="sm"
                              size="sm"
                              mr={10}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ))}
              </>
            )}
            {Object.keys(behavioralDimensionTrackingTags).length > 0 && (
              <>
              <Text weight={500} mt={15}>Tracking tags</Text>
              <div className="ads_network" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                {Object.keys(behavioralDimensionTrackingTags).map(parameterName => {
                  const parameter = behavioralDimensionTrackingTags[parameterName];
                  return (
                    <div key={`${parameterName}`} style={{ display: 'flex', marginRight: '15px' }}>
                      <Text color="red" mx={5} mt={4}>{parameterName}</Text>
                      <input type="hidden" name={`${parameterName}-id`} value={parameter.array_id} />
                      <MultiSelect
                        data={parameter.dropdown_data.map(item => ({
                          value: item.key,
                          label: item.value
                        }))}
                        onChange={(value) => handleSelectTrackingTags(parameterName, value, parameter.array_id)}
                        name={parameterName}
                        nothingFound="No matches found"
                        searchable
                        radius="sm"
                        size="sm"
                        mr={10}
                      />
                    </div>
                  );
                })}
              </div>
             </>
            )}            
            {Object.keys(behavioralDimensionCustomTags).length > 0 && (
              <>
              <Text weight={500} mt={15}>Custom tags</Text>
              <div className="ads_network" style={{ display: 'flex', marginTop: '10px' }}>
                {Object.keys(behavioralDimensionCustomTags).map(parameterName => {
                  const parameter = behavioralDimensionCustomTags[parameterName];
                  return (
                    <div key={`${parameterName}`} style={{ display: 'flex', marginRight: '15px' }}>
                      <Text color="red" mx={5} mt={4}>{parameterName}</Text>
                      <input type="hidden" name={`${parameterName}-id`} value={parameter.array_id} />
                      <MultiSelect
                        data={parameter.dropdown_data.map(item => ({
                          value: item.key,
                          label: item.value
                        }))}
                        onChange={(value) => handleSelectCustomTags(parameterName, value, parameter.array_id)}
                        name={parameterName}
                        nothingFound="No matches found"
                        searchable
                        radius="sm"
                        size="sm"
                        mr={10}
                      />
                    </div>
                  );
                })}
              </div>
            </>
            )}
            {Object.keys(behavioralDimensionSource).length > 0 && (
              <>
              <Text weight={500} mt={15}>Source</Text>
              <div className="ads_network" style={{ display: 'flex', marginTop: '10px' }}>
                <Text color="red" mx={5} mt={4}>source</Text>
                <MultiSelect
                  data={behavioralDimensionSource.map(item => ({
                    value: item.key,
                    label: item.value
                  }))}
                  onChange={handleSelectSource}
                  nothingFound="No matches found"
                  searchable
                  radius="sm"
                  size="sm"
                  mr={10}
                />
              </div>
              </>
            )}
            {displayFilterButton === 1 &&(
              <Button style={{ marginTop: '15px' }} onClick={() => behavioralDimensionFilter()}>
                Filter
              </Button>
            )}
          </div>
  		    <Group>
            {testVariationsData.length > 1 && (
  			  	<Table striped highlightOnHover withBorder my={15} fontSize="xs">
             <thead>
               <tr>
                 <th>ID</th>
                 <th>Variations</th>
                  {selectedfilteredOptions.includes('Uniques') && <th>Uniques</th>}
                  {selectedfilteredOptions.includes('Visits') && <th>Visits</th>}
                  {selectedfilteredOptions.includes('Conv. # to page') && <th>Conv. # to page</th>}
                  {selectedfilteredOptions.includes('Conv. % to page') && <th>Conv. % to page</th>}
                  {selectedfilteredOptions.includes('Percentage Improvement') &&<th>Percentage Improvement</th>}
                 <th>Choose Winner</th>
                 {selectedfilteredOptions.includes('Statistical Significance') &&<th>Statistical Significance</th>}
                 {webhookSetShow !== 0 && (
                 <>
                  {selectedfilteredOptions.includes('Total Revenue') && <th>Total Revenue</th>}
                  {selectedfilteredOptions.includes('AOV') && <th>AOV</th>}
                  {selectedfilteredOptions.includes('Avg. Revenue per unique') && <th>Avg. Revenue per unique</th>}
                 </>
                 )}
                 {selectedfilteredOptions.includes('Avg. Time Spent') && <th>Avg. Time Spent</th>}
                 {hidePauseShow !== 'hide' && (
                 <>
                  <th>Pause Variation</th>
                 </>
                 )}
               </tr>
             </thead>
             <tbody>{testVariationsRows}</tbody>
  	  	    </Table>
            )}
  		    </Group>
  			</Card>
  			<Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
  				<Group position="apart" mb="xs">
  		      <Text weight={500}>CONVERSION TO PAGE/GOALS <IconHelp size="1.1rem" color="red" style={{marginBottom:-3,marginRight:15}}/>   {convtopageNameShow}</Text>
  		    </Group>
          <Group>
            <Text>Select Graph :</Text>
            <Select
              mb={5}
              mt={5}
              dropdownPosition="bottom"
              defaultValue="0"
              maxDropdownHeight={100}
              searchable
              data={[
                { value: '0', label: 'Main Conversions' },
                { value: '1', label: 'Cumulative Conversions' },
              ]}
              onChange={(value) => setSelectedGraph(value)}
            />
          </Group>
  		    <Grid mb={15}>
  			    <Grid.Col span={9} style={{ textAlign: 'left' }}>
            {selectedGraph === '1' ? (
            <div>
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={dataLineChart}
                options={optionsLineChart}
              />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
                  {paginatedLabels.map((label, index) => (
                    <div key={label} style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '15px',
                          height: '15px',
                          backgroundColor: paginatedColors[index],
                          marginRight: '5px',
                        }}
                      >
                      </div>
                      <span>{label}</span>
                    </div>
                  ))}
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                    <span onClick={() => setCurrentPage(Math.max(currentPage - 1, 0))} style={{ cursor: currentPage === 0 ? 'none' : 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}>
                      <IconChevronLeft stroke={2} size="1.125rem"/>
                    </span>
                    <span style={{ margin: '-3px 5px' }}>{currentPage + 1}/{charttotalPages}</span>
                    <span onClick={() => setCurrentPage(Math.min(currentPage + 1, charttotalPages - 1))} style={{ cursor: currentPage === charttotalPages - 1 ? 'none' : 'pointer', opacity: currentPage === charttotalPages - 1 ? 0.5 : 1 }}>
                      <IconChevronRight stroke={2} size="1.125rem"/>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <>
              <Radio.Group
                name="Conversions"
                mb={10}
                value={selectedValue}
                onChange={handleConversionRadioChange}
              >
                <Group mt="md">
                  <Radio value="1" label="Conversions" />
                  <Radio value="2" label="Conversion %" />
                </Group>
              </Radio.Group>
              {dataColumnChartRows.length > 1 && dataColumnPercentageChartRows.length > 1 && (
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={selectedValue === "1" ? dataColumnChartRows : dataColumnPercentageChartRows}
                  options={selectedValue === "1" ? optionsColumnChart : optionsColumnPercentageChart}
                />
              )}
              </>
            )}
  			    </Grid.Col>
  			    <Grid.Col span={3} style={{ textAlign: 'left' }} mt={20}>
  				    <Box
  				      sx={(theme) => ({
  				        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : "#f8f9fa",
  				        padding: "10px",
  				        borderRadius: "4px",
  				        width:"auto"
  				      })}
  				    >
  				      {/*<div style={{ display: 'flex'}}>
  				      	<Text size="sm" fz={14} fw={500} mr={5}>Test Start on:</Text>
  				      	<Text size="sm" fz={14}>12-14-2023</Text>
  				      </div>*/}
                <div style={{ display: 'flex'}}>
                  <Text size="sm" fz={14} fw={500} mr={5} style={{ display: 'inline-block',width:"90px"}}>Test Totals</Text>
                  <div style={{ display: 'inline-block'}}>
                    <Text size="sm" fz={14} mr={5}>({testNameShow} - <Anchor href={testPageLinkShow} target="_blank" fw={500} mr={5} mt={-2}>{testPageTitleShow} </Anchor>)</Text>
                  </div>
                </div>
  				      <Space h="xs" />
  			        <div style={{ display: 'flex'}}>
  			        	<Text size="sm" fz={14} fw={500} mr={5}>Uniques:</Text>
  			        	<Text size="sm" fz={14}>{totalUniquesShow}</Text>
  			        </div>
  							<div style={{ display: 'flex'}}>
  								<Text size="sm" fz={14} fw={500} mr={5}>Visits:</Text>
  								<Text size="sm" fz={14}>{totalVisitsShow}</Text>
  							</div>
  							<div style={{ display: 'flex'}}>
  								<Text size="sm" fz={14} fw={500} mr={5}>Conversions:</Text>
  								<Text size="sm" fz={14}>{totalConversionsShow}</Text>
  							</div>
  				    </Box>
  			    </Grid.Col>
  			  </Grid>
  			</Card>
        {pageBottomMsgShow !== '' && (
        <Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
          <Grid mb={15}>
            <Text mt={15} fz={14} ml={10}>
              {pageBottomMsgShow}
            </Text>
          </Grid>
        </Card>
        )}
      </div>
      <div style={{ display: variablesBtn === '1' ? "block" : "none" }}>
        <Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
          <Text weight={500} mt={10} c="red">{variablesMessageShow}</Text>
          <Text weight={500}>NOTE: if running repeat experiments this data will continue to update accordingly</Text>
            <Table highlightOnHover withColumnBorders withBorder my={15} fontSize="xs" maw={500}>
             <thead>
               <tr>
                 <th>Variable / Version</th>
                 <th>Variable influence</th>
               </tr>
             </thead>
             <tbody>{testVariablesRows}</tbody>
            </Table>
        </Card>
        <Card shadow="sm" padding="sm" radius="md" withBorder mt={10} mb={20}>
          <Group position="apart" mb="xs">
            <Text weight={500}>OPTIMUM EXPECTED CONVERSION RATE <IconHelp size="1.1rem" color="red" style={{marginBottom:-3,marginRight:15}}/></Text>
          </Group>
          <Text weight={500} mt={20}>Optimum Expected Conversion Rate: {variablesOptimumResponse}%</Text>
          <Space h="xs" />
          <Text weight={500}>How To Use This Data:</Text>
          <Text>The above chart details which variables had the most influence on making a conversion. Above you will find the best variable options to use.Create a new page using the above influential variables.</Text>
          <Space h="xs" />
          <Text>Important Note:</Text>
          <Space h="xs" />
          <Text weight={500}>What about the Non-Influential Variables?</Text>
          <Text>You can not ignore them.</Text>
          <Text>Choose the option that is least expensive or most convenient. In most cases we recommend choosing the control version of the variable for non-influential variables.</Text>
          <Space h="xs" />
          <Text weight={500}>Optimum Expected Conversion Rate</Text>
          <Text>This is the estimated best expected conversion rate if you create a new page version using the data as described.</Text>
          <Text>Please note this is not a guarantee of a specific conversion rate as there are too many other factors in the marketplace that affect conversion.</Text>
          <Text>We highly recommend you test this new page against the best page from your last test.</Text>
          <Space h="xs" />
          <Text>Please also note that if you use the repeat experiment option variable influence data becomes more accurate.</Text>
        </Card>
      </div>
      {/*ResetStats Modal*/}
      <Modal
        opened={resetStatsModalIsOpen}
        onClose={() => {
          setResetStatsModalIsOpen(false);
          setResetStatsValue('');
          setError('');
        }}
        title="Reset Stats"
      >
        <Text size="sm">
          Are you sure you want to reset the stats of your test to zero? This will also reset your campaign data so you will lose all data. If you want to proceed, please enter the word <b>RESET</b> below:
        </Text>
        <Input
          mt={10}
          mb={10}
          value={resetStatsValue}
          onChange={(e) => {
            const value = e.currentTarget.value;
            setResetStatsValue(value);
            if (value.trim() === 'RESET') {
              setError('');
            }
          }}
        />
        {error && (
          <Text color="red" size="sm" mt={10}>
            {error}
          </Text>
        )}
        <Group position="right">
          <Button onClick={() => handleResetStatsTest(pageIdShow)}>OK</Button>
        </Group>
      </Modal>
      {/*Error Model*/}
      {errorModelOpen && (
      <Modal.Root opened={true} onClose={handlerrorCloseModal} style={{ zIndex: 2 }}>
        <Modal.Overlay />
        <Modal.Content>
          {showMessageHeader ? (
            <Modal.Header>
              <Modal.Title>MESSAGE</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ) : showErrorHeader ? (
            <Modal.Header>
              <Modal.Title>ERROR</Modal.Title>
              <Modal.CloseButton />
            </Modal.Header>
          ) : (
            <Modal.Header>
            </Modal.Header>
          )}
          <Modal.Body>
            <form>
              <div style={{ display: 'flex'}}>
                  <Text weight={500}>{errorMessage}</Text>
              </div>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      )}
      </Skeleton>
	  </Container>
	  </>
	);
}