import { visiUserPermission } from './GeneralFunction';

let userPermissions = [];

if(visiUserPermission()){
  for(const role in visiUserPermission()){
    userPermissions = visiUserPermission()[role];
  }
}

export const rolePermissions = userPermissions;