import { Group, Text, Select, Input, Button } from '@mantine/core';
import { IconHelp, IconX, IconCheck } from '@tabler/icons-react';
import { useState, useEffect } from 'react';
import { SelectMemberWebsiteList } from "../../GeneralComponent/GeneralComponent";
import { VisiLoader } from "../../GeneralComponent/GeneralComponent";
import { useNavigate } from "react-router-dom";
import { notifications } from '@mantine/notifications';
import SettingsApi from "../../../api/SettingsApi";

export function GoogleAnalyticsIntegration({ SelectMemberWebsiteListValue }:any) {

	const navigate = useNavigate();

	const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');
	const [googleAnalyticsIntegrationLoader, setGoogleAnalyticsIntegrationLoader] = useState(false);
	const [googleAnalyticsIntegrationData, setGoogleAnalyticsIntegrationData] = useState({});
	const [gaDimension, setGaDimension] = useState('gtag');
	const [gaAccountPrefix, setGaAccountPrefix] = useState('');

	const handleSelectMemberWebsiteListComponentChange = (Value:any) => {
    setSelectedMemberWebsite(Value);
  };

  useEffect(() => {
  	if(SelectMemberWebsiteListValue !== ""){
  		setSelectedMemberWebsite(SelectMemberWebsiteListValue);
  	}
  }, [SelectMemberWebsiteListValue]);

  useEffect(() => {
  	setGoogleAnalyticsIntegrationLoader(true);
    SettingsApi.getgoogleanalyticsintegrationdata()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setGoogleAnalyticsIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1 && data.data){
      	setGoogleAnalyticsIntegrationData(data.data);
      }
      setGoogleAnalyticsIntegrationLoader(false);
    })
    .catch(error => {
      console.log(error);
      setGoogleAnalyticsIntegrationLoader(false);
    });
  }, []);

  useEffect(() => {
  	if(selectedMemberWebsite !== "" && Object.keys(googleAnalyticsIntegrationData).length > 0){
  		if(googleAnalyticsIntegrationData[selectedMemberWebsite]){
  			if(googleAnalyticsIntegrationData[selectedMemberWebsite].ga_dimension && googleAnalyticsIntegrationData[selectedMemberWebsite].ga_dimension !== ""){
  				setGaDimension(googleAnalyticsIntegrationData[selectedMemberWebsite].ga_dimension);
  			}
  			if(googleAnalyticsIntegrationData[selectedMemberWebsite].ga_account_prefix && googleAnalyticsIntegrationData[selectedMemberWebsite].ga_dimension !== ""){
  				setGaAccountPrefix(googleAnalyticsIntegrationData[selectedMemberWebsite].ga_account_prefix);
  			}else{
  				setGaAccountPrefix("");
  			}
  		}
  	}
  }, [selectedMemberWebsite, googleAnalyticsIntegrationData]);

  const submitGAI = () => {
  	setGoogleAnalyticsIntegrationLoader(true);
  	let dataObject = {
  		ga_website : selectedMemberWebsite,
  		ga_dimension : gaDimension,
  		ga_account_prefix : gaAccountPrefix
  	}
  	SettingsApi.savegoogleanalyticssettings(dataObject)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          setGoogleAnalyticsIntegrationLoader(false);
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
      	if(Object.keys(googleAnalyticsIntegrationData).length > 0){
      		if(googleAnalyticsIntegrationData[selectedMemberWebsite]){
      			googleAnalyticsIntegrationData[selectedMemberWebsite].ga_dimension = gaDimension;
      			googleAnalyticsIntegrationData[selectedMemberWebsite].ga_account_prefix = gaAccountPrefix;
      		}
      	}else{
      		setGoogleAnalyticsIntegrationData({
      			selectedMemberWebsite : {
      				ga_dimension : gaDimension,
      				ga_account_prefix : gaAccountPrefix
      			}
      		});
      	}
        setGoogleAnalyticsIntegrationLoader(false);
        notifications.show({
          color: 'teal',
          icon: <IconCheck size="1.1rem" />,
          title: 'Success',
          message: data.message,
        });
      }else{
        setGoogleAnalyticsIntegrationLoader(false);
        notifications.show({
          color: 'red',
          icon: <IconX size="1.1rem" />,
          title: 'Error',
          message: data.message,
        })
      }
    })
    .catch(error => {
      console.log(error);
      setGoogleAnalyticsIntegrationLoader(false);
    });
  }

  return (
    <>
    	<VisiLoader VisiLoaderVisible={googleAnalyticsIntegrationLoader} />
      <Group position="apart" mb="xs">
        <Text weight={500}>GOOGLE ANALYTICS INTEGRATION <IconHelp size="1.1rem" color="red" style={{marginBottom:-3}}/></Text>
      </Group>
      <Group spacing="xs">
        <Text size="sm" fz={14} fw={500} maw={150} mb={10}>Select Site:</Text>
        <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange} SelectMemberWebsiteListWidth={250} SelectMemberWebsiteListUsedIn="settings" SelectMemberWebsiteListMaxDropdownHeight={300} SelectMemberWebsiteListdropdownPosition="bottom" SelectMemberWebsiteListdropdownPositionMB={10} SelectMemberWebsiteListML={4}/>
	    </Group>
	    <Group spacing="xs">
     		<Text size="sm" fz={14} fw={500} maw={150} mb={10}>Tracking JS:</Text>
	     	<Select
	     		w={250}
	     		value={gaDimension}
	     		onChange={setGaDimension}
	     	  data={[
	     	    { value: 'gtag', label: 'gtag.js' },
	     	    { value: 'analytics', label: 'analytics.js' },
	     	  ]}
	     	  dropdownPosition="bottom"
	     	  mb={10}
	     	/>
	    </Group>
	    <Group spacing="xs" mb={10}>
	    	<Text size="sm" fz={14} fw={500} maw={150} mb={10}>Tracking ID:</Text>
	      <Input w={250} ml={0} value={gaAccountPrefix} onChange={(e) => setGaAccountPrefix(e.target.value)}/>
	    </Group>
	    <div style={{ display: 'flex'}}>
	    	<Text fw={500}>Note:</Text>
	    	<Text ml={5}>Make sure you have added Google Tracking JS to your page.</Text>
	    </div>
      <Button type="button" onClick={submitGAI} size="sm" w={150} mt={10} mb={100}>
        Save Settings
      </Button>
    </>
  );
}