import React from 'react';
import { useAuth } from './AuthContext';

const PermissionWrapper = ({ permission, children }) => {
  const { hasPermission } = useAuth();

  if (!hasPermission(permission)) {
    return null;
  }

  return <>{children}</>;
};

export default PermissionWrapper;