import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import {
  createStyles,
  Header,
  Group,
  Button,
  UnstyledButton,
  Text,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  Menu,
  ActionIcon,
  useMantineColorScheme,
  Anchor,
  Popover,
  Flex,
  ColorSwatch,
  Grid,
  Modal
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconChevronDown,
  IconUser,
  IconCurrencyDollar,
  IconHelp,
  IconMessage2Share,
  IconLogout,
  IconSun,
  IconMoon,
  IconBell
} from "@tabler/icons-react";
import visioptLogo from '../../img/visiopt-logo-small.png';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { setVisiUserSession } from '../../GeneralFunction';
import { useNavigate } from "react-router-dom";
import { SelectMemberWebsiteList } from "../GeneralComponent/GeneralComponent";
import Cookies from 'js-cookie';
import PermissionWrapper from '../../PermissionWrapper';
import GeneralComponentApi from "../../api/GeneralComponentApi";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none !important",
    // color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: 42,
      display: "flex",
      alignItems: "center",
      width: "100%"
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.gray[8]
          : theme.colors.gray[0],
      height: "50%",
      borderRadius: "5px"
    })
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none"
    }
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none"
    }
  },

  activeLink: {
    backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.gray[8]
          : theme.colors.gray[0],
    height: "50%",
    borderRadius: "5px"
  }
}));

export function HeaderMenu({ HeaderMenuValue, HeaderMenuOnChange }:any) {

  const [selectedMemberWebsite, setSelectedMemberWebsite] = useState('');

  const handleSelectMemberWebsiteListComponentChange = (HeaderMenuValue:any) => {
    setSelectedMemberWebsite(HeaderMenuValue);
    HeaderMenuOnChange(HeaderMenuValue);
  };

  const navigate = useNavigate();

  const location = useLocation().pathname;

  setVisiUserSession('page', location);
  const [ColorScheme, setColorScheme] = useState(null);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";

  useEffect(() => {
    localStorage.setItem('visicolorscheme', colorScheme);
  }, [colorScheme]);

  useEffect(() => {
    const visicolorscheme = localStorage.getItem('visicolorscheme');
    if (visicolorscheme) {
      setColorScheme(visicolorscheme);
    }
  }, [colorScheme]);

  const [
    drawerOpened,
    { toggle: toggleDrawer, close: closeDrawer }
  ] = useDisclosure(false);
  const [TestslinksOpened, { toggle: toggleTestsLinks }] = useDisclosure(false);
  const [CampaignslinksOpened, { toggle: toggleCampaignsLinks }] = useDisclosure(false);
  const [InsightslinksOpened, { toggle: toggleInsightsLinks }] = useDisclosure(false);
  const { classes, theme } = useStyles();

  const [NotificationPopOverOpened, setNotificationPopOverOpened] = useState(false);

  const [MemberNotificationList, setMemberNotificationList] = useState([]);

  const [MemberAllNotificationList, setMemberAllNotificationList] = useState([]);
  const [memberShortName, setMemberShortName] = useState(null);
  useEffect(() => {
    if(NotificationPopOverOpened){
      GeneralComponentApi.getmembernotificationlist({
        notification_limit : 3
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/login");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setMemberNotificationList(data.data.notification_list);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [NotificationPopOverOpened]);

  const [isSeeAllMemberNotificationsOpen, setIsSeeAllMemberNotificationsOpen] = useState(false);

  const handleSeeAllMemberNotificationsModal = () => {
    setIsSeeAllMemberNotificationsOpen(false);
  };

  const SeeAllMemberNotifications = () => {
    setNotificationPopOverOpened(false);
    setIsSeeAllMemberNotificationsOpen(true);
  };

  useEffect(() => {
    if(isSeeAllMemberNotificationsOpen){
      GeneralComponentApi.getmembernotificationlist()
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/login");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setMemberAllNotificationList(data.data.notification_list);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [isSeeAllMemberNotificationsOpen]);

  useEffect(() => {
    const userDataString = localStorage.getItem('visiUserData');
    const userData = JSON.parse(userDataString);
    if (userData) {
      setMemberShortName(userData.member_short_name);
    }
  }, []);
  return (
    <>
    <Helmet>
      {theme.colorScheme === "dark"?'':<style>{`body {background-color: #f8f9fb;}`}</style>}
    </Helmet>
      <Modal.Root size={1100} opened={isSeeAllMemberNotificationsOpen} onClose={handleSeeAllMemberNotificationsModal}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title><Text weight={500}>NOTIFICATION <IconHelp size="1.1rem" color="red"/></Text></Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            {MemberAllNotificationList.map(Notification => (
              <>
                <Text weight={500} fz="sm">{Notification.title}</Text>
                <Text dangerouslySetInnerHTML={{ __html: Notification.description }} fz="sm"></Text>
                <Flex
                  justify="flex-end"
                >
                  <Text fz="sm">{Notification.date}</Text>
                </Flex>
                <Divider my={10} />
              </>
            ))}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      <Box pb={10}>
        <Header height={50} px="md">
          <Group position="apart" sx={{ height: "100%" }}>
            <Group
              sx={{ height: "100%" }}
              spacing={5}
              className={classes.hiddenMobile}
            >
              <img src={visioptLogo } alt="Company Logo"/>
              <Link to="/dashboard" style={{color: dark ? "white" : "black"}} className={`${classes.link} ${location === "/dashboard" ? classes.activeLink : ""}`}>Dashboard</Link>
              <Link to="/funnels" style={{color: dark ? "white" : "black"}} className={`${classes.link} ${location === "/funnels" ? classes.activeLink : ""}`}>Funnels</Link>
              <Menu shadow="md" width={200} trigger="hover">
                <Menu.Target>
                  <Button variant="transparent" color="dark">Tests<IconChevronDown size="1rem"/></Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item><Link to="/review-tests" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Review Tests</Link></Menu.Item>
                  <Menu.Item><Link to="/create-test" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Create Test</Link></Menu.Item>
                  <Menu.Item><Link to="/test-history" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Test History</Link></Menu.Item>
                </Menu.Dropdown>
              </Menu>
              <PermissionWrapper permission="campaign_tab_access">
                <Menu shadow="md" width={200} trigger="hover">
                  <Menu.Target>
                    <Button variant="transparent" color="dark">Campaigns<IconChevronDown size="1rem"/></Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item><Link to="/manage-campaigns" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Manage Campaigns</Link></Menu.Item>
                    <Menu.Item><Link to="/reports" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Reports</Link></Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </PermissionWrapper>
              <Menu shadow="md" width={200} trigger="hover">
                <Menu.Target>
                  <Button variant="transparent" color="dark">Insights<IconChevronDown size="1rem"/></Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <PermissionWrapper permission="heat_maps_access">
                    <Menu.Item><Link to="/heat-maps" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Heat Maps</Link></Menu.Item>
                  </PermissionWrapper>
                  <PermissionWrapper permission="scroll_maps_access">
                    <Menu.Item><Link to="/scroll-maps" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Scroll Maps</Link></Menu.Item>
                  </PermissionWrapper>
                  <PermissionWrapper permission="recordings_access">
                    <Menu.Item><Link to="/recordings" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Recordings</Link></Menu.Item>
                  </PermissionWrapper>
                </Menu.Dropdown>
              </Menu>
              <PermissionWrapper permission="operational_access">
                <Link to="/landing-pages" style={{color: dark ? "white" : "black"}} className={`${classes.link} ${location === "/landing-pages" ? classes.activeLink : ""}`}>Landing Pages</Link>
                <Link to="/settings" style={{color: dark ? "white" : "black"}} className={`${classes.link} ${location === "/settings" ? classes.activeLink : ""}`}>Settings</Link>
              </PermissionWrapper>
            </Group>
            <Group
              sx={{ height: "100%" }}
              spacing={10}
              className={classes.hiddenMobile}
            >
            <SelectMemberWebsiteList SelectMemberWebsiteListValue={selectedMemberWebsite} SelectMemberWebsiteListOnChange={handleSelectMemberWebsiteListComponentChange} SelectMemberWebsiteListUsedIn="HeaderMenu"/>
            <Popover opened={NotificationPopOverOpened} onChange={setNotificationPopOverOpened} width={450} position="bottom" withArrow shadow="md">
              <Popover.Target>
                <ActionIcon
                  variant="default"
                  size="lg"
                  color={dark ? 'yellow' : 'blue'}
                  onClick={() => setNotificationPopOverOpened((o) => !o)}
                  title="Notification"
                >
                  <IconBell size="1.1rem" />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <Text weight={500}>Notifications</Text>
                <Divider my={10} />
                {MemberNotificationList.map(Notification => (
                  <>
                  <Grid>
                    <Grid.Col span={11}>
                      <Text weight={500} fz="sm">{Notification.title}</Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <Flex
                        justify="flex-end"
                      >
                        {Notification.status === 0 && <ColorSwatch color="#a1a1a1" w={17} h={17} />}
                        {Notification.status === 1 && <ColorSwatch color="#ff0000" w={17} h={17} />}
                      </Flex>
                    </Grid.Col>
                  </Grid>
                    <Text dangerouslySetInnerHTML={{ __html: Notification.description }} fz="sm"></Text>
                    <Flex
                      justify="flex-end"
                    >
                      <Text fz="sm">{Notification.date}</Text>
                    </Flex>
                    <Divider my={10} />
                  </>
                ))}
                <Flex
                  justify="center"
                >
                <Anchor weight={500} fz="sm" onClick={SeeAllMemberNotifications}>See All</Anchor>
                </Flex>
              </Popover.Dropdown>
            </Popover>
            <ActionIcon
              variant="default"
              size="lg"
              color={dark ? 'yellow' : 'blue'}
              onClick={() => toggleColorScheme()}
              title="Toggle color scheme"
            >
              {dark ? <IconSun size="1.1rem" /> : <IconMoon size="1.1rem" />}
            </ActionIcon>
            <Menu shadow="md" trigger="hover">
              <Menu.Target>
                <Button variant="default" color="dark">{memberShortName}<IconChevronDown size="0.9rem" stroke={1.5} /></Button>
              </Menu.Target>
              <Menu.Dropdown>
                <PermissionWrapper permission="operational_access">
                  <Menu.Item icon={<IconUser size={14} />}><Link to="/settings" state={{ from: 'my_account' }} style={{color: dark ? "white" : "black",textDecoration: "none"}}>My Account</Link></Menu.Item>
                </PermissionWrapper>
                <PermissionWrapper permission="billing_portal_access,operational_access">
                  <Menu.Item icon={<IconCurrencyDollar size={14} />}><Link to="/billing-details" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Billing Details</Link></Menu.Item>
                </PermissionWrapper>
                <Menu.Item icon={<IconHelp size={14} />} component="a" href="https://visiopt.com/help-videos.html" target="_blank">Help</Menu.Item>
                <PermissionWrapper permission="operational_access">
                  <Menu.Item icon={<IconMessage2Share size={14} />}><Link to="/send-feedback" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Send Feedback</Link></Menu.Item>
                </PermissionWrapper>
                <Menu.Item icon={<IconLogout size={14} />}><Link to="/logout" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Logout</Link></Menu.Item>
              </Menu.Dropdown>
            </Menu>
            </Group>

            <Burger
              opened={drawerOpened}
              onClick={toggleDrawer}
              className={classes.hiddenDesktop}
            />
          </Group>
        </Header>

        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Navigation"
          className={classes.hiddenDesktop}
          zIndex={1000000}
        >
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <UnstyledButton className={classes.link}>
            <Text className={classes.link}>
              <Link to="/dashboard" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Dashboard</Link>
            </Text>
          </UnstyledButton>
          <UnstyledButton className={classes.link}>
            <Text className={classes.link}>
              <Link to="/funnels" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Funnels</Link>
            </Text>
          </UnstyledButton>
          <UnstyledButton className={classes.link} onClick={toggleTestsLinks}>
            <Center inline>
              <Box component="span" mr={5}>
                Tests
              </Box>
              <IconChevronDown size={16} color={theme.fn.primaryColor()} />
            </Center>
          </UnstyledButton>
          <Collapse in={TestslinksOpened}>
            <UnstyledButton className={classes.link}>
              <Text className={classes.link}>
                <Link to="/review-tests" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Review Tests</Link>
              </Text>
            </UnstyledButton>
            <UnstyledButton className={classes.link}>
              <Text className={classes.link}>
                <Link to="/create-test" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Create Test</Link>
              </Text>
            </UnstyledButton>
            <UnstyledButton className={classes.link}>
              <Text className={classes.link}>
                <Link to="/test-history" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Test History</Link>
              </Text>
            </UnstyledButton>
          </Collapse>
          <PermissionWrapper permission="campaign_tab_access">
            <UnstyledButton className={classes.link} onClick={toggleCampaignsLinks}>
              <Center inline>
                <Box component="span" mr={5}>
                  Campaigns
                </Box>
                <IconChevronDown size={16} color={theme.fn.primaryColor()} />
              </Center>
            </UnstyledButton>
            <Collapse in={CampaignslinksOpened}>
              <UnstyledButton className={classes.link}>
                <Text className={classes.link}>
                  <Link to="/manage-campaigns" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Manage Campaigns</Link>
                </Text>
              </UnstyledButton>
              <UnstyledButton className={classes.link}>
                <Text className={classes.link}>
                  <Link to="/reports" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Reports</Link>
                </Text>
              </UnstyledButton>
            </Collapse>
          </PermissionWrapper>
          <UnstyledButton className={classes.link} onClick={toggleInsightsLinks}>
            <Center inline>
              <Box component="span" mr={5}>
                Insights
              </Box>
              <IconChevronDown size={16} color={theme.fn.primaryColor()} />
            </Center>
          </UnstyledButton>
          <Collapse in={InsightslinksOpened}>
            <PermissionWrapper permission="heat_maps_access">
              <UnstyledButton className={classes.link}>
                <Text className={classes.link}>
                  <Link to="/heat-maps" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Heat Maps</Link>
                </Text>
              </UnstyledButton>
            </PermissionWrapper>
            <PermissionWrapper permission="scroll_maps_access">
              <UnstyledButton className={classes.link}>
                <Text className={classes.link}>
                  <Link to="/scroll-maps" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Scroll Maps</Link>
                </Text>
              </UnstyledButton>
            </PermissionWrapper>
            <PermissionWrapper permission="recordings_access">
              <UnstyledButton className={classes.link}>
                <Text className={classes.link}>
                  <Link to="/recordings" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Recordings</Link>
                </Text>
              </UnstyledButton>
            </PermissionWrapper>
          </Collapse>
          <PermissionWrapper permission="operational_access">
            <UnstyledButton className={classes.link}>
              <Text className={classes.link}>
                <Link to="/landing-pages" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Landing Pages</Link>
              </Text>
            </UnstyledButton>
            <UnstyledButton className={classes.link}>
              <Text className={classes.link}>
                <Link to="/settings" style={{color: dark ? "white" : "black",textDecoration: "none"}}>Settings</Link>
              </Text>
            </UnstyledButton>
          </PermissionWrapper>
        </Drawer>
      </Box>
    </>
  );
}