import {
  Select,
  Modal,
  Flex,
  Button,
  LoadingOverlay,
  Tooltip,
  Group, 
  Text,
  Anchor
} from "@mantine/core";
import { DatePickerInput } from '@mantine/dates';
import {
  IconChevronDown, IconHelp, IconX,
} from "@tabler/icons-react";
import { visiUserSession, setVisiUserSession } from '../../GeneralFunction';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import moment from 'moment';
import './GeneralComponent.css';
import GeneralComponentApi from "../../api/GeneralComponentApi";

export function SelectMemberWebsiteList({ SelectMemberWebsiteListValue, SelectMemberWebsiteListOnChange, SelectMemberWebsiteListWidth, SelectMemberWebsiteListUsedIn, SelectMemberWebsiteListMaxDropdownHeight, SelectMemberWebsiteListdropdownPosition, SelectMemberWebsiteListdropdownPositionMB, SelectMemberWebsiteListML  }:any) {

  const handleMemberWebsiteChange = (e:any) => {
    SelectMemberWebsiteListOnChange(e);
    if(SelectMemberWebsiteListUsedIn && SelectMemberWebsiteListUsedIn === 'HeaderMenu'){
      setVisiUserSession('website', e);
    }
  };

  const navigate = useNavigate();

  type Website = {
    website_id: string;
    website_name: string;
  }

  const [memberWebsiteList, setmemberWebsiteList] = useState<Website[]>([]);

  useEffect(() => {
    GeneralComponentApi.getmemberwebsitelist()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        setmemberWebsiteList(data.data);
        if(data.data.length > 0){
          if(visiUserSession() && visiUserSession().website && visiUserSession().website !== ''){
            SelectMemberWebsiteListOnChange(visiUserSession().website);
          }else{
            SelectMemberWebsiteListOnChange(data.data[0].website_id);
          }
        }
      }
    })
    .catch(error => {
      console.log(error);
    });

  }, []);

  return (
    <>
      <Select
        rightSection={<IconChevronDown size="1rem" />}
        rightSectionWidth={30}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        data={memberWebsiteList.map(website => ({
            value: website.website_id,
            label: website.website_name
        }))}
        value={SelectMemberWebsiteListValue}
        onChange={handleMemberWebsiteChange}
        w={SelectMemberWebsiteListWidth}
        searchable
        maxDropdownHeight={SelectMemberWebsiteListMaxDropdownHeight}
        dropdownPosition={SelectMemberWebsiteListdropdownPosition}
        mb={SelectMemberWebsiteListdropdownPositionMB}
        ml={SelectMemberWebsiteListML}
      />
    </>
  );
}

export function SelectFilterType({ SelectFilterTypeValue, SelectFilterTypeOnChange }:any) {

  const handleFilterTypeChange = (e:any) => {
    SelectFilterTypeOnChange(e);
  };

  const [filterTypeList, setfilterTypeList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    GeneralComponentApi.getfiltertypelist()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        let filterTypeListData = [];
        for(const key in data.data[0]){
          filterTypeListData.push({
            value: key, label: data.data[0][key]
          });
        }
        setfilterTypeList(filterTypeListData);
        if(data.data.length > 0){
          const filterTypeListKeys = Object.keys(data.data[0]);
          SelectFilterTypeOnChange(filterTypeListKeys[0]);
        }
      }
    })
    .catch(error => {
      console.log(error);
    });

  }, []);

  return (
    <>
      <Select
        data={filterTypeList}
        value={SelectFilterTypeValue}
        onChange={handleFilterTypeChange}
        searchable
      />
    </>
  );
}

export function SelectTimePicker({ SelectTimePickerValue, SelectTimePickerOnChange, SelectTimePickerFromDateOnChange, SelectTimePickerToDateOnChange, SelectTestStartDate }:any) {

  const [isTimeRangeModalOpen, setIsTimeRangeModalOpen] = useState(false);
  const [FromDatevalue, setFromDateValue] = useState<Date | null>(SelectTestStartDate);
  const [ToDatevalue, setToDateValue] = useState<Date | null>(null);

  const handleTimePickerChange = (e:any) => {
    SelectTimePickerOnChange(e);
    if(e === -2){
      const [year, month, day] = SelectTestStartDate.split("-");
      const formattedDate = new Date(`${year}-${month}-${day}`);
      const endformattedDate = new Date();
      setFromDateValue(formattedDate);
      setToDateValue(endformattedDate);
      setIsTimeRangeModalOpen(true);
    }else{
      setFromDateValue(null);
      setToDateValue(null);
      SelectTimePickerFromDateOnChange("");
      SelectTimePickerToDateOnChange("");
    }
  };

  const [TimePickerList, setTimePickerList] = useState([]);

  const navigate = useNavigate();
  let pagename = visiUserSession().page.replace("/","");
  useEffect(() => {
    GeneralComponentApi.gettimepicker({
      type:pagename
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/logout");
        }
      }
    })
    .then(data => {
      if(data.status === 1){
        let TimePickerListData = [];
        for(const key in data.data[0]){
          TimePickerListData.push({
            value: data.data[0][key]['option_value'], label: data.data[0][key]['option_name']
          });
        }
        setTimePickerList(TimePickerListData);
        if(data.data.length > 0){
          if(pagename === "dashboard"){
            SelectTimePickerOnChange(-3);
          }else{
            SelectTimePickerOnChange(-1);
          }
        }
      }
    })
    .catch(error => {
      console.log(error);
    });

  }, []);

  const handleTimeRangeCloseModal = () => {
    setIsTimeRangeModalOpen(false);
  };
  const currentDate = moment();
  const handleDateFilterSubmit = (event:any) => {
    event.preventDefault();
    const currentDate = moment();
    const FromDate = (FromDatevalue) ? moment(FromDatevalue).format("DD-MM-YYYY") : moment(currentDate).format("DD-MM-YYYY");
    const ToDate = (ToDatevalue) ? moment(ToDatevalue).format("DD-MM-YYYY") : moment(currentDate).format("DD-MM-YYYY");
    if(SelectTimePickerValue === -2){
      SelectTimePickerFromDateOnChange(FromDate);
      SelectTimePickerToDateOnChange(ToDate);
    }else{
      SelectTimePickerFromDateOnChange("");
      SelectTimePickerToDateOnChange("");
    }
    setIsTimeRangeModalOpen(false);
  };

  return (
    <>
      <Modal opened={isTimeRangeModalOpen} onClose={handleTimeRangeCloseModal} title="TIME RANGE FILTER" centered>
        <form onSubmit={handleDateFilterSubmit}>
          <DatePickerInput
            mb={10}
            popoverProps={{ withinPortal: true }}
            valueFormat="DD-MM-YYYY"
            label="From Date"
            name="from_date"
            value={FromDatevalue}
            onChange={setFromDateValue}
            maxDate={pagename === "dashboard" ? undefined : currentDate.toDate()}
            mx="auto"
            maw={400}
          />
          <DatePickerInput
            mb={10}
            popoverProps={{ withinPortal: true }}
            valueFormat="DD-MM-YYYY"
            label="To Date"
            name="to_date"
            value={ToDatevalue}
            onChange={setToDateValue}
            maxDate={pagename === "dashboard" ? undefined : currentDate.toDate()}
            mx="auto"
            maw={400}
          />
          <Flex
            justify="flex-end"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Button type="submit" color="green">Filter</Button>
          </Flex>
        </form>
      </Modal>
      <Select
        onChange={handleTimePickerChange}
        value={SelectTimePickerValue}
        data={TimePickerList}
      />
    </>
  );
}

export function SelectWebsitePages({ SelectWebsitePagesValue, SelectWebsitePagesOnChange, SelectWebsitePagesSelectedMemberWebsite }:any) {

  const handleWebsitePagesChange = (e:any) => {
    SelectWebsitePagesOnChange(e);
  };

  const navigate = useNavigate();

  type Page = {
    page_id: string;
    custom_title: string;
  }

  const [WebsitePages, setWebsitePages] = useState<Page[]>([]);

  useEffect(() => {
    if(SelectWebsitePagesSelectedMemberWebsite !== ''){
      GeneralComponentApi.getwebsitepages({
        system_website_id: SelectWebsitePagesSelectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setWebsitePages(data.data);
          const allPagesOption = data.data.find((page: Page) => page.custom_title === "All pages");
          if(allPagesOption){
            SelectWebsitePagesOnChange(allPagesOption.page_id);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [SelectWebsitePagesSelectedMemberWebsite]);

  return (
    <>
      <Select
        data={WebsitePages.map(Page => ({
          value: Page.page_id,
          label: Page.custom_title
        }))}
        value={SelectWebsitePagesValue}
        onChange={handleWebsitePagesChange}
        searchable
      />
    </>
  );
}

export function VisiLoader({ VisiLoaderVisible }:any) {

  return (
    <>
      <LoadingOverlay zIndex={9999999999} visible={VisiLoaderVisible} overlayBlur={2} loaderProps={{variant:"bars", color:"gray"}} style={{width:'100%', height:'100%', display:'flex', position:'fixed'}} />
    </>
  );
}

export function SelectActiveTest({ SelectActiveTestValue, SelectActiveTestOnChange, SelectActiveTestSelectedMemberWebsite, setActiveTests }:any) {

  const handleActiveTestChange = (e:any) => {
    SelectActiveTestOnChange(e);
  };

  const navigate = useNavigate();

  type Test = {
    id: string;
    value: string;
  }

  const [ActiveTest, setActiveTest] = useState<Test[]>([]);

  useEffect(() => {
    if(SelectActiveTestSelectedMemberWebsite !== ''){
      GeneralComponentApi.getdashboardactivetestdropdown({
        system_website_id: SelectActiveTestSelectedMemberWebsite
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setActiveTest(data.data);
          setActiveTests(data.data);
          if(data.data.length > 0){
            SelectActiveTestOnChange(data.data[0].id);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [SelectActiveTestSelectedMemberWebsite]);

  return (
    <>
      <Select
        data={ActiveTest.map(Test => ({
          value: Test.id,
          label: Test.value
        }))}
        value={SelectActiveTestValue}
        onChange={handleActiveTestChange}
        searchable
      />
    </>
  );
}

export function SelectGetGoal({ SelectGetGoalValue, SelectGetGoalOnChange, SelectGetGoalSelectedMemberWebsite, SelectGetGoalSelectedActiveTestPage, SelectGetGoalSelectedGoalType }:any) {

  const handleGetGoalChange = (e:any) => {
    SelectGetGoalOnChange(e);
  };

  const navigate = useNavigate();

  type Goal = {
    id: string;
    value: string;
  }

  const [Goal, setGoal] = useState<Goal[]>([]);

  useEffect(() => {
    if(SelectGetGoalSelectedMemberWebsite !== '' && SelectGetGoalSelectedActiveTestPage !== '' && SelectGetGoalSelectedGoalType){
      GeneralComponentApi.getgoal({
        system_website_id: SelectGetGoalSelectedMemberWebsite,
        active_test_page_id: SelectGetGoalSelectedActiveTestPage,
        goal_type: SelectGetGoalSelectedGoalType,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          setGoal(data.data);
          if(data.data.length > 0){
            SelectGetGoalOnChange(data.data[0].id);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [SelectGetGoalSelectedMemberWebsite, SelectGetGoalSelectedActiveTestPage, SelectGetGoalSelectedGoalType]);

  return (
    <>
      <Select
        data={Goal.map(Goal => ({
          value: Goal.id,
          label: Goal.value
        }))}
        value={SelectGetGoalValue}
        onChange={handleGetGoalChange}
        searchable
      />
    </>
  );
}

export function BoxPageThumb({ BoxPageThumbSelectedMemberWebsite, BoxPageThumbSelectedActiveTestPage }:any) {

  const navigate = useNavigate();

  const [ThumbTitle, setThumbTitle] = useState('');
  const [ThumbCustomTitle, setThumbCustomTitle] = useState('');
  const [Thumb, setThumb] = useState('');
  const [ThumbURL, setThumbURL] = useState('');

  useEffect(() => {
    if(BoxPageThumbSelectedMemberWebsite !== '' && BoxPageThumbSelectedActiveTestPage !== ''){
      GeneralComponentApi.getpagethumb({
        system_website_id: BoxPageThumbSelectedMemberWebsite,
        active_test_page_id: BoxPageThumbSelectedActiveTestPage
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })
      .then(data => {
        if(data.status === 1){
          if(data){
            if(data.data.title){
              setThumbTitle(data.data.title);
            }
            if(data.data.custom_title){
              setThumbCustomTitle(data.data.custom_title);
            }
            if(data.data.thumb){
              setThumb(data.data.thumb);
            }
            if(data.url){
              setThumbURL(data.data.url);
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [BoxPageThumbSelectedMemberWebsite, BoxPageThumbSelectedActiveTestPage]);
  return (
    <>
      <div className="visi-page">
        <div className="page">
          <a href="">
            <span className="page-url">{ThumbTitle}</span>
          </a>
          <div className="thumbnail" style={{backgroundImage : `url(${Thumb})`}}>
          </div>
          <span className="page-title">{ThumbCustomTitle}</span>
        </div>
      </div>
    </>
  );
}

export function SelectFunnelList({updated_time, Value, OnChange, MemberWebsiteId, Width, IsSelected = true }:any) {

  const handleFunnelListChange = (e:any) => {
    OnChange(e);
  };

  const navigate = useNavigate();

  type Funnel = {
    funnel_id: string;
    funnel_name: string;
  }

  const [funnelList, setfunnelList] = useState<Funnel[]>([]);

  useEffect(() => {
    if(MemberWebsiteId !== ''){
      GeneralComponentApi.getfunnellist({
          system_website_id: MemberWebsiteId
        })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401 || response.status === 400) {
            navigate("/logout");
          }
        }
      })

      .then(data => {
        if(data.status === 1){
          setfunnelList(data.data);
          if(data.data.length > 0){
            if(IsSelected){
              var i;
              for(i = 0; i <data.data.length; i++){
                if(data.data[i].selected === 'true'){
                  OnChange(data.data[i].funnel_id);
                  break;
                }
              }
            }else{
              OnChange('');
            }
          }else{
            OnChange('');
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [MemberWebsiteId,updated_time]);
  return (
    <>
      <Select
        data={funnelList.map(funnel => ({
          value: funnel.funnel_id ? funnel.funnel_id : '',
          label: funnel.funnel_name ? funnel.funnel_name : ''
        }))}
        value={Value}
        onChange={handleFunnelListChange}
        w={Width}
        searchable
        placeholder="Select a funnel"
      />
    </>
  );
}

export function SelectTooltip({Value}:any) {
  const [tooltipOpened, setTooltipOpened] = useState(false);

  const handleIconClick = () => {
    setTooltipOpened(true);
  };

  const handlecloseIconClick = () => {
    setTooltipOpened(false);
  };

  return (
    <>
      <Tooltip
        w={470}
        h={240}
        style={{ backgroundColor:'#d04e4c'}}
        ml={75}
        label={
          <div>
            <Group position="apart">
              <Text size="lg">Tracking</Text>
              <IconX size="1.1rem" style={{ color: 'black', cursor: 'pointer' }} onClick={handlecloseIconClick} />
            </Group>
            <Text mt="sm" style={{margin: 'auto',lineHeight: '20px', whiteSpace: 'normal'}}>
              This section provides information about all the pages you are currently tracking. You can view the tracking information in different ways. You have the Page Tracking Data, Links tracking, and Sources, as seen below. It is also possible to create custom funnels so that you can view tracking data of your visitors as they pass through different pages on your site.
            </Text>
            <div style={{ textAlign: 'center', marginTop: '16px' }}>
              <Button size="sm" style={{ backgroundColor: '#7d211f', width: '180px' }}>Watch Video Tutorial</Button>
            </div>
            <Anchor 
              href="https://yourhelplibrary.com" 
              target="_blank" 
              size="xs" 
              style={{ display: 'block', textAlign: 'center', textDecoration: 'underline', color: 'black', marginTop: '8px' }}
            >
              Click Here To Reach Our Help Library
            </Anchor>
          </div>
        }
        opened={tooltipOpened}
      >
        <IconHelp 
          size="1.1rem" 
          color="red" 
          style={{ marginBottom: -3, cursor: 'pointer' }} 
          onClick={handleIconClick}
        />
      </Tooltip>
    </>
  );
}