import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import LoginLogoutApi from "../../api/LoginLogoutApi";

export function Logout() {

  const navigate = useNavigate();

  useEffect(() => {

    LoginLogoutApi.logout()
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        if (response.status === 401 || response.status === 400) {
          navigate("/login");
        }
      }
    })
    .then(data => {
      localStorage.removeItem('visiMemberData');
      localStorage.removeItem('visiUserData');
      navigate("/login");
    })
    .catch(error => {
      console.error('Error:', error);
    });

  }, []);

  return (
    <>
    </>
  );
}