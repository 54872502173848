import { ServerAPI } from '../GeneralFunction';

export default {
	deletecampaign: (data) => {
		let url = "delete_campaign";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaignlist: (data) => {
		let url = "get_campaign_list";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	checkexistingcampaignpage: (data) => {
		let url = "check_existing_campaign_page";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getconnectshoppingcartandprocessor: (data) => {
		let url = "get_connect_shopping_cart_and_processor";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	checkexistingcampaign: (data) => {
		let url = "check_existing_campaign";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaignpagebyfunnelid: (data) => {
		let url = "get_campaign_page_by_funnel_id";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	addcampaignstepfirst: (data) => {
		let url = "add_campaign_step_first";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaigndetail: (data) => {
		let url = "get_campaign_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoalsdropdown: (data) => {
		let url = "get_goals_dropdown";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getconversiondata: (data) => {
		let url = "get_conversion_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getfbpixeldetails: (data) => {
		let url = "get_fb_pixel_details";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoogleconvadcustomer: (data) => {
		let url = "get_google_conv_ad_customer";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoogleconvadsubcustomer: (data) => {
		let url = "get_google_conv_ad_subcustomer";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoogleconversiondata: (data) => {
		let url = "get_google_conversion_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getgoogleconversionpagedetail: (data) => {
		let url = "get_google_conversion_page_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	taboolaconvaduserdata: (data) => {
		let url = "taboola_conv_ad_user_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gettaboolaconversionpagedetail: (data) => {
		let url = "get_taboola_conversion_page_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	gettaboolaconversiondata: (data) => {
		let url = "get_taboola_conversion_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savecampaigndetail: (data) => {
		let url = "save_campaign_detail";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	saveconversiondata: (data) => {
		let url = "save_conversion_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	validatedeletecampaignpaymentprocessorcart: (data) => {
		let url = "validate_delete_campaign_payment_processor_cart";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	getcampaigntypeaduserdata: (data) => {
		let url = "get_campaign_type_ad_user_data";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	addcampaignpaymentprocessorcart: (data) => {
		let url = "add_campaign_payment_processor_cart";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	},
	savegoogleadaccountcredentials: (data) => {
		let url = "save_google_ad_account_credentials";
		const requestOptions = ServerAPI(url, 'POST', data);
		return fetch(process.env.REACT_APP_BACKEND_API_BASE_URL+url, requestOptions);
	}
};