// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashBoard_wrapper__I2hdD {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
}

.DashBoard_title__ThRvJ {
  margin-bottom: var(--mantine-spacing-md);
  padding-left: var(--mantine-spacing-md);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
}

.DashBoard_item__vKO3t {
  font-size: var(--mantine-font-size-sm);
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
}`, "",{"version":3,"sources":["webpack://./src/pages/DashBoard/DashBoard.module.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,mDAAmD;AACrD;;AAEA;EACE,wCAAwC;EACxC,uCAAuC;EACvC,yEAAyE;EACzE;;8BAE4B;AAC9B;;AAEA;EACE,sCAAsC;EACtC,2EAA2E;AAC7E","sourcesContent":[".wrapper {\n  padding-top: calc(var(--mantine-spacing-xl) * 2);\n  padding-bottom: calc(var(--mantine-spacing-xl) * 2);\n}\n\n.title {\n  margin-bottom: var(--mantine-spacing-md);\n  padding-left: var(--mantine-spacing-md);\n  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));\n  font-family:\n    Greycliff CF,\n    var(--mantine-font-family);\n}\n\n.item {\n  font-size: var(--mantine-font-size-sm);\n  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DashBoard_wrapper__I2hdD`,
	"title": `DashBoard_title__ThRvJ`,
	"item": `DashBoard_item__vKO3t`
};
export default ___CSS_LOADER_EXPORT___;
